<template>
    <Main>
        <ExecStepHeader :hak="order ? order.hak : null" :order="order" />
        <b-spinner v-if="!order" />
        <router-view v-else :order="order" />
    </Main>
</template>

<script>
import { mapActions } from "vuex";

import ExecStepHeader from "@/components/execution/ExecStepHeader";
import auftragsAPI from "@/services/api/auftrags.api";

export default {
    name: "Step_1",
    components: {
        ExecStepHeader,
    },
    data() {
        return {
            order: null,
        };
    },
    async mounted() {
        auftragsAPI.getSingle(this.$route.params.orderId).then((resp) => {
            this.order = resp.data;
        });
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
            setAuftragDetails: "execution/setAuftragDetails",
        }),
    },
};
</script>
