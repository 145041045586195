<template>
    <Box :isLoading="!hak">
        <div v-if="hak" class="font-weight-bold">
            <!-- First row: HAK -->
            <b-row>
                <b-col class="font-weight-normal">
                    <router-link
                        v-if="order"
                        :to="{ name: 'execution-single', params: { orderId: order.auftragID.toString() } }"
                        >{{ hak.tp }}</router-link
                    >
                    <span v-else>{{ hak.tp }}</span>
                </b-col>
                <b-col>{{ hak.address }}</b-col>
            </b-row>
            <!-- Second row: DevicePool -->
            <b-row v-if="devicePool">
                <b-col>{{ devicePool.address }}</b-col>
                <b-col>{{ devicePool.position }}</b-col>
                <b-col>{{ devicePool.positionDetail }}</b-col>
            </b-row>
            <!-- Third row: Device -->
            <b-row v-if="device">
                <b-col>{{ device.factoryNo }}</b-col>
                <b-col>{{ device.description }}</b-col>
            </b-row>
        </div>
    </Box>
</template>

<script>
export default {
    name: "ExecStepHeader",
    props: {
        order: {
            type: Object,
            default: null,
        },
        hak: {
            type: Object,
            default: null,
        },
        devicePool: {
            type: Object,
            default: null,
        },
        device: {
            type: Object,
            default: null,
        },
        enableLinks: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped></style>
