import AppVersion from "@/views/common/AppVersion";
import Callback from "@/views/common/Callback";
import Logout from "@/views/common/Logout";
import ReNotAuth from "@/views/common/ReNotAuth";
import SilentRefresh from "@/views/common/SilentRefresh";
import Env from "@/views/Env";
import LoggedOut from "@/views/common/LoggedOut.vue";

export default [
    {
        path: "/version",
        name: "appVersion",
        component: AppVersion,
        meta: { isPublic: true },
    },
    {
        path: "/env",
        name: "env",
        component: Env,
        meta: { isPublic: false },
    },
    {
        path: "/callback",
        name: "callback",
        component: Callback,
        meta: { isPublic: true },
    },
    {
        path: "/silent-refresh",
        name: "silentRefresh",
        component: SilentRefresh,
        meta: { isPublic: true },
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout,
        meta: { isPublic: true },
    },
    {
        path: "/unauthorized",
        name: "unauthorized",
        component: ReNotAuth,
        meta: { isPublic: true },
    },
    {
        path: "/logged-out",
        name: "loggedOut",
        component: LoggedOut,
        meta: { isPublic: true },
    },
];
