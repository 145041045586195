import { httpClient } from "@/services/api/client";

const endpoint = "/mdm/metering-codes";

export default {
    // **************
    // custom methods
    // **************
    get(params) {
        return httpClient.get(endpoint, {
            params: params,
        });
    },
};
