<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div v-if="decoratedMeterRegisters && decoratedMeterRegisters.length > 0">
                <!-- decoratedMeterRegister -->
                <div
                    v-for="(decoratedMeterRegister, index) in decoratedMeterRegisters"
                    :key="decoratedMeterRegister.meterRegisterID"
                    class="border-bottom"
                >
                    <b-row class="mt-2">
                        <b-col cols="3">{{ decoratedMeterRegister.obisCodeNoPrefix }}</b-col>
                        <b-col cols="9">
                            {{ decoratedMeterRegister.last.accountingType }}
                            <font-awesome-icon
                                v-if="
                                    decoratedMeterRegister.last.meteringType !== 'Blindstrom' &&
                                        decoratedMeterRegister.last.meterReturn
                                "
                                :icon="['fas', 'arrow-left']"
                                size="sm"
                            />
                            <font-awesome-icon
                                v-if="
                                    decoratedMeterRegister.last.meteringType !== 'Blindstrom' &&
                                        !decoratedMeterRegister.last.meterReturn
                                "
                                :icon="['fas', 'arrow-right']"
                                size="sm"
                            />
                            ({{ decoratedMeterRegister.last.unit }})
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4" />
                        <b-col cols="4" class="text-right">
                            {{ new Date() | formatDate(constants.DATE_FORMAT) }}
                        </b-col>
                        <b-col cols="4" class="text-right">
                            {{ decoratedMeterRegister.last.readingDate | formatDate(constants.DATE_FORMAT) }}
                        </b-col>
                    </b-row>
                    <b-row class="text-right d-flex align-items-center">
                        <!-- ReadValue -->
                        <b-col cols="4" class="text-left pr-0">Zählerstand</b-col>
                        <!-- current misured value or readingDate=TODAY -->
                        <b-col cols="4" class="text-right">
                            <b-form-input
                                v-model="decoratedMeterRegister.readValue"
                                type="number"
                                class="text-right"
                                @input="checkOutOfTolerance(decoratedMeterRegister.readValue, index)"
                            />
                        </b-col>
                        <b-col cols="4">
                            {{
                                printDecimal(
                                    decoratedMeterRegister.last.readValue,
                                    decoratedMeterRegister.last.decimalPlaces
                                )
                            }}
                        </b-col>
                    </b-row>
                    <b-row class="text-right mt-2">
                        <!-- Consumption -->
                        <b-col cols="8" class="text-left">Erwarteter Verbrauch:</b-col>
                        <b-col cols="4">
                            {{ printDecimal(decoratedMeterRegister.last.consumptionComparison) }}
                        </b-col>
                    </b-row>
                    <b-row class="text-right">
                        <!-- Consumption -->
                        <b-col cols="8" class="text-left mb-2">Aktueller Verbrauch:</b-col>
                        <b-col cols="4">
                            {{
                                printDecimal(
                                    currentConsumption(
                                        decoratedMeterRegister.readValue,
                                        decoratedMeterRegister.last.readValue
                                    )
                                )
                            }}
                        </b-col>
                    </b-row>
                    <!-- OUT OF TOLERANCE
                    Show "Ausserhalb Toleranz" with checkbox if read value is lower than <lowerLimite> or higher than <upperLimite>.
                    Going ahead should just be possible if checkbox is activated but this information does not have to be saved. -->
                    <div v-if="decoratedMeterRegister.showOutOfToleranceMessage">
                        <span class="text-danger">
                            <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                            Der erfasste Stand ist ausserhalb der Toleranz von
                            {{ parseInt(decoratedMeterRegister.last.lowerLimite) }} bis
                            {{ parseInt(decoratedMeterRegister.last.upperLimite) }}!
                        </span>
                        <b-checkbox v-model="decoratedMeterRegister.outOfToleranceCheckConfirmed" class="mb-1">
                            Der erfasste Stand ist korrekt.
                        </b-checkbox>
                        <PhotoUpload
                            :key="decoratedMeterRegister.obisCodeNoPrefix"
                            app="rellout"
                            entity="device"
                            :entityId="decoratedMeterRegister.last.deviceID"
                            refEntity="auftragDetail"
                            :refEntityId="actAuftragDetail.auftragDetailID"
                            :refEntityKey="decoratedMeterRegister.obisCodeNoPrefix"
                            folder="devices"
                            :fileDescription="
                                `Auftrag ${$route.params.orderId} - Device ${decoratedMeterRegister.last.deviceID} - Value ${decoratedMeterRegister.readValue} is out of the tolerance (${decoratedMeterRegister.last.lowerLimite},${decoratedMeterRegister.last.upperLimite}) for the OBIS: ${decoratedMeterRegister.obisCodeNoPrefix}`
                            "
                            :showImages="true"
                            :assetDelete="true"
                            :captureTypes="['camera']"
                            :isDisabled="false"
                        />
                    </div>
                </div>
                <!-- Common information -->
                <div class="mt-3">
                    <strong>Vorhergehende Zählerablesung</strong>
                    <ul>
                        <li>Datum: {{ readingDate }}</li>
                        <li v-if="readingType">Ableseart: {{ readingType }}</li>
                        <li v-if="readingReason">Ablesegrund: {{ readingReason }}</li>
                    </ul>
                </div>
            </div>
            <div v-else>
                Dieser Zähler hat kein zugehöriges Zählerregister
            </div>
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                :showAbbrechen="true"
                @click-abbrechen="onClickAbbrechen"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import constants from "@/constants/constants";
// ToDo: do this locally, remove import and delete @/utils/DateUtils (because nowhere used) -> useless component
import * as dateUtils from "@/utils/DateUtils";

import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import PhotoUpload from "@/components/general/PhotoUpload";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import meterRegistersAPI from "@/services/api/meterRegisters.api";

export default {
    name: "Step_7_1_3",
    components: {
        ExecSectionErledigt,
        PhotoUpload,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            constants: constants,
            decoratedMeterRegisters: [],
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            originalMeterRegisters: [],
            readingDate: null,
            readingReason: null,
            readingType: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        allOutOfToleranceConfirmed() {
            return this.decoratedMeterRegisters.every(
                (meterRegister, index) =>
                    !this.isOutOfTolerance(meterRegister.readValue, index) || meterRegister.outOfToleranceCheckConfirmed
            );
        },
        compIsFormFilled() {
            return this.decoratedMeterRegisters.every((decoratedMeterRegister) => decoratedMeterRegister.readValue);
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.compIsFormFilled) {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Zählerstand erfasst." });
            } else {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Zählerstand muss erfasst werden." });
            }

            if (this.allOutOfToleranceConfirmed) {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Zählerstand ist innerhalb der Toleranz.",
                });
            } else {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Zählerstand ist ausserhalb der Toleranz." });
            }

            return preconditionsLocalDetails;
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId
        );
        await this.setActAuftragDetail(auftragDetail);
        this.deviceID = auftragDetail.deviceID;
        // Dirk 05.10.2022 "AW: Auftrag 41 - reading values": only forAccounting=true meter register must be displayed
        const allMeterRegisters = (
            await meterRegistersAPI.get({ deviceID: this.deviceID, latestAccountingValuesOnly: true })
        ).data;
        // Starting from allMeterRegisters build another array: decoratedMeterRegisters which has
        // - 1 element foreach OBIS code
        // - current: contains the meterRegister with readingDate=TODAY (if any)
        // - last: contains the meterRegister with the most recent reading date (eventually null), except TODAY
        for (const meterRegister of allMeterRegisters) {
            // Initialize
            const obisNoPrefix = this.removeObisPrefix(meterRegister.obis); // AuftragExecution_Mobile: OBIS Without leading part to the ":"
            let decoratedMeterRegister = _.find(this.decoratedMeterRegisters, function(mrd) {
                if (mrd.obisCodeNoPrefix === obisNoPrefix) {
                    return true;
                }
            });
            if (decoratedMeterRegister == null) {
                this.decoratedMeterRegisters.push({
                    obisCode: meterRegister.obis,
                    obisCodeNoPrefix: obisNoPrefix,
                    readValue: null,
                    outOfTolerance: false,
                    outOfToleranceCheckConfirmed: false,
                    current: null,
                    last: null,
                    showOutOfToleranceMessage: false,
                });
            }
        }
        // Populate current (meterRegister with readingDate=TODAY) and last (meterRegister with readingDate=MAX, before today)
        for (const decoratedMeterRegister of this.decoratedMeterRegisters) {
            let meterRegistersSameObis = _.filter(allMeterRegisters, function(mr) {
                if (mr.obis === decoratedMeterRegister.obisCode) {
                    return true;
                }
            });
            _.orderBy(meterRegistersSameObis, ["readingDate"], ["desc"]);
            decoratedMeterRegister.current = _.find(meterRegistersSameObis, (mr) => {
                if (dateUtils.isToday(mr.readingDate)) {
                    decoratedMeterRegister.readValue = mr.readValue;
                    decoratedMeterRegister.isOutOfToleranceCorrect = mr.isOutOfToleranceCorrect;
                    return true; // return the meterRegister with readingDate=TODAY
                }
            });
            decoratedMeterRegister.last = _.find(meterRegistersSameObis, function(mr) {
                if (!dateUtils.isToday(mr.readingDate)) {
                    return true; // return the first meterRegister with readingDate != TODAY
                }
            });
            // Case that should never happen: this obis code has readingDate=TODAY only --> take this as current
            if (!decoratedMeterRegister.last && decoratedMeterRegister.current) {
                decoratedMeterRegister.last = _.clone(decoratedMeterRegister.current);
            }
            // AuftragExecution_Mobile: readingType & readingReason under all AccountingTypes of the first AccountingType in the xml.
            this.readingType = this.readingType ?? decoratedMeterRegister?.last?.readingType; // take the first not null value of the old meterRegister
            this.readingReason = this.readingReason ?? decoratedMeterRegister?.last?.readingReason;
            this.readingDate =
                this.readingDate ?? moment(decoratedMeterRegister?.last?.readingDate).format(constants.DATE_FORMAT);
        }
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        checkOutOfTolerance(readValue, index) {
            if (!this.debouncedFunctions) {
                this.debouncedFunctions = [];
            }
            if (!this.debouncedFunctions[index]) {
                this.debouncedFunctions[index] = _.debounce((readValue, index) => {
                    this.setOutOfTolerance(readValue, index);
                }, 800);
            }
            this.debouncedFunctions[index](readValue, index);
        },
        currentConsumption(currentValue, lastValue) {
            if (!currentValue || !lastValue) {
                return 0;
            }
            return currentValue - lastValue;
        },
        setOutOfTolerance(readValue, index) {
            const outOfTolerance = this.isOutOfTolerance(readValue, index);
            this.decoratedMeterRegisters[index].showOutOfToleranceMessage = outOfTolerance;
            this.decoratedMeterRegisters[index].outOfTolerance = outOfTolerance;
            if (!outOfTolerance) {
                this.decoratedMeterRegisters[index].outOfToleranceCheckConfirmed = false;
            }
        },
        isOutOfTolerance(readValue, index) {
            const meterRegister = this.decoratedMeterRegisters[index];
            if (
                !readValue ||
                isNaN(readValue) ||
                !meterRegister?.last ||
                !meterRegister?.last.lowerLimite ||
                !meterRegister?.last.upperLimite
            ) {
                return false;
            }
            return readValue < meterRegister.last.lowerLimite || readValue > meterRegister.last.upperLimite;
        },
        async onClickErledigt() {
            await this.save();
            const payloadAuftragDetail = [
                {
                    op: "replace",
                    path: "/status",
                    value: constants.auftragDetailStatus.DONE_CONDITION_1,
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail);
            // Update 7-1-1 status, since when 7-1-3 is done, 7-1-1 is skipped
            // ToDo(clarify): why not also set 7-1-2 to skipped?
            //  -> note: 7-1-1, 7-1-2 and 7-1-3 are steps in which at least one of them must be completed and the others should be set to skipped...
            auftragDetailsAPI
                .getByOrderIdAndStep(
                    this.$route.params.orderId,
                    "7-1-1",
                    this.$route.params.devicePoolId,
                    this.actAuftragDetail.deviceID
                )
                .then((auftragDetail711) => {
                    auftragDetailsAPI.patch(auftragDetail711.auftragDetailID, [
                        {
                            op: "replace",
                            path: "/status",
                            value: constants.auftragDetailStatus.SKIPPED, // 300 - step 7-1-1 skipped
                        },
                    ]);
                });
            // Update 7-1-4 status
            const auftragDetail714 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.$route.params.orderId,
                "7-1-4",
                this.$route.params.devicePoolId,
                this.actAuftragDetail.deviceID
            );
            if (auftragDetail714) {
                //console.log("auftragDetail714", auftragDetail714);
                await auftragDetailsAPI.patch(auftragDetail714.auftragDetailID, [
                    {
                        op: "replace",
                        path: "/status",
                        value: constants.auftragDetailStatus.DONE_CONDITION_2, // 203 - manual reading - 7-1-3
                    },
                ]);
                /*
                console.log(
                    `patchResponse for device ${auftragDetail714.auftragDetailID}, device ${this.actAuftragDetail.deviceID}`,
                    patchResponse
                );
                */
            } else {
                this.$bvToast.toast(
                    `Für den Auftrag ${this.$route.params.orderId} und DeviceID ${this.actAuftragDetail.deviceID} konnte kein Step 7-1-4 gefunden werden`,
                    {
                        title: "Fehler",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    }
                );
            }
            //this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            // go to ExecutionMeters instead
            await this.$router.push({
                name: "execution-meters",
                params: {
                    orderId: this.$route.params.orderId,
                    devicePoolId: this.$route.params.devicePoolId,
                    deviceId: this.deviceID,
                },
            });
        },
        onClickAbbrechen() {
            this.$router.push({
                name: "execution-meters",
                params: {
                    orderId: this.$route.params.orderId,
                    devicePoolId: this.$route.params.devicePoolId,
                    deviceId: this.deviceID,
                },
            });
        },
        printDecimal(number, decimalPlaces) {
            if (isNaN(number)) {
                return "Invalid number";
            }
            return new Intl.NumberFormat("de-CH", {
                minimumFractionDigits: decimalPlaces ?? 0,
                maximumFractionDigits: decimalPlaces ?? 0,
            }).format(number);
        },
        removeObisPrefix(obis) {
            if (!obis || !obis.includes(":")) {
                return obis;
            }
            // given 1-1:1-8-1 return 1-8-1
            return obis.split(":").pop();
        },
        async save() {
            for (const decoratedMeterRegister of this.decoratedMeterRegisters) {
                // no value inserted: skip
                if (!decoratedMeterRegister.readValue) {
                    continue;
                }
                // case TODAY: overwrite
                if (decoratedMeterRegister.current && decoratedMeterRegister.current.meterRegisterID) {
                    const payloadMetereRegister = [
                        {
                            op: "replace",
                            path: "/readValue",
                            value: decoratedMeterRegister.readValue,
                        },
                        {
                            op: "replace",
                            path: "/readingType",
                            value: constants.readingType.MANUAL, // The AbleseArt should be "Manuell am Ablesegerät" (hardcode 7), not "Manuell am PC". Mail Dirk "Step 7-1-3: Manuelle Zähler Ablesung "
                        },
                        {
                            op: "replace",
                            path: "/readingDate",
                            value: moment().format(constants.DATE_FORMAT_ISO),
                        },
                        {
                            op: "replace",
                            path: "/isOutOfToleranceCorrect",
                            value: decoratedMeterRegister.isOutOfToleranceCorrect,
                        },
                    ];
                    await meterRegistersAPI.patch(
                        decoratedMeterRegister.current.meterRegisterID,
                        payloadMetereRegister
                    );
                    //console.log("Update meter register", decoratedMeterRegister.current);
                } else {
                    // else insert NEW meterRegister by cloning last
                    const payloadMeterRegister = _.clone(decoratedMeterRegister.last);
                    payloadMeterRegister.readValue = decoratedMeterRegister.readValue;
                    payloadMeterRegister.isOutOfToleranceCorrect = decoratedMeterRegister.isOutOfToleranceCorrect;
                    payloadMeterRegister.readingType = constants.readingType.MANUAL;
                    payloadMeterRegister.readingDate = moment().format(constants.DATE_FORMAT_ISO);
                    payloadMeterRegister.readingReason = null; // Dirk 20.01.2023: remove readingReason when manual reading #14797
                    delete payloadMeterRegister.meterRegisterID;
                    delete payloadMeterRegister.creationName;
                    delete payloadMeterRegister.creationDate;
                    delete payloadMeterRegister.revisionName;
                    delete payloadMeterRegister.revisionName;
                    await meterRegistersAPI.post(payloadMeterRegister);
                    //console.log("Insert meter register", payloadMeterRegister);
                }
            }
        },
    },
};
</script>
