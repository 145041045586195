<template>
    <b-table
        :items="orderedAuftragDetails"
        :fields="computedMainTableFields"
        :tbody-tr-class="rowClass"
        @row-clicked="
            (orderedAuftragDetails) => $set(orderedAuftragDetails, '_showDetails', !orderedAuftragDetails._showDetails)
        "
    >
        <template #cell(status)="data">
            <div class="float-left">{{ getDataStatusKey(data.item.data) }}</div>
        </template>
        <template #cell(accordionStatusIndication)="data">
            <span>
                <font-awesome-icon :icon="data.item._showDetails ? 'chevron-up' : 'chevron-down'" />
            </span>
        </template>

        <template #row-details="row">
            <b-table-lite
                :items="row.item.data"
                :fields="computedRowDetailsFields"
                :tbody-tr-class="rowDetailsClass"
                head-variant="light"
            >
                >
                <template #head(auftragDetailsID)="data">
                    <span class="float-left">{{ data.label }}</span>
                </template>
                <template #cell(auftragDetailsID)="data">
                    <div class="float-left">
                        {{ data.item.auftragDetailID }}
                    </div>
                </template>
                <template #head(detailInfomations)="data">
                    <span class="float-left">{{ data.label }}</span>
                </template>
                <template #cell(detailInfomations)="data">
                    <OrderDetailsRowTable :id="getId(data.item)" :type="getType(data.item)"></OrderDetailsRowTable>
                </template>
                <template #head(status)="data">
                    <span class="float-right">{{ data.label }}</span>
                </template>
                <template #cell(status)="data">
                    <div class="float-right">
                        <status-badge :statusCode="data.value" />
                    </div>
                </template>
                <template #head(executionTime)="data">
                    <span class="float-left">{{ data.label }}</span>
                </template>
                <template #cell(executionTime)="data">
                    <span class="float-left" v-if="data.item.executionTime">{{
                        compDate(data.item.executionTime, DATE_FORMAT_INPUT)
                    }}</span>
                </template>
                <template #head(link)="data">
                    <span class="float-left">{{ data.label }}</span>
                </template>
                <template #cell(link)="data">
                    <div class="float-left">
                        <b-link :href="compAuftragDetailsLink(data.item)" target="_blank"
                            ><font-awesome-icon :icon="['fas', 'up-right-from-square']" />
                        </b-link>
                    </div>
                </template>
            </b-table-lite>
        </template>
    </b-table>
</template>
<script>
import moment from "moment";
import constants from "@/constants/constants";
import OrderDetailsRowTable from "@/views/orders/OrderDetailsRowTable";
import StatusBadge from "@/components/execution/StatusBadge";
import { mapGetters } from "vuex";

export default {
    name: "OrderDetailsProcessOverviewTable",
    components: {
        StatusBadge,
        OrderDetailsRowTable,
    },
    data() {
        return {
            items: [],
            mainTableFields: [
                { key: "step", label: "Step" },
                { key: "data[0].action.group.name", label: "Aktionsgruppe" },
                { key: "data[0].action.detail.name", label: "Aktion" },
                { key: "status", label: "Status" },
                { key: "accordionStatusIndication", label: "" },
            ],
            rowDetailsFields: [
                { key: "auftragDetailsID", label: "AuftragDetails" },
                { key: "detailInfomations", label: "Detail Infomationen" },
                { key: "executionTime", label: "Ausführungszeit" },
                { key: "status", label: "Status", thStyle: "table-light" },
                { key: "link", label: "Link", requiresAdmin: true },
            ],
            DATE_FORMAT_INPUT: "YYYY-MM-DDTHH:mm:ss",
        };
    },
    props: {
        orderedAuftragDetails: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters(["currentUser"]),
        computedRowDetailsFields() {
            // If the user isn't an admin, filter out fields that require auth.
            if (!this.isUserAdmin) return this.rowDetailsFields.filter((field) => !field.requiresAdmin);
            // If the user IS an admin, return all fields.
            else return this.rowDetailsFields;
        },
        computedMainTableFields() {
            return this.mainTableFields;
        },
        isUserAdmin() {
            return (
                this.currentUser &&
                this.currentUser.userRoles.some((userRole) => [constants.ADMIN_ROLE_ID].includes(userRole.roleID))
            );
        },
    },
    methods: {
        compAuftragDetailsLink(row) {
            if (row.stepCurrent === "7-1-1" || row.stepCurrent === "7-1-3") {
                return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}/${row.devicePoolID}/zaehler`;
            }
            // Step 6-4-1 redirect to the SMAN page, where you can configure the Relais
            if (row.stepCurrent === "6-4-1") {
                return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}/${row.devicePoolID}/rse-sman`;
            }
            switch (row.action.group.sequence) {
                case 1:
                    return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}/step/${row.stepCurrent}`;
                case 2:
                    return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}/${row.devicePoolID}/step/${row.stepCurrent}`;
                case 5:
                    return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}/${row.devicePoolID}/${row.deviceID}/step/${row.stepCurrent}`;
                case 6:
                    return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}/${row.devicePoolID}/${row.deviceID}/step-6/${row.stepCurrent}`;
                case 7:
                    return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}/${row.devicePoolID}/${row.auftragItemID}/step-7/${row.stepCurrent}`;
                case 8:
                    return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}/${row.devicePoolID}/${row.deviceID}/step-8/${row.stepCurrent}`;
                case 9:
                    return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}/${row.devicePoolID}/step-${row.action.group.sequence}/${row.stepCurrent}`;
                default:
                    return `${process.env.VUE_APP_WEB_ROOT_URL}/execution/${this.$route.params.id}`;
            }
        },
        compDate(dateString, formatInput = this.DATE_FORMAT_INPUT, formatOutput = "DD.MM.YYYY, HH:mm") {
            return moment(dateString, formatInput).format(formatOutput);
        },
        getId(item) {
            if ([2, 9].includes(item.action.group.sequence)) {
                return item.devicePoolID ? item.devicePoolID : -1;
            } else if ([5, 6, 7].includes(item.action.group.sequence)) {
                return item.deviceID ? item.deviceID : -1;
            } else {
                return -1;
            }
        },
        getType(item) {
            if ([2, 9].includes(item.action.group.sequence)) {
                return "devicePool";
            } else if ([6, 7].includes(item.action.group.sequence)) {
                return "device";
            } else if (item.action.group.sequence === 5) {
                return "flexibility";
            } else {
                return "other";
            }
        },
        getDataStatusKey(data) {
            if (data.every((item) => item.status === 300)) {
                return `${data.filter((item) => item.status === 300).length} / ${data.length}  übersprungen`;
            }

            if (data.some((item) => item.status === 300)) {
                return `${
                    data.filter((item) => item.status === 300 || (item.status >= 200 && item.status <= 299)).length
                } / ${data.length} abgeschlossen / übersprungen`;
            }

            return `${data.filter((item) => item.status >= 200 && item.status <= 299).length} / ${
                data.length
            } abgeschlossen`;
        },
        rowClass(item) {
            if (item.data.some((item) => item.status === -1 || item.status === 500)) {
                return "table-danger";
            }
            if (item.data.some((item) => item.status !== 0 && item.status >= 200 && item.status <= 299)) {
                return "table-success";
            }
            if (item.data.some((item) => item.status === 300)) {
                return "table-secondary";
            }
            if (item.data.some((item) => item.status !== 0)) {
                return "table-warning";
            }
        },
        rowDetailsClass() {
            return "table-light p-0";
        },
    },
    async mounted() {},
};
</script>
