<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail">
        <template v-if="actAuftragDetail">
            <div class="mb-3">
                <exec-button-previous @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <exec-button-next class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div class="mb-2">Bitte aktualisieren und ergänzen Sie die Daten des Hausanschlusses:</div>
            <b-form>
                <!-- Lage -->
                <b-form-group label="Lage" class="mt-2">
                    <b-form-radio-group
                        v-model="formHakDaten.lage"
                        :options="options.lage"
                        text-field="text"
                        value-field="keyValueOptionID"
                    />
                </b-form-group>
                <!-- Standort -->
                <b-form-group id="standort-group" label="Standort" label-for="standort-input" class="mt-2">
                    <b-form-input id="standort-input" v-model="formHakDaten.standort" placeholder="" />
                </b-form-group>
                <!-- Zugänglichkeit -->
                <b-form-group label="Zugänglichkeit" v-slot="{ ariaDescribedby }" class="mt-2">
                    <b-form-radio-group
                        v-model="formHakDaten.zugaenglichkeit"
                        :aria-describedby="ariaDescribedby"
                        name="zugaenglichkeit-form"
                        :options="options.zugaenglichkeit"
                        text-field="text"
                        value-field="keyValueOptionID"
                    />
                </b-form-group>
                <!-- Bauart -->
                <b-form-group label="Bauart" v-slot="{ ariaDescribedby }" class="mt-2">
                    <b-form-radio-group
                        v-model="formHakDaten.bauart"
                        :aria-describedby="ariaDescribedby"
                        name="bauart-form"
                        :options="options.bauart"
                        text-field="text"
                        value-field="keyValueOptionID"
                    />
                </b-form-group>
                <!-- Anschlusssicherung Typ -->
                <b-form-group label="Anschlusssicherung Typ" v-slot="{ ariaDescribedby }" class="mt-2">
                    <b-form-radio-group
                        v-model="formHakDaten.sicherungTypKeyValueOptionID"
                        :aria-describedby="ariaDescribedby"
                        name="anschlusssicherung-form"
                        :options="options.sicherungTyp"
                        text-field="text"
                        value-field="keyValueOptionID"
                        stacked
                    />
                </b-form-group>
                <b-form-input
                    id="anschlusssicherung-typ-andere-input"
                    v-model="formHakDaten.sicherungTypAndereText"
                    placeholder="Andere text"
                    :disabled="!isAndereOption(options.sicherungTyp, formHakDaten.sicherungTypKeyValueOptionID)"
                />
                <!-- SicherungActual -->
                <div class="mt-2">Sicherungsgrösse Nennstrom (NS) HA</div>
                <b-form-group label="Aktuell installiert" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        v-model="formHakDaten.sicherungAmpereKeyValueOptionID"
                        :aria-describedby="ariaDescribedby"
                        name="sicherungActual-form"
                        :options="options.sicherungAmpere"
                        text-field="text"
                        value-field="keyValueOptionID"
                        stacked
                    />
                </b-form-group>
                <b-form-input
                    id="anschlusssicherung-typ-andere-input"
                    v-model="formHakDaten.sicherungAmpereAndereText"
                    placeholder="Andere text"
                    :disabled="!isAndereOption(options.sicherungAmpere, formHakDaten.sicherungAmpereKeyValueOptionID)"
                />
                <!-- SicherungMax -->
                <b-form-group
                    label="Max. mögliche Grösse für Sicherungseinsatz"
                    v-slot="{ ariaDescribedby }"
                    class="mt-2"
                >
                    <b-form-radio-group
                        v-model="formHakDaten.sicherungMaxGrosseKeyValueOptionID"
                        :aria-describedby="ariaDescribedby"
                        name="sicherungMax-form"
                        :options="options.sicherungMaxGrosse"
                        text-field="text"
                        value-field="keyValueOptionID"
                        stacked
                    />
                </b-form-group>
                <b-form-input
                    id="anschlusssicherung-typ-andere-input"
                    v-model="formHakDaten.sicherungMaxGrosseAndereText"
                    placeholder="Andere text"
                    :disabled="
                        !isAndereOption(options.sicherungMaxGrosse, formHakDaten.sicherungMaxGrosseKeyValueOptionID)
                    "
                />
                <!-- Bemerkung -->
                <b-form-group id="bemerkung-group" label="Bemerkung" label-for="Bemerkung-input" class="mt-2">
                    <b-form-textarea id="bemerkung-input" v-model="formHakDaten.bemerkung" rows="3" placeholder="" />
                </b-form-group>
            </b-form>
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";

import constants from "@/constants/constants";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import auftragsApi from "@/services/api/auftrags.api";
import devicepoolsApi from "@/services/api/devicepools.api";
import haksAPI from "@/services/api/haks.api";
import keyValueOptionsAPI from "@/services/api/keyValueOptionsScd.api";

export default {
    name: "Step_1_3_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data: function() {
        return {
            devicePools: [],
            formHakDaten: {
                lage: null,
                standort: null,
                zugaenglichkeit: null,
                bauart: null,
                sicherungTypKeyValueOptionID: null,
                sicherungTypAndereText: null,
                sicherungAmpereKeyValueOptionID: null,
                sicherungAmpereAndereText: null,
                sicherungMaxGrosseKeyValueOptionID: null,
                sicherungMaxGrosseAndereText: null,
                bemerkung: null,
            },
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            options: {
                lage: [
                    /*
                    { text: "Innen", value: "innen" },
                    { text: "Aussen", value: "aussen" },
                    */
                ],
                zugaenglichkeit: [
                    /*
                    { text: "nur mit Voranmeldung", value: "nur_mit_voranmeldung" },
                    { text: "ohne Voranmeldung möglich", value: "ohne_voranmeldung_moeglich" },
                    */
                ],
                bauart: [
                    /*
                    { text: "Aufputz", value: "aufputz" },
                    { text: "Unterputz", value: "unterputz" },
                    { text: "Einbau", value: "einbau" },
                    */
                ],
                sicherungTyp: [
                    /*
                    { text: "NHS 00 - 160A", value: "NHS_00" },
                    { text: "NHS 1 - 250A", value: "NHS_1" },
                    { text: "NHS 2 - 400A", value: "NHS_2" },
                    { text: "NHS 3 630A", value: "NHS_3" },
                    { text: "Diazed - Abfragen was max!", value: "Diazed" },
                    { text: "Leistungsschalter", value: "Leistungsschalter" },
                    { text: "NH00 Sicherungslastschaltleiste - 160A", value: "NH00_Sicherungslastschaltleiste" },
                    { text: "NH1 Sicherungslastschaltleiste - 250A", value: "NH1_Sicherungslastschaltleiste" },
                    { text: "NH2 Sicherungslastschaltleiste - 400A", value: "NH2_Sicherungslastschaltleiste" },
                    { text: "NH3 Sicherungslastschaltleiste - 630A", value: "NH3_Sicherungslastschaltleiste" },
                    { text: "Andere", value: "andere" },
                    */
                ],
                sicherungAmpere: [
                    /*
                    { text: "16 A (11 kW)", value: "16A" },
                    { text: "25 A (17 kW)", value: "25A" },
                    { text: "35 A (24 kW)", value: "35A" },
                    { text: "40 A (28 kW)", value: "40A" },
                    { text: "60 A (42 kW) alt", value: "60A" },
                    { text: "63 A (44 kW)", value: "63A" },
                    { text: "80 A (55 kW)", value: "80A" },
                    { text: "100 A (69 kW)", value: "100A" },
                    { text: "125 A (87 kW)", value: "125A" },
                    { text: "160 A (111 kW)", value: "160A" },
                    { text: "200 A (139 kW)", value: "200A" },
                    { text: "250 A (173 kW)", value: "250A" },
                    { text: "315 A (218 kW)", value: "315A" },
                    { text: "355 A (246 kW)", value: "355A" },
                    { text: "400 A (277 kW)", value: "400A" },
                    { text: "500 A (346 kW)", value: "500A" },
                    { text: "630 A (436 kW)", value: "630A" },
                    { text: "Andere", value: "andere" },
                    */
                ],
                sicherungMaxGrosse: [],
            },
        };
    },
    async mounted() {
        await keyValueOptionsAPI.get({ category: "hak" }).then((resp) => {
            const options = resp.data;
            this.keyValueOptionsHak = options;
            this.options.lage = _.filter(options, { subcategory: "PositionKeyValueOptionID" });
            this.options.zugaenglichkeit = _.filter(options, { subcategory: "PositionDetailsKeyValueOptionID" });
            this.options.bauart = _.filter(options, { subcategory: "TypKeyValueOptionID" });
            this.options.sicherungTyp = _.filter(options, { subcategory: "SicherungTypKeyValueOptionID" });
            this.options.sicherungAmpere = _.filter(options, { subcategory: "SicherungAmpereKeyValueOptionID" });
            this.options.sicherungMaxGrosse = _.filter(options, { subcategory: "SicherungMaxGrosseKeyValueOptionID" });
        });
        await this.loadAuftragDetail();
        await this.loadHak();
        this.devicePools = await devicepoolsApi.get({ hakID: this.order.hakID });
    },
    computed: {
        ...mapGetters({
            storeActAuftragDetail: "execution/storeActAuftragDetail",
        }),
        actAuftragDetail() {
            return this.storeActAuftragDetail;
        },
        compErledigtIsDisabled() {
            const optionSicherungTypAndere = this.options.sicherungTyp.find((x) => x.text.toLowerCase() === "andere");
            const sicherungTypAndereId = optionSicherungTypAndere ? optionSicherungTypAndere.keyValueOptionID : 0;
            const optionSicherungAmpereAndere = this.options.sicherungAmpere.find(
                (x) => x.text.toLowerCase() === "andere"
            );
            const sicherungAmpereAndereId = optionSicherungAmpereAndere
                ? optionSicherungAmpereAndere.keyValueOptionID
                : 0;
            const optionSicherungMaxGrosseAndere = this.options.sicherungMaxGrosse.find(
                (x) => x.text.toLowerCase() === "andere"
            );
            const sicherungMaxGrosseAndereId = optionSicherungMaxGrosseAndere
                ? optionSicherungMaxGrosseAndere.keyValueOptionID
                : 0;
            return (
                !this.actAuftragDetail.isExecutable ||
                this.status !== this.constants.auftragDetailStatus.DONE_CLOSED ||
                (this.formHakDaten.sicherungTypKeyValueOptionID === sicherungTypAndereId &&
                    !this.formHakDaten.sicherungTypAndereText) ||
                (this.formHakDaten.sicherungAmpereKeyValueOptionID === sicherungAmpereAndereId &&
                    !this.formHakDaten.sicherungAmpereAndereText) ||
                (this.formHakDaten.sicherungMaxGrosseKeyValueOptionID === sicherungMaxGrosseAndereId &&
                    !this.formHakDaten.sicherungMaxGrosseAndereText)
            );
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.compErledigtIsDisabled) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Lokale Vorbedingungen nicht erfüllt." });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Lokale Vorbedingungen erfüllt." });
            }

            return preconditionsLocalDetails;
        },
        constants() {
            return constants;
        },
        status() {
            let status = constants.auftragDetailStatus.DONE_CLOSED;
            if (!this.formHakDaten.lage) status = constants.auftragDetailStatus.NOT_STARTED;
            if (!this.formHakDaten.standort) status = constants.auftragDetailStatus.NOT_STARTED;
            if (!this.formHakDaten.zugaenglichkeit) status = constants.auftragDetailStatus.NOT_STARTED;
            if (!this.formHakDaten.bauart) status = constants.auftragDetailStatus.NOT_STARTED;
            if (!this.formHakDaten.sicherungTypKeyValueOptionID) status = constants.auftragDetailStatus.NOT_STARTED;
            if (!this.formHakDaten.sicherungAmpereKeyValueOptionID) status = constants.auftragDetailStatus.NOT_STARTED;
            if (!this.formHakDaten.sicherungMaxGrosseKeyValueOptionID)
                status = constants.auftragDetailStatus.NOT_STARTED;
            return status;
        },
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        fixAndereFields() {
            /*this.formHakDaten.sicherungTypAndereText =
                this.formHakDaten.sicherungTypKeyValueOptionID === "andere"
                    ? this.formHakDaten.sicherungTypAndereText
                    : null;
            this.formHakDaten.sicherungAmpereAndereText =
                this.formHakDaten.sicherungAmpereKeyValueOptionID === "andere"
                    ? this.formHakDaten.sicherungAmpereAndereText
                    : null;
            this.formHakDaten.sicherungMaxGrosseAndereText =
                this.formHakDaten.sicherungMaxGrosseKeyValueOptionID === "andere"
                    ? this.formHakDaten.sicherungMaxGrosseAndereText
                    : null;*/
        },
        isAndereOption(options, keyValueOptionID) {
            if (!options || !keyValueOptionID) {
                return false;
            }
            const andereOption = _.find(options, { text: "Andere" });
            if (andereOption && andereOption.keyValueOptionID === keyValueOptionID) {
                return true;
            }
            return false;
        },
        async loadAuftragDetail() {
            const pathSplit = this.$route.fullPath.split("/");
            const step = pathSplit[pathSplit.length - 1];
            await auftragDetailsAPI.getByOrderIdAndStep(this.$route.params.orderId, step).then((res) => {
                this.setActAuftragDetail(res);
            });
        },
        async loadHak() {
            const hak = (await haksAPI.getSingle(this.order.hakID)).data;
            // formHakDaten
            //this.formHakDaten.lage = hak.position;
            this.formHakDaten.lage = hak.positionKeyValueOptionID;
            this.formHakDaten.standort = hak.standort;
            //this.formHakDaten.zugaenglichkeit = hak.positionDetails;
            this.formHakDaten.zugaenglichkeit = hak.positionDetailsKeyValueOptionID;
            //this.formHakDaten.bauart = hak.typ;
            this.formHakDaten.bauart = hak.typKeyValueOptionID;
            this.formHakDaten.sicherungTypKeyValueOptionID = hak.sicherungTypKeyValueOptionID;
            this.formHakDaten.sicherungTypAndereText = hak.sicherungTypAndere;
            this.formHakDaten.sicherungAmpereKeyValueOptionID = hak.sicherungAmpereKeyValueOptionID;
            this.formHakDaten.sicherungAmpereAndereText = hak.sicherungAmpereAndere;
            this.formHakDaten.sicherungMaxGrosseKeyValueOptionID = hak.sicherungMaxGrosseKeyValueOptionID;
            this.formHakDaten.sicherungMaxGrosseAndereText = hak.sicherungMaxGrosseAndere;
            this.formHakDaten.bemerkung = hak.bezeichnung;
        },
        async onClickErledigt() {
            await this.save(this.status);
            // If this hak has no related DevicePool, set the Auftrag.status=completed
            if (this.devicePools.length === 0) {
                // all step 9-1-1 are completed: put auftrag.status=completed
                await auftragsApi.patch(this.$route.params.orderId, [
                    {
                        op: "replace",
                        path: "/auftragStatusID",
                        value: "completed",
                    },
                ]);
                console.log(`Auftrag ${this.$route.params.orderId} put in status: completed`);
            }
            // Navigate to next step
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        async onClickNext(step) {
            await this.save(null);
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        async save(status) {
            this.fixAndereFields();
            if (status) {
                const auftragDetailPayload = [
                    {
                        op: "replace",
                        path: "/status",
                        value: status,
                    },
                ];
                await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, auftragDetailPayload);
            }
            const hakPayload = [
                {
                    op: "replace",
                    //path: "/position",
                    path: "/positionKeyValueOptionID",
                    value: this.formHakDaten.lage,
                },
                {
                    op: "replace",
                    path: "/standort",
                    value: this.formHakDaten.standort,
                },
                {
                    op: "replace",
                    //path: "/positionDetails",
                    path: "/positionDetailsKeyValueOptionID",
                    value: this.formHakDaten.zugaenglichkeit,
                },
                {
                    op: "replace",
                    //path: "/typ",
                    path: "/typKeyValueOptionID",
                    value: this.formHakDaten.bauart,
                },
                {
                    op: "replace",
                    path: "/sicherungTypKeyValueOptionID",
                    value: this.formHakDaten.sicherungTypKeyValueOptionID,
                },
                {
                    op: "replace",
                    path: "/sicherungTypAndere",
                    value: this.formHakDaten.sicherungTypAndereText,
                },
                {
                    op: "replace",
                    path: "/sicherungAmpereKeyValueOptionID",
                    value: this.formHakDaten.sicherungAmpereKeyValueOptionID,
                },
                {
                    op: "replace",
                    path: "/sicherungAmpereAndere",
                    value: this.formHakDaten.sicherungAmpereAndereText,
                },
                {
                    op: "replace",
                    path: "/sicherungMaxGrosseKeyValueOptionID",
                    value: this.formHakDaten.sicherungMaxGrosseKeyValueOptionID,
                },
                {
                    op: "replace",
                    path: "/sicherungMaxGrosseAndere",
                    value: this.formHakDaten.sicherungMaxGrosseAndereText,
                },
                {
                    op: "replace",
                    path: "/bezeichnung",
                    value: this.formHakDaten.bemerkung,
                },
            ];
            await haksAPI.patch(this.order.hakID, hakPayload);
        },
    },
};
</script>
