<template>
    <div>
        <div>
            <DxDataGrid
                id="grid-container"
                ref="dataGridRef"
                :data-source="users"
                :show-borders="true"
                :remote-operations="true"
                :allow-column-resizing="true"
                :allow-column-reordering="true"
                :show-row-lines="true"
                :show-column-lines="false"
                :row-alternation-enabled="true"
                :column-auto-width="true"
                :on-row-prepared="onRowPrepared"
            >
                <DxLoadPanel :visible="isLoading" :enabled="true" :showIndicator="true" :showPane="true"></DxLoadPanel>
                <DxHeaderFilter :visible="true" :search="{ enabled: true }">
                    <DxSearch :enabled="true" :timeout="900" :editor-options="searchEditorOptions" mode="contains" />
                </DxHeaderFilter>
                <DxSearchPanel :visible="true" width="30vw"></DxSearchPanel>
                <DxPaging :page-size="20" />
                <DxPager
                    :show-page-size-selector="true"
                    :allowed-page-sizes="[20, 50, 100]"
                    :show-navigation-buttons="true"
                    :show-info="true"
                />
                <DxGroupPanel :visible="true" />
                <DxGrouping :auto-expand-all="false" />
                <DxToolbar>
                    <DxItem name="groupPanel" />
                    <DxItem location="after" widget="dxButton" :options="clearFilterButtonOptions" />
                    <DxItem widget="dxButton" :options="reloadButtonOptions" />
                    <DxItem name="searchPanel"></DxItem>
                    <DxItem widget="dxButton" :options="addButtonOptions" v-if="canAdd" />
                </DxToolbar>

                <DxColumn data-field="userID" caption="ID" data-type="number" width="60" :visible="false" />
                <DxColumn data-field="firstName" caption="Vorname" data-type="string" />
                <DxColumn data-field="lastName" caption="Nachname" data-type="string" sortOrder="asc" />
                <DxColumn data-field="username" caption="Nutzername" data-type="string" />
                <DxColumn data-field="email" caption="E-Mail" data-type="string" />
                <DxColumn data-field="phoneNumber" caption="Telefonnummer" data-type="string" />
                <DxColumn data-field="company.name" caption="Firma" data-type="string" />
                <DxColumn
                    data-field="userRolesDisplay"
                    caption="Rollen"
                    :allow-sorting="false"
                    :allow-filtering="true"
                    :allow-grouping="false"
                    :calculate-filter-expression="calculateFilterExpressionRole"
                >
                    <DxHeaderFilter :data-source="rolesMasterData" />
                </DxColumn>
                <DxColumn
                    data-field="userQualificationsDisplay"
                    caption="Qualifikationen"
                    :allow-sorting="false"
                    :allow-filtering="true"
                    :allow-grouping="false"
                    :calculate-filter-expression="calculateFilterExpressionQualification"
                >
                    <DxHeaderFilter :data-source="qualificationsMasterData" />
                </DxColumn>
                <DxColumn type="buttons" :width="50" :allow-sorting="false" :allow-grouping="false">
                    <DxButton
                        hint="Bearbeiten"
                        icon="edit"
                        text="go"
                        :on-click="onClickEditUser"
                        :visible="checkIconVisibility"
                    />
                </DxColumn>
                <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
                <template #masterDetailTemplate="{ data: user }">
                    <div v-if="user.data.groups.length > 0">
                        <font-awesome-icon :icon="['fas', 'user-group']" size="sm" />
                        <span class="ml-2">Mitglied der folgenden Gruppen:</span>
                        <div>
                            <span v-for="(group, index) of user.data.groups" :key="group.id">
                                <span v-if="index !== 0">, </span>
                                <router-link
                                    :is="canEdit ? 'router-link' : 'span'"
                                    :to="{ name: 'group-edit', params: { groupID: group.id } }"
                                >
                                    {{ group.name }}
                                </router-link>
                            </span>
                        </div>
                    </div>
                    <template v-else><div class="text-muted">Ist nicht Mitglied einer Gruppe</div></template>
                </template>
            </DxDataGrid>
        </div>
    </div>
</template>

<script>
import constants from "@/constants/constants";
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    DxButton,
    DxColumn,
    DxDataGrid,
    DxFilterPanel,
    DxFilterRow,
    DxGrouping,
    DxGroupPanel,
    DxHeaderFilter,
    DxItem,
    DxLoadPanel,
    DxLookup,
    DxMasterDetail,
    DxPager,
    DxPaging,
    DxSearch,
    DxSearchPanel,
    DxToolbar,
} from "devextreme-vue/data-grid";
import usersApi from "../../services/api/users.api";
import rolesApi from "../../services/api/roles.api";
import qualificationsApi from "../../services/api/qualification.api";
import gridService from "../../services/gridBaseService";
import userCompanyPermissionService from "../../services/userCompanyPermissionService";
import { mapGetters } from "vuex";

export default {
    name: "Users",
    components: {
        DxDataGrid,
        DxColumn,
        DxFilterPanel,
        DxFilterRow,
        DxGroupPanel,
        DxGrouping,
        DxHeaderFilter,
        DxItem,
        DxLoadPanel,
        DxMasterDetail,
        DxPager,
        DxPaging,
        DxSearch,
        DxSearchPanel,
        DxButton,
        DxLookup,
        DxToolbar,
    },
    data() {
        return {
            users: [],
            roles: [],
            rolesMasterData: [],
            qualificationsMasterData: [],
            searchEditorOptions: {
                placeholder: "Search value",
                mode: "text",
            },
            clearFilterButtonOptions: {
                icon: "fas fa-filter-circle-xmark",
                hint: "Filter löschen",
                onClick: () => {
                    this.$refs.dataGridRef.instance.clearFilter();
                },
            },
            reloadButtonOptions: {
                icon: "fas fa-rotate",
                hint: "Aktualisieren",
                onClick: () => {
                    this.$refs.dataGridRef.instance.refresh();
                },
            },
            addButtonOptions: {
                icon: "fa fa-plus",
                hint: "Hinzufügen",
                onClick: () => {
                    this.onClickAddUser();
                },
            },
            isLoading: false,
        };
    },
    async mounted() {
        this.isLoading = true;

        try {
            // get Roles for Filter
            const roles = await rolesApi.get();
            this.rolesMasterData = roles.data.map((r) => {
                return { text: r.name, value: r.name };
            });
            // get Qualifications for Filter
            const qualifications = await qualificationsApi.get();
            this.qualificationsMasterData = qualifications.data.map((q) => {
                return { text: q.name, value: q.name };
            });

            this.users = createStore(
                gridService.getStoreConfig({
                    key: "userID",
                    loadUrl: usersApi.getUsersForGridUrl(),
                })
            );
            this.roles = roles.data;
        } finally {
            this.isLoading = false;
        }
    },
    computed: {
        ...mapGetters(["currentUser"]),
        canEdit() {
            return this.currentUser
                ? userCompanyPermissionService.checkIfMultipleRoles(
                      this.currentUser.userRoles.map((userRole) => userRole.roleID),
                      [constants.LEITER_ROLE_ID, constants.ADMIN_ROLE_ID],
                      constants.MATCH_ALL_ROLES
                  )
                : false;
        },
        canAdd() {
            return this.currentUser ? userCompanyPermissionService.isUserAdmin(this.currentUser.userRoles) : false;
        },
    },
    methods: {
        checkIconVisibility(e) {
            return this.currentUser
                ? userCompanyPermissionService.isUserEditable(this.currentUser, e.row.data, this.roles)
                : false;
        },
        onClickEditUser(e) {
            this.$router.push({ name: "user-edit", params: { userID: e.row?.data?.userID } });
            e.event.preventDefault();
        },
        onClickAddUser() {
            this.$router.push({ name: "user-edit", params: { userID: "new" } });
        },
        onRowPrepared(row) {
            if (row.rowType === "data" && row.data.groups?.length === 0) {
                row.rowElement.children[0]?.children[0]?.remove();
            }
        },
        calculateFilterExpressionRole(filterValue) {
            return ["userRolesDisplay", "contains", filterValue];
        },
        calculateFilterExpressionQualification(filterValue) {
            return ["userQualificationsDisplay", "contains", filterValue];
        },
    },
};
</script>

<style scoped>
#grid-container {
    width: 100%;
    height: calc(100vh - 112px);
}
</style>
