<template>
    <div>
        <b-row>
            <!-- Name -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-name-fg" label="Name:" label-for="contact-name-input">
                    <b-form-input id="contact-name-input" v-model="contact.name" trim />
                </b-form-group>
            </b-col>
            <!-- Company -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-company-fg" label="Firma:" label-for="contact-company-input">
                    <b-form-input id="contact-company-input" v-model="contact.company" trim />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <!-- Address -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-address-fg" label="Adresse:" label-for="contact-address-input">
                    <b-form-input id="contact-address-input" v-model="contact.address" trim disabled />
                </b-form-group>
            </b-col>
            <!-- City -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-city-fg" label="Ort:" label-for="contact-city-input">
                    <b-form-input id="contact-city-input" v-model="contact.city" trim disabled />
                </b-form-group>
            </b-col>
            <!-- PostalCode -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-postalCode-fg" label="PLZ:" label-for="contact-postalCode-input">
                    <b-form-input id="contact-postalCode-input" v-model="contact.postalCode" trim disabled />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <!-- Contact Info -->
            <b-card title="Kontaktmethoden" class="w-100">
                <b-card-body>
                    <b-row
                        v-for="(contactInfo, index) in contactInfos"
                        :key="contactInfo.contactInfoID"
                        align-v="center"
                    >
                        <!-- Type -->
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group id="contact-type-fg" label="Typ:" label-for="contact-type-input">
                                <b-select
                                    id="contact-type-input"
                                    v-model="contactInfo.type"
                                    required
                                    :options="contactInfoTypes"
                                />
                            </b-form-group>
                        </b-col>
                        <!-- Value -->
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group id="contact-value-fg" label="Wert:" label-for="contact-value-input">
                                <b-form-input id="contact-value-input" v-model="contactInfo.value" trim required />
                            </b-form-group>
                        </b-col>
                        <!-- Note -->
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group id="contact-note-fg" label="Anmerkung:" label-for="contact-note-input">
                                <b-form-input id="contact-note-input" v-model="contactInfo.note" trim />
                            </b-form-group>
                        </b-col>
                        <!-- Actions -->
                        <b-col cols="1" sm="1" md="1">
                            <font-awesome-icon
                                v-if="!contactInfo.contactInfoID"
                                :icon="['fas', 'minus-circle']"
                                size="lg"
                                class="mr-1"
                                @click="deleteContactInfo(contactInfo, index)"
                            />
                            <font-awesome-icon
                                v-if="index + 1 === contactInfos.length"
                                :icon="['fas', 'plus-circle']"
                                size="lg"
                                class=""
                                @click="addContactInfo"
                            />
                        </b-col>
                    </b-row>
                    <b-row v-if="contactInfos.length === 0">
                        <b-col>
                            <b-alert v-if="contactInfos.length === 0" show>
                                Keine Kontaktinformationen
                            </b-alert>
                        </b-col>
                        <b-col>
                            <font-awesome-icon
                                :icon="['fas', 'plus-circle']"
                                size="lg"
                                class=""
                                @click="addContactInfo"
                            />
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-row>
        <b-alert v-if="multipleContactsWarning" dismissible>{{ multipleContactsWarning }}</b-alert>
        <b-row class="mt-3">
            <b-col>
                <b-button variant="primary" v-on:click="saveContact()" :disabled="!contactExists">Speichern</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import contactsAPI from "@/services/api/contacts.api";
import contactInfosAPI from "@/services/api/contactInfos.api";

export default {
    name: "Contacts",
    components: {},
    props: {
        entity: {
            type: String,
            required: true,
        },
        entityID: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            contact: {
                contactID: null,
                name: null,
                company: null,
                address: null,
                city: null,
                postalCode: null,
                phone: null,
                email: null,
            },
            contactInfos: [],
            multipleContactsWarning: null,
            contactInfoTypes: [
                { value: "Telefon", text: "Telefon" },
                { value: "E-Mail", text: "E-Mail" },
                { value: "Mobil", text: "Mobil" },
                { value: "Telefax", text: "Telefax" },
                { value: "Internet/WWW", text: "Internet/WWW" },
            ],
        };
    },
    computed: {
        contactExists() {
            return this.contact.contactID != null;
        },
    },
    async mounted() {
        // ToDo: remove awaits
        await this.loadContacts();
        await this.loadContactInfos(this.contact.contactID);
    },
    methods: {
        addContactInfo() {
            this.contactInfos.push({ type: null, value: null, note: null, contactID: this.contact.contactID });
        },
        deleteContactInfo(contactInfo, index) {
            this.contactInfos.splice(index, 1);
        },
        async loadContacts() {
            try {
                const contacts = await contactsAPI.get({
                    parentTable: this.entity,
                    parentID: this.entityID,
                });
                if (contacts.length > 0) {
                    this.contact = contacts[0];
                }
                if (contacts.length > 1) {
                    this.multipleContactsWarning = "Multiple contacts related to this HAK";
                }
            } catch (err) {
                //console.error(err);
                return null; // TODO: manage errors
            }
        },
        async loadContactInfos(contactID) {
            // ToDo: remove awaits
            try {
                if (!contactID) {
                    return;
                }
                const response = await contactInfosAPI.get({ contactID: contactID });
                this.contactInfos = response;
            } catch (err) {
                //console.error(err);
                return null; // TODO: manage errors
            }
        },
        async saveContact() {
            // ToDo: remove awaits
            // Save Contact
            const patchDoc = [
                {
                    op: "replace",
                    path: "/name",
                    value: this.contact.name,
                },
                {
                    op: "replace",
                    path: "/company",
                    value: this.contact.company,
                },
            ];
            await contactsAPI.patch(this.contact.contactID, patchDoc);
            // Save ContactInfo
            for (const contactInfo of this.contactInfos) {
                if (contactInfo.contactInfoID) {
                    continue; // skip existing, no edit allowed
                }
                if (contactInfo.type && contactInfo.value) {
                    await contactInfosAPI.post(contactInfo);
                }
            }
            await this.loadContacts();
            await this.loadContactInfos(this.contact.contactID);
        },
    },
};
</script>
