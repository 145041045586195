import Groups from "@/views/users/Groups";
import Users from "@/views/users/Users";
import Calendars from "@/views/users/Calendars";
import UsersRoot from "@/views/users/UsersRoot";
import GroupEdit from "@/views/users/GroupEdit";
import UserEdit from "@/views/users/UserEdit";
import CompaniesEdit from "@/views/users/CompaniesEdit";
import Companies from "@/views/users/Companies";

export default [
    {
        path: "/tech",
        component: UsersRoot,
        name: "tech",
        redirect: { name: "users" },
        children: [
            {
                path: "users",
                name: "users",
                component: Users,
            },
            {
                path: "users/:userID/",
                name: "user-edit",
                component: UserEdit,
            },
            {
                path: "groups",
                name: "groups",
                component: Groups,
            },
            {
                path: "groups/:groupID/",
                name: "group-edit",
                component: GroupEdit,
            },
            {
                path: "calendars",
                name: "calendars",
                component: Calendars,
            },
            {
                path: "companies",
                name: "companies",
                component: Companies,
            },
            {
                path: "companies/:companyID/",
                name: "companies-edit",
                component: CompaniesEdit,
            },
        ],
    },
];
