<template>
    <div id="ReSpinner" class="d-flex justify-content-center" :class="cssOptionalClass">
        <b-spinner :small="isSmall" class="spinner" label="Loading..."></b-spinner>
    </div>
</template>

<script>
export default {
    name: "ReSpinner",
    props: {
        cssOptionalClass: {
            type: String,
            default: null,
            required: false,
        },
        isSmall: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
#ReSpinner {
    color: #ff463c;
}
</style>
