import { httpClient } from "@/services/api/client";

const endpoint = "/net/rolloutview/auftrag-ausfuehrung";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, {
            params: params,
        });
    },
    // **************
    // custom methods
    // **************
};
