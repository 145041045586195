<template>
    <div>
        <b-alert class="mb-1" show>
            <div>
                <div class="font-weight-bold">TBD</div>
                <ul class="m-0">
                    <li>
                        define content (business) -> possibly related to issue
                        <b-link href="https://repowerag.visualstudio.com/REtasks/_workitems/edit/18542">#18542</b-link>
                    </li>
                </ul>
            </div>
        </b-alert>
    </div>
</template>

<script>
export default {
    name: "OrdersAnalysis",
};
</script>
