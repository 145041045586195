import constants from "../../constants/constants";
import usersAPI from "../../services/api/users.api";
import companiesAPI from "../../services/api/companies.api";
import rolesAPI from "../../services/api/roles.api";
import qualificationsAPI from "../../services/api/qualification.api";

const jsonApiHeaders = function(accessToken) {
    return { "Content-Type": "application/json", Accept: "application/json", Authorization: `Bearer ${accessToken}` };
};

const state = {
    currentUser: null,
    calendars: [],
    groups: [],
    users: [],
    companies: [],
    roles: [],
    qualifications: [],
};

const actions = {
    async fetchCurrentUser({ commit, rootGetters }) {
        try {
            commit("SET_CURRENT_USER", null);
            const loggedUserID = rootGetters.oidcUser.preferred_username;
            const user = await usersAPI.getByUsername(loggedUserID);
            commit("SET_CURRENT_USER", user);
        } catch (error) {
            commit("SET_CURRENT_USER", null);
            commit("SET_ERROR", { code: -1, description: "Error while fetching current user", details: error });
        }
    },
    async fetchCalendars({ commit, getters }) {
        try {
            commit("SET_CALENDARS", []); //TODO: API not yet implemented
            const rawResponse = await fetch(constants.env.API_SMARTMETER_URL + "/calendars/", {
                method: "GET",
                headers: jsonApiHeaders(getters.oidcAccessToken),
            });
            const response = await rawResponse.json();
            commit("SET_CALENDARS", response.data);
        } catch (error) {
            commit("SET_CALENDARS", { data: [], error: error, isLoading: false, isError: false });
            commit("SET_ERROR", { code: -1, description: "Error while fetching calendars", details: error });
        }
    },
    async fetchGroups({ commit, getters }) {
        try {
            commit("SET_GROUPS", []);
            const rawResponse = await fetch(constants.env.API_SMARTMETER_URL + "/app/groups/", {
                method: "GET",
                headers: jsonApiHeaders(getters.oidcAccessToken),
            });
            const response = await rawResponse.json();
            commit("SET_GROUPS", response);
        } catch (error) {
            commit("SET_GROUPS", { data: [], error: error, isLoading: false, isError: false });
            commit("SET_ERROR", { code: -1, description: "Error while fetching groups", details: error });
        }
    },
    async fetchUsers({ commit }) {
        try {
            commit("SET_USERS", { data: [], error: null, isLoading: true, isError: false });
            const response = await usersAPI.get(); // TODO: filter by company?
            commit("SET_USERS", response.data);
        } catch (error) {
            commit("SET_USERS", { data: [], error: error, isLoading: false, isError: false });
            commit("SET_ERROR", { code: -1, description: "Error while fetching users", details: error });
        }
    },
    async fetchCompanies({ commit }) {
        try {
            commit("SET_COMPANIES", { data: [], error: null, isLoading: true, isError: false });
            const response = await companiesAPI.get();
            commit("SET_COMPANIES", response.data);
        } catch (error) {
            commit("SET_COMPANIES", { data: [], error: error, isLoading: false, isError: false });
            commit("SET_ERROR", { code: -1, description: "Error while fetching companies", details: error });
        }
    },
    async fetchRoles({ commit }) {
        try {
            commit("SET_ROLES", { data: [], error: null, isLoading: true, isError: false });
            const response = await rolesAPI.get();
            commit("SET_ROLES", response.data);
        } catch (error) {
            commit("SET_ROLES", { data: [], error: error, isLoading: false, isError: false });
            commit("SET_ERROR", { code: -1, description: "Error while fetching roles", details: error });
        }
    },
    async fetchQualifications({ commit }) {
        try {
            commit("SET_QUALIFICATIONS", { data: [], error: null, isLoading: true, isError: false });
            const response = await qualificationsAPI.get();
            commit("SET_QUALIFICATIONS", response.data);
        } catch (error) {
            commit("SET_QUALIFICATIONS", { data: [], error: error, isLoading: false, isError: false });
            commit("SET_QUALIFICATIONS", { code: -1, description: "Error while fetching roles", details: error });
        }
    },
};

const mutations = {
    SET_CURRENT_USER(state, user) {
        state.currentUser = user;
    },
    SET_CALENDARS(state, calendars) {
        state.calendars = calendars;
    },
    SET_GROUPS(state, groups) {
        state.groups = groups;
    },
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_COMPANIES(state, companies) {
        state.companies = companies;
    },
    SET_ROLES(state, roles) {
        state.roles = roles;
    },
    SET_QUALIFICATIONS(state, qualifications) {
        state.qualifications = qualifications;
    },
};

const getters = {
    currentUser: (state) => state.currentUser,
    calendars: (state) => state.calendars,
    users: (state) => state.users,
    groups: (state) => state.groups,
    companies: (state) => state.companies,
    roles: (state) => state.roles,
    qualifications: (state) => state.qualifications,
};

export default {
    state,
    actions,
    mutations,
    getters,
};
