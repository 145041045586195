<template>
    <Main>
        <b-alert class="mt-2" show>
            Unbekannter Schritt <strong>{{ $route.params.pathMatch }}</strong>
        </b-alert>
    </Main>
</template>

<script>
export default {
    name: "StepUnknown",
};
</script>
