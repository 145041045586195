<template>
    <b-link :href="`tel:${phoneNumber}`" data-rel="external" class="text-decoration-none">
        <font-awesome-icon :icon="['fas', 'phone']" v-if="phoneIconShow" /> <strong>{{ phoneNumber }}</strong>
    </b-link>
</template>

<script>
/*
a component to display a phone number as a link to initiate a phone call
*/
export default {
    name: "PhoneCall",
    props: {
        phoneIconShow: {
            type: Boolean,
            default: true,
        },
        phoneNumber: {
            type: String,
            required: true,
        },
    },
};
</script>
