<template>
    <b-card class="mt-3" header-class="px-3 py-2" no-body>
        <template #header v-if="headerText">
            <h6 class="mb-0">
                <span>{{ headerText }}</span>
                <span v-if="headerIcon"><font-awesome-icon :icon="headerIcon" class="float-right"/></span>
                <span v-if="headerInfo" class="float-right">{{ headerInfo }}</span>
            </h6>
        </template>
        <div v-if="!!$slots.default" :class="`card-body p-0 ${bodyBgVariantClass}`">
            <b-spinner v-if="isLoading" class="d-block mx-3 my-3" small />
            <div v-else :class="marginClass">
                <slot />
            </div>
        </div>
        <div v-if="!!$slots.footer" class="card-footer px-3 py-1 small">
            <slot name="footer" />
        </div>
    </b-card>
</template>

<script>
export default {
    name: "Box",
    computed: {
        bodyBgVariantClass() {
            switch (this.variant) {
                case "danger":
                    return "bg-danger";

                case "light":
                    return "bg-light";

                case "success":
                    return "bg-success";

                case "warning":
                    return "bg-warning";

                default:
                    return "";
            }
        },
        marginClass() {
            return this.noBody ? undefined : "mx-3 my-3";
        },
    },
    props: {
        headerText: {
            type: String,
            required: false,
        },
        headerIcon: {
            type: Array,
            required: false,
        },
        headerInfo: {
            type: String,
            required: false,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        variant: {
            type: String,
            required: false,
            default: "",
            validator: (value) => ["", "danger", "light", "success", "warning"].includes(value),
        },
        noBody: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
