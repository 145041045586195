import constants from "@/constants/constants";
import { httpClient } from "@/services/api/client";

const endpoint = "/app/groups";

export default {
    // ********************
    // methods designed for datagrid
    // ********************
    getGroupsForGridUrl() {
        return constants.env.API_ROOT_URL + endpoint + "/grid";
    },

    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, {
            params: params,
        });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload);
    },
};
