<template>
    <div :class="`px-3 py-3 ${bodyBgVariantClass}`">
        <slot />
    </div>
</template>

<script>
export default {
    name: "BoxBody",
    computed: {
        bodyBgVariantClass() {
            switch (this.variant) {
                case "danger":
                    return "bg-danger";

                case "light":
                    return "bg-light";

                case "success":
                    return "bg-success";

                case "warning":
                    return "bg-warning";

                default:
                    return "";
            }
        },
    },
    props: {
        variant: {
            type: String,
            required: false,
            default: "",
            validator: (value) => ["", "danger", "light", "success", "warning"].includes(value),
        },
    },
};
</script>
