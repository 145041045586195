<template>
    <div class="calendar-sidebar">
        <section class="instructions">
            <h2>Calendar list</h2>
        </section>

        <section class="quick-toggles">
            <label>
                <input type="checkbox" v-model="weekendsVisibleCheckbox" />
                Toggle weekends
            </label>
        </section>
        <section class="groups-list">
            <h2>Group calendars</h2>
            Not implemented
            <ul>
                <li v-for="calendar in groupCalendars" :key="calendar.calendarId">
                    <b-form-checkbox v-model="calendar.isVisible" v-on:change="onChangeCalendarVisibility(calendar)">
                        <font-awesome-icon
                            :icon="['fas', 'square-full']"
                            size="xs"
                            class="mr-1"
                            :style="{ color: calendar.color }"
                        />
                        <i>{{ calendar.name }}</i>
                    </b-form-checkbox>
                </li>
            </ul>
        </section>

        <section class="users-list">
            <h2>Users calendar</h2>
            Not implemented
            <ul>
                <li v-for="calendar in userCalendars" :key="calendar.calendarId">
                    <b-form-checkbox v-model="calendar.isVisible" v-on:change="onChangeCalendarVisibility(calendar)">
                        <font-awesome-icon
                            :icon="['fas', 'square-full']"
                            size="xs"
                            class="mr-1"
                            :style="{ color: calendar.color }"
                        />
                        <i>{{ calendar.name }}</i>
                    </b-form-checkbox>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    name: "CalendarsSidebar",
    props: {
        calendars: {
            type: Array,
            required: true,
        },
        weekendsVisible: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    mounted() {},
    computed: {
        groupCalendars() {
            return _.filter(this.calendars, { type: "group" });
        },
        userCalendars() {
            return _.filter(this.calendars, { type: "user" });
        },
        weekendsVisibleCheckbox: {
            get() {
                return this.weekendsVisible;
            },
            set(value) {
                return this.$emit("set-weekends-visible", value);
            },
        },
    },
    methods: {
        onChangeCalendarVisibility(calendar) {
            this.$emit("calendar-change-visibility", {
                calendarId: calendar.calendarId,
                isVisible: calendar.isVisible,
            });
        },
    },
};
</script>

<style scoped>
.calendar-sidebar {
    width: 300px;
    line-height: 1.5;
    background: #eaf9ff;
    border-right: 1px solid #d3e2e8;
}

ul {
    margin: 0;
    padding: 0 0 0 1em;
}

ul li {
    margin: 1em 0;
    padding: 0;
}

li {
    list-style-type: none;
}

section {
    padding: 1em;
}

h2 {
    margin: 0;
    font-size: 16px;
}
</style>
