import Vue from "vue";
import VueRouter from "vue-router";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

import store from "@/store";
import constants from "@/constants/constants";
import { isMobile } from "@/utils/energyspace/ScreenHelper";
import { isUserAuthorizedByRole } from "@/utils/energyspace/PermissionUtils";

// import subroutes
import routerCommon from "@/router/routerCommon";
import routerExecution from "@/router/routerExecution";
import routerHaks from "@/router/routerHaks";
import routerHelp from "@/router/routerHelp";
import routerOrders from "@/router/routerOrders";
import routerUsers from "@/router/routerUsers";

// import components
import AuftragPlanung from "@/views/AuftragPlanung.vue";
import Hangfire from "@/views/Hangfire";
import Home from "@/views/Home.vue";
import DeviceDetails from "@/views/devices/DeviceDetails";
import ReNotFound from "@/views/common/ReNotFound";
import administration from "./administration";

Vue.use(VueRouter);

const routes = [
    ...routerCommon,
    ...routerExecution,
    ...routerHaks,
    ...routerHelp,
    ...routerOrders,
    ...routerUsers,
    ...administration,
    // hangfire route
    {
        path: "/hangfire",
        name: "hangfire",
        component: Hangfire,
    },
    {
        path: "/hangfire/jobs/:jobId",
        name: "hangfire-job-detail",
        component: Hangfire,
    },
    {
        path: "/",
        name: "home",
        redirect: { name: isMobile() ? "execution-list" : "haks-list" }, // Home for mobile:  Ausfürung, Home for Desktop: Hak list
    },
    {
        path: "/auftrag-planung",
        name: "auftrag-planung",
        component: AuftragPlanung,
    },
    {
        path: "/devices/:id",
        name: "device-details",
        component: DeviceDetails,
    },
    {
        path: "/help",
        name: "help",
    },
    // required by service-worker.js
    {
        path: "/index.html",
        name: "index",
        component: Home,
    },
    {
        path: "*",
        name: "notFound",
        component: ReNotFound,
        props: {
            is404: true,
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.VUE_APP_BASE_ROUTE,
    routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store));

// check roles. Important: "unauthorized" and "logout" routes must be public: meta: { isPublic: true }
router.beforeEach((to, from, next) => {
    /* Was added to limit the visibility of the application to a specific user
    if (store.getters.oidcUser && store.getters.oidcUser.name === "odi.andrea.magoni") {
        // TODO: remove after testing. Avoid external consultants to see the whole application
        // Password: see Credentials file
        if (to.name !== "execution-meters") {
            // redirect to page: https://energyspace-test.repower.com/Apps/REtasks/execution/399/4719/zaehler
            next({ name: "execution-meters", params: { orderId: "399", devicePoolId: "4719" } });
        } else {
            next();
        }
    }*/
    if (
        store.getters.oidcUser &&
        !isUserAuthorizedByRole(to, store.getters.oidcUser.roles, constants.identity.REQUIRED_ROLES)
    ) {
        next({ name: "unauthorized", query: { reason: "role" } });
    } else {
        next();
    }
});

export default router;
