import store from "@/store/index";

export default {
    // ********************
    // Add authorization token to grid calls
    // ********************
    getStoreConfig(options) {
        options.onBeforeSend = (method, ajaxOptions) => {
            ajaxOptions.headers = {
                Authorization: `Bearer ${store ? store.getters.oidcAccessToken : "STORE NOT READY"}`,
            };
        };
        return options;
    },
};
