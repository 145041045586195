<template>
    <div>
        <DxDataGrid
            :allow-column-reordering="true"
            :data-source="businessHourManipulatedData"
            :width="'100%'"
            :show-borders="true"
            :ref="businessHoursKey"
            @saved="saveTiming"
            @row-updating="onRowUpdating"
            @row-inserting="onRowInserting"
        >
            <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="popup">
                <DxForm label-location="top">
                    <DxGroupItem item-type="group" :col-span="2" :col-count="2">
                        <DxSimpleItem
                            data-field="weekday.id"
                            :col-span="2"
                            editor-type="dxSelectBox"
                            :editor-options="{
                                dataSource: this.daysArray,
                                displayExpr: 'name',
                                valueExpr: 'id',
                            }"
                        />

                        <DxSimpleItem
                            data-field="from"
                            :editor-type="'dxDateBox'"
                            :editor-options="{
                                type: 'time',
                            }"
                        />
                        <DxSimpleItem
                            data-field="to"
                            :editor-type="'dxDateBox'"
                            :editor-options="{
                                type: 'time',
                            }"
                        />
                    </DxGroupItem>
                </DxForm>
                <DxPopup :show-title="true" title="Arbeitszeit" height="33vh" min-height="310px" width="350px" />
            </DxEditing>

            <DxColumn
                data-field="weekday.id"
                caption="Wochentag"
                calculate-sort-value="weekday.sorter"
                :group-index="0"
                calculate-group-value="weekday.sorter"
                group-cell-template="weekdayTemplate"
            >
            </DxColumn>

            <template #weekdayTemplate="{ data }">
                <div>{{ getWeekDayName(data) }}</div>
            </template>

            <DxColumn
                data-field="from"
                caption="Von"
                sort-order="asc"
                :editor-options="{ placeholder: '00:00:00' }"
                format="HH:mm"
                data-type="date"
            />
            <DxColumn
                data-field="to"
                caption="Bis"
                :editor-options="{ placeholder: '00:00:00' }"
                format="HH:mm"
                data-type="date"
            />

            <DxColumn type="buttons" :width="100" :allow-sorting="false" :allow-grouping="false">
                <DxButton name="edit" icon="edit" />
                <DxButton icon="save" name="save" />
                <DxButton icon="revert" name="cancel" />
                <DxButton icon="trash" name="delete" />
            </DxColumn>

            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="true" />
            <DxPaging :page-size="30" />
            <DxSearchPanel :visible="true" />
        </DxDataGrid>
    </div>
</template>

<script>
import {
    DxButton,
    DxColumn,
    DxDataGrid,
    DxEditing,
    DxForm,
    DxGrouping,
    DxGroupPanel,
    DxPaging,
    DxPopup,
    DxSearchPanel,
} from "devextreme-vue/data-grid";
import { DxGroupItem, DxSimpleItem } from "devextreme-vue/form";
import businessHourAPI from "@/services/api/businessHours.api";
import _ from "lodash";
import moment from "moment/moment";
import constants from "@/constants/constants";

export default {
    name: "BusinessHour",
    components: {
        DxButton,
        DxEditing,
        DxGroupPanel,
        DxSearchPanel,
        DxForm,
        DxSimpleItem,
        DxPaging,
        DxGroupItem,
        DxColumn,
        DxPopup,
        DxGrouping,
        DxDataGrid,
    },
    props: {
        businessHoursKey: {
            type: String,
            required: true,
        },
        businessHours: {
            type: Array,
            required: true,
        },
        companyID: { type: Number, required: true },
        userID: { type: Number, required: false }, // null in case of Company page
    },
    data() {
        return {
            businessHourManipulatedData: [],
        };
    },
    computed: {
        daysArray() {
            return constants.daysArray;
        },
        dataGrid: function() {
            return this.$refs[this.businessHoursKey].instance;
        },
        isUserView() {
            return !!this.userID; // if userID is not null, then the component is in the User view
        },
    },
    mounted() {
        this.businessHourManipulatedData = this.businessHours.map((businessHour) => {
            return this.getManipulatedBusinessHourObject(businessHour);
        });
    },
    methods: {
        getWeekDay(id) {
            return this.daysArray.find((day) => day.id === id);
        },
        getWeekDayName(data) {
            return data?.value >= 0 ? this.daysArray.find((day) => day.sorter === data.value).name : null;
        },
        getManipulatedBusinessHourObject(obj) {
            return {
                id: obj.businessHoursID,
                weekday: this.getWeekDay(obj.dayOfWeek),
                from: this.getIsoDateFromStringTime(obj.startTime),
                to: this.getIsoDateFromStringTime(obj.endTime),
            };
        },
        checkOverlap(newTiming) {
            const { startTime: newStartTime, endTime: newEndTime, dayOfWeek: newDayOfWeek } = newTiming;
            const timingsSameDay = this.businessHourManipulatedData.filter((existingTiming) => {
                return (
                    existingTiming.Weekday === newDayOfWeek &&
                    existingTiming.ID !== undefined &&
                    existingTiming.ID !== newTiming.ID
                );
            });
            for (const existingTiming of timingsSameDay) {
                const { From: existingStartTime, To: existingEndTime } = existingTiming;
                if (
                    (newStartTime >= existingStartTime && newStartTime < existingEndTime) ||
                    (newEndTime > existingStartTime && newEndTime <= existingEndTime) ||
                    (newStartTime <= existingStartTime && newEndTime >= existingEndTime)
                ) {
                    return true;
                }
            }
            return false;
        },
        createBusinessHourObject(data) {
            const obj = {
                businessHoursID: data?.id ?? 0,
                startTime: this.formatTime(data.from),
                endTime: this.formatTime(data.to),
                dayOfWeek: data.weekday.id,
                companyID: this.companyID,
                userID: this.userID ?? null,
            };
            return obj;
        },
        formatTime(time) {
            return moment(time).format("HH:mm:ss");
        },
        getIsoDateFromStringTime(stringTime) {
            if (stringTime) {
                const endTimeSplit = stringTime.split(":");
                return new Date(2024, 0, 1, endTimeSplit[0], endTimeSplit[1], 0, 0).toISOString();
            }
            return null;
        },
        isIncompleteData(data) {
            return data.weekday === undefined || data.from === undefined || data.to === undefined;
        },
        async onRowInserting(e) {
            const newBusinessHourData = e.data;
            const existingBusinessHourData = this.businessHourManipulatedData.find(
                (entry) => entry.weekday === newBusinessHourData.weekday.id
            );
            if (existingBusinessHourData) {
                e.cancel = true;
                this.displayToast("danger", "Es wurde bereits eine Arbeitszeit an diesem Tag hinzugefügt");
                return;
            }
            if (this.isIncompleteData(newBusinessHourData)) {
                e.cancel = true;
                this.displayToast("danger", "Unvollständige Daten für das Einfügen der Arbeitszeit");
                return;
            }
            if (newBusinessHourData.from >= newBusinessHourData.to) {
                e.cancel = true;
                this.displayToast("danger", "Ungültige Daten für das Einfügen der Arbeitszeit");
                return;
            }
            const newBusinessHourObject = this.createBusinessHourObject(newBusinessHourData);
            if (this.checkOverlap(newBusinessHourObject)) {
                e.cancel = true;
                this.displayToast("danger", "Überlappung entdeckt");
                return;
            }
            try {
                const savedBusinessHour = (await businessHourAPI.post(newBusinessHourObject)).data;
                this.businessHourManipulatedData.splice(
                    -1,
                    1,
                    this.getManipulatedBusinessHourObject(savedBusinessHour)
                );
                this.displayToast(
                    "success",
                    `Arbeitszeit von  ${newBusinessHourObject.startTime} bis ${newBusinessHourObject.endTime} wurde erfolgreich hinzugefügt`
                );
            } catch (err) {
                e.cancel = true;
                this.displayToast("danger", "Es ist ein Fehler während des Einfügens aufgetreten");
            } finally {
                this.dataGrid.refresh();
            }
        },
        async onRowUpdating(e) {
            const updatedRowData = _.clone(e.newData);
            const oldRowData = _.clone(e.oldData);
            const rowData = { ...oldRowData, ...updatedRowData };
            const existingBusinessHourData = this.businessHourManipulatedData.find(
                (entry) => entry.weekday === rowData.weekday.id && entry.id !== rowData.id
            );
            if (existingBusinessHourData) {
                e.cancel = true;
                this.displayToast("danger", "Es wurde bereits eine Arbeitszeit an diesem Tag hinzugefügt");
                return;
            }
            if (this.isIncompleteData(rowData)) {
                e.cancel = true;
                this.displayToast("danger", "Unvollständige Daten für das Aktualisieren der Arbeitszeit");
                return;
            }
            if (rowData.from >= rowData.to) {
                e.cancel = true;
                this.displayToast(
                    "danger",
                    "Ungültige Daten für die Aktualisierung der Arbeitszeit mit der ID " + rowData.id
                );
                return;
            }
            const indexOfBusinessHour = this.businessHourManipulatedData.findIndex(
                (element) => element.id === rowData.id
            );
            if (indexOfBusinessHour === -1) {
                e.cancel = true;
                this.displayToast("danger", `Element mit der ID ${rowData.id} konnte nicht gefunden werden.`);
                return;
            }
            if (this.checkOverlap(rowData)) {
                e.cancel = true;
                this.displayToast("danger", "Überlappung entdeckt");
                return;
            }
            this.updateBusinessHourData(indexOfBusinessHour, rowData);
            const updatePayload = this.createBusinessHourObject(rowData);
            try {
                await businessHourAPI.put(rowData.id, updatePayload);
                this.displayToast("success", `ID ${rowData.id} wurde erfolgreich aktualisiert`);
            } catch {
                this.displayToast("danger", "Es ist ein Fehler während der Aktualisierung aufgetreten");
            }
            this.dataGrid.refresh();
        },
        async saveTiming(payload) {
            for (const item of payload.changes) {
                const { key, type } = item;
                if (type === "remove") {
                    await businessHourAPI.delete(key.id).then(() => {
                        this.displayToast("success", `Arbeitszeit mit der ID ${key.id} gelöscht`);
                    });
                }
            }
        },
        displayToast(variant = "INFO", message) {
            let noAutoHide = false;
            let title;
            switch (variant.toUpperCase()) {
                case "DANGER":
                    noAutoHide = true;
                    title = "Fehler";
                    break;
                case "INFO":
                    title = "INFO";
                    break;
                case "SUCCESS":
                    title = "SUCCESS";
                    break;
                default:
                    variant = "info";
                    title = "INFO";
            }
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
        updateBusinessHourData(index, rowData) {
            this.businessHourManipulatedData[index].from = rowData.from;
            this.businessHourManipulatedData[index].to = rowData.to;
            this.businessHourManipulatedData[index].weekday = this.getWeekDay(rowData.weekday.id);
        },
    },
};
</script>

<style scoped></style>
