import constants from "@/constants/constants";
import { httpClient } from "@/services/api/client";

const endpoint = "/net/rolloutview";

export default {
    // ********************
    // methods designed for datagrid
    // ********************
    getHaksForGridUrl() {
        return `${constants.env.API_ROOT_URL}${endpoint}/hak-view`;
    },

    getAllowedAuftragIdsSelectionForAuftragView(auftragStatus) {
        const params = new URLSearchParams();
        auftragStatus.map((auftragState) => params.append("auftragStatus", auftragState));
        return httpClient.get(`${constants.env.API_ROOT_URL}${endpoint}/auftrag-view/auftragIds`, {
            params: params,
        });
    },
};
