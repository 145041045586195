<template>
    <div v-b-tooltip.hover :title="compTooltipContent" :class="compClass" class="d-flex p-1 re-orch-etl">
        <div class="d-flex">
            <font-awesome-icon
                :icon="['fas', compFontAwesomeIcon]"
                class="d-flex align-self-center mr-1"
                :spin="compStatus === 'RUNNING'"
                :beat="compStatus === 'ERROR'"
            />
            <span class="text-nowrap">{{ name }}</span>
        </div>
        <div
            v-if="displayTooltipTodo"
            v-b-tooltip.hover
            title="ToDo: add code to ETL to display 'QUEUED', 'RUNNING', 'ERROR' or 'SUCCESS'"
            class="d-flex ml-1"
        >
            <font-awesome-icon :icon="['fas', 'person-digging']" class="d-flex align-self-center ml-1" />
        </div>
    </div>
</template>

<script>
export default {
    name: "OrchestratorEtlStatus",
    props: {
        asq: {
            type: Object,
        },
        displayTooltipTodo: {
            type: Boolean,
            default: false,
        },
        displayTtInfoDbColumns: {
            type: Boolean,
            default: false,
        },
        displayTtInfoScope: {
            type: Boolean,
            default: true,
        },
        // ETL short name
        name: {
            type: String,
            default: "ETL",
        },
    },
    data() {
        return {
            etlDbColumns: "",
            etlStatusDetail: "",
            etlScope: "",
            etlStatus: "",
        };
    },
    computed: {
        compClass() {
            let compClass = "";
            switch (this.compStatus) {
                case "QUEUED":
                    compClass += " bg-info";
                    break;
                case "RUNNING":
                    compClass += " bg-warning";
                    break;
                case "SKIPPED":
                case "SUCCESS":
                    compClass += " bg-success";
                    break;
                case "ERROR":
                    compClass += " bg-danger";
                    break;
                default:
                    compClass += " bg-danger";
                    break;
            }
            return compClass;
        },
        compFontAwesomeIcon() {
            let fontAwesomeIcon = "";
            switch (this.compStatus) {
                case "QUEUED":
                    fontAwesomeIcon = "hourglass-start";
                    break;
                case "RUNNING":
                    fontAwesomeIcon = "rotate";
                    break;
                case "SKIPPED":
                    fontAwesomeIcon = "forward";
                    break;
                case "ERROR":
                    fontAwesomeIcon = "exclamation-triangle";
                    break;
                case "SUCCESS":
                    fontAwesomeIcon = "circle-check";
                    break;
                default:
                    fontAwesomeIcon = "exclamation-triangle";
                    break;
            }
            return fontAwesomeIcon;
        },
        compStatus() {
            // default to QUEUED if in case of null or empty string
            return [null, ""].includes(this.etlStatus) ? "QUEUED" : this.etlStatus.toUpperCase();
        },
        compTooltipContent() {
            let tooltipContent = `${this.name} `;
            if (this.displayTtInfoScope) {
                tooltipContent += [null, ""].includes(this.etlScope) ? "" : `(${this.etlScope}) `;
            }
            switch (this.compStatus) {
                case "QUEUED":
                    tooltipContent += `is QUEUED but not started yet`;
                    break;
                case "RUNNING":
                    tooltipContent += `is currently RUNNING (but not completed yet)`;
                    break;
                case "SKIPPED":
                    tooltipContent += `has been SKIPPED -> ${this.etlStatusDetail}`;
                    break;
                case "ERROR":
                    tooltipContent += `is in ERROR -> check column "Bemerkung" for details`;
                    break;
                case "SUCCESS":
                    tooltipContent += `has completed SUCCESSfully`;
                    break;
                default:
                    tooltipContent += `has an UNKNOWN STATUS -> please check!`;
                    break;
            }

            if (this.displayTtInfoDbColumns) {
                tooltipContent += [null, ""].includes(this.etlDbColumns) ? "" : ` - (${this.etlDbColumns})`;
            }
            return tooltipContent;
        },
    },
    mounted() {
        this.etlAttributesInit();
    },
    methods: {
        etlAttributesInit() {
            switch (this.name) {
                case "ETL451a":
                    this.etlScope = "EA to REtasks";
                    this.etlDbColumns = "asq.Etl451aStatus/asq.Etl451aStatusDetail";
                    this.etlStatus = this.asq.etl451aStatus;
                    this.etlStatusDetail = this.asq.etl451aStatusDetail;
                    break;
                case "ETL451b":
                    this.etlScope = "REtasks to EA";
                    this.etlDbColumns = "asq.Etl451bStatus/asq.Etl451bStatusDetail";
                    this.etlStatus = this.asq.etl451bStatus;
                    this.etlStatusDetail = this.asq.etl451bStatusDetail;
                    break;
                case "ETL453":
                    this.etlScope = "REtasks - Rellout Auftrag Creation";
                    this.etlDbColumns = "asq.Etl453Status/asq.Etl453StatusDetail";
                    this.etlStatus = this.asq.etl453Status;
                    this.etlStatusDetail = this.asq.etl453StatusDetail;
                    break;
                case "ETL454":
                    this.etlScope = "REtasks to IS-E - XML";
                    this.etlDbColumns = "asq.Etl454Status/asq.Etl454StatusDetail";
                    this.etlStatus = this.asq.etl454Status;
                    this.etlStatusDetail = this.asq.etl454StatusDetail;
                    break;
                case "ETL480":
                    this.etlScope = "IS-E to REtasks";
                    this.etlDbColumns = "asq.Etl480Status/asq.Etl480StatusDetail";
                    this.etlStatus = this.asq.etl480Status;
                    this.etlStatusDetail = this.asq.etl480StatusDetail;
                    break;
                case "ETL481":
                    this.etlScope = "REtasks to IS-E - WS";
                    this.etlDbColumns = "asq.Etl481Status/asq.Etl481StatusDetail";
                    this.etlStatus = this.asq.etl481Status;
                    this.etlStatusDetail = this.asq.etl481StatusDetail;
                    break;
                default:
                    // do nothing
                    break;
            }
        },
    },
    watch: {
        asq: {
            handler() {
                this.etlAttributesInit();
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.re-orch-etl {
    border: 1px solid darkgrey;
    border-radius: 4px;
}
</style>
