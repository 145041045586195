<template>
    <b-container fluid no-gutters>
        <div id="ReHeader">
            <re-header v-if="true" v-bind:isUserAuthenticated="oidcIsAuthenticated" v-bind:title="menuTitle" />
        </div>
        <p v-if="!oidcIsAuthenticated && !$route.meta.isPublic">Authentication in progress, please wait</p>
        <router-view />
    </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import constants from "@/constants/constants";
import ReHeader from "@/components/energyspace/ReHeader";

export default {
    name: "Root",
    components: {
        ReHeader,
    },
    data: function() {
        return {
            menuTitle: "REtasks",
        };
    },
    computed: {
        ...mapGetters(["oidcAccessToken", "oidcIsAuthenticated", "oidcUser", "currentUser"]),
        config: function() {
            return {
                accessToken: this.oidcIsAuthenticated ? this.oidcAccessToken : null,
                apiServerPrefix: constants.env.API_ROOT_URL,
                environmentName: constants.env.NAME,
                username: this.oidcIsAuthenticated ? this.oidcUser.name : null,
            };
        },
    },
    methods: {
        ...mapActions(["fetchCurrentUser"]),
    },
    watch: {
        async oidcIsAuthenticated(val) {
            if (val) {
                await this.fetchCurrentUser();
                if (!this.currentUser || this.currentUser.length === 0) {
                    await this.$router.replace({ name: "unauthorized", query: { reason: "config" } });
                }
            }
        },
    },
};
</script>

<style scoped></style>
