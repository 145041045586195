var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',{attrs:{"headerText":_vm.actAuftragDetail?.action?.title,"isLoading":!_vm.actAuftragDetail || !_vm.order}},[(_vm.actAuftragDetail && _vm.order)?[_c('div',{staticClass:"mb-3"},[_c('ExecButtonPrevious',{attrs:{"navigateToStep":_vm.navigateToStepPrevious},on:{"clickPrevious":_vm.onClickPrevious}}),_c('ExecButtonNext',{staticClass:"float-right",attrs:{"navigateToStep":_vm.navigateToStepNext},on:{"clickNext":_vm.onClickNext}})],1),_c('div',[(!_vm.mainDevice)?_c('b-spinner'):_c('div',[_c('exec-meter-info',{staticClass:"mb-2",attrs:{"mainDevice":_vm.mainDevice,"order":_vm.order,"devicePool":_vm.devicePool,"category":'Wandler',"showRelatedDeviceInfo":true}}),_c('div',[_c('span',[_vm._v("Bitte erfassen Sie die folgenden Daten zur Berechnung des Faktors. Der Faktor wird automatisch in MSP geschrieben. ")])]),_c('b-form',{staticClass:"mt-2"},[_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{attrs:{"cols":"7"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Verhältnis Stromwandler"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.actAuftragDetail.isExecutable},model:{value:(_vm.form732.verhaeltnisStromwandler.numerator),callback:function ($$v) {_vm.$set(_vm.form732.verhaeltnisStromwandler, "numerator", $$v)},expression:"form732.verhaeltnisStromwandler.numerator"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_vm._v(" / ")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.actAuftragDetail.isExecutable},model:{value:(_vm.form732.verhaeltnisStromwandler.denominator),callback:function ($$v) {_vm.$set(_vm.form732.verhaeltnisStromwandler, "denominator", $$v)},expression:"form732.verhaeltnisStromwandler.denominator"}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Faktor"}},[_c('b-form-input',{attrs:{"disabled":"","state":_vm.isInputFormValid(
                                            _vm.form732.verhaeltnisStromwandler.factor,
                                            'verhaeltnisStromwandlerFactor'
                                        )},model:{value:(_vm.form732.verhaeltnisStromwandler.factor),callback:function ($$v) {_vm.$set(_vm.form732.verhaeltnisStromwandler, "factor", $$v)},expression:"form732.verhaeltnisStromwandler.factor"}})],1)],1),_c('b-col',{staticClass:"mb-2 pl-0",attrs:{"cols":"1"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-circle'],"size":"lg"},on:{"click":function($event){return _vm.onClickMoreInfo(_vm.verhaeltnisStromwandlerInfo)}}})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-invalid-feedback',{staticClass:"mb-2",attrs:{"state":_vm.isInputFormValid(
                                        _vm.form732.verhaeltnisStromwandler.factor,
                                        'verhaeltnisStromwandlerFactor'
                                    )}},[_vm._v(" "+_vm._s(_vm.validationErrors.verhaeltnisStromwandlerFactor)+" ")])],1)],1),_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{attrs:{"cols":"7"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Verhältnis Zähler-Parametrierung Strom"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.form732.isDeviatingRatioChecked || !_vm.actAuftragDetail.isExecutable},model:{value:(_vm.form732.verhaeltnisZaehlerParametrierungStrom.numerator),callback:function ($$v) {_vm.$set(_vm.form732.verhaeltnisZaehlerParametrierungStrom, "numerator", $$v)},expression:"form732.verhaeltnisZaehlerParametrierungStrom.numerator"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_vm._v(" / ")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.form732.isDeviatingRatioChecked || !_vm.actAuftragDetail.isExecutable},model:{value:(_vm.form732.verhaeltnisZaehlerParametrierungStrom.denominator),callback:function ($$v) {_vm.$set(_vm.form732.verhaeltnisZaehlerParametrierungStrom, "denominator", $$v)},expression:"form732.verhaeltnisZaehlerParametrierungStrom.denominator"}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Faktor"}},[_c('b-form-input',{attrs:{"disabled":"","state":_vm.isInputFormValid(
                                            _vm.form732.verhaeltnisZaehlerParametrierungStrom.factor,
                                            'verhaeltnisZaehlerParametrierungStromFactor'
                                        )},model:{value:(_vm.form732.verhaeltnisZaehlerParametrierungStrom.factor),callback:function ($$v) {_vm.$set(_vm.form732.verhaeltnisZaehlerParametrierungStrom, "factor", $$v)},expression:"form732.verhaeltnisZaehlerParametrierungStrom.factor"}})],1)],1),_c('b-col',{staticClass:"mb-2 pl-0",attrs:{"cols":"1"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-circle'],"size":"lg"},on:{"click":function($event){return _vm.onClickMoreInfo(_vm.verhaeltnisZaehlerParametrierungStromInfo)}}})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-invalid-feedback',{staticClass:"mb-2",attrs:{"state":_vm.isInputFormValid(
                                        _vm.form732.verhaeltnisZaehlerParametrierungStrom.factor,
                                        'verhaeltnisZaehlerParametrierungStromFactor'
                                    )}},[_vm._v(" "+_vm._s(_vm.validationErrors.verhaeltnisZaehlerParametrierungStromFactor)+" ")])],1)],1),_c('b-row',{staticClass:"align-items-center mb-3"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"disabled":!_vm.actAuftragDetail.isExecutable},model:{value:(_vm.form732.isDeviatingRatioChecked),callback:function ($$v) {_vm.$set(_vm.form732, "isDeviatingRatioChecked", $$v)},expression:"form732.isDeviatingRatioChecked"}},[_vm._v("abweichendes Verhältnis")])],1)],1),_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Resultierender Gesamtfaktor für MSP"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.form732.verhaeltnisMsp.numerator),callback:function ($$v) {_vm.$set(_vm.form732.verhaeltnisMsp, "numerator", $$v)},expression:"form732.verhaeltnisMsp.numerator"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_vm._v(" / ")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.form732.verhaeltnisMsp.denominator),callback:function ($$v) {_vm.$set(_vm.form732.verhaeltnisMsp, "denominator", $$v)},expression:"form732.verhaeltnisMsp.denominator"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('ExecSectionErledigt',{attrs:{"isBusy":_vm.compIsBusy,"isExecutable":_vm.actAuftragDetail.isExecutable,"isExecutableDetails":_vm.actAuftragDetail.isExecutableDetails,"preconditionsLocalDetails":_vm.compPreconditionsLocalDetails},on:{"click-erledigt":_vm.onClickErledigt}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }