import Decimal from "decimal.js";

/**
 *
 * @param string1
 * @param string2
 * @param decimalDigits
 * @returns {string}
 */
export function sumStrings(string1, string2, decimalDigits = 2) {
    const number1 = new Decimal(string1);
    const number2 = new Decimal(string2);
    const sum = number1.plus(number2);
    return sum.toFixed(decimalDigits).toString();
}
