<template>
    <div>
        <h3>Device Detail</h3>
        <hr />
        <b-row class="mt-2">
            <b-col class="cst-col-summary">Gerät</b-col>
            <b-col v-if="device">
                <b-form>
                    <b-row>
                        <b-col cols="3">
                            <label class="mr-2">Gerätetyp:</label>
                            <b-form-input v-model="device.type" disabled></b-form-input>
                        </b-col>
                        <b-col cols="3">
                            <label class="mx-2">Gerät:</label>
                            <b-form-input v-model="device.deviceSuffix" disabled></b-form-input>
                        </b-col>
                        <b-col cols="3">
                            <label class="mx-2">Typ:</label>
                            <b-form-input v-model="device.deviceType"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols="3">
                            <label class="mr-2">Information:</label>
                            <b-form-input v-model="device.description"></b-form-input>
                        </b-col>
                        <b-col cols="3">
                            <label class="mx-2">Baujahr:</label>
                            <b-form-input v-model="device.yearOfConstruction" type="number"></b-form-input>
                        </b-col>
                        <b-col cols="3">
                            <label class="mx-2">Fabriknummer:</label>
                            <b-form-input v-model="device.factoryNo"></b-form-input>
                        </b-col>
                        <b-col cols="3">
                            <label class="mx-2">Interne Nummer:</label>
                            <b-form-input v-model="device.internalNo"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-button class="mt-2" variant="primary" @click="onClickUpdateDevice" :disabled="isSavingDevice">
                        Speichern
                    </b-button>
                </b-form>
            </b-col>
        </b-row>
        <hr v-if="meteringCode" />
        <b-row v-if="meteringCode" class="mt-2">
            <b-col class="cst-col-summary">Messpunkt</b-col>
            <b-col>
                <b-form v-if="meteringCode.length > 0">
                    <b-row>
                        <b-col cols="3">
                            <label class="mr-2">Gemessenes Objekt:</label>
                            <b-form-input v-model="meteringCode[0].measuringObject" disabled></b-form-input>
                        </b-col>
                        <b-col cols="3">
                            <label class="mr-2">Messpunkt:</label>
                            <b-form-input v-model="meteringCode[0].mc" disabled></b-form-input>
                        </b-col>
                        <b-col cols="6">
                            <label class="mr-2">Name:</label>
                            <b-form-input v-model="meteringCode[0].measuringName" disabled></b-form-input>
                        </b-col>
                        <!-- TODO: this column is not in PROD -->
                        <!--b-col cols="1">
                            <label class="mr-2">Priorität:</label>
                            <b-form-input v-model="meteringCode[0].priority" disabled></b-form-input>
                        </b-col-->
                    </b-row>
                </b-form>
                <b-alert v-else class="mb-0" show>
                    Für den Elektrizitätszähler wurde kein Messpunkt gefunden.
                    <div v-if="oidcUserHasRoleAdmin" class="small text-black-50">
                        <font-awesome-icon :icon="['fas', 'hard-hat']" />:
                        <code>
                            select * from businessData.mdm.MeteringCode where FromDate &lt;= GETDATE() and ToDate >
                            GETDATE() and MeteringCode in (select Meteringcode from businessData.net.Device where
                            DeviceID={{ device.deviceID }})
                        </code>
                    </div>
                </b-alert>
            </b-col>
        </b-row>
        <hr />
        <b-row class="mt-2">
            <b-col class="cst-col-summary">Geräteinfo</b-col>
            <b-col>
                <b-table
                    v-if="deviceInfos.length"
                    class="mb-0"
                    :items="deviceInfos"
                    :fields="deviceInfosFields"
                    small
                    hover
                >
                    <template v-slot:cell()="{ value, item, field: { key } }">
                        <template v-if="deviceInfosIdEdited != item.deviceInfoID">{{ value }}</template>
                        <b-form-input v-else v-model="item[key]" />
                    </template>
                    <template #cell(actionEdit)="row">
                        <span v-if="deviceInfosIdEdited === 0" @click="onDeviceInfoEdit(row.item.deviceInfoID)">
                            <font-awesome-icon :icon="['fas', 'edit']" class="re-icon" size="lg" />
                        </span>
                    </template>
                    <template #cell(actionCancel)="row">
                        <b-button
                            v-if="deviceInfosIdEdited === row.item.deviceInfoID"
                            variant="secondary"
                            @click="onDeviceInfoCancel()"
                            size="sm"
                            :disabled="isSavingDeviceInfo"
                        >
                            Abbrechen
                        </b-button>
                    </template>
                    <template #cell(actionSave)="row">
                        <b-button
                            v-if="deviceInfosIdEdited === row.item.deviceInfoID"
                            variant="primary"
                            @click="onDeviceInfoSave(row.item)"
                            size="sm"
                            :disabled="isSavingDeviceInfo"
                        >
                            Speichern
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="cst-col-summary"></b-col>
            <b-col>
                <b-button class="mt-2" v-b-toggle.collapse-deviceinfo-add variant="primary">
                    Geräteinfo hinzufügen
                </b-button>
                <b-collapse id="collapse-deviceinfo-add" class="mt-2">
                    <b-card>
                        <b-form class="mb-2">
                            <b-row>
                                <b-col cols="1">
                                    <label class="mr-2">Geräte ID:</label>
                                    <b-form-input v-model="deviceInfoAdd.deviceID" required disabled />
                                </b-col>
                                <b-col cols="3">
                                    <label class="mr-2">Name:</label>
                                    <b-form-input
                                        v-model="deviceInfoAdd.name"
                                        required
                                        placeholder="Bitte Name eintragen..."
                                        :state="stateDeviceInfoAddName"
                                    />
                                </b-col>
                                <b-col cols="3">
                                    <label class="mr-2">Wert:</label>
                                    <b-form-input
                                        v-model="deviceInfoAdd.value"
                                        :state="stateDeviceInfoAddValue"
                                        required
                                        placeholder="Bitte Wert eintragen..."
                                    />
                                </b-col>
                                <b-col cols="2">
                                    <label class="mr-2">Datentyp:</label>
                                    <b-form-input
                                        v-model="deviceInfoAdd.dataType"
                                        :state="stateDeviceInfoAddDataType"
                                        required
                                        placeholder="Bitte Datentyp eintragen..."
                                    />
                                </b-col>
                                <b-col cols="1">
                                    <label class="mr-2">Einheit:</label>
                                    <b-form-input v-model="deviceInfoAdd.unit" />
                                </b-col>
                            </b-row>
                            <!-- disabled because not required -->
                            <b-row v-if="false" class="mt-2">
                                <b-col cols="2">
                                    <label class="mr-2">Gültig von:</label>
                                    <b-form-input v-model="deviceInfoAdd.fromDate" type="datetime" required disabled />
                                </b-col>
                                <b-col cols="2">
                                    <label class="mr-2">Gültig bis:</label>
                                    <b-form-input v-model="deviceInfoAdd.toDate" type="datetime" required disabled />
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="7">
                                    <label class="mr-2">Notiz:</label>
                                    <b-form-textarea
                                        rows="3"
                                        v-model="deviceInfoAdd.note"
                                        placeholder="Hier kann eine Notiz eingeben werden"
                                    />
                                </b-col>
                            </b-row>
                            <div class="mt-2">
                                <b-button v-b-toggle.collapse-deviceinfo-add variant="secondary">Abbrechen</b-button>
                                <b-button
                                    class="ml-2"
                                    variant="primary"
                                    @click="onDeviceInfoAdd"
                                    :disabled="validationFailedDeviceInfoAdd || isSavingDeviceInfoAdd"
                                >
                                    Speichern
                                </b-button>
                            </div>
                        </b-form>
                    </b-card>
                </b-collapse>
            </b-col>
        </b-row>
        <hr />
        <b-row v-if="deviceType === 'elektrizitätszähler'" class="mt-2">
            <b-col class="cst-col-summary">Messwerte</b-col>
            <b-col>
                <MeterRegister v-if="device" :deviceId="deviceId" :allowTelemetering="device.allowTelemetering" />
            </b-col>
        </b-row>
        <hr v-if="deviceType === 'elektrizitätszähler'" />
        <b-row class="mt-2">
            <b-col class="cst-col-summary">Fotos</b-col>
            <b-col>
                <DateSortedImageGallery
                    app="rellout"
                    entity="device"
                    :entityId="deviceId"
                    :downloadable="true"
                ></DateSortedImageGallery>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";

import { mapGetters } from "vuex";

import devicesAPI from "@/services/api/devices.api";
import deviceInfosAPI from "@/services/api/deviceInfos.api";
import meteringCodesAPI from "@/services/api/meteringCodes.api";

import MeterRegister from "@/components/devices/MeterRegister";
import DateSortedImageGallery from "@/components/hak/DateSortedImageGallery";

export default {
    name: "DeviceDetails",
    components: {
        MeterRegister,
        DateSortedImageGallery,
    },
    data() {
        return {
            device: null,
            deviceInfoAdd: {
                deviceID: 0,
                name: "",
                dataType: "",
                value: "",
                unit: "",
                note: "",
                fromDate: "",
                toDate: "",
            },
            deviceInfoAddInitial: {
                deviceID: 0,
                name: "",
                dataType: "",
                value: "",
                unit: "",
                note: "",
                fromDate: "",
                toDate: "",
            },
            deviceInfos: [],
            // the initial value of deviceInfos (before changes during edit mode have been applied)
            // note: used to restore initial values after "cancel" has been clicked during edit
            deviceInfosBefore: [],
            deviceInfosFields: [
                { key: "name", label: "Name" },
                { key: "value", label: "Wert" },
                { key: "dataType", label: "Datentyp" },
                { key: "actionEdit", label: "", thStyle: { width: "25px" } },
                { key: "actionCancel", label: "", thStyle: { width: "95px" } },
                { key: "actionSave", label: "", thStyle: { width: "90px" } },
            ],
            // the id of currently edited deviceInfo record
            deviceInfosIdEdited: 0,
            isSavingDevice: false,
            isSavingDeviceInfo: false,
            isSavingDeviceInfoAdd: false,
            meteringCode: null,
            meterRegistersFields: [
                { key: "meterRegisterID" },
                { key: "deviceID" },
                { key: "obis" },
                { key: "identifier" },
                { key: "accountingType" },
                { key: "meterReturn" },
                { key: "cumulative" },
                { key: "unit" },
                { key: "meteringType" },
                { key: "energyType" },
                { key: "forAccounting" },
                { key: "fromDate" },
                { key: "toDate" },
            ],
        };
    },
    computed: {
        ...mapGetters(["oidcUser"]),
        deviceId() {
            return Number(this.$route.params.id);
        },
        deviceType() {
            return this.device ? this.device.type.toLowerCase() : "n/a";
        },
        stateDeviceInfoAddDataType() {
            return !!this.deviceInfoAdd.dataType.length;
        },
        oidcUserHasRoleAdmin() {
            return this.oidcUser ? this.oidcUser.role.includes("miAdmin") : false;
        },
        stateDeviceInfoAddName() {
            return !!this.deviceInfoAdd.name.length;
        },
        stateDeviceInfoAddValue() {
            return !!this.deviceInfoAdd.value.length;
        },
        validationFailedDeviceInfoAdd() {
            return !(this.stateDeviceInfoAddDataType && this.stateDeviceInfoAddName && this.stateDeviceInfoAddValue);
        },
    },
    methods: {
        onClickUpdateDevice() {
            const payload = [
                {
                    op: "replace",
                    path: "/deviceType",
                    value: this.device.deviceType,
                },
                {
                    op: "replace",
                    path: "/description",
                    value: this.device.description,
                },
                {
                    op: "replace",
                    path: "/factoryNo",
                    value: this.device.factoryNo,
                },
                {
                    op: "replace",
                    path: "/yearOfConstruction",
                    value: this.device.yearOfConstruction,
                },
            ];
            this.isSavingDevice = true;
            this.patchDevices(this.device.deviceID, payload)
                .then((resp) => {
                    this.deviceInfosIdEdited = 0;
                    this.$bvToast.toast(`Gerät erfolgreich gespeichert.`, {
                        title: "Success",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                    return resp.data;
                })
                .finally(() => {
                    this.isSavingDevice = false;
                });
        },
        onDeviceInfoAdd() {
            this.isSavingDeviceInfoAdd = true;
            this.postDeviceInfos(this.deviceInfoAdd)
                .then((resp) => {
                    this.$bvToast.toast(`Geräteinfo erfolgreich hinzugefügt.`, {
                        title: "Success",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                    // reset form model data to initial values
                    this.deviceInfoAdd = JSON.parse(JSON.stringify(this.deviceInfoAddInitial));
                    // update deviceInfos
                    this.getDeviceInfos(this.deviceId).then();
                    return resp.data;
                })
                .finally(() => {
                    this.isSavingDeviceInfoAdd = false;
                });
        },
        onDeviceInfoCancel() {
            this.deviceInfos = JSON.parse(JSON.stringify(this.deviceInfosBefore));
            this.deviceInfosIdEdited = 0;
        },
        onDeviceInfoEdit(deviceInfoId) {
            this.deviceInfosBefore = JSON.parse(JSON.stringify(this.deviceInfos));
            this.deviceInfosIdEdited = deviceInfoId;
        },
        onDeviceInfoSave(deviceInfo) {
            const payload = [
                {
                    op: "replace",
                    path: "/dataType",
                    value: deviceInfo.dataType,
                },
                {
                    op: "replace",
                    path: "/name",
                    value: deviceInfo.name,
                },
                {
                    op: "replace",
                    path: "/value",
                    value: deviceInfo.value,
                },
            ];
            this.isSavingDeviceInfo = true;
            this.patchDeviceInfos(deviceInfo.deviceInfoID, payload)
                .then((resp) => {
                    this.deviceInfosIdEdited = 0;
                    this.$bvToast.toast(`Geräteinfo erfolgreich gespeichert.`, {
                        title: "Success",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                    return resp.data;
                })
                .finally(() => {
                    this.isSavingDeviceInfo = false;
                });
        },
        getDevice(id) {
            return devicesAPI.getSingle(id);
        },
        getDeviceInfos(id) {
            return deviceInfosAPI.get({ deviceID: id }).then((resp) => {
                this.deviceInfos = resp;
            });
        },
        getMeteringCodeActive(meteringCode) {
            return meteringCodesAPI.get({
                mc: meteringCode,
                onlyActive: true,
            });
        },
        patchDeviceInfos(id, payload) {
            return deviceInfosAPI.patch(id, payload);
        },
        patchDevices(id, payload) {
            return devicesAPI.patch(id, payload);
        },
        postDeviceInfos(payload) {
            return deviceInfosAPI.post(payload);
        },
    },
    async mounted() {
        this.deviceInfoAddInitial.deviceID = this.deviceId;
        this.deviceInfoAddInitial.fromDate = moment().format("YYYY-MM-DD[T00:00:00]");
        this.deviceInfoAddInitial.toDate = moment("2050-12-31").format("YYYY-MM-DD[T00:00:00]");
        this.deviceInfoAdd = JSON.parse(JSON.stringify(this.deviceInfoAddInitial));

        // we have to await here because we need the retrieved device for further processing
        await this.getDevice(this.deviceId).then((resp) => {
            this.device = resp.data;
        });
        this.getDeviceInfos(this.deviceId).then();
        if (this.device.type.toLowerCase() === "elektrizitätszähler") {
            this.getMeteringCodeActive(this.device.meteringCode).then((resp) => {
                this.meteringCode = resp.data;
            });
        }
    },
};
</script>

<style scoped>
.cst-col-summary {
    font-weight: bold;
    max-width: 200px;
}
</style>
