<template>
    <Main>
        <ExecHeader :devicePool="devicePool" :order="order" />
        <router-view :devicePool="devicePool" :order="order" />
    </Main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecHeader from "@/components/execution/ExecHeader";
import auftragsAPI from "@/services/api/auftrags.api";
import devicePoolsAPI from "@/services/api/devicepools.api";

export default {
    name: "Step_8",
    components: { ExecHeader },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            devicePool: null,
            order: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
    },
    mounted() {
        auftragsAPI.getSingle(this.$route.params.orderId).then((resp) => {
            this.order = resp.data;
        });
        devicePoolsAPI.getSingle(this.$route.params.devicePoolId).then((resp) => {
            this.devicePool = resp.data;
        });
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
            setAuftragDetails: "execution/setAuftragDetails",
        }),
    },
};
</script>
