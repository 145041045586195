<template>
    <Main>
        <ExecHeader :devicePool="devicePool" :order="order" />
        <b-alert
            v-if="!isLoading && auftragDetails511?.length !== deviceFlexibilitaets.length"
            variant="danger"
            class="my-2"
            show
        >
            <div class="font-weight-bold">FEHLER - Missverhältnis Flexibilitäten</div>
            <div>
                Tabelle "deviceFlexibilitaet" enthält <strong>{{ deviceFlexibilitaets.length }}</strong> Einträge,
                Tabelle "auftragDetail" enthält <strong>{{ auftragDetails511?.length ?? 0 }}</strong> Einträge.
            </div>
            <div class="mt-1 small font-italic">
                <font-awesome-icon :icon="['fas', 'info-circle']" /> Die Anzahl der Einträge muss identisch sein.
            </div>
        </b-alert>
        <ExecFlexibilities :isLoading="isLoading" :order="order" noBody />
    </Main>
</template>

<script>
import _ from "lodash";

import ExecHeader from "@/components/execution/ExecHeader";
import ExecFlexibilities from "@/components/execution/ExecFlexibilities";

import auftragsAPI from "@/services/api/auftrags.api";
import deviceFlexibilitaetsAPI from "@/services/api/deviceFlexibilitaets.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import assetsAPI from "@/services/api/assets.api";

export default {
    name: "ExecutionFlexibilities",
    components: { ExecHeader, ExecFlexibilities },
    data() {
        return {
            deviceFlexibilitaets: [],
            devicePool: null,
            isLoading: true,
            order: null,
            auftragDetails511: [],
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            for (const ad of this.auftragDetails511) {
                // Set fotoAufgenommen
                ad.fotoAufgenommen = await this.hasAssets(ad.deviceID, ad.auftragDetailID);
                ad.statusCode = ad.status;
                // Load flexibilitaets
                if (ad.deviceID) {
                    const deviceFlex = (await deviceFlexibilitaetsAPI.getSingle(ad.deviceID, { includeDevices: true }))
                        .data;
                    this.deviceFlexibilitaets.push(deviceFlex);
                    ad.device = deviceFlex ? _.clone(deviceFlex) : ad.device;
                } else {
                    console.warn(`AuftragDetail ${ad.auftragDetailID} has no deviceID`); // should never happend
                }
            }
            await Promise.all([
                auftragsAPI.getSingle(this.$route.params.orderId).then((resp) => {
                    this.order = resp.data;
                }),
                devicePoolsAPI.getSingle(this.$route.params.devicePoolId).then((resp) => {
                    this.devicePool = resp.data;
                }),
            ]);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        async hasAssets(deviceID, auftragDetailID) {
            if (!deviceID || !auftragDetailID) {
                return false; // should never happend
            }
            const resp = assetsAPI.get({
                app: "rellout",
                entity: "device",
                entityId: deviceID,
                refEntity: "auftragdetail",
                refEntityId: auftragDetailID,
            });
            return resp.data?.length > 0;
        },
    },
};
</script>
