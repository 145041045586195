<template>
    <div>
        <DxDataGrid
            id="grid-container"
            ref="dataGridRef"
            :data-source="groups"
            :show-borders="true"
            :remote-operations="true"
            :allow-column-resizing="true"
            :allow-column-reordering="true"
            :show-row-lines="true"
            :show-column-lines="false"
            :row-alternation-enabled="true"
            :column-auto-width="true"
            :on-row-prepared="onRowPrepared"
        >
            <DxLoadPanel :visible="isLoading" :enabled="true" :showIndicator="true" :showPane="true"></DxLoadPanel>
            <DxHeaderFilter :visible="true" :search="{ enabled: true }">
                <DxSearch :enabled="true" :timeout="900" :editor-options="searchEditorOptions" mode="contains" />
            </DxHeaderFilter>
            <DxSearchPanel :visible="true" width="30vw"></DxSearchPanel>
            <DxPaging :page-size="20" />
            <DxPager
                :show-page-size-selector="true"
                :allowed-page-sizes="[20, 50, 100]"
                :show-navigation-buttons="true"
                :show-info="true"
            />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="false" />
            <DxToolbar>
                <DxItem name="groupPanel" />
                <DxItem location="after" widget="dxButton" :options="clearFilterButtonOptions" />
                <DxItem widget="dxButton" :options="reloadButtonOptions" />
                <DxItem name="searchPanel"></DxItem>
            </DxToolbar>

            <DxColumn data-field="groupID" caption="ID" data-type="number" width="60" :visible="false" />
            <DxColumn data-field="name" caption="Gruppenname" data-type="string" sortOrder="asc" />
            <DxColumn data-field="company.name" caption="Firma" data-type="string" />
            <DxColumn data-field="membersCount" caption="Anzahl Mitglieder" data-type="number" />
            <DxColumn type="buttons" :width="50" :allow-sorting="false" :allow-grouping="false">
                <DxButton hint="Bearbeiten" :disabled="!canEdit" icon="edit" text="go" :on-click="onClickEditGroup" />
            </DxColumn>
            <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
            <template #masterDetailTemplate="{ data: group }">
                <div v-if="group.data.users.length > 0">
                    <font-awesome-icon :icon="['fas', 'user-group']" size="sm" />
                    <span class="ml-2">Mitglieder:</span>
                    <div>
                        <span v-for="(user, index) of group.data.users" :key="user.id">
                            <span v-if="index !== 0">, </span>
                            <router-link
                                :is="canEdit ? 'router-link' : 'span'"
                                :to="{ name: 'user-edit', params: { userID: user.id } }"
                            >
                                {{ user.name }}
                            </router-link>
                        </span>
                    </div>
                </div>
                <template v-else><div class="text-muted">Keine Mitglieder</div></template>
            </template>
        </DxDataGrid>
    </div>
</template>

<script>
import constants from "@/constants/constants";
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    DxButton,
    DxColumn,
    DxDataGrid,
    DxGroupPanel,
    DxGrouping,
    DxHeaderFilter,
    DxItem,
    DxLoadPanel,
    DxMasterDetail,
    DxPager,
    DxPaging,
    DxSearch,
    DxSearchPanel,
    DxToolbar,
} from "devextreme-vue/data-grid";
import { mapGetters } from "vuex";
import groupsApi from "../../services/api/groups.api";
import gridService from "../../services/gridBaseService";

export default {
    name: "Groups",
    components: {
        DxButton,
        DxDataGrid,
        DxColumn,
        DxGroupPanel,
        DxGrouping,
        DxHeaderFilter,
        DxItem,
        DxLoadPanel,
        DxMasterDetail,
        DxPager,
        DxPaging,
        DxSearch,
        DxSearchPanel,
        DxToolbar,
    },
    data() {
        return {
            groups: [],
            searchEditorOptions: {
                placeholder: "Search value",
                mode: "text",
            },
            clearFilterButtonOptions: {
                icon: "fas fa-filter-circle-xmark",
                hint: "Filter löschen",
                onClick: () => {
                    this.$refs.dataGridRef.instance.clearFilter();
                },
            },
            reloadButtonOptions: {
                icon: "fas fa-rotate",
                hint: "Aktualisieren",
                onClick: () => {
                    this.$refs.dataGridRef.instance.refresh();
                },
            },
            isLoading: false,
        };
    },
    async mounted() {
        this.isLoading = true;

        try {
            const remoteDataSource = createStore(
                gridService.getStoreConfig({
                    key: "groupID",
                    loadUrl: groupsApi.getGroupsForGridUrl(),
                })
            );
            this.groups = remoteDataSource;
        } finally {
            this.isLoading = false;
        }
    },
    computed: {
        ...mapGetters(["currentUser"]),
        canEdit() {
            return (
                this.currentUser &&
                this.currentUser.userRoles.some((userRole) =>
                    [constants.LEITER_ROLE_ID, constants.ADMIN_ROLE_ID].includes(userRole.roleID)
                )
            );
        },
    },
    methods: {
        onClickEditGroup(e) {
            this.$router.push({ name: "group-edit", params: { groupID: e.row?.data?.groupID } });
            e.event.preventDefault();
        },
        onRowPrepared(row) {
            if (row.rowType === "data" && row.data.users?.length === 0) {
                row.rowElement.children[0]?.children[0]?.remove();
            }
        },
    },
};
</script>

<style scoped>
#grid-container {
    width: 100%;
    height: calc(100vh - 112px);
}
</style>
