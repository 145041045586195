import { httpClient } from "@/services/api/client";

const endpoint = "/net/key-value-options";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, { params: params });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    // **************
    // custom methods
    // **************
};
