<template v-if="imageList">
    <div>
        <div v-for="(image, index) in imageList" :key="`image_${index}`" class="d-inline-flex">
            <image-box
                v-if="!imageIDs.includes(image.id)"
                :image="image"
                :isAssetDeletable="isAssetDeletable"
                :isDisabled="isDisabled"
                :isImageUploaded="image.IsUploaded"
                :isUploadCurrentlyInProgress="isUploadCurrentlyInProgress"
                :isDeletionDetailShown="compIsImageListCountInvalid"
                @assetIsDeleted="assetDeleted(image.id)"
                @assetIsUploaded="assetUpload"
                @animationStatus="animationStatus"
            ></image-box>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImageBox from "./ImageBox.vue";

export default {
    name: "ImageGallery",
    components: {
        ImageBox,
    },
    props: {
        isAssetDeletable: {
            type: Boolean,
            default: false,
        },
        imageList: {
            type: Array,
            default: null,
        },
        isDisabled: {
            type: Boolean,
            defaults: true,
        },
        isUploadCurrentlyInProgress: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            imageIDs: [],
        };
    },
    computed: {
        compIsImageListCountInvalid() {
            return this.imageList?.length <= 1;
        },
        ...mapGetters({
            oidcAccessToken: "oidcAccessToken",
        }),
    },
    methods: {
        animationStatus(value) {
            this.$emit("animationStatus", value);
        },
        assetUpload(image) {
            this.$emit("assetIsUploaded", image);
        },
        assetDeleted(imageId) {
            this.imageIDs.push(imageId);
            this.$emit("assetIsDeleted", imageId);
        },
    },
};
</script>

<style scoped></style>
