<template>
    <div>
        <b-row class="mt-4">
            <b-col class="text-center">
                <h1>Benutzer nicht autorisiert</h1>
                <p>Sie haben keine Berechtigung, diese Seite zu sehen</p>
                <p v-if="reason === 'role'">
                    Role nicht im JWT-Token gefunden
                </p>
                <p v-if="reason === 'config'" class="font-weight-bold">
                    Benutzer ist nicht in der REtasks-Datenbank konfiguriert
                </p>
                <p v-if="reason === 'oidc-error'" class="font-weight-bold">
                    Beim Einloggen ist ein interner Fehler aufgetreten
                </p>
                <b-button variant="primary" class="mt-1" @click="signOutOidc">Logout</b-button>
            </b-col>
        </b-row>
        <b-row v-if="reason != 'oidc-error'">
            <b-col>
                <h3>Details</h3>
                <ul>
                    <li>Grund: {{ reason }}</li>
                    <li>Name: {{ user.name }}</li>
                    <li>Email: {{ user.email }}</li>
                    <li>Username: {{ user.username }}</li>
                    <li>Roles: {{ user.roles }}</li>
                    <li>OID: {{ user.oid }}</li>
                </ul>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ReNotAuth",
    data() {
        return {
            reason: "",
            user: {
                name: "",
                email: "",
                username: "",
                roles: [],
                oid: "",
            },
        };
    },
    computed: {
        ...mapGetters(["oidcUser"]),
    },
    mounted() {
        this.reason = this.$route?.query?.reason ?? "generic";
        this.user.name = this.oidcUser?.name ?? "";
        this.user.username = this.oidcUser?.preferred_username ?? "";
        this.user.email = this.oidcUser?.email ?? "";
        this.user.oid = this.oidcUser?.oid ?? "";
        this.user.roles = this.oidcUser?.roles?.join(", ") ?? "";
    },
    methods: {
        ...mapActions(["signOutOidc"]),
    },
};
</script>

<style scoped></style>
