import { httpClient } from "@/services/api/client";

const endpoint = "/common/ping";

export default {
    // **************
    // custom methods
    // **************
    sendPing() {
        return httpClient.options(endpoint);
    },
};
