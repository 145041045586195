// noinspection ES6ConvertVarToLetConst
/**
 * Check if the current browser is Internet Explorer (not compatible)
 * @returns {boolean}
 */
export function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
        // IE 10 or older => return version number
        return true;
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
        // IE 11 => return version number
        return true;
    }

    // other browser
    return false;
}
