import Vue from "vue";
import Vuex from "vuex";
import { WebStorageStateStore } from "oidc-client-ts";
import { vuexOidcCreateStoreModule } from "vuex-oidc";

import constants from "../constants/constants";
import execution from "@/store/modules/execution";
import haksModule from "@/store/modules/haks";
import userModule from "@/store/modules/users";

Vue.use(Vuex);
// Enable oidc-client debug log -> import { WebStorageStateStore, Log } from "oidc-client";
//Log.logger = console;
//Log.level = Log.DEBUG;

const state = {
    packageVersion: process.env.PACKAGE_VERSION || "0",
    /**
     * Error object.
     * code: 0 for Ok, < 0 for errors, > 0 for warnings
     */
    error: {
        code: null,
        message: null,
        details: null,
    },
};

const actions = {
    raiseError({ commit }, errorObject) {
        commit("SET_ERROR", errorObject);
    },
    resetError({ commit }) {
        commit("SET_ERROR", { code: null, message: null, details: null });
    },
};

const mutations = {
    SET_ERROR(state, errorObject) {
        state.error = errorObject;
    },
};

const getters = {
    error: (state) => {
        return state.error;
    },
};

const oidcModule = vuexOidcCreateStoreModule(
    {
        ...constants.identity.settings,
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    },
    { routeBase: process.env.VUE_APP_BASE_ROUTE },
    // Optional OIDC event listeners
    {
        userLoaded: () => console.log("[Identity] OIDC user is loaded:"),
        userUnloaded: () => console.log("[Identity] OIDC user is unloaded"),
        accessTokenExpiring: () => console.log("[Identity] Access token will expire"),
        accessTokenExpired: async () => {
            // https://github.com/perarnborg/vuex-oidc/issues/20
            console.error("[Identity] Access token did expire - making an authenticateOidcSilent");
            await store.dispatch("authenticateOidcSilent");
        },
        silentRenewError: () => console.log("[Identity] OIDC user is unloaded"),
        userSignedOut: () => console.log("[Identity] OIDC user is signed out"),
        oidcError: (payload) => console.error(`[Identity] An error occured at ${payload.context}:`, payload.error),
    }
);

const store = new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    modules: {
        execution,
        haksModule,
        oidcModule,
        userModule,
    },
});

export default store;
