<template>
    <b-button variant="primary" @click="onClickButtonPrevious">
        Zurück
    </b-button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { navigation } from "@/mixins/execution/navigation";

export default {
    name: "ExecButtonPrevious",
    mixins: [navigation],
    props: {
        navigateToStep: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            deviceId: null,
            devicePoolId: null,
            orderId: null,
            auftragItemId: null,
        };
    },
    computed: {
        ...mapGetters({
            actAuftragDetail: "execution/storeActAuftragDetail",
        }),
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        onClickButtonPrevious() {
            this.$emit("clickPrevious", this.actAuftragDetail.stepPrevious);
        },
    },
    mounted() {
        this.deviceId = this.$route.params.deviceId ? this.$route.params.deviceId : null;
        this.devicePoolId = this.$route.params.devicePoolId ? Number(this.$route.params.devicePoolId) : null;
        this.orderId = this.$route.params.orderId ? this.$route.params.orderId : null;
        this.auftragItemId = this.$route.params.auftragItemId ?? null;
    },
    watch: {
        navigateToStep(step) {
            this.navigateTo({
                step: step,
                devicePoolId: this.devicePoolId,
                deviceId: this.deviceId,
                orderId: this.orderId,
                auftragItemId: this.auftragItemId,
            });
        },
    },
};
</script>
