import Haks from "@/views/hak/Haks";
import HaksList from "@/views/hak/HaksList";
import HakMap from "@/views/hak/HakMap";
import HaksMap from "@/views/hak/HaksMap";
import HakDetails from "@/views/hak/HakDetails";

export default [
    {
        path: "/haks",
        name: "haks",
        component: Haks,
        redirect: { name: "haks-list" },
        children: [
            {
                path: "list",
                name: "haks-list",
                component: HaksList,
            },
            {
                path: "map",
                name: "haks-map",
                component: HaksMap,
            },
            {
                path: "map/:id",
                name: "hak-map",
                component: HakMap,
            },
            {
                path: ":id",
                name: "hak-details",
                component: HakDetails,
            },
        ],
    },
];
