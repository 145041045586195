<!-- TODO: this view is no longer used -->
<template>
    <Main>
        <ExecHeader :devicePool="devicePool" :order="order" />
        <Box headerText="Optical Reader">
            <b-button variant="primary" @click="onClickStartOptRead">
                Optisches Lesen starten
            </b-button>
            <!--
            <b-button variant="primary" class="my-2 ml-auto" @click="onClickReadFile">Read file (tmp)</b-button>
            <div>{{ fileContent }}</div>
            -->
        </Box>
    </Main>
</template>

<script>
import ExecHeader from "@/components/execution/ExecHeader";
import constants from "@/constants/constants";
import auftragsAPI from "@/services/api/auftrags.api";
import devicePoolsAPI from "@/services/api/devicepools.api";

export default {
    name: "ExecutionOpticalReader",
    components: { ExecHeader },
    data() {
        return {
            devicePool: null,
            devices: null,
            order: null,
            fileContent: null,
        };
    },
    mounted() {
        this.devicePoolId = this.$route.params.devicePoolId;
        this.orderId = this.$route.params.orderId;

        auftragsAPI.getSingle(this.orderId).then((resp) => {
            this.order = resp.data;
        });
        devicePoolsAPI.getSingle(this.devicePoolId).then((resp) => {
            this.devicePool = resp.data;
        });
    },
    methods: {
        onClickStartOptRead() {
            window.location.href = `${constants.env.BRIDGE_ROOT_URL}/`;
        },
        /*async onClickReadFile() {
            try {
                let fileHandle;
                [fileHandle] = await window.showOpenFilePicker();
                const file = await fileHandle.getFile();
                const content = await file.text();
                this.fileContent = content;
                console.log("content", content);
            } catch (err) {
                console.error("Error while reading file", err);
            }
        },*/
    },
};
</script>

<style scoped></style>
