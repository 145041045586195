<template>
    <div>
        <h1>HAK detail</h1>
        <router-link :to="prevRoutePath">
            <b-button variant="primary">Zurück</b-button>
        </router-link>
        <hr />
        <re-spinner v-if="isLoadingHak" />
        <template v-else>
            <div v-if="!hakExists">Hak {{ this.hakID }} does not exists</div>
            <div v-else>
                <b-row align-v="center">
                    <b-col cols="12" md="6">
                        <b-input-group prepend="TP">
                            <b-form-input id="tp-input" v-model="hak.tp" trim disabled />
                            <span class="ml-2 mt-2 font-italic">
                                Last ISE Import:
                                <span class="font-weight-bold text-secondary">
                                    {{ this.hak.iseImportLast | formatDate("YYYY-MM-DD HH:mm:ss") }}
                                </span>
                            </span>
                        </b-input-group>
                    </b-col>
                </b-row>
                <!-- Beschreibung -->
                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Beschreibung" active>
                        <b-row>
                            <!-- Bezeichnung -->
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group id="bezeichnung-fg" label="Bezeichnung" label-for="bezeichnung-input">
                                    <b-form-input id="bezeichnung-input" v-model="hak.bezeichnung" trim />
                                </b-form-group>
                            </b-col>
                            <!-- Adresse -->
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group id="address-fg" label="Adresse" label-for="address-input">
                                    <b-form-input id="address-input" v-model="hak.address" trim disabled />
                                </b-form-group>
                            </b-col>
                            <!-- Ort -->
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group id="city-fg" label="Ort" label-for="city-input">
                                    <b-form-input id="city-input" v-model="hak.city" trim disabled />
                                </b-form-group>
                            </b-col>
                            <!-- PLZ -->
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group id="postalCode-fg" label="PLZ" label-for="postalCode-input">
                                    <b-form-input id="postalCode-input" v-model="hak.postalCode" trim disabled />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <!-- inBetrieb -->
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group id="inBetrieb-fg" label="In Betrieb" label-for="inBetrieb-input">
                                    <b-select
                                        id="inBetrieb-input"
                                        v-model="hak.inBetrieb"
                                        :options="[
                                            { value: true, text: 'Ja' },
                                            { value: false, text: 'Nein' },
                                        ]"
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- Typ -->
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group id="typ-fg" label="Typ/Marke" label-for="typ-input">
                                    <b-form-input
                                        id="typ-input"
                                        v-model="hak.typKeyValueOptionText"
                                        trim
                                        readonly
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- TS -->
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group id="ts-fg" label="TS" label-for="ts-input">
                                    <b-form-input id="ts-input" v-model="hak.ts" trim disabled />
                                </b-form-group>
                            </b-col>
                            <!-- VK -->
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group id="vk-fg" label="VK" label-for="vk-input">
                                    <b-form-input id="vk-input" v-model="hak.vk" trim disabled />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col>
                                <b-button variant="primary" v-on:click="onClickHakSave()" :disabled="isSavingHak">
                                    Speichern
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
                <!-- Zustand -->
                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Zustand" active>
                        <b-row>
                            <!-- position: TODO use positionKeyValueOptionText/ID -->
                            <b-col cols="12" sm="6" md="4">
                                <b-form-group id="position-fg" label="Lage" label-for="position-input">
                                    <b-form-input
                                        id="position-input"
                                        v-model="hak.positionKeyValueOptionText"
                                        trim
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- sicherungAmpere -->
                            <!-- TODO: aggiungere anche gli altri campi Sicherung? -->
                            <!--b-col>
                                <b-form-group
                                    id="sicherungAmpere-fg"
                                    label="Sicherung Nennstrom [A]:"
                                    label-for="sicherungAmpere-input"
                                >
                                    <b-form-input
                                        id="sicherungAmpere-input"
                                        v-model="hak.sicherungAmpere"
                                        trim
                                    />
                                </b-form-group>
                            </b-col-->
                            <!-- spannungsArtVolt -->
                            <b-col cols="12" sm="6" md="4">
                                <b-form-group
                                    id="spannungsArtVolt-fg"
                                    label="Spannungsart [V]:"
                                    label-for="spannungsArtVolt-input"
                                >
                                    <b-form-input id="spannungsArtVolt-input" v-model="hak.spannungsArtVolt" trim />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <!-- positionDetails -->
                            <b-col cols="12" sm="6" md="4">
                                <b-form-group
                                    id="positionDetails-fg"
                                    label="Lage Details:"
                                    label-for="positionDetails-input"
                                >
                                    <b-form-textarea
                                        id="positionDetails-input"
                                        v-model="hak.positionDetailsKeyValueOptionText"
                                        trim
                                        rows="2"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- dangers -->
                            <b-col cols="12" sm="6" md="4">
                                <b-form-group id="dangers-fg" label="Generelle Gefährdungen:" label-for="dangers-input">
                                    <b-form-textarea
                                        id="dangers-input"
                                        v-model="hak.dangerAndereBeschreibung"
                                        trim
                                        rows="2"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col>
                                <b-button variant="primary" v-on:click="onClickZustandSave()">Speichern</b-button>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
                <!-- Contact -->
                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Kontakt" active>
                        <Contacts app="rellout" :entityID="hak.hakID" :entity="'hak'" />
                    </b-tab>
                </b-tabs>
                <!-- Comments -->
                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Kommentar" active>
                        <re-spinner v-if="isLoadingComments" />
                        <b-row v-else>
                            <!-- Comments table  -->
                            <b-col>
                                <b-table striped hover :items="commentsItems" :fields="commentsFields">
                                    <template #cell(commentActions)="row">
                                        <font-awesome-icon
                                            :icon="['fas', 'edit']"
                                            v-on:click="onClickCommentEdit(row.item)"
                                            class="ml-2 re-icon"
                                        />
                                        <font-awesome-icon
                                            :icon="['fas', 'trash']"
                                            v-on:click="onClickCommentDelete(row.item)"
                                            class="ml-2 re-icon"
                                        />
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!--buttons-->
                        <b-row class="mt-3 mb-3">
                            <b-col cols="12" sm="2" md="2">
                                <b-button variant="primary" v-on:click="onClickCommentSave()" :disabled="!comment.text">
                                    <i class="fa fa-plus mr-1" />
                                    {{ comment.commentID ? "Kommentar speichern" : "Kommentar hinzufügen" }}
                                </b-button>
                            </b-col>
                            <b-col cols="12" sm="10" md="4">
                                <b-form-textarea
                                    rows="3"
                                    v-model="comment.text"
                                    required
                                    placeholder="Bitte Kommentar eingeben"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
                <!-- Assets/Attachments -->
                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Fotos" active>
                        <DateSortedImageGallery
                            app="rellout"
                            entity="hak"
                            :entityId="hak.hakID"
                            :downloadable="true"
                        ></DateSortedImageGallery>
                    </b-tab>
                </b-tabs>
                <h5>Gerätestandort ({{ devicePools.length }})</h5>
                <b-table
                    v-if="devicePools.length"
                    hover
                    :items="devicePools"
                    :fields="devicePoolsFields"
                    select-mode="single"
                    @row-selected="onDevicePoolSelected"
                    selectable
                />
                <b-alert v-else show>
                    Keine Einträge.
                </b-alert>
                <div v-if="devicePools.length">
                    <b-tabs
                        content-class="mt-3"
                        class="mt-3 pb-3 border-bottom"
                        active-nav-item-class="font-weight-bold cursor-default"
                    >
                        <b-tab :title="'Geräte (' + devices.length + ')'" active>
                            <b-table v-if="devices.length" hover :items="devices" :fields="devicesFields">
                                <template #cell(actionEdit)="row">
                                    <router-link :to="{ name: 'device-details', params: { id: row.item.deviceID } }">
                                        <font-awesome-icon :icon="['fas', 'edit']" class="re-icon" />
                                    </router-link>
                                </template>
                            </b-table>
                        </b-tab>
                        <b-tab :title="'Fotos (' + numberOfImages + ')'" v-if="devicePoolIdSelected !== null">
                            <DateSortedImageGallery
                                app="rellout"
                                entity="devicepool"
                                :entityId="devicePoolIdSelected"
                                :key="devicePoolIdSelected"
                                :downloadable="true"
                                @numberOfImages="updateNumberOfImages"
                            ></DateSortedImageGallery>
                        </b-tab>
                        <b-alert v-else-if="devicePoolIdSelected === null" show>
                            Bitte eine Gerätestandort ID aus obiger Tabelle auswählen.
                        </b-alert>
                    </b-tabs>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import moment from "moment";

import ReSpinner from "@/components/energyspace/ReSpinner";
import Contacts from "@/components/general/Contacts";
import constants from "@/constants/constants";

import devicesAPI from "@/services/api/devices.api";
import commentsAPI from "@/services/api/comments.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import haksAPI from "@/services/api/haks.api";

import DateSortedImageGallery from "../../components/hak/DateSortedImageGallery.vue";

const DATE_FORMAT = "DD.MM.YYYY HH:mm";

export default {
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },

    name: "HakDetails",
    components: {
        Contacts,
        ReSpinner,
        DateSortedImageGallery,
    },
    data() {
        return {
            comment: {
                commentID: null,
                text: null,
                webAppId: "rellout",
                entity: "hak",
                entityID: this.$route.params.id,
            },
            commentsFields: [
                { key: "commentActions", label: "Aktionen" },
                {
                    key: "text",
                    label: "Komment",
                    sortable: false,
                },
                {
                    key: "creationName",
                    label: "Erstellt von",
                    sortable: true,
                },
                {
                    key: "creationDate",
                    label: "Erstellt am",
                    sortable: true,
                    formatter: (value) => {
                        return moment(value).format(DATE_FORMAT);
                    },
                },
                {
                    key: "revisionName",
                    label: "Geändert von",
                    sortable: true,
                },
                {
                    key: "revisionDate",
                    label: "Geändert am",
                    sortable: true,
                    formatter: (value) => {
                        return value ? moment(value).format(DATE_FORMAT) : "";
                    },
                },
            ],
            commentsItems: [],
            devices: [],
            devicesFields: [
                { key: "devicePoolID", label: "Gerätestandort ID" },
                { key: "type", label: "Gerätetyp" },
                { key: "deviceSuffix", label: "Gerät" },
                { key: "deviceType", label: "Typ" },
                { key: "factoryNo", label: "Seriennummer" },
                { key: "meteringCode", label: "Messpunkt" },
                { key: "description", label: "Beschreibung" },
                { key: "yearOfConstruction", label: "Baujahr" },
                { key: "actionEdit", label: "" },
            ],
            devicePoolIdSelected: null,
            devicePools: [],
            devicePoolsFields: [
                { key: "devicePoolID", label: "Gerätestandort ID" },
                { key: "address", label: "Adresse" },
                { key: "city", label: "Ort" },
                { key: "postalCode", label: "PLZ" },
                { key: "position", label: "Position" },
                { key: "positionDetail", label: "Position Detail" },
            ],
            hak: {
                hakID: null,
                bezeichnung: null,
                address: null,
                postalCode: null,
                city: null,
                inBetrieb: null,
                typ: null, // TODO: use *KeyValueOption
                directConnectedVk: null,
                rootTs: null,
                position: null, // TODO: use *KeyValueOption
                positionDetails: null, // TODO: use *KeyValueOption
                sicherungAmpere: null,
                spannungsArtVolt: null,
                asbestos: null,
                dangers: null,
                relatedEntities: {
                    directConnectedVk: { entityTP: null },
                    rootTs: { entityTP: null },
                    rootVk: { entityTP: null },
                },
            },
            hakID: this.$route.params.id,
            isLoadingComments: true,
            isLoadingHak: true,
            isSavingHak: false,
            numberOfImages: 0,
        };
    },
    mounted() {
        haksAPI
            .getSingle(this.hakID)
            .then((resp) => {
                this.hak = resp.data;
            })
            .finally(() => {
                this.isLoadingHak = false;
            });
        this.commentsLoad();
        this.devicePoolsLoad();
    },
    computed: {
        hakExists() {
            return !!this.hak && !!this.hak.hakID;
        },
        prevRoutePath() {
            return this.prevRoute ? this.prevRoute.path : "/";
        },
    },
    methods: {
        onClickCommentDelete(comment) {
            // deia: previous '&physical=true' removed since api doesn't support it
            commentsAPI.delete(comment.commentID).then(() => {
                this.commentsLoad();
            });
        },
        onClickCommentEdit(comment) {
            // clone the passed comment (from clicked row)
            this.comment = JSON.parse(JSON.stringify(comment));
        },
        onClickCommentSave() {
            let callPromise;
            if (this.comment.commentID) {
                // if the comment has a commentID, we update
                const patchDoc = [
                    {
                        op: "replace",
                        path: "/text",
                        value: this.comment.text,
                    },
                ];
                callPromise = commentsAPI.patch(this.comment.commentID, patchDoc);
            } else {
                // no commentID -> new comment
                callPromise = commentsAPI.post(this.comment);
            }

            callPromise.then(() => {
                this.commentsLoad();
                // set the following fields to "null" after comment has been PATCHed or POSTed
                this.comment.text = null;
                this.comment.commentID = null;
            });
        },
        onClickHakSave() {
            const hakPayload = [
                {
                    op: "replace",
                    path: "/bezeichnung",
                    value: this.hak.bezeichnung,
                },
                {
                    op: "replace",
                    path: "/inBetrieb",
                    value: this.hak.inBetrieb,
                },
                /* TODO: use TypKeyValueOptionID/Text
                {
                    op: "replace",
                    path: "/typ",
                    value: this.hak.typ,
                },*/
            ];
            this.isSavingHak = true;
            haksAPI
                .patch(this.hak.hakID, hakPayload)
                .then((resp) => {
                    this.hak = resp;
                })
                .finally(() => {
                    this.isSavingHak = false;
                });
        },
        onClickZustandSave() {
            const hakPayload = [
                /* TODO: use *KeyValueOption
                {
                    op: "replace",
                    path: "/position",
                    value: this.hak.position,
                },
                {
                    op: "replace",
                    path: "/positionDetails",
                    value: this.hak.positionDetails,
                },*/
                /*{
                    op: "replace",
                    path: "/sicherungAmpere",
                    value: this.hak.sicherungAmpere,
                },*/
                {
                    op: "replace",
                    path: "/spannungsArtVolt",
                    value: this.hak.spannungsArtVolt,
                },
            ];
            haksAPI.patch(this.hak.hakID, hakPayload).then((resp) => {
                this.hak = resp;
            });
        },
        async onDevicePoolSelected(row) {
            if (!row || row.length === 0) {
                // User has clicked on the same row is now currently selected
                return;
            }
            this.devicePoolIdSelected = row[0].devicePoolID;
            await this.devicesLoad(this.devicePoolIdSelected);
        },
        updateNumberOfImages(count) {
            this.numberOfImages = count;
        },
        commentsLoad() {
            this.isLoadingComments = true;
            commentsAPI
                .get({
                    app: constants.WEB_APP_NAME,
                    entity: "hak",
                    entityID: this.hakID,
                })
                .then((resp) => {
                    this.commentsItems = resp;
                })
                .finally(() => {
                    this.isLoadingComments = false;
                });
        },
        async devicesLoad(devicePoolId) {
            this.devices = await devicesAPI.get({ devicePoolID: devicePoolId });
        },
        devicePoolsLoad() {
            devicePoolsAPI
                .get({
                    hakID: this.hakID,
                })
                .then((resp) => {
                    this.devicePools = resp;
                });
        },
    },
};
</script>
