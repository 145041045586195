import moment from "moment";

export function getDeviceStatus(device) {
    if (!device || !device.fromDate || !device.toDate) {
        return "N/A";
    }
    const today = moment();
    const fromDate = moment(device.fromDate);
    const toDate = moment(device.toDate);
    if (today >= fromDate && today <= toDate) {
        return "ACTIVE";
    }
    if (today >= toDate) {
        return "UNINSTALLED";
    }
    return "TO BE INSTALLED";
}
