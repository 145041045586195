<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div>
                <div>
                    <ScanQrBarCode
                        close-after-read
                        @scanQrBarCodeActive="onScanQrBarCodeActive"
                        @scanQrBarCodeDecodedString="onScanDecodedString"
                    />
                </div>
                <b-spinner v-if="!device" />
                <div v-else>
                    <b-form-group
                        label="Smart Manager Fabriknummer hinzufügen"
                        label-for="input-factory-nr"
                        class="mb-0"
                    >
                        <b-form-input
                            id="input-factory-nr"
                            v-model="factoryNo"
                            placeholder="Bitte Fabriknummer eingeben..."
                            @input="() => (factoryNo = factoryNo.toUpperCase())"
                        />
                    </b-form-group>
                    <b-alert v-if="smanNotFound" class="my-1 p-2" variant="danger" show>
                        Fabriknummer <strong>{{ factoryNo }}</strong> nicht gefunden. Bitte die Eingabe überprüfen.
                    </b-alert>
                    <b-alert v-if="smanAlreadyActive" class="my-1 p-2" variant="warning" show>
                        Fabriknummer <strong>{{ factoryNo }}</strong> wird bereits verwendet. Bitte eine andere
                        Fabriknummer verwenden.
                    </b-alert>
                </div>
            </div>
            <ExecSectionErledigt
                :isBusy="isBusy"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import ScanQrBarCode from "@/components/general/ScanQrBarCode";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";
import smartpowerApi from "@/services/api/smartpower.api";

export default {
    name: "Step_6_1_2",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        ScanQrBarCode,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            device: null,
            factoryNo: "",
            isBusy: false,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            smanAlreadyActive: false,
            smanNotFound: false,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.isBusy) {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Daten werden gespeichert. Bitte warten...",
                });
            }

            if (this.factoryNo.length === 0) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Fabriknummer fehlt." });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Fabriknummer erfasst." });
            }

            return preconditionsLocalDetails;
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        // Get the auftragDetail
        const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId
        );
        // Set the auftragDetail
        await this.setActAuftragDetail(auftragDetail);
        // this is handled here because deviceID changes in step 6-1-2 (coming from step 6-1-1).
        // gae 20230316: It does not work in case of multiple SMAN in the devicePool
        /*if (parseInt(this.$route.params.deviceId) !== parseInt(this.actAuftragDetail.deviceID)) {
            await this.$router.push({
                name: "step-6-1-2",
                params: {
                    deviceId: this.actAuftragDetail.deviceID,
                    devicePoolId: this.$route.params.devicePoolId,
                    orderId: this.$route.params.orderId,
                },
            });
        }*/
        // Store the device
        this.device = (
            await devicesAPI.getSingle(auftragDetail.deviceID, { params: { includeDeviceInfos: true } })
        ).data;
        if (this.device?.factoryNo) {
            this.factoryNo = this.device.factoryNo;
        }
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        async onClickErledigt() {
            this.isBusy = true;
            let saveOnErledigt = false;
            this.smanNotFound = false;
            this.smanAlreadyActive = false;
            await smartpowerApi.getSmanMinimal(this.factoryNo).then((resp) => {
                if (resp.status === 200) {
                    if (resp.data.smartMeters.length === 0) {
                        // requirement: SMAN ok -> front end save the SmartManager Fabriknummer and go to next step
                        saveOnErledigt = true;
                    } else {
                        // requirement: SMAN already active in another place -> warning message (blocker, user has to change sman number)
                        this.smanAlreadyActive = true;
                    }
                } else if (resp.status === 204) {
                    // requirement: SMAN not found -> front end shows a warning message (blocker, user has to change sman number)
                    this.smanNotFound = true;
                }
            });
            // save erledigt if conditions are met
            if (saveOnErledigt) {
                // Activate SMAN
                const payloadSman = [
                    {
                        op: "replace",
                        path: "/factoryNo",
                        value: this.factoryNo,
                    },
                    // internalNo contains same number as factoryNo
                    // source: pie (e-mail Mon 07.03.2022 14:49)
                    {
                        op: "replace",
                        path: "/internalNo",
                        value: this.factoryNo,
                    },
                    {
                        op: "replace",
                        path: "/fromDate",
                        value: moment().format("YYYY-MM-DD"),
                    },
                    {
                        op: "replace",
                        path: "/toDate",
                        value: "2050-12-31",
                    },
                ];
                this.device = (await devicesAPI.patch(this.actAuftragDetail.deviceID, payloadSman)).data;
                if (this.device.factoryNo) {
                    this.factoryNo = this.device.factoryNo;
                }
                // Deactivate RSE - Step 6-1-1 - Do the deactivation in this step in order to avoid zombie process
                const auftrag611 = await auftragDetailsAPI.getByOrderIdAndStep(
                    this.$route.params.orderId,
                    "6-1-1",
                    this.$route.params.devicePoolId,
                    null,
                    this.actAuftragDetail.auftragItemID
                );
                if (auftrag611) {
                    await devicesAPI.uninstall(auftrag611.deviceID);
                    console.log(`Deactivating RSE ${auftrag611.deviceID} - step 6-1-1`);
                } else {
                    console.warn(`Step 6-1-1 not found`);
                }
                // Update auftragDetail record
                const payloadAuftragDetail = [
                    {
                        op: "replace",
                        path: "/status",
                        value: 200,
                    },
                ];
                await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail);
                // on SUCCESS, navigate to next step
                this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            }
            this.isBusy = false;
        },
        onClickNext(step) {
            // on SUCCESS, navigate to next step
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            // on SUCCESS, navigate to previous step
            this.navigateToStepPrevious = step;
        },
        onScanQrBarCodeActive(val) {
            console.log("QR/Barcode reader active: ", val);
        },
        onScanDecodedString(decodedString) {
            this.factoryNo = decodedString;
        },
    },
};
</script>
