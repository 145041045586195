<template>
    <div>
        <b-navbar type="light" variant="LIGHT" class="p-0">
            <b-navbar-nav>
                <b-nav-item :to="{ name: 'haks-list' }" :active="$route.name === 'haks-list'">Übersicht</b-nav-item>
                <b-nav-item :to="{ name: 'haks-map' }" :active="$route.name === 'haks-map'">Karte</b-nav-item>
            </b-navbar-nav>
        </b-navbar>
        <router-view />
    </div>
</template>

<script>
export default {
    name: "Haks",
};
</script>

<style scoped></style>
