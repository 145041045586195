import constants from "@/constants/constants";

export default {
    validateEditingPermission(company, currentUser) {
        if (!company || !currentUser) return false;

        return this.isUserCompanyManagerOrLeiter(company, currentUser) || this.isUserAdmin(currentUser.userRoles);
    },
    isUserTechnicianOfCompany(currentUserCompanyID, rowDataCompanyID, roles) {
        return (
            currentUserCompanyID === rowDataCompanyID &&
            this.checkIfMultipleRoles(
                roles,
                [constants.LEITER_ROLE_ID, constants.TECHNICIAN_ROLE_ID],
                constants.MATCH_SINGLE_ROLES
            )
        );
    },
    isUserCompanyManagerOrLeiter(company, currentUser) {
        // check if user is the manager of a company / a 'regular' leiter of a company
        if (!company || !currentUser) return false;
        const { companyID, managerID } = company;
        return (
            (currentUser.userID === managerID || currentUser.companyID == companyID) &&
            this.containsRole(currentUser.userRoles, constants.LEITER_ROLE_ID)
        );
    },
    isUserAdmin(roles) {
        return this.containsRole(roles, constants.ADMIN_ROLE_ID);
    },
    containsRole(roles, roleID) {
        if (roles.length === 0) return false;

        return roles.some((role) => [roleID].includes(role.roleID));
    },
    checkIfMultipleRoles(roles, roleIDs, matchAllRoles) {
        if (roles.length === 0) return false;
        return matchAllRoles
            ? roleIDs.every((roleID) => this.containsRole(roles, roleID))
            : roleIDs.some((roleID) => this.containsRole(roles, roleID));
    },
    validateRolePermissions(prohibitedRoleID, availableRoles) {
        const mappedRoles = availableRoles.map((role) => ({
            roleID: role.role.roleID,
            name: role.role.name,
        }));
        const isProhibitedRolePresent = this.containsRole(mappedRoles, prohibitedRoleID);

        if (isProhibitedRolePresent) {
            return mappedRoles.length > 1;
        }

        return true;
    },
    isUserEditable(currentUser, userToEdit, roles) {
        if (roles.length === 0) return false;

        const { company: dataCompany, userID, userRolesDisplay } = userToEdit;

        if (this.isUserAdmin(currentUser.userRoles)) {
            return true;
        }

        if (currentUser.userID === userToEdit.userID) {
            return true;
        }

        if (!userRolesDisplay) {
            return this.validateEditingPermission(dataCompany, currentUser);
        }

        const userRolesArray = userRolesDisplay ? userRolesDisplay.split(", ") : [];

        const filteredRoles =
            userRolesArray.length > 0 ? roles.filter((role) => userRolesArray.includes(role.name)) : [];

        if (
            this.isUserCompanyManagerOrLeiter(
                { managerID: dataCompany.managerID, companyID: dataCompany.companyID },
                currentUser
            )
        ) {
            return this.isUserTechnicianOfCompany(currentUser.company.companyID, dataCompany.companyID, filteredRoles);
        }
        return currentUser.userID === userID;
    },
};
