<!--suppress HtmlUnknownTarget -->
<template>
    <div class="home">
        <p>
            Not used
        </p>
    </div>
</template>

<script>
export default {
    name: "Home",
    components: {},
};
</script>
