import { httpClient } from "@/services/api/client";

const endpoint = "/comments";

export default {
    // ********************
    // default REST methods
    // ********************
    delete(id) {
        return httpClient.delete(`${endpoint}/${id}`);
    },
    get(params) {
        return httpClient
            .get(endpoint, {
                params: params,
            })
            .then((resp) => {
                return resp.data;
            });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload);
    },
    post(payload) {
        return httpClient.post(endpoint, payload);
    },
    // **************
    // custom methods
    // **************
};
