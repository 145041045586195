import constants from "@/constants/constants";
import { httpClient } from "@/services/api/client";

const endpoint = "/app/users";

export default {
    // ********************
    // methods designed for datagrid
    // ********************
    getUsersForGridUrl() {
        return constants.env.API_ROOT_URL + endpoint + "/grid";
    },
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, {
            params: params,
        });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload);
    },
    updateUser(userID, payload) {
        return httpClient.put(`${endpoint}/${userID}`, payload);
    },
    createUser(payload) {
        return httpClient.post(`${endpoint}`, payload);
    },
    // **************
    // custom methods
    // **************
    async getByUsername(username) {
        const response = await httpClient.get(endpoint, {
            params: { username: username },
        });
        if (response.data.length > 0) {
            return response.data[0];
        }
        return null;
    },
};
