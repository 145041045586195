<template>
    <div v-if="checkPermissionToEditUser()">
        <h3>Benutzer {{ isCreateMode ? "hinzufügen" : "bearbeiten" }}</h3>
        <re-spinner v-if="isLoading"></re-spinner>
        <div v-else>
            <b-form>
                <b-row>
                    <!-- UserID -->
                    <b-col cols="12" sm="6" md="3">
                        <b-form-group id="userid-fg" label="UserID" label-for="userid-input">
                            <b-form-input
                                id="userid-input"
                                v-model="user.userID"
                                trim
                                disabled
                                placeholder="Zugewiesen vom System"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- UserName -->
                    <b-col cols="12" sm="6" md="3">
                        <b-form-group id="username-fg" label="Username" label-for="username-input">
                            <b-form-input
                                id="username-input"
                                v-model="user.username"
                                trim
                                :disabled="!isCreateMode"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- FirstName -->
                    <b-col cols="12" sm="6" md="3">
                        <b-form-group id="firstName-fg" label="Vorname" label-for="firstName-input">
                            <b-form-input id="firstName-input" v-model="user.firstName" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- LastName -->
                    <b-col cols="12" sm="6" md="3">
                        <b-form-group id="lastName-fg" label="Nachname" label-for="lastName-input">
                            <b-form-input id="lastName-input" v-model="user.lastName" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- Email -->
                    <b-col cols="12" sm="6" md="3">
                        <b-form-group id="email-fg" label="Email" label-for="email-input">
                            <b-form-input
                                id="email-input"
                                v-model="user.email"
                                trim
                                :disabled="!isCreateMode"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- PhoneNumber -->
                    <b-col cols="12" sm="6" md="3">
                        <b-form-group id="phoneNumber-fg" label="Telefonnummer" label-for="phoneNumber-input">
                            <b-form-input
                                id="phoneNumber-input"
                                v-model="user.phoneNumber"
                                trim
                                placeholder="+41 XX XXX XX XX"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- Company -->
                    <b-col cols="12" sm="6" md="3">
                        <b-form-group id="company-fg" label="Firma" label-for="company-input">
                            <b-form-select v-model="user.companyID" :options="companyOptions"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <!-- Role -->
                    <b-col cols="12" sm="6" md="3">
                        <b-form-group id="role-fg" label="Rolle" label-for="role-input">
                            <DxTagBox
                                :data-source="roleOptions"
                                :search-enabled="true"
                                searchExpr="role"
                                value-expr="roleID"
                                display-expr="name"
                                v-model="selectedRoles"
                                :show-clear-button="true"
                                :read-only="!isUserAbleToEditRole"
                                tag-template="customDeleteButtonTemplate"
                                @selection-changed="selectionChanged"
                            >
                                <template slot="customDeleteButtonTemplate" slot-scope="roleData">
                                    <div>
                                        <div ref="target" :class="{ 'dx-tag-content': true }">
                                            <span>{{ roleData.data.name }}</span>
                                            <div v-if="!roleData.data.disabled" class="dx-tag-remove-button"></div>
                                        </div>
                                    </div>
                                </template>
                            </DxTagBox>
                        </b-form-group>
                    </b-col>
                    <!-- Qualification -->
                    <b-col cols="12" sm="6" md="3">
                        <b-form-group id="role-fg" label="Qualifikation" label-for="role-input">
                            <DxTagBox
                                :data-source="qualifications"
                                :search-enabled="true"
                                searchExpr="qualification"
                                value-expr="qualificationID"
                                display-expr="name"
                                v-model="selectedQualifications"
                                :show-clear-button="true"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <!-- Buttons -->
                <b-row>
                    <b-col>
                        <b-button
                            class="ml-2 float-right"
                            @click="onClickSave"
                            :disabled="!isModified || areRequiredFieldsEmpty"
                            variant="primary"
                            >Speichern</b-button
                        >
                        <b-button class="float-right" @click="$router.push({ name: 'users' })">Abbrechen</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-alert v-if="!isModified" variant="info" show class="mt-2"
                            >Es gibt keine Änderungen zu speichern.</b-alert
                        >
                    </b-col>
                </b-row>
            </b-form>
            <h5>Absenzen</h5>
            <div v-if="isCreateMode">
                <i>Der Benutzer muss zuerst gespeichert werden, um Absenzen zu verwalten</i>
            </div>
            <AbsenceScheduler
                v-else-if="user"
                :time-zone="'Europe/Zurich'"
                :current-date="currentDate"
                height="50vh"
                :start-day-hour="0"
                current-view="month"
                :views="views"
                :cell-duration="60"
                :companyID="parseInt(user.companyID)"
                :userID="parseInt(user.userID)"
            />
            <hr />
            <h5>Arbeitszeiten</h5>
            <div v-if="isCreateMode">
                <i>Der Benutzer muss zuerst gespeichert werden, um Arbeitszeiten zu verwalten</i>
            </div>
            <BusinessHour
                v-else-if="user"
                :business-hours="user.businessHours"
                :business-hours-key="businessHoursKey"
                :companyID="user.companyID"
                :userID="user.userID"
            ></BusinessHour>
            <hr />
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import ReSpinner from "@/components/energyspace/ReSpinner";
import usersAPI from "@/services/api/users.api";
import { DxTagBox } from "devextreme-vue/tag-box";
import AbsenceScheduler from "@/components/user/AbsenceScheduler";
import userCompanyPermissionService from "../../services/userCompanyPermissionService";
import constants from "@/constants/constants";
import BusinessHour from "@/components/user/BusinessHour.vue";

export default {
    name: "UserEdit",
    components: {
        BusinessHour,
        ReSpinner,
        DxTagBox,
        AbsenceScheduler,
    },
    data() {
        return {
            user: null,
            originalUser: null,
            originalRoles: null,
            originalQualifications: null,
            selectedCompany: null,
            selectedRoles: null,
            selectedQualifications: null,
            companyOptions: null,
            roleOptions: null,
            currentDate: new Date(),
            views: ["week", "workWeek", "month"],
            isLoading: true,
            businessHoursKey: "businessHourUserGrid",
        };
    },
    computed: {
        ...mapGetters(["companies", "roles", "qualifications", "currentUser"]),
        userID() {
            const param = this.$route.params.userID;
            if (param === "new") {
                return 0;
            }
            if (!isNaN(param)) {
                return Number(param);
            }
            return -1; // not a valid ID
        },
        isCreateMode() {
            return this.userID === 0;
        },
        isModified() {
            const selectedRoles = this.sortValues(this.selectedRoles);
            const selectedQualifications = this.sortValues(this.selectedQualifications);
            return (
                !_.isEqual(this.user, this.originalUser) ||
                !_.isEqual(selectedRoles, this.originalRoles) ||
                !_.isEqual(selectedQualifications, this.originalQualifications)
            );
        },
        areRequiredFieldsEmpty() {
            return (
                !this.user.username ||
                !this.user.firstName ||
                !this.user.lastName ||
                !this.user.email ||
                !this.user.companyID
            );
        },
        isUserAbleToEditRole() {
            return userCompanyPermissionService.validateRolePermissions(
                constants.TECHNICIAN_ROLE_ID,
                this.currentUser.userRoles
            );
        },
    },
    async mounted() {
        this.isLoading = true;
        try {
            if (this.userID < 0 || !Number.isInteger(this.userID)) {
                await this.navigateToUsers();
                this.displayToast("danger", "Es wurde kein Benutzer mit dieser ID gefunden.");
            }
            if (this.companies.length === 0) {
                await this.fetchCompanies();
            }
            if (this.roles.length === 0) {
                await this.fetchRoles();
            }
            if (this.qualifications.length === 0) {
                await this.fetchQualifications();
            }
            if (this.userID > 0) {
                // edit mode
                this.user = (await usersAPI.getSingle(this.userID)).data;
                if (this.user) {
                    this.selectedCompany = this.user.company.name;
                    this.selectedRoles = this.user.userRoles?.map((ur) => ur.role.roleID);
                    this.selectedQualifications = this.user.userQualifications?.map(
                        (u) => u.qualification.qualificationID
                    );
                } else {
                    await this.navigateToUsers();
                    this.displayToast("danger", "Es wurde kein Benutzer mit dieser ID gefunden.");
                }
            } else {
                // create mode
                this.user = {};
                this.selectedCompany = null;
                this.selectedRoles = [];
                this.selectedQualifications = [];
            }
            if (this.companies.length > 0) {
                this.companyOptions = this.companies?.map((c) => ({ value: c.companyID, text: c.name }));
            }
            if (this.currentUser.userRoles) {
                const minRoleID = Math.min(...this.currentUser.userRoles.map((role) => role.roleID), Infinity);
                this.roleOptions = this.roles
                    ? this.roles.map((role) => ({
                          ...role,
                          disabled: role.roleID < minRoleID,
                      }))
                    : [];
            }
            this.originalUser = _.clone(this.user);
            this.originalRoles = _.clone(this.selectedRoles);
            this.originalQualifications = _.clone(this.selectedQualifications);
            this.isLoading = false;
        } catch (err) {
            console.error(err);
            await this.navigateToUsers();
            this.displayToast(
                "danger",
                err?.response?.status === constants.NOT_FOUND_CODE ? constants.NOT_FOUND_MESSAGE : err?.message
            );
        }
    },
    methods: {
        ...mapActions(["fetchCompanies", "fetchRoles", "fetchQualifications"]),
        selectionChanged(e) {
            const minRoleID = Math.min(...this.currentUser.userRoles.map((role) => role.roleID), Infinity);
            e.removedItems.forEach((item) => {
                if (item.roleID < minRoleID) {
                    this.displayToast("danger", "Du kannst diese Rolle nicht entfernen");
                    // re-adding the wrongly deleted role
                    this.selectedRoles.push(item.roleID);
                    this.roleOptions.push(item);
                    this.roleOptions = this.roleOptions.filter(
                        (obj, index, self) => index === self.findIndex((t) => t.roleID === obj.roleID)
                    );
                }
            });
        },
        async checkPermissionToEditUser() {
            if (!this.user) return; // always false because in createMode this.user = {}
            if (this.isCreateMode) {
                if (
                    userCompanyPermissionService.containsRole(this.currentUser.userRoles, constants.LEITER_ROLE_ID) ||
                    userCompanyPermissionService.containsRole(this.currentUser.userRoles, constants.ADMIN_ROLE_ID)
                ) {
                    return true;
                } else {
                    await this.navigateToUsers();
                    this.displayToast("danger", "Sie sind nicht berechtigt, diesen Vorgang durchzuführen.");
                }
            }
            if (userCompanyPermissionService.isUserEditable(this.currentUser, this.user, this.roles)) {
                return true;
            } else {
                await this.navigateToUsers();
                this.displayToast("danger", "Sie sind nicht berechtigt, diesen Vorgang durchzuführen.");
            }
        },
        sortValues(array) {
            return array.length > 0 ? array.slice().sort((a, b) => a - b) : array;
        },
        async onClickSave() {
            this.selectedRoles = this.sortValues(this.selectedRoles);
            this.selectedQualifications = this.sortValues(this.selectedQualifications);
            if (this.isModified) {
                const userPayload = {
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    phoneNumber: this.user.phoneNumber,
                    email: this.user.email,
                    companyID: this.user.companyID,
                    UserRoleIds: this.selectedRoles, // gae: why is this PascalCase?
                    UserQualificationIds: this.selectedQualifications, // gae: why is this PascalCase?
                };
                if (this.isCreateMode) {
                    await usersAPI.createUser({
                        username: this.user.username,
                        ...userPayload,
                    });
                    await this.navigateToUsers();
                    this.displayToast("success", "Benutzer erfolgreich erstellt.");
                    return;
                }
                await usersAPI.updateUser(this.userID, {
                    userID: this.userID,
                    ...userPayload,
                });
                if (!_.isEqual(this.user, this.originalUser)) {
                    this.originalUser = _.clone(this.user);
                }
                if (!_.isEqual(this.selectedRoles, this.originalRoles)) {
                    this.originalRoles = _.clone(this.selectedRoles);
                }
                if (!_.isEqual(this.selectedQualifications, this.originalQualifications)) {
                    this.originalQualifications = _.clone(this.selectedQualifications);
                }
                await this.navigateToUsers();
                this.displayToast("success", "Benutzer erfolgreich gespeichert.");
            } else {
                // Should never happen because isModified computed prop is working now
                this.displayToast("warning", "Die Daten wurden nicht geändert.");
            }
        },
        async navigateToUsers() {
            await this.$router.push({ name: "users" });
        },
        displayToast(variant = "INFO", message) {
            let noAutoHide = false;
            let title;
            switch (variant.toUpperCase()) {
                case "DANGER":
                    noAutoHide = true;
                    title = "Fehler";
                    break;
                case "INFO":
                    title = "INFO";
                    break;
                case "SUCCESS":
                    title = "SUCCESS";
                    break;
                default:
                    variant = "info";
                    title = "INFO";
            }
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
    },
};
</script>

<style scoped></style>
