<template>
    <Main>
        <Box headerText="Gerätestandort" :isLoading="!devicePool || !order">
            <div v-if="order" class="d-flex">
                <div>
                    <router-link :to="{ name: 'execution-single', params: { orderId: order.auftragID.toString() } }">
                        {{ order.hak.tp }}
                    </router-link>
                </div>
                <div class="ml-auto font-weight-bold">
                    {{ order.hak.address }}
                </div>
            </div>
            <div v-if="devicePool && order" class="d-flex font-weight-bold">
                <div>
                    {{ devicePool.position }}
                    <span v-if="devicePool.positionDetail && devicePool.positionDetail.length">
                        - {{ devicePool.positionDetail }}
                    </span>
                </div>
                <div v-if="order.hak.address !== devicePool.address" class="ml-auto">
                    {{ devicePool.address }}
                </div>
            </div>
        </Box>
        <ExecGsDataAquisition :dp="devicePool" />
        <Box headerText="RSE / SMAN" :isLoading="!devicePool" :variant="compStatusVariantRseSman">
            <div v-if="devicePool" class="d-flex">
                <div>
                    <!-- Steps in which SMAN are activated: ["6-1-3", "6-2-3", "6-3-2"] -->
                    <div v-if="status611all.length + status621all.length">
                        {{ status612success.length + status622success.length }} von
                        {{ status611all.length + status621all.length }} gewechselt (davon
                        {{ status613success.length + status623success.length }} aktiviert)
                    </div>
                    <div v-else>Keine RSE zu wechseln.</div>
                    <!-- ["6-1-3", "6-2-3", "6-3-2"] -->
                    <div v-if="status632all.length">
                        {{ status632success.length }} von {{ status632all.length }} eingebaut (davon
                        {{ status632success.length }} aktiviert)
                    </div>
                    <div class="mt-1">{{ status641success.length }} von {{ status641all.length }} konfiguriert</div>
                </div>
                <div class="ml-auto">
                    <b-button @click="onClickRseSman">
                        <font-awesome-icon :icon="['fas', 'play-circle']" size="lg" />
                    </b-button>
                </div>
            </div>
        </Box>
        <Box headerText="Zähler" :isLoading="!devicePool" :variant="compStatusVariantZaehler">
            <div v-if="devicePool" class="d-flex">
                <div>
                    <div class="mt-1">
                        <!-- Example: 23 von 23 abgelesen (0 fern, 23 vor Ort) -->
                        {{ statusCompletedMeterAbgelesenVorOrt.length }} von {{ status751all.length }} abgelesen ({{
                            statusCompletedMeterAbgelesenFern.length
                        }}
                        fern, {{ statusCompletedMeterAbgelesenVorOrt.length }} vor Ort)
                    </div>
                    <div class="mt-1">
                        <!-- Example: 23 von 23 gewechselt (davon 1 mit Wandler) -->
                        {{ statusCompletedMeterGewechselt.length + statusCompletedMeterGewechseltMitWandler.length }}
                        von {{ status751all.length }} gewechselt (davon
                        {{ statusCompletedMeterGewechseltMitWandler.length }} mit Wandler)
                    </div>
                    <div class="mt-1">
                        {{ statusCompletedMeterGetestet.length }} von {{ status751all.length }} erfolgreich getestet
                    </div>
                </div>
                <div class="ml-auto">
                    <b-button @click="onClickZaehler">
                        <font-awesome-icon :icon="['fas', 'play-circle']" size="lg" />
                    </b-button>
                </div>
            </div>
        </Box>
        <Box headerText="Andere Geräte" :isLoading="!devicePool" :variant="compStatusVariantAndere">
            <div v-if="devicePool" class="d-flex">
                <div v-if="status811all.length > 0" class="mt-1">
                    {{ status811success.length }} von {{ status811all.length }} demontiert. (Total:
                    {{ devicesOther.length }})
                </div>
                <div v-else>Keine zu demontierenden Geräte. (Total: {{ devicesOther.length }})</div>
                <div class="ml-auto">
                    <b-button v-if="devicesOther.length > 0" @click="onClickOtherDevices">
                        <font-awesome-icon :icon="['fas', 'play-circle']" size="lg" />
                    </b-button>
                </div>
            </div>
        </Box>
        <Box headerText="Flexibilitäten" :isLoading="!devicePool" :variant="compStatusVariantFlexibilitaeten">
            <div v-if="devicePool" class="d-flex">
                <div v-if="deviceFlexibilitaets.length" class="mt-1">
                    {{ statusCompletedFlex.length }} von {{ deviceFlexibilitaets.length }} überprüft
                    <span v-if="statusCompletedFlexBeendet.length > 0">
                        (davon {{ statusCompletedFlexBeendet.length }} beendet)
                    </span>
                </div>
                <div v-else>Keine Flexibilitäten erfasst</div>
                <div class="ml-auto">
                    <b-button @click="onClickFlex">
                        <font-awesome-icon :icon="['fas', 'play-circle']" size="lg" />
                    </b-button>
                </div>
            </div>
        </Box>
        <Box headerText="Gerätestandort Abschlussfoto" :variant="compStatusVariant">
            <div class="d-flex">
                <div>{{ status911.length === 1 ? "Abschlussfoto erfasst" : "Abschlussfoto nicht erfasst" }}</div>
                <div class="ml-auto">
                    <b-button @click="onClickAbschlussfoto">
                        <font-awesome-icon :icon="['fas', 'play-circle']" size="lg" />
                    </b-button>
                </div>
            </div>
        </Box>
    </Main>
</template>

<script>
import ExecGsDataAquisition from "@/components/execution/ExecGsDataAquisition.vue";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import auftragsAPI from "@/services/api/auftrags.api";
import deviceFlexibilitaetsAPI from "@/services/api/deviceFlexibilitaets.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import devicesAPI from "@/services/api/devices.api";

export default {
    name: "ExecutionGeraetestandort",
    components: { ExecGsDataAquisition },
    data() {
        return {
            auftragDetails: [], // containt all the auftragDetails of the current Auftrag
            deviceFlexibilitaets: [],
            devicePool: null,
            devicePoolId: null,
            devicesOther: [], // Contains all Other Device
            devicesMeters: [],
            order: null,
            orderDetails: [],
            orderId: null,
            status611all: [],
            status612success: [], // SMAN einbau
            status622success: [], // SMAN einbau
            status613all: [], // SMAN activation
            status613success: [],
            status621all: [],
            status623all: [], // SMAN activation
            status623success: [],
            status632all: [], // SMAN activation
            status632success: [],
            status641all: [], // SMAN config/test
            status641success: [],
            status714all: [],
            status751all: [],
            status811all: [],
            status811success: [],
            status911: [],
            statusCompletedFlex: [],
            statusCompletedFlexBeendet: [],
            statusCompletedMeterAbgelesenFern: [],
            statusCompletedMeterAbgelesenVorOrt: [],
            statusMeterToReplaceAll: [],
            statusCompletedMeterGewechselt: [],
            statusCompletedMeterGewechseltMitWandler: [],
            statusCompletedMeterGetestet: [],
            statusAllFlexConfigured: false,
        };
    },
    async mounted() {
        this.devicePoolId = this.$route.params.devicePoolId;
        this.orderId = this.$route.params.orderId;

        const [
            orderPromise,
            devicePoolPromise,
            devicesMetersPromise,
            devicesOtherPromise,
            auftragDetailsPromise,
        ] = await Promise.all([
            auftragsAPI.getSingle(this.orderId),
            devicePoolsAPI.getSingle(this.devicePoolId),
            devicesAPI.get({ devicePoolID: this.devicePoolId, type__in: "Elektrizitätszähler" }),
            devicesAPI.get({
                devicePoolID: this.devicePoolId,
                type__in:
                    "Kommunikationseinheit,Kommunikationsmodul,Schnittstellenkonverter,Kommunikation-Steuermodul,Modem",
                deviceSuffix__not_in: "Smart Manager",
            }),
            auftragDetailsAPI.get({ auftragID: this.orderId, devicePoolID: this.devicePoolId }),
        ]);
        this.order = orderPromise.data;
        this.devicePool = devicePoolPromise.data;
        this.devicesMeters = devicesMetersPromise;
        this.devicesOther = devicesOtherPromise;
        // Load all the AuftragDetails of this auftrag once
        this.auftragDetails = auftragDetailsPromise;
        /* FLEX */
        auftragDetailsAPI
            .get({
                auftragID: this.$route.params.orderId,
                devicePoolID: this.devicePoolId,
                actionGroupSequence: 5,
                actionItemSequence: 1,
            })
            .then((res) => {
                this.orderDetails = res;
            })
            .then(() => {
                const ids = this.orderDetails.filter((o) => o.deviceID !== null).map((o) => o.deviceID);
                if (ids.length) {
                    deviceFlexibilitaetsAPI
                        .get({ deviceFlexibilitaetID__in: ids.toString(), includeDevices: true })
                        .then((resp) => {
                            this.deviceFlexibilitaets = resp.data;
                        })
                        .then(() => {
                            const orderDetailsNew = [];
                            this.orderDetails.forEach((od) => {
                                const odNew = JSON.parse(JSON.stringify(od));
                                const obj = this.deviceFlexibilitaets.find(
                                    (df) => df.deviceFlexibilitaetID === od.deviceID
                                );
                                odNew.device = obj ? obj : null;
                                orderDetailsNew.push(odNew);
                            });
                            this.orderDetails = orderDetailsNew;
                        })
                        .finally(() => {
                            this.isMeshupRunnung = false;
                        });
                } else {
                    this.deviceFlexibilitaets = [];
                    this.isMeshupRunnung = false;
                }
            });
        // Requirement: for flexibilities we count the active flexibilities with step 5.1.1. completed (Aufragdetail status= 201) out of the total number of active flexibilites on this device pool
        this.statusCompletedFlex = this.determineAuftragDetailStatusArray(
            this.auftragDetails.filter((ad) => ad.stepCurrent === "5-1-1"),
            [201, 202]
        );
        this.statusCompletedFlexBeendet = this.determineAuftragDetailStatusArray(
            this.auftragDetails.filter((ad) => ad.stepCurrent === "5-1-1"),
            [202]
        );
        this.statusAllFlexConfigured = this.auftragDetails
            .filter((ad) => ad.stepCurrent === "9-1-1")
            .map((ad) => {
                const parsedJSON = JSON.parse(ad.value);
                return parsedJSON?.areFlexibilitiesConfigured ?? false;
            });

        /* RSE/SMAN */
        // gae note: the name "status611all" is misleading because it contains an array of AuftragDetails instead of an array of statuses. It was done in this way before, so I leave it as it was
        this.status611all = this.auftragDetails.filter((ad) => ad.stepCurrent === "6-1-1");
        this.status612success = this.determineAuftragDetailStatusArray(
            this.auftragDetails.filter((ad) => ad.stepCurrent === "6-1-2"),
            [200]
        );
        this.status622success = this.determineAuftragDetailStatusArray(
            this.auftragDetails.filter((ad) => ad.stepCurrent === "6-2-2"),
            [200]
        );
        // Requirement: for RSE/SMAN the number of RSE that has been replaced (step 6.1.3 completed - status 202) out of the total number of RSE to be replaced in the devicepool (total number of step 6.1.3)
        this.status613all = this.auftragDetails.filter((ad) => ad.stepCurrent === "6-1-3");
        this.status613success = this.determineAuftragDetailStatusArray(this.status613all, [202]);
        this.status621all = this.auftragDetails.filter((ad) => ad.stepCurrent === "6-2-1");
        this.status623all = this.auftragDetails.filter((ad) => ad.stepCurrent === "6-2-3");
        this.status623success = this.determineAuftragDetailStatusArray(this.status623all, [202]);
        // Requirement: bisogna aggiungere una riga x von y eingebaut viene mostrato se ci sono step 6.3.2. x=numero di step 6.3.2. completati
        this.status632all = this.auftragDetails.filter((ad) => ad.stepCurrent === "6-3-2");
        this.status632success = this.determineAuftragDetailStatusArray(this.status632all, [202]);
        // SMAN config/tested
        this.status641all = this.auftragDetails.filter((ad) => ad.stepCurrent === "6-4-1");
        this.status641success = this.determineAuftragDetailStatusArray(this.status641all, [200]);
        /* METERS */
        // Requirement abgelesen fern: number of meter with step 7.1.4 in status 204
        // Requirement abgelesen vor ort: number of meter with step 7.1.4 in status 202,203
        this.statusCompletedMeterAbgelesenFern = this.determineAuftragDetailStatusArray(
            this.auftragDetails.filter((ad) => ad.stepCurrent === "7-1-2"),
            [203]
        );
        this.status714all = this.auftragDetails.filter((ad) => ad.stepCurrent === "7-1-4");
        this.statusCompletedMeterAbgelesenVorOrt = this.determineAuftragDetailStatusArray(this.status714all, [
            202,
            203,
        ]);
        // Requirement gewechselt: number of meter replaced (Step 7.2.1 completed - status 202) out of total number of meter to be replaced
        this.statusMeterToReplaceAll = this.auftragDetails.filter(
            (ad) => ad.stepCurrent === "7-2-1" || ad.stepCurrent === "7-2-0"
        );
        this.statusCompletedMeterGewechselt = this.determineAuftragDetailStatusArray(this.statusMeterToReplaceAll, [
            202,
            203,
        ]);
        // Requirement gewechselt (mit wandler): number of meter replaced (Step 7-2-1/0 - status 202 and Meter with Wandler) out of total number of meter to be replaced
        this.statusCompletedMeterGewechseltMitWandler = this.statusMeterToReplaceAll.filter((ad) => {
            const meter = this.devicesMeters.find((m) => m.deviceID === ad.deviceID);
            return meter && meter?.requireWandler;
        });
        // this.determineAuftragDetailStatusArray(this.auftragDetails.filter((ad) => ad.stepCurrent === "7-2-0"),[202, 203]);
        // Requirement getestet: number of smart meter installed and tested (Step 7.5.1 completed - 200) out of total number of smart meter to be installed
        this.status751all = this.auftragDetails.filter((ad) => ad.stepCurrent === "7-5-1");
        this.statusCompletedMeterGetestet = this.determineAuftragDetailStatusArray(this.status751all, [200]);
        /* OTHER DEVICES */
        this.status811all = this.auftragDetails.filter((ad) => ad.stepCurrent === "8-1-1");
        this.status811success = this.determineAuftragDetailStatusArray(this.status811all, [202]);
        /* AUFTRAG CLOSING */
        this.status911 = this.determineAuftragDetailStatusArray(
            this.auftragDetails.filter((ad) => ad.stepCurrent === "9-1-1"),
            [202]
        );
    },
    computed: {
        compStatusVariant() {
            return this.status911.length === 1 ? "success" : "";
        },
        compStatusVariantAndere() {
            /*
               requirements:
                 Verde se non ci sono device con step 8.1.1
                 x von y se ci sono device con step 8.1.1. (bianco x=0, giallo x<y, verde x=y)
            */
            if (this.status811all.length > 0 && this.status811success.length === 0) {
                return "";
            } else if (this.status811all.length > 0 && this.status811success.length === this.status811all.length) {
                // completed
                return "success";
            } else if (this.status811success.length < this.status811all.length) {
                // in progress
                return "warning";
            } else {
                return "success";
            }
        },
        compStatusVariantFlexibilitaeten() {
            if (
                this.statusCompletedFlex.length === this.deviceFlexibilitaets.length &&
                this.statusAllFlexConfigured[0] === true
            ) {
                // completed
                return "success";
            } else if (this.statusCompletedFlex.length > 0) {
                // in progress
                return "warning";
            } else {
                return "";
            }
        },
        compStatusVariantRseSman() {
            if (this.status613success.length + this.status623success.length + this.status632all.length === 0) {
                // not started
                return "";
            } else if (this.status641success.length === this.status641all.length) {
                // completed
                return "success";
            } else {
                // in progress
                return "warning";
            }
        },
        compStatusVariantZaehler() {
            if (this.status751all.length > 0 && this.statusCompletedMeterGetestet.length === this.status751all.length) {
                // completed
                return "success";
            } else if (
                this.statusCompletedMeterAbgelesenFern.length + this.statusCompletedMeterAbgelesenVorOrt.length >
                0
            ) {
                // in progress
                return "warning";
            } else {
                return "";
            }
        },
    },
    methods: {
        /**
         * Return an array of stause
         * @param arrayOfAuftragDetails
         * @param arrayStatusOk
         * @returns an arry of intengers
         */
        determineAuftragDetailStatusArray(arrayOfAuftragDetails, arrayStatusOk) {
            if (!Array.isArray(arrayStatusOk)) {
                throw SyntaxError("arrayStatusOk has to be an array");
            }
            const arrayResult = [];
            arrayOfAuftragDetails.forEach((ad) => {
                if (arrayStatusOk.includes(ad.status)) {
                    arrayResult.push(ad.status);
                }
            });
            return arrayResult;
        },
        onClickAbschlussfoto() {
            this.$router.push({
                name: "step-9-1-1",
                params: { orderId: this.orderId, devicePoolId: this.devicePoolId },
            });
        },
        onClickFlex() {
            this.$router.push({
                name: "execution-flexibilities",
                params: { orderId: this.orderId, devicePoolId: this.devicePoolId },
            });
        },
        onClickOtherDevices() {
            this.$router.push({
                name: "execution-other-devices",
                params: { orderId: this.orderId, devicePoolId: this.devicePoolId },
            });
        },
        onClickRseSman() {
            this.$router.push({
                name: "execution-rse-sman",
                params: { orderId: this.orderId, devicePoolId: this.devicePoolId },
            });
        },
        onClickZaehler() {
            this.$router.push({
                name: "execution-meters",
                params: { orderId: this.orderId, devicePoolId: this.devicePoolId },
            });
        },
    },
};
</script>
