<template>
    <div>
        <b-alert
            v-if="
                [
                    'NEW',
                    'CREATED',
                    'ASSIGNED',
                    'ACCEPTED',
                    'IN PROGRESS',
                    'COMPLETED',
                    'TRANSFERRING',
                    'ERROR-TRANSFER',
                    'TRANSFERRED',
                ].includes(compAuftragStatusID)
            "
            class="p-1 my-2 font-italic"
            show
            variant="info"
        >
            <font-awesome-icon :icon="['fas', 'file-circle-check']" />
            Der tech. Auftrag wurde erstellt.
            <b-button
                @click="onBtnAsqDetailsShowClick"
                variant="link"
                class="py-0 px-2"
                title="Details (Auftragserstellung) ein-/ausblenden"
                size="sm"
            >
                <font-awesome-icon :icon="asqDetailsShow ? 'chevron-down' : 'chevron-up'" />
            </b-button>
        </b-alert>
        <template v-else>
            <b-alert v-if="compAuftragStatusID === 'ERROR'" variant="danger" show class="p-1 mt-2 font-italic">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                Bei der Erstellung des tech. Auftrages ist ein Fehler aufgetreten! Bitte Backoffice informieren.
                <div class="font-italic">(Auftrag.AuftragStatusID="{{ compAuftragStatusID }}")</div>
            </b-alert>
            <template v-else-if="['NEW'].includes(compAuftragStatusID)">
                <b-button
                    v-if="auftrag?.realityCheckCompleted"
                    :disabled="auftragCreateIsBusy"
                    variant="primary"
                    v-b-modal.auftrag-create
                >
                    <font-awesome-icon :icon="['fas', 'file-circle-plus']" title="technischen Auftrag erstellen" />
                    Tech. Auftrag erstellen <b-spinner v-if="auftragCreateIsBusy" small />
                </b-button>
                <b-modal
                    id="auftrag-create"
                    title="Tech. Auftrag erstellen"
                    cancel-title="Abbrechen"
                    ok-title="Tech. Auftrag erstellen"
                    @ok="onClickAuftragCreateSingle"
                    :ok-disabled="auftragCreateIsBusy"
                >
                    Soll der technische Auftrag wirklich erstellt werden?
                </b-modal>
            </template>
            <b-alert v-else variant="danger" show class="p-1 mt-2 font-italic">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                Der technische Auftrag kann nur für Aufträge in Status "NEW" erstellt werden.
                <div class="font-italic">(Auftrag.AuftragStatusID="{{ compAuftragStatusID }}")</div>
            </b-alert>
        </template>
        <div v-if="auftragCreateIsBusy || asqDetailsShow" class="mt-2 font-italic">
            <div v-if="auftragCreateIsBusy" class="mt-1 text-black-50 small">
                Abfrage-Iteration: <strong>{{ pollingIteration }}</strong> (nächste in
                <strong>{{ pollingCountdown }}</strong>
                s)
            </div>
            <OrchestratorStatus
                :asq="auftrag?.auftragSyncQueue"
                :displayLegend="true"
                :displayOrchestratorCreate="true"
                class="mt-1"
            />
        </div>
    </div>
</template>

<script>
import OrchestratorStatus from "@/components/order/OrchestratorStatus.vue";

import processApi from "@/services/api/process.api";

export default {
    name: "BtnAuftragCreate",
    components: { OrchestratorStatus },
    props: {
        auftrag: Object,
    },
    data() {
        return {
            asqDetailsShow: false,
            auftragCreateIsBusy: false,
            pollingCountdown: null,
            pollingIteration: 0,
            setIntervalVar: null,
        };
    },
    computed: {
        compAuftragStatusID() {
            return this.auftrag?.auftragStatusID.toUpperCase();
        },
    },
    methods: {
        onClickAuftragCreateSingle() {
            this.auftragCreate().then(() => {
                this.pollingStart();
            });
        },
        auftragCreate() {
            this.auftragCreateIsBusy = true;
            return processApi
                .createAuftrag({ auftragID: this.auftrag?.auftragID })
                .then(() => {
                    this.$bvToast.toast(`Die Erstellung des technischen Auftrags (ROLLOUT) wurde gestartet.`, {
                        title: "INFO",
                        variant: "info",
                        toaster: "b-toaster-bottom-right",
                        appendToast: true,
                    });
                })
                .catch(() => {
                    this.auftragCreateIsBusy = false;
                });
        },
        onBtnAsqDetailsShowClick() {
            this.asqDetailsShow = !this.asqDetailsShow;
        },
        pollData(intervalSeconds = 10) {
            this.pollingCountdownStart(intervalSeconds);
            this.pollingIteration++;
            this.$emit("poll-data", true);
            if (["ERROR", "CREATED"].includes(this.compAuftragStatusID)) {
                this.asqDetailsShow = true;
                this.auftragCreateIsBusy = false;
                clearInterval(this.setIntervalVar);
            }
        },
        pollingCountdownStart(seconds = 10) {
            let count = seconds;
            this.pollingCountdown = count;
            const timer = setInterval(() => {
                count--;
                this.pollingCountdown = count;
                if (count === 0) {
                    clearInterval(timer);
                    this.pollingCountdown = seconds;
                }
            }, 1000);
        },
        pollingStart(intervalSeconds = 10) {
            // note: keep this -> to make sure that auftragCreateIsBusy is set from whenever pollingStart() is called
            this.auftragCreateIsBusy = true;
            this.pollData(intervalSeconds);
            this.setIntervalVar = setInterval(() => {
                this.pollData(intervalSeconds);
            }, intervalSeconds * 1000);
        },
    },
    mounted() {
        if (this.compAuftragStatusID === "CREATING") {
            // note: in case the user refreshes/enters the page during the auftrag creation process
            this.pollingStart();
        }
    },
};
</script>
