import HelpRoot from "@/views/help/HelpRoot";
import Bridge from "@/views/help/Bridge";
import Support from "@/views/help/Support.vue";

export default [
    {
        path: "/help",
        component: HelpRoot,
        redirect: { name: "help-bridge" },
        children: [
            {
                path: "bridge",
                name: "help-bridge",
                component: Bridge,
            },
            {
                path: "support",
                name: "support",
                component: Support,
            },
        ],
    },
];
