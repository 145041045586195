<template>
    <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "logout",
    methods: {
        ...mapActions(["signOutOidc"]),
    },
    mounted() {
        this.signOutOidc();
    },
};
</script>
