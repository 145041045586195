<template>
    <div>
        <div v-if="false">
            <div>Kartenausschnitt:</div>
            <div class="pl-3">
                <div>Anzahl der geladenen Objekte: {{ recordsBounds.length }}</div>
                <div>Anzahl der nicht ausgewählten Objekte: {{ haksUnselectedObjects.length }}</div>
            </div>
            <div>
                Ausgewählte Objekte ({{ haksSelectedIds.length }}):
                <span v-for="(hID, i) in haksSelectedIds" :key="`hs_${i}`" @click="onClickCircleMarkerRemove(hID)">
                    {{ hID }}<span v-if="i + 1 < haksSelectedIds.length">, </span>
                </span>
            </div>
        </div>
        <div class="mt-1">
            <b-button class="mr-2" @click="onClickButtonClearAll">Ausgewählte Objekte leeren</b-button>
            <b-button class="mr-2" variant="primary" @click="onClickButtonAddAll">
                Alle Karten-Objekte hinzufügen
            </b-button>
            <b-button variant="primary" @click="onClickButtonDisplayList" :disabled="haksSelectedIdsLength === 0">
                Ausgewählte Objekte in Tabelle anzeigen
            </b-button>
        </div>
        <div class="d-flex">
            <div class="mt-2" style="height: calc(100vh - 240px); width: calc(100% - 170px)">
                <l-map
                    style="height: 100%; width: 100%"
                    :zoom="zoomInit"
                    :center="compCenter"
                    :maxZoom="18"
                    ref="map"
                    @ready="onMapReady"
                    @update:bounds="onMapBoundsUpdated"
                    @update:zoom="onMapZoomUpdated"
                >
                    <l-control-scale position="topright" :imperial="false" :metric="true"></l-control-scale>
                    <l-tile-layer :url="url" :attribution="attribution" />
                    <!-- HAKs inside map bounds (not selected by user) -->
                    <l-circle-marker
                        v-for="r in haksUnselectedObjectsFiltered"
                        :key="`hak_id_${r.hakID}`"
                        :lat-lng="{ lat: r.latitude, lng: r.longitude }"
                        :color="getCircleColorByStatus(r.activeAuftrag)"
                        :fill-color="getCircleFillColorByStatus(r.activeAuftrag)"
                        @click="onClickCircleMarkerAdd(r)"
                    />
                    <!-- selected HAKs -->
                    <l-circle-marker
                        v-for="r in haksSelectedObjects"
                        :key="`hak_selected_id_${r.hakID}`"
                        :lat-lng="{ lat: r.latitude, lng: r.longitude }"
                        color="red"
                        fill-color="red"
                        @click="onClickCircleMarkerRemove(r.hakID)"
                    />
                    <div class="map-text">Zoom: {{ zoom }}</div>
                </l-map>
            </div>

            <div class="pl-3 mt-2" v-if="statusOptions.length > 0" style="width: 170px">
                <b-button @click="onClickButtonToggle" size="sm">
                    {{ isAnyFilterSelected ? "Alle abwählen" : "Alle auswählen" }}
                </b-button>
                <b-form-checkbox-group
                    v-model="statusSelected"
                    :options="statusOptions"
                    value-field="auftragStatusID"
                    text-field="label"
                    stacked
                >
                </b-form-checkbox-group>
                Filter: {{ haksUnselectedObjectsFiltered.length }}
            </div>
        </div>
    </div>
</template>

<script>
import "leaflet/dist/leaflet.css";

import { LCircleMarker, LMap, LTileLayer, LControlScale } from "vue2-leaflet";

import constants from "@/constants/constants";
import haksAPI from "@/services/api/haks.api";
import auftragStatusesAPI from "@/services/api/auftragStatuses.api";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "HaksMap",
    components: {
        LCircleMarker,
        LMap,
        LTileLayer,
        LControlScale,
    },
    data() {
        return {
            attribution:
                '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> | &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> | <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>',
            auftragStatuses: [],
            center: [46.96764, 9.559375],
            constants: constants,
            haksSelectedIds: [],
            haksSelectedObjects: [],
            haksUnselectedObjects: [],
            mapBbox: [],
            recordsBounds: [],
            selectAll: false,
            statusOptions: [],
            statusSelected: [
                "null",
                "new",
                "creating",
                "created",
                "assigned",
                "accepted",
                "in progress",
                "suspended",
                "completed",
                "cancelled",
            ],
            url:
                "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWktcmVwb3dlciIsImEiOiJja2t6anF6dnUzMXB4MnFxbm16ejVyMXJlIn0.Wf_akGZUp0tghsSMYJRf4g",
            zoom: 16,
            zoomInit: 16,
        };
    },
    computed: {
        ...mapGetters(["storeHakIds"]),
        compCenter() {
            return this.center;
        },
        haksUnselectedObjectsFiltered() {
            const objectsFiltered = [];
            // iterate over recordsBounds (to re-apply selected filters)
            // note: this is to make sure to always start from all records inside bounds when applying filters (this also covers the scenario in which a removed filter is re-applied)
            this.recordsBounds.forEach((h) => {
                // only process unselected records
                if (!this.haksSelectedIds.includes(h.hakID)) {
                    if (h.activeAuftrag === null && this.statusSelected.includes("null")) {
                        // add haks with activeAuftrag=null
                        objectsFiltered.push(h);
                    } else {
                        if (h.activeAuftrag && this.statusSelected.includes(h.activeAuftrag.auftragStatusID)) {
                            objectsFiltered.push(h);
                        }
                    }
                }
            });
            this.updateHaksUnselectedObjects(objectsFiltered);
            return objectsFiltered;
        },
        haksSelectedIdsLength() {
            return this.haksSelectedIds.length;
        },
        isAnyFilterSelected() {
            return this.selectAll || this.statusSelected.length > 0;
        },
    },
    methods: {
        ...mapActions(["setHakIds"]),
        boundsToBbox(bounds) {
            const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
            this.mapBbox = bbox;
            return bbox;
        },
        onClickButtonAddAll() {
            this.haksUnselectedObjects.forEach((r) => {
                this.haksSelectedAdd(r);
            });
        },
        async onClickButtonClearAll() {
            this.haksSelectedIds = [];
            this.haksSelectedObjects = [];
            await this.recordsGetByBboxAndExcludeSelectedHaks(this.mapBbox);
        },
        onClickButtonDisplayList() {
            this.$router.push({ name: "haks-list", params: { hakIds: this.haksSelectedIds.toString() } });
        },
        onClickButtonToggle() {
            this.statusSelected = this.isAnyFilterSelected ? [] : this.statusOptions.map((x) => x.auftragStatusID);
            this.selectAll = !this.selectAll;
        },
        onClickCircleMarkerAdd(hak) {
            this.haksSelectedAdd(hak);
        },
        onClickCircleMarkerRemove(hakID) {
            this.haksSelectedRemove(hakID);
        },
        recordsGetByIds(ids) {
            // mirage.js response format -> response.data.entity
            return haksAPI.get({ hakid__in: ids.toString() }).then((response) => response.data);
        },
        haksSelectedRemove(hakID) {
            this.haksSelectedIds = this.haksSelectedIds.filter((item) => item !== hakID);
            const hakRemovedObj = JSON.parse(
                JSON.stringify(this.haksSelectedObjects.filter((item) => item.hakID === hakID)[0])
            );
            // add hak to unselected objects
            this.haksUnselectedObjects.push(hakRemovedObj);
            // update selected objects
            this.haksSelectedObjects = this.haksSelectedObjects.filter((item) => item.hakID !== hakID);
            if (this.haksSelectedIds.length === 0) {
                this.setHakIds([]);
            }
        },
        async onMapBoundsUpdated(bounds) {
            const bbox = this.boundsToBbox(bounds);
            if (this.zoom >= 16) {
                await this.recordsGetByBboxAndExcludeSelectedHaks(bbox);
            }
        },
        async onMapReady(obj) {
            obj.doubleClickZoom.disable();
            const bounds = obj.getBounds();
            const bbox = this.boundsToBbox(bounds);
            await this.recordsGetByBboxAndExcludeSelectedHaks(bbox);
        },
        async recordsGetByBboxAndExcludeSelectedHaks(bbox) {
            const recordsNotSelected = [];
            const recordsInsideBbox = await this.recordsGetByBbox(bbox);
            this.recordsBounds = recordsInsideBbox;
            recordsInsideBbox.forEach((r) => {
                if (!this.haksSelectedIds.includes(r.hakID)) {
                    recordsNotSelected.push(r);
                }
            });
            this.haksUnselectedObjects = recordsNotSelected;
            return recordsNotSelected;
        },
        onMapZoomUpdated(zoom) {
            this.zoom = zoom;
        },
        getCircleColorByStatus(obj) {
            return this.getColorByStatus(obj);
        },
        getCircleFillColorByStatus(obj) {
            return this.getColorByStatus(obj);
        },
        getColorByStatus(obj) {
            if (!obj || !obj.auftragStatus) {
                return "skyblue";
            }
            return obj.auftragStatus.color;
        },
        haksSelectedAdd(hak) {
            if (!this.haksSelectedIds.includes(hak.hakID)) {
                // if filter
                this.haksSelectedIds.push(hak.hakID);
                this.haksSelectedObjects.push(hak);
            }
            // remove hak from other objects
            this.haksUnselectedObjects = this.haksUnselectedObjects.filter((item) => item.hakID !== hak.hakID);
        },
        recordsGetByBbox(bbox) {
            // don't send a request if no Status-Filter was selected
            return this.statusSelected.length > 0
                ? haksAPI
                      .get({ bbox: bbox.toString(), status__in: this.statusSelected.join(",") })
                      .then((response) => response.data)
                : [];
        },
        updateHaksUnselectedObjects(haks) {
            const recordsNotSelected = [];
            haks.forEach((r) => {
                if (!this.haksSelectedIds.includes(r.hakID)) {
                    recordsNotSelected.push(r);
                }
            });
            this.haksUnselectedObjects = recordsNotSelected;
        },
    },
    async mounted() {
        this.auftragStatuses = await auftragStatusesAPI.get().then((response) => response.data);
        this.statusOptions = [{ auftragStatusID: "null", label: "Null" }].concat(this.auftragStatuses); // Add Null option
        if (this.storeHakIds.length) {
            // clear filter selection
            this.statusSelected = [];
            this.haksSelectedIds = JSON.parse(JSON.stringify(this.storeHakIds));
            // get selected ids (they could be outside visible map bounds)
            this.haksSelectedObjects = await this.recordsGetByIds(this.storeHakIds);
            // get last retrieved object
            const selectedLast = this.haksSelectedObjects[this.haksSelectedObjects.length - 1];
            // center map on object
            this.center = [selectedLast.latitude, selectedLast.longitude];
        }
        this.selectAll = this.statusSelected.length === this.statusOptions.length;
    },
    watch: {
        statusSelected(newFilter) {
            // if no filter is selected --> set "selectAll" to false
            if (newFilter.length === 0) {
                this.selectAll = false;
            }
        },
    },
};
</script>

<style scoped>
.map-text {
    position: absolute !important;
    z-index: 999;
    top: 1em !important;
    left: 5em !important;
    padding: 2px 6px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 2px;
}
</style>
