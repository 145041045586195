<template>
    <b-button variant="primary" @click="onClickButtonNext">
        Weiter
    </b-button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { navigation } from "@/mixins/execution/navigation";

export default {
    name: "ExecButtonNext",
    mixins: [navigation],
    props: {
        navigateToStep: {
            type: String,
            default: "",
        },
        stepNextType: {
            type: String,
            default: "standard",
        },
    },
    data() {
        return {
            deviceId: null,
            devicePoolId: null,
            orderId: null,
            auftragItemId: null,
        };
    },
    computed: {
        ...mapGetters({
            actAuftragDetail: "execution/storeActAuftragDetail",
        }),
        stepNext() {
            let stepNext;

            let errorMessage = "";
            switch (this.stepNextType) {
                case "choice_false":
                    if (this.actAuftragDetail.stepNextFalse) {
                        // stepNext has been configured in actionDetail.stepNext
                        stepNext = this.actAuftragDetail.stepNextFalse;
                    } else {
                        errorMessage = `Nicht behandeltes Szenario: auftragDetail.stepNextFalse ist nicht definiert. Erwartet: auftragDetail.stepNextFalse muss vom Backend befüllt werden.`;
                        this.$bvToast.toast(errorMessage, {
                            title: "Fehler",
                            variant: "danger",
                            toaster: "b-toaster-bottom-right",
                            noAutoHide: true,
                            appendToast: true,
                        });
                        throw SyntaxError(errorMessage);
                    }
                    break;
                case "choice_true":
                case "standard":
                    if (this.actAuftragDetail.stepNextTrue) {
                        // stepNext has been configured in actionDetail.stepNext
                        stepNext = this.actAuftragDetail.stepNextTrue;
                    } else {
                        errorMessage = `Nicht behandeltes Szenario: auftragDetail.stepNextTrue ist nicht definiert. Erwartet: auftragDetail.stepNextTrue muss vom Backend befüllt werden.`;
                        this.$bvToast.toast(errorMessage, {
                            title: "Fehler",
                            variant: "danger",
                            toaster: "b-toaster-bottom-right",
                            noAutoHide: true,
                            appendToast: true,
                        });
                        throw SyntaxError(errorMessage);
                    }
                    break;
                default:
                    errorMessage = `Nicht behandeltes Szenario: auftragDetail.stepNextType=${this.stepNextType} ist nicht definiert. Erwartet: auftragDetail.stepNextType muss "choice_false", "choice_true" oder "standard" sein.`;
                    this.$bvToast.toast(errorMessage, {
                        title: "Fehler",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    });
                    throw SyntaxError(errorMessage);
            }

            return stepNext;
        },
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        onClickButtonNext() {
            this.$emit("clickNext", this.stepNext);
        },
    },
    mounted() {
        this.deviceId = this.$route.params.deviceId ? this.$route.params.deviceId : null;
        this.devicePoolId = this.$route.params.devicePoolId ? Number(this.$route.params.devicePoolId) : null;
        this.orderId = this.$route.params.orderId ? this.$route.params.orderId : null;
        this.auftragItemId = this.$route.params.auftragItemId ?? null;
    },
    watch: {
        navigateToStep(step) {
            this.navigateTo({
                step: step,
                devicePoolId: this.devicePoolId,
                deviceId: this.deviceId,
                orderId: this.orderId,
                auftragItemId: this.auftragItemId,
            });
        },
    },
};
</script>
