<template>
    <Box headerText="Datenerfassung Gerätestandort" :isLoading="!progressStatus" :variant="compStatusVariant">
        <div v-if="progressStatus" class="d-flex">
            <div v-if="progressStatus.completed.length === 0">Nicht begonnen</div>
            <div v-else-if="progressStatus.completed.length < progressStatus.total">
                In Bearbeitung ({{ progressStatus.completed.length }}/{{ progressStatus.total }} erledigt)
            </div>
            <div v-else>Beendet</div>
            <div class="ml-auto">
                <b-button @click="onClickDevicePool(dp)">
                    <font-awesome-icon :icon="['fas', 'play-circle']" size="lg" />
                </b-button>
            </div>
        </div>
    </Box>
</template>

<script>
import auftragDetailsAPI from "@/services/api/auftragDetails.api";

export default {
    name: "ExecGsDataAquisition",
    props: {
        dp: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            progressStatus: null,
        };
    },
    computed: {
        compStatusVariant() {
            if (!this.progressStatus?.completed.length) {
                return "";
            } else if (this.progressStatus.blocked.length > 0) {
                return "danger";
            } else if (this.progressStatus.completed.length === this.progressStatus.total) {
                return "success";
            } else {
                return "warning";
            }
        },
    },
    methods: {
        determineStatus(actionGroupId, devicePoolId) {
            return auftragDetailsAPI
                .get({
                    auftragID: this.$route.params.orderId,
                    actionGroupID: actionGroupId,
                    devicePoolID: devicePoolId,
                })
                .then((resp) => {
                    const stepsStatus = {
                        total: resp.length,
                        in_progress: [],
                        completed: [],
                        blocked: [],
                        todo: [],
                    };
                    resp.forEach((ad) => {
                        switch (true) {
                            case ad.status === 0:
                                stepsStatus.todo.push(ad);
                                break;
                            case ad.status >= 100 && ad.status <= 199:
                                stepsStatus.in_progress.push(ad);
                                stepsStatus.todo.push(ad);
                                break;
                            case ad.status >= 200 && ad.status < 500: // DONE and SKIPPED
                                stepsStatus.completed.push(ad);
                                break;
                            case ad.status >= 500 && ad.status <= 599:
                                stepsStatus.blocked.push(ad);
                                stepsStatus.todo.push(ad);
                                break;
                            default:
                                stepsStatus.todo.push(ad);
                                alert(`auftragDetail.status=${ad.status} not defined!`);
                        }
                    });
                    return stepsStatus;
                });
        },
        onClickDevicePool(dp) {
            const devicePoolId = dp.devicePoolID;
            const orderId = this.$route.params.orderId;
            this.$router.push({ name: "step-2", params: { orderId, devicePoolId } });
        },
    },
    watch: {
        dp(dp) {
            this.determineStatus(2, dp.devicePoolID).then((resp) => {
                this.progressStatus = resp;
            });
        },
    },
};
</script>
