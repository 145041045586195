<template>
    <div class="dx-tooltip-appointment-item">
        <div class="dx-tooltip-appointment-item-marker">
            <div class="dx-tooltip-appointment-item-marker-body" :style="{ backgroundColor: markerColor }"></div>
        </div>
        <div class="dx-tooltip-appointment-item-content">
            <div class="dx-tooltip-appointment-item-content">
                <div class="dx-tooltip-appointment-item-content-subject">
                    {{ data.appointmentData.text }}
                </div>
                <div class="dx-tooltip-appointment-item-content-date">
                    {{ formatDate(data.appointmentData.startDate) }} - {{ formatDate(data.appointmentData.endDate) }}
                </div>
            </div>
        </div>
        <div v-if="isDeleteButtonExist" class="dx-tooltip-appointment-item-delete-button-container">
            <DxButton
                class="dx-tooltip-appointment-item-delete-button"
                icon="trash"
                styling-mode="text"
                @click="onClick"
            ></DxButton>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";

import constants from "@/constants/constants";
import moment from "moment";

export default {
    components: {
        DxButton,
    },
    props: {
        /**
         * The data object for the component
         *
         * @example
         * {
         *   "appointmentData": {
         *     "absenceID": 13,
         *     "startDate": "2024-04-07T22:00:00.000Z",
         *     "endDate": "2024-04-10T22:00:00Z",
         *     "text": "Vacation User #4",
         *     "companyID": 1,
         *     "userID": 36,
         *     "absenceType": 1,
         *     "creationName": "matteo.galli@repower.com",
         *     "creationDate": "2024-04-18T17:22:54.93",
         *     "revisionName": "matteo.galli@repower.com",
         *     "revisionDate": "2024-04-19T09:55:31.21"
         *   },
         *   "targetedAppointmentData": {
         *     "absenceID": 13,
         *     "startDate": "2024-04-07T22:00:00.000Z",
         *     "endDate": "2024-04-10T22:00:00.000Z",
         *     "text": "Vacation User #4",
         *     "companyID": 1,
         *     "userID": 36,
         *     "absenceType": 1,
         *     "creationName": "matteo.galli@repower.com",
         *     "creationDate": "2024-04-18T17:22:54.93",
         *     "revisionName": "matteo.galli@repower.com",
         *     "revisionDate": "2024-04-19T09:55:31.21",
         *     "displayStartDate": "2024-04-07T22:00:00.000Z",
         *     "displayEndDate": "2024-04-10T22:00:00.000Z"
         *   },
         *   "isButtonClicked": false
         * }
         */
        data: {
            type: Object,
            default: () => {},
            required: true,
        },
        isDeleteButtonExist: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        markerColor() {
            return this.data.appointmentData.absenceType === constants.userCompanyEnum.Company
                ? constants.calendarColor.Company
                : constants.calendarColor.User;
        },
    },
    methods: {
        formatDate(date) {
            if (date) {
                return moment(date).format("DD.MM HH:mm");
            }
            return "";
        },
        onClick(e) {
            this.$emit("delete-button-click", e);
        },
    },
};
</script>

<style scoped></style>
