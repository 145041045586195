import { httpClient } from "@/services/api/client";

const endpoint = "/net/device-pools";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient
            .get(endpoint, {
                params: params,
            })
            .then((resp) => {
                return resp.data;
            });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload).then((resp) => {
            return resp.data;
        });
    },
    // **************
    // custom methods
    // **************
};
