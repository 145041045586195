<!-- GAE -->
<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div v-if="communicationModuleDevice">
                <div>
                    <strong>{{ communicationModuleDevice.factoryNo }}</strong> ({{
                        communicationModuleDevice.deviceType
                    }})
                </div>
                <div>{{ communicationModuleDevice.deviceSuffix }}</div>
                <!--div>Status: {{ getDeviceStatus() }}</div-->
            </div>
            <div v-else>
                Kommunikationsmodul im Gerätestandort nicht gefunden.
            </div>
            <div class="d-flex mt-3" v-if="communicationModuleDevice">
                <b-button @click="onClickRemoveNotPossible" :disabled="compErledigtIsDisabled" variant="primary">
                    Ausbau nicht möglich
                </b-button>
                <b-button class="ml-auto" @click="onClickRemoved" :disabled="compErledigtIsDisabled" variant="primary">
                    Ausgebaut
                </b-button>
            </div>
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import constants from "@/constants/constants";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";
import * as deviceUtils from "@/utils/DeviceUtils";

export default {
    name: "Step_7_2_2",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            communicationModuleDevice: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compErledigtIsDisabled() {
            return !this.actAuftragDetail.isExecutable;
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId
        );
        await this.setActAuftragDetail(auftragDetail);
        if (auftragDetail.deviceID) {
            this.communicationModuleDevice = (await devicesAPI.getSingle(auftragDetail.deviceID)).data;
        } else {
            // Skip step - rolled back
            //console.log("No Communication Module found, skip step 7-2-2");
            if (auftragDetail.status !== constants.auftragDetailStatus.DONE_CONDITION_1) {
                const payloadAuftragDetails = [
                    {
                        op: "replace",
                        path: "/status",
                        value: constants.auftragDetailStatus.DONE_CONDITION_1,
                    },
                ];
                await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetails);
            }
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        }
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        getDeviceStatus() {
            return deviceUtils.getDeviceStatus(this.communicationModuleDevice);
        },
        onClickNext(step) {
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        async onClickRemoved() {
            await devicesAPI.uninstall(this.communicationModuleDevice.deviceID);
            const payloadAuftragDetails = [
                {
                    op: "replace",
                    path: "/status",
                    value: constants.auftragDetailStatus.DONE_CONDITION_1,
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetails);
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        async onClickRemoveNotPossible() {
            const payload = [
                {
                    op: "replace",
                    path: "/status",
                    value: constants.auftragDetailStatus.BLOCKED,
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payload).then(() => {
                // on SUCCESS, navigate to next step
                this.navigateToStepPrevious = this.actAuftragDetail.stepPrevious;
            });
        },
    },
};
</script>
