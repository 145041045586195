<template>
    <div class="d-inline">
        <b-button v-if="!selected" @click="onClickScan()" variant="primary">
            QR/Barcode lesen <font-awesome-icon :icon="['fas', 'barcode']" size="lg" />
        </b-button>
        <template v-else>
            <div class="font-weight-bold font-italic">Barcode lesen</div>
            <div>
                <div v-if="decodedString.length" class="font-italic text-black-50">
                    Ergebnis QR/Barcode scan: <strong>{{ decodedString }}</strong>
                </div>
                <div id="qrContainer" class="d-flex justify-content-center">
                    <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
                </div>
            </div>
            <b-button class="mt-1" @click="onClickCancel()">
                Abbrechen
            </b-button>
        </template>
    </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
    name: "ScanQrBarCode",
    components: {
        StreamBarcodeReader,
    },
    props: {
        closeAfterRead: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            selected: false,
            decodedString: "",
        };
    },
    methods: {
        onClickCancel() {
            this.selected = false;
            this.$emit("scanQrBarCodeActive", this.selected);
        },
        onClickScan() {
            this.selected = true;
            this.$emit("scanQrBarCodeActive", this.selected);
        },
        onDecode(decodedString) {
            // when barcode is read, the result is passed here
            this.decodedString = decodedString;
            this.$emit("scanQrBarCodeDecodedString", decodedString);
            if (this.closeAfterRead) {
                this.selected = false;
            }
        },
        onLoaded() {
            //console.log("Ready to scan QR/Barcode");
        },
    },
};
</script>
