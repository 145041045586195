import { BToast } from "bootstrap-vue";

import { httpClient } from "@/services/api/client";
import _ from "lodash";
import constants from "@/constants/constants";
import moment from "moment";
import store from "../../store/index";

const endpoint = "/net/auftrag-details";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient
            .get(endpoint, {
                params: params,
            })
            .then((resp) => {
                return resp.data;
            });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    /* TRICK ALERT: if 200 <= status < 400 then save also executionTime and userID (taken from the store)  */
    patch(id, payload) {
        const status = payload.find((element) => element?.path === "/status");
        if (status && status?.value >= 200 && status?.value < 400) {
            payload.push({
                op: "REPLACE",
                path: "/executionTime",
                value: moment().format(constants.DATETIME_FORMAT_ISO),
            });
            payload.push({
                op: "REPLACE",
                path: "/userID",
                value: store.getters.currentUser.userID,
            });
            //console.log("Saving AuftragDetail and adding ExecutionTime and UserID", payload);
        }

        return httpClient.patch(`${endpoint}/${id}`, payload).then((resp) => {
            return resp.data;
        });
    },
    post(payload) {
        return httpClient.post(endpoint, payload);
    },
    delete(id) {
        return httpClient.delete(`${endpoint}/${id}`);
    },
    // **************
    // custom methods
    // **************
    /**
     * Returns response.data
     * @param orderId
     * @param step
     * @param devicepoolId
     * @param deviceId
     * @param auftragItemId
     * @param excludeSingleRecordCheck
     * @returns a promise
     */
    async getByOrderIdAndStep(
        orderId,
        step,
        devicepoolId = null,
        deviceId = null,
        auftragItemId = null,
        excludeSingleRecordCheck = false
    ) {
        // leave commented for debug purpose
        //console.log(`Getting AuftragDetail orderId: ${orderId}, step: ${step}, devicepoolId: ${devicepoolId}, deviceId: ${deviceId}, auftragItemId: ${auftragItemId}`)
        const m = step.match(/(\d+)-(\d+)-(\d+)/);
        const actionGroupSequence = m[1];
        const actionItemSequence = m[2];
        const actionDetailSequence = m[3];
        const params = {
            auftragID: orderId,
            actionGroupSequence: actionGroupSequence,
            actionItemSequence: actionItemSequence,
            actionDetailSequence: actionDetailSequence,
        };
        if (devicepoolId) {
            params.devicepoolID = Number(devicepoolId);
        }
        if (deviceId) {
            params.deviceID = Number(deviceId);
        }
        if (auftragItemId) {
            params.auftragItemID = Number(auftragItemId);
        }
        // Rewritten in a simple way. Before we had 2 returns in the same function
        const resp = await httpClient.get(endpoint, {
            params: params,
        });
        if (excludeSingleRecordCheck) {
            return resp.data;
        }
        let respData;
        let errorMessage;
        let bootstrapToast = new BToast();
        switch (resp.data.length) {
            case 0:
                // no results
                console.warn(
                    `No step found for orderId: ${orderId}, step: ${step}, devicepoolId: ${devicepoolId}, deviceId: ${deviceId}, auftragItemId: ${auftragItemId}`
                );
                respData = resp.data;
                break;
            case 1:
                // exact 1 result
                respData = resp.data[0];
                console.debug(
                    `Loading AuftragDetail ${respData.auftragDetailID}, step: ${respData.stepCurrent}, status: ${respData.status}, device: ${respData.deviceID}, isExecutable: ${respData.isExecutable}`
                );
                break;
            default:
                errorMessage = `Nicht behandeltes Szenario: Das Ergebnis enthält ${resp.data.length} Einträge. Erwartet: 0 oder 1 Einträge.`;
                bootstrapToast.$bvToast.toast(errorMessage, {
                    title: "Fehler",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: true,
                    appendToast: true,
                });
                throw SyntaxError(errorMessage);
        }
        return respData;
    },
    /**
     * PATCH "valueNew" only if it is different from "valueOld".
     * note: you can pass an optional "statusNew" in case you want to change it.
     *
     * @param id
     * @param valueOld
     * @param valueNew
     * @param statusNew: optional
     * @returns {Promise<void>}
     */
    patchValueAndStatusOnDiff(id, valueOld, valueNew, statusNew = null) {
        if (_.isEqual(valueNew, JSON.parse(valueOld))) {
            // valueNew and valueOld are equal. PATCH only status, if not null
            if (statusNew) {
                return this.patch(id, [
                    {
                        path: "/status",
                        op: "REPLACE",
                        value: statusNew,
                    },
                ]);
            }
            return Promise.resolve();
        } else {
            const patchValue = [
                {
                    path: "/value",
                    op: "REPLACE",
                    value: JSON.stringify(valueNew),
                },
            ];
            if (statusNew) {
                patchValue.push({
                    path: "/status",
                    op: "REPLACE",
                    value: statusNew,
                });
            }
            return this.patch(id, patchValue);
        }
    },
    async setAuftragDetailSkipped(
        orderId,
        step,
        devicepoolId = null,
        deviceId = null,
        auftragItemId = null,
        excludeSingleRecordCheck = false
    ) {
        const auftragDetail = await this.getByOrderIdAndStep(
            orderId,
            step,
            devicepoolId,
            deviceId,
            auftragItemId,
            excludeSingleRecordCheck
        );
        return this.patch(auftragDetail.auftragDetailID, [
            {
                op: "replace",
                path: "/status",
                value: constants.auftragDetailStatus.SKIPPED,
            },
        ]);
    },
};
