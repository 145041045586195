import Order from "@/views/orders/Order";
import OrderDetails from "@/views/orders/OrderDetails";
import Orders from "@/views/orders/Orders";
import OrdersAnalysis from "@/views/orders/OrdersAnalysis";
import OrdersList from "@/views/orders/OrdersList";

export default [
    {
        path: "/orders",
        name: "orders",
        component: Orders,
        redirect: { name: "orders-list" },
        children: [
            {
                path: "analysis",
                name: "orders-analysis",
                component: OrdersAnalysis,
            },
            {
                path: "list",
                name: "orders-list",
                component: OrdersList,
            },
        ],
    },
    {
        path: "/orders/:id",
        name: "order-details",
        component: OrderDetails,
    },
    {
        path: "/orders/:id",
        name: "order",
        component: Order,
    },
];
