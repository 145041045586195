import { httpClient } from "@/services/api/client";

const endpoint = "/app/company-postalData";

export default {
    // ********************
    // default REST methods
    // ********************
    updateCompanyPostalData(companyID, communityCodes) {
        return httpClient.put(`${endpoint}/${companyID}`, communityCodes);
    },
};
