<template>
    <Main>
        <ExecHeader :devicePool="devicePool" :order="order" />
        <Box headerText="Andere Geräte" :isLoading="!devices" noBody>
            <BoxBody v-if="!devices?.length">
                Keine Geräte vorhanden.
            </BoxBody>
            <div v-else v-for="(d, i) in devices" :key="`d_${i}`">
                <HorizontalSeparator v-if="i > 0" />
                <BoxBody>
                    <div>
                        <strong>{{ d.type }}</strong> ({{ d.factoryNo }})
                    </div>
                    <div v-if="isDeviceToUninstall(d)" class="d-flex py-2">
                        <b-button class="ml-auto" @click="onClickRemove(d.deviceID)">Ausbau</b-button>
                    </div>
                </BoxBody>
            </div>
        </Box>
    </Main>
</template>

<script>
import ExecHeader from "@/components/execution/ExecHeader";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import auftragsAPI from "@/services/api/auftrags.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import devicesAPI from "@/services/api/devices.api";

export default {
    name: "ExecutionOtherDevices",
    components: { ExecHeader },
    data() {
        return {
            devicePool: null,
            devices: null,
            order: null,
            auftragDetails811: [],
        };
    },
    async mounted() {
        this.devicePoolId = this.$route.params.devicePoolId;
        this.orderId = this.$route.params.orderId;

        auftragsAPI.getSingle(this.orderId).then((resp) => {
            this.order = resp.data;
        });
        devicePoolsAPI.getSingle(this.devicePoolId).then((resp) => {
            this.devicePool = resp.data;
        });
        devicesAPI
            .get({
                devicePoolID: this.devicePoolId,
                type__in:
                    "Kommunikationseinheit,Kommunikationsmodul,Schnittstellenkonverter,Kommunikation-Steuermodul,Modem",
                deviceSuffix__not_in: "Smart Manager",
                includeDeviceInfos: true,
            })
            .then((resp) => {
                this.devices = resp;
            });
        this.auftragDetails811 = await auftragDetailsAPI.get({
            auftragID: this.orderId,
            devicePoolID: this.devicePoolId,
            actionGroupSequence: 8,
            actionItemSequence: 1,
            actionDetailSequence: 1,
        });
    },
    methods: {
        onClickRemove(deviceID) {
            // navigate to step 8-1-1 for deviceID
            this.$router.push({
                name: "step-8-1-1",
                params: {
                    deviceId: deviceID,
                    devicePoolId: this.devicePoolId,
                    orderId: this.orderId,
                },
            });
        },
        isDeviceToUninstall(d) {
            /* Returns true if device is in 8-1-1 step */
            if (!this.auftragDetails811 && this.auftragDetails811.length === 0) {
                return false;
            }
            return this.auftragDetails811.find((ad) => ad.deviceID === d.deviceID) != null;
        },
    },
};
</script>
