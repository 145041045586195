<template>
    <div>
        <b-navbar type="light" variant="LIGHT" class="p-0">
            <b-navbar-nav>
                <b-nav-item :to="{ name: 'users' }" :active="$route.name === 'users'">Techniker</b-nav-item>
                <b-nav-item :to="{ name: 'companies' }" :active="$route.name === 'companies'">Firmen</b-nav-item>
                <b-nav-item :to="{ name: 'groups' }" :active="$route.name === 'groups'">Gruppen</b-nav-item>
                <b-nav-item :to="{ name: 'calendars' }" :active="$route.name === 'calendars'">Kalender</b-nav-item>
            </b-navbar-nav>
        </b-navbar>
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "UsersRoot",
    data() {
        return {};
    },
    async beforeMount() {},
    mounted() {
        if (this.$route.name === "userManager") {
            this.$router.push({ name: "users" });
        }
    },
    methods: {},
};
</script>

<style scoped></style>
