<template>
    <div>
        <DxDataGrid
            style="width: 100%;"
            id="grid-container"
            ref="dataGridRef"
            :data-source="gridCompanies"
            :show-borders="true"
            :remote-operations="true"
            :allow-column-resizing="true"
            :allow-column-reordering="true"
            :show-row-lines="true"
            :show-column-lines="false"
            :row-alternation-enabled="true"
            :column-auto-width="true"
        >
            <DxLoadPanel :visible="isLoading" :enabled="true" :showIndicator="true" :showPane="true" />
            <DxHeaderFilter :visible="true" :search="{ enabled: true }">
                <DxSearch :enabled="true" :timeout="900" :editor-options="searchEditorOptions" mode="contains" />
            </DxHeaderFilter>
            <DxToolbar>
                <DxItem location="after" widget="dxButton" :options="clearFilterButtonOptions" />
                <DxItem widget="dxButton" :options="reloadButtonOptions" />
                <DxItem name="searchPanel" />
                <DxItem name="groupPanel" />
                <DxItem widget="dxButton" :options="addButtonOptions" v-if="checkIfUserIsAdmin()" />
            </DxToolbar>
            <DxSearchPanel :visible="true"></DxSearchPanel>
            <DxPaging :page-size="20" />
            <DxPager
                :show-page-size-selector="true"
                :allowed-page-sizes="[20, 50, 100]"
                :show-navigation-buttons="true"
                :show-info="true"
            />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="false" />

            <DxColumn data-field="companyID" caption="ID" data-type="number" :visible="false" />
            <DxColumn data-field="name" caption="Name des Unternehmens" data-type="string" />
            <DxColumn data-field="user.fullName" caption="Manager" data-type="string" />
            <DxColumn
                data-field="municipalitiesDisplay"
                caption="Gemeinde"
                data-type="string"
                :allow-sorting="false"
                :allow-grouping="false"
                :allow-filtering="false"
            />
            <DxColumn type="buttons" :width="100" :allow-sorting="false" :allow-grouping="false">
                <DxButton
                    hint="Bearbeiten"
                    icon="edit"
                    text="go"
                    :on-click="onClickEditCompany"
                    :visible="isIconVisible"
                />
                <DxButton
                    hint="Löschen"
                    icon="trash"
                    text="go"
                    :on-click="onClickDeleteCompany"
                    :visible="isIconVisible"
                />
            </DxColumn>
        </DxDataGrid>
    </div>
</template>

<script>
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
    DxButton,
    DxColumn,
    DxDataGrid,
    DxGroupPanel,
    DxGrouping,
    DxHeaderFilter,
    DxItem,
    DxLoadPanel,
    DxPager,
    DxPaging,
    DxSearch,
    DxSearchPanel,
    DxToolbar,
} from "devextreme-vue/data-grid";
import { mapGetters } from "vuex";
import companiesAPI from "../../services/api/companies.api";
import gridService from "../../services/gridBaseService";
import constants from "@/constants/constants";
import userCompanyPermissionService from "../../services/userCompanyPermissionService";
export default {
    name: "Companies",
    components: {
        DxDataGrid,
        DxColumn,
        DxGroupPanel,
        DxGrouping,
        DxHeaderFilter,
        DxItem,
        DxLoadPanel,
        DxPager,
        DxPaging,
        DxSearch,
        DxSearchPanel,
        DxButton,
        DxToolbar,
    },
    data() {
        return {
            gridCompanies: [],
            searchEditorOptions: {
                placeholder: "Search value",
                mode: "text",
            },
            clearFilterButtonOptions: {
                icon: "fas fa-filter-circle-xmark",
                hint: "Filter löschen",
                onClick: () => {
                    this.$refs.dataGridRef.instance.clearFilter();
                },
            },
            reloadButtonOptions: {
                icon: "fas fa-rotate",
                hint: "Aktualisieren",
                onClick: () => {
                    this.$refs.dataGridRef.instance.refresh();
                },
            },
            addButtonOptions: {
                icon: "fa fa-plus",
                hint: "Hinzufügen",
                onClick: () => {
                    this.onClickCreateCompany();
                },
            },
            isLoading: false,
        };
    },
    async mounted() {
        this.isLoading = true;
        try {
            const remoteDataSource = createStore(
                gridService.getStoreConfig({
                    key: "companyID",
                    loadUrl: companiesAPI.getCompaniesForGridUrl(),
                })
            );
            this.gridCompanies = remoteDataSource;
        } finally {
            this.isLoading = false;
        }
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    methods: {
        isIconVisible(e) {
            return userCompanyPermissionService.validateEditingPermission(e.row.data.managerID, this.currentUser);
        },
        checkIfUserIsAdmin() {
            return this.currentUser
                ? userCompanyPermissionService.containsRole(this.currentUser.userRoles, constants.ADMIN_ROLE_ID)
                : false;
        },
        onClickEditCompany(e) {
            this.$router.push({ name: "companies-edit", params: { companyID: e.row?.data?.companyID } });
            e.event.preventDefault();
        },
        async onClickDeleteCompany(e) {
            await companiesAPI.delete(e.row?.data?.companyID);
            await this.$refs.dataGridRef.instance.refresh();
        },
        async onClickCreateCompany() {
            const payload = {
                managerID: this.currentUser.userID,
            };
            const company = (await companiesAPI.post(payload)).data;
            await this.$refs.dataGridRef.instance.refresh();
            await this.$router.push({
                name: "companies-edit",
                params: { companyID: company?.companyID },
                query: { newCompany: true },
            });
        },
    },
};
</script>

<style scoped>
#grid-container {
    width: 100%;
    height: calc(100vh - 128px);
}
</style>
