<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail">
        <template v-if="actAuftragDetail">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div>
                <!-- Form Risks -->
                <div class="mb-2 mt-2">Welche Gefahren bestehen an diesem Hausanschluss?</div>
                <b-form>
                    <b-form-group>
                        <b-form-checkbox v-model="formRisks.isDangerGruenspan" v-on:change="onChangeOtherRisk">
                            Grünspan
                        </b-form-checkbox>
                        <PhotoUpload
                            v-show="formRisks.isDangerGruenspan"
                            key="gruenspanPhoto"
                            app="rellout"
                            entity="hak"
                            :entityId="order.hakID"
                            refEntity="auftragdetail"
                            :refEntityId="actAuftragDetail.auftragDetailID"
                            refEntityKey="gruenspan"
                            folder="haks"
                            :fileDescription="
                                `Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title} - Gruenspan`
                            "
                            :showImages="true"
                            :assetDelete="true"
                            @assetItems="onAssetItemsIsDangerGruenspan"
                            :showLabelForCaptureTypes="true"
                            :captureTypes="['camera', 'gallery']"
                            :isDisabled="false"
                            :auftragDetailStatus="actAuftragDetail.status"
                            :auftragStatus="order.auftragStatusID"
                        />
                        <b-form-checkbox
                            v-model="formRisks.isDangerKeinNeutralleitertrenner"
                            v-on:change="onChangeOtherRisk"
                        >
                            Kein Neutralleiter für Auftrennung TNC auf TNS
                        </b-form-checkbox>
                        <PhotoUpload
                            v-show="formRisks.isDangerKeinNeutralleitertrenner"
                            key="neutralleiterImage"
                            app="rellout"
                            entity="hak"
                            :entityId="order.hakID"
                            refEntity="auftragdetail"
                            :refEntityId="actAuftragDetail.auftragDetailID"
                            refEntityKey="neutralleiter"
                            folder="haks"
                            :fileDescription="
                                `Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title} - Neutralleiter`
                            "
                            :showImages="true"
                            :assetDelete="true"
                            @assetItems="onAssetItemsIsDangerKeinNeutralleitertrenner"
                            :showLabelForCaptureTypes="true"
                            :captureTypes="['camera', 'gallery']"
                            :isDisabled="false"
                            :auftragDetailStatus="actAuftragDetail.status"
                            :auftragStatus="order.auftragStatusID"
                        />
                        <b-form-checkbox v-model="formRisks.isDangerKorrosion" v-on:change="onChangeOtherRisk">
                            Korrosion / Oxidation
                        </b-form-checkbox>
                        <PhotoUpload
                            v-show="formRisks.isDangerKorrosion"
                            key="korrosionImage"
                            app="rellout"
                            entity="hak"
                            :entityId="order.hakID"
                            refEntity="auftragdetail"
                            :refEntityId="actAuftragDetail.auftragDetailID"
                            refEntityKey="korrosion"
                            folder="haks"
                            :fileDescription="
                                `Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title} - Korrosion`
                            "
                            :showImages="true"
                            :assetDelete="true"
                            @assetItems="onAssetItemsIsDangerKorrosion"
                            :showLabelForCaptureTypes="true"
                            :captureTypes="['camera', 'gallery']"
                            :isDisabled="false"
                            :auftragDetailStatus="actAuftragDetail.status"
                            :auftragStatus="order.auftragStatusID"
                        />
                        <b-form-checkbox
                            v-model="formRisks.isDangerOffeneSpannungsfuehrendeTeile"
                            v-on:change="onChangeOtherRisk"
                        >
                            Offene spannungsführende Teile
                        </b-form-checkbox>
                        <PhotoUpload
                            v-show="formRisks.isDangerOffeneSpannungsfuehrendeTeile"
                            key="spannungsfuehrendePhoto"
                            app="rellout"
                            entity="hak"
                            :entityId="order.hakID"
                            refEntity="auftragdetail"
                            :refEntityId="actAuftragDetail.auftragDetailID"
                            refEntityKey="spannungsfuehrende"
                            folder="haks"
                            :fileDescription="
                                `Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title} - Offene spannungsführende Teile`
                            "
                            :showImages="true"
                            :assetDelete="true"
                            @assetItems="onAssetItemsIsDangerOffeneSpannungsfuehrendeTeile"
                            :showLabelForCaptureTypes="true"
                            :captureTypes="['camera', 'gallery']"
                            :isDisabled="false"
                            :auftragDetailStatus="actAuftragDetail.status"
                            :auftragStatus="order.auftragStatusID"
                        />
                        <b-form-checkbox
                            v-model="formRisks.isDangerTeerfuellungUngenuegend"
                            v-on:change="onChangeOtherRisk"
                        >
                            Teerfüllung ungenügend
                        </b-form-checkbox>
                        <PhotoUpload
                            v-show="formRisks.isDangerTeerfuellungUngenuegend"
                            key="teerfuellungPhoto"
                            app="rellout"
                            entity="hak"
                            :entityId="order.hakID"
                            refEntity="auftragdetail"
                            :refEntityId="actAuftragDetail.auftragDetailID"
                            refEntityKey="teerfuellung"
                            folder="haks"
                            :fileDescription="
                                `Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title} - Teerfüllung ungenügend`
                            "
                            :showImages="true"
                            :assetDelete="true"
                            @assetItems="onAssetItemsIsDangerTeerfuellungUngenuegend"
                            :showLabelForCaptureTypes="true"
                            :captureTypes="['camera', 'gallery']"
                            :isDisabled="false"
                            :auftragDetailStatus="actAuftragDetail.status"
                            :auftragStatus="order.auftragStatusID"
                        />
                        <b-form-checkbox v-model="formRisks.isDangerKeine" v-on:change="onChangeKeine">
                            Keine
                        </b-form-checkbox>
                        <b-form-checkbox v-model="formRisks.isDangerAndere" v-on:change="onChangeOtherRisk">
                            Andere
                        </b-form-checkbox>
                        <PhotoUpload
                            v-show="formRisks.isDangerAndere"
                            key="anderePhoto"
                            app="rellout"
                            entity="hak"
                            :entityId="order.hakID"
                            refEntity="auftragdetail"
                            :refEntityId="actAuftragDetail.auftragDetailID"
                            refEntityKey="andere"
                            folder="haks"
                            :fileDescription="
                                `Auftrag ${$route.params.orderId} - ${actAuftragDetail.action.title} - Andere`
                            "
                            :showImages="true"
                            :assetDelete="true"
                            @assetItems="onAssetItemsIsDangerAndere"
                            :showLabelForCaptureTypes="true"
                            :captureTypes="['camera', 'gallery']"
                            :isDisabled="false"
                            :auftragDetailStatus="actAuftragDetail.status"
                            :auftragStatus="order.auftragStatusID"
                        />
                        <b-form-textarea
                            v-model="formRisks.dangerAndereBeschreibung"
                            placeholder="Bitte Beschreibung eingeben..."
                            rows="3"
                            :disabled="!formRisks.isDangerAndere"
                        />
                    </b-form-group>
                </b-form>
                <!-- Form Control -->
                <div class="mb-2 mt-4" v-if="hak">Zusätzliche Kontrolle am Hausanschluss</div>
                <b-form>
                    <b-form-checkbox v-model="auftragValue.formControl.isLetztmaligePruefung" @change="getEmailPreview">
                        Es soll eine zusätzliche Kontrolle vor Ort vorgenommen werden
                    </b-form-checkbox>
                    <div v-show="auftragValue.formControl.isLetztmaligePruefung">
                        <b-form-textarea
                            v-model="auftragValue.formControl.letztmaligePruefungBeschreibung"
                            placeholder="Bitte Kontrollhinweis eingeben..."
                            rows="3"
                        />
                        <div v-if="auftragValue.isMailSent">
                            <font-awesome-icon icon="check" style="color: green" /> E-Mail gesendet:
                            {{ auftragValue.mailSentDate }}
                        </div>
                        <div v-if="compIsEmailValid">
                            <div
                                v-for="toAddress in emailObject.toAddresses"
                                :key="`toAddress - ${toAddress.name}`"
                                class="ml-2 text-secondary"
                            >
                                To: <span class="font-italic">{{ toAddress.address }}</span>
                            </div>
                            <div
                                v-for="ccAddress in emailObject.ccAddresses"
                                :key="`ccAddress - ${ccAddress.name}`"
                                class="ml-2 text-secondary"
                            >
                                Cc: <span class="font-italic">{{ ccAddress.address }}</span>
                            </div>
                            <div class="my-2 text-secondary">
                                Betreff: <span class="font-italic">{{ emailObject.subject }}</span>
                            </div>
                        </div>
                    </div>
                </b-form>
            </div>
            <b-button
                class="my-3 float-right"
                @click="onClickErledigt"
                :disabled="compErledigtIsDisabled"
                variant="primary"
            >
                Erledigt
            </b-button>
            <b-button
                v-show="auftragValue.formControl.isLetztmaligePruefung"
                class="my-3 mr-3 float-right"
                @click="onClickSendMail"
                variant="primary"
            >
                Email senden
            </b-button>
        </template>
    </Box>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import constants from "@/constants/constants";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import PhotoUpload from "@/components/general/PhotoUpload";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import emailsAPI from "@/services/api/emails.api";
import haksAPI from "@/services/api/haks.api";
import keyValueOptionsAPI from "@/services/api/keyValueOptionsScd.api";

import { keyValueOptionsByIdReturnValue, keyValueOptionsByType } from "@/utils/ExecutionUtils";

export default {
    name: "Step_1_2_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        PhotoUpload,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            assetItems: {},
            auftragValue: {
                formControl: {
                    isLetztmaligePruefung: false,
                    letztmaligePruefungBeschreibung: null,
                },
                isMailSent: false,
                mailSentDate: null,
                mailBody: null,
            },
            formRisks: {
                isDangerGruenspan: false,
                isDangerKeinNeutralleitertrenner: false,
                isDangerKorrosion: false,
                isDangerOffeneSpannungsfuehrendeTeile: false,
                isDangerTeerfuellungUngenuegend: false,
                isDangerKeine: false,
                isDangerAndere: false,
                dangerAndereBeschreibung: null,
            },
            hak: null,
            keyValueOptionsHak: [],
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            currentStep: null,
            emailObject: null,
        };
    },
    async mounted() {
        await keyValueOptionsAPI.get({ category: "hak" }).then((resp) => {
            this.keyValueOptionsHak = resp.data;
        });
        this.loadAuftragDetail();
        await this.loadHak();
    },
    computed: {
        ...mapGetters({
            actAuftragDetail: "execution/storeActAuftragDetail",
            oidcUser: "oidcUser",
        }),
        formattedText() {
            return this.auftragValue.formControl.letztmaligePruefungBeschreibung
                ?.replace(/\n/g, "<br/>")
                .replace(/\t/g, "&emsp;");
        },
        compErledigtIsDisabled() {
            let isDisabled = false;
            // requirement: change erledigt behaviour. if a danger is selected, photo is a requirement in order to activate erledigt.
            for (const [key, value] of Object.entries(this.formRisks)) {
                const checkboxTicked = value;
                if (checkboxTicked && key !== "isDangerKeine") {
                    // if there is a value checked (or commented), check for photos
                    isDisabled = !(!isDisabled && this.assetItems[key] > 0);
                }
            }
            // requirement: Andere + foto + testo: erledigt attivo. Andere senza foto e/o testo erledigt non attivo
            if (
                this.formRisks.isDangerAndere &&
                (!this.assetItems.isDangerAndere || !this.formRisks.dangerAndereBeschreibung)
            ) {
                isDisabled = true;
            } else {
                isDisabled = false;
            }
            if (this.actAuftragDetail.status === 201) {
                // disable erledigt button if status is 201 (already completed)
                isDisabled = true;
            }
            return (
                !this.actAuftragDetail.isExecutable ||
                this.compStatus !== constants.auftragDetailStatus.DONE_REPEATABLE ||
                isDisabled
            );
        },
        compIsEmailValid() {
            return this.emailObject && this.emailObject.toAddresses && this.emailObject.toAddresses.length > 0;
        },
        constants() {
            return constants;
        },
        compStatus() {
            if (this.auftragValue.formControl.isLetztmaligePruefung) {
                // Zusätzliche Kontrolle am Hausanschluss
                if (this.auftragValue.isMailSent) {
                    return constants.auftragDetailStatus.DONE_REPEATABLE;
                }
            }
            if (
                this.formRisks.isDangerGruenspan ||
                this.formRisks.isDangerKeinNeutralleitertrenner ||
                this.formRisks.isDangerKorrosion ||
                this.formRisks.isDangerOffeneSpannungsfuehrendeTeile ||
                this.formRisks.isDangerTeerfuellungUngenuegend ||
                this.formRisks.isDangerKeine ||
                this.formRisks.isDangerAndere
            ) {
                return constants.auftragDetailStatus.DONE_REPEATABLE;
            }
            return constants.auftragDetailStatus.NOT_STARTED; // Status=3 id at least a checbox of formRisks is selected
        },
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        fixAndereFields() {
            if (!this.formRisks.isDangerAndere) {
                this.formRisks.andereText = null;
            }
            if (!this.auftragValue.formControl.isLetztmaligePruefung) {
                this.auftragValue.formControl.letztmaligePruefungBeschreibung = null;
            }
        },
        loadAuftragDetail() {
            const pathSplit = this.$route.fullPath.split("/");
            this.currentStep = pathSplit[pathSplit.length - 1];
            return auftragDetailsAPI.getByOrderIdAndStep(this.$route.params.orderId, this.currentStep).then((res) => {
                this.setActAuftragDetail(res);

                if (res?.value) {
                    const parsedJSON = JSON.parse(res.value);
                    _.assign(this.auftragValue, parsedJSON);
                }
            });
        },
        async loadHak() {
            const hak = (await haksAPI.getSingle(this.order.hakID)).data;
            // formRisks
            //this.formRisks.isDangerGruenspan = hak.isDangerGruenspan == null ? false : hak.isDangerGruenspan;
            this.formRisks.isDangerGruenspan =
                hak.dangerGruenspanKeyValueOptionID === null
                    ? false
                    : keyValueOptionsByIdReturnValue(this.keyValueOptionsHak, hak.dangerGruenspanKeyValueOptionID);
            //this.formRisks.isDangerKeinNeutralleitertrenner =
            //    hak.isDangerKeinNeutralleitertrenner == null ? false : hak.isDangerKeinNeutralleitertrenner;
            this.formRisks.isDangerKeinNeutralleitertrenner =
                hak.dangerKeinNeutralleitertrennerKeyValueOptionID === null
                    ? false
                    : keyValueOptionsByIdReturnValue(
                          this.keyValueOptionsHak,
                          hak.dangerKeinNeutralleitertrennerKeyValueOptionID
                      );

            //this.formRisks.isDangerKorrosion = hak.isDangerKorrosion == null ? false : hak.isDangerKorrosion;
            this.formRisks.isDangerKorrosion =
                hak.dangerKorrosionKeyValueOptionID === null
                    ? false
                    : keyValueOptionsByIdReturnValue(this.keyValueOptionsHak, hak.dangerKorrosionKeyValueOptionID);

            //this.formRisks.isDangerOffeneSpannungsfuehrendeTeile =
            //    hak.isDangerOffeneSpannungsfuehrendeTeile == null ? false : hak.isDangerOffeneSpannungsfuehrendeTeile;
            this.formRisks.isDangerOffeneSpannungsfuehrendeTeile =
                hak.dangerOffeneSpannungsfuehrendeTeileKeyValueOptionID === null
                    ? false
                    : keyValueOptionsByIdReturnValue(
                          this.keyValueOptionsHak,
                          hak.dangerOffeneSpannungsfuehrendeTeileKeyValueOptionID
                      );
            //this.formRisks.isDangerTeerfuellungUngenuegend =
            //    hak.isDangerTeerfuellungUngenuegend == null ? false : hak.isDangerTeerfuellungUngenuegend;
            this.formRisks.isDangerTeerfuellungUngenuegend =
                hak.dangerTeerfuellungUngenuegendKeyValueOptionID === null
                    ? false
                    : keyValueOptionsByIdReturnValue(
                          this.keyValueOptionsHak,
                          hak.dangerTeerfuellungUngenuegendKeyValueOptionID
                      );

            this.formRisks.isDangerKeine = hak.isDangerKeine == null ? false : hak.isDangerKeine;
            //this.formRisks.isDangerAndere = hak.isDangerAndere == null ? false : hak.isDangerAndere;
            this.formRisks.isDangerAndere =
                hak.dangerAndereKeyValueOptionID === null
                    ? false
                    : keyValueOptionsByIdReturnValue(this.keyValueOptionsHak, hak.dangerAndereKeyValueOptionID);

            this.formRisks.dangerAndereBeschreibung = hak.dangerAndereBeschreibung;
            this.hak = hak;
        },
        onAssetItemsIsDangerGruenspan(val) {
            this.assetItems.isDangerGruenspan = val.images.length;
        },
        onAssetItemsIsDangerKeinNeutralleitertrenner(val) {
            this.assetItems.isDangerKeinNeutralleitertrenner = val.images.length;
        },
        onAssetItemsIsDangerKorrosion(val) {
            this.assetItems.isDangerKorrosion = val.images.length;
        },
        onAssetItemsIsDangerOffeneSpannungsfuehrendeTeile(val) {
            this.assetItems.isDangerOffeneSpannungsfuehrendeTeile = val.images.length;
        },
        onAssetItemsIsDangerTeerfuellungUngenuegend(val) {
            this.assetItems.isDangerTeerfuellungUngenuegend = val.images.length;
        },
        onAssetItemsIsDangerAndere(val) {
            this.assetItems.isDangerAndere = val.images.length;
        },
        onClickErledigt() {
            this.save(this.compStatus).then(() => {
                // only navigate to next step if everything was saved correctly
                this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            });
        },
        onClickNext(step) {
            // ToDo(clarify): are you kidding me? save status "null" when navigating to next step?!?! why would you do this? -> disabled for the moment
            //this.save(null);
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        onChangeKeine() {
            // when "keine" is selected uncheck other values
            if (this.formRisks.isDangerKeine) {
                this.formRisks = {
                    isDangerGruenspan: false,
                    isDangerKeinNeutralleitertrenner: false,
                    isDangerKorrosion: false,
                    isDangerOffeneSpannungsfuehrendeTeile: false,
                    isDangerTeerfuellungUngenuegend: false,
                    isDangerKeine: true,
                    isDangerAndere: false,
                    dangerAndereBeschreibung: null,
                };
            }
        },
        onChangeOtherRisk() {
            // when other checkbox is selected uncheck "keine" value
            if (this.formRisks.isDangerKeine) {
                this.formRisks.isDangerKeine = false;
            }
        },

        getEmailPreview(isCheckBoxChecked) {
            if (isCheckBoxChecked) {
                const payload = this.createEmailPayload(constants.mail.PREVENT_SENDING_MAIL);
                emailsAPI
                    .sendChefmonteurMeldungEmail(this.order.auftragID, payload)
                    .then((resp) => {
                        this.emailObject = resp.data;
                    })
                    .catch(() => {
                        this.emailObject = null;
                    });
            }
        },

        createEmailPayload(prevention) {
            return {
                message: this.formattedText ?? "",
                prevention: prevention,
            };
        },

        onClickSendMail() {
            const payload = this.createEmailPayload(constants.mail.SEND_MAIL);
            emailsAPI
                .sendChefmonteurMeldungEmail(this.order.auftragID, payload)
                .then((resp) => {
                    this.emailObject = resp.data;
                    this.auftragValue.mailBody = this.emailObject.htmlBody;
                })
                .then(() => {
                    this.$bvModal.msgBoxOk("Nachricht erfolgreich gesendet", {
                        title: "E-mail gesendet",
                        size: "sm",
                        buttonSize: "sm",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    });
                })
                .then(() => {
                    this.auftragValue.isMailSent = true;
                    this.auftragValue.mailSentDate = moment().format(constants.DATETIME_FORMAT);
                })
                .then(() => {
                    const auftragDetailPayload = [
                        {
                            op: "replace",
                            path: "/value",
                            value: JSON.stringify(this.auftragValue),
                        },
                    ];
                    // ToDo(clarify): why do this on "send e-mail" and not on "erledigt"?
                    auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, auftragDetailPayload);
                })
                .catch(() => {
                    this.emailObject = null;
                });
        },
        async save(status) {
            this.fixAndereFields();
            if (status) {
                const auftragDetailPayload = [
                    {
                        op: "replace",
                        path: "/status",
                        value: status,
                    },
                ];
                await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, auftragDetailPayload);
            }
            const hakPayload = [
                {
                    op: "replace",
                    //path: "/isDangerGruenspan",
                    //value: this.formRisks.isDangerGruenspan,
                    path: "/dangerGruenspanKeyValueOptionID",
                    value: keyValueOptionsByType(
                        this.keyValueOptionsHak,
                        "JaNein",
                        this.formRisks.isDangerGruenspan,
                        "hak",
                        "DangerGruenspanKeyValueOptionID"
                    ).keyValueOptionID,
                },
                {
                    op: "replace",
                    //path: "/isDangerKeinNeutralleitertrenner",
                    //value: this.formRisks.isDangerKeinNeutralleitertrenner,
                    path: "/dangerKeinNeutralleitertrennerKeyValueOptionID",
                    value: keyValueOptionsByType(
                        this.keyValueOptionsHak,
                        "JaNein",
                        this.formRisks.isDangerKeinNeutralleitertrenner,
                        "hak",
                        "DangerKeinNeutralleitertrennerKeyValueOptionID"
                    ).keyValueOptionID,
                },
                {
                    op: "replace",
                    //path: "/isDangerKorrosion",
                    //value: this.formRisks.isDangerKorrosion,
                    path: "/dangerKorrosionKeyValueOptionID",
                    value: keyValueOptionsByType(
                        this.keyValueOptionsHak,
                        "JaNein",
                        this.formRisks.isDangerKorrosion,
                        "hak",
                        "DangerKorrosionKeyValueOptionID"
                    ).keyValueOptionID,
                },
                {
                    op: "replace",
                    //path: "/isDangerOffeneSpannungsfuehrendeTeile",
                    //value: this.formRisks.isDangerOffeneSpannungsfuehrendeTeile,
                    path: "/dangerOffeneSpannungsfuehrendeTeileKeyValueOptionID",
                    value: keyValueOptionsByType(
                        this.keyValueOptionsHak,
                        "JaNein",
                        this.formRisks.isDangerOffeneSpannungsfuehrendeTeile,
                        "hak",
                        "DangerOffeneSpannungsfuehrendeTeileKeyValueOptionID"
                    ).keyValueOptionID,
                },
                {
                    op: "replace",
                    //path: "/isDangerTeerfuellungUngenuegend",
                    //value: this.formRisks.isDangerTeerfuellungUngenuegend,
                    path: "/dangerTeerfuellungUngenuegendKeyValueOptionID",
                    value: keyValueOptionsByType(
                        this.keyValueOptionsHak,
                        "JaNein",
                        this.formRisks.isDangerTeerfuellungUngenuegend,
                        "hak",
                        "DangerTeerfuellungUngenuegendKeyValueOptionID"
                    ).keyValueOptionID,
                },
                {
                    op: "replace",
                    path: "/isDangerKeine",
                    value: this.formRisks.isDangerKeine,
                },
                {
                    op: "replace",
                    //path: "/isDangerAndere",
                    //value: this.formRisks.isDangerAndere,
                    path: "/dangerAndereKeyValueOptionID",
                    value: keyValueOptionsByType(
                        this.keyValueOptionsHak,
                        "JaNein",
                        this.formRisks.isDangerAndere,
                        "hak",
                        "DangerAndereKeyValueOptionID"
                    ).keyValueOptionID,
                },
                {
                    op: "replace",
                    path: "/dangerAndereBeschreibung",
                    value: this.formRisks.dangerAndereBeschreibung,
                },
                {
                    op: "replace",
                    path: "/isLetztmaligePruefung",
                    value: this.auftragValue.formControl.isLetztmaligePruefung,
                },
                {
                    op: "replace",
                    path: "/letztmaligePruefungBeschreibung",
                    value: this.auftragValue.formControl.letztmaligePruefungBeschreibung,
                },
            ];
            return haksAPI.patch(this.order.hakID, hakPayload);
        },
    },
};
</script>
