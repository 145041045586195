<template>
    <Main>
        <ExecHeader :devicePool="devicePool" :order="order" />
        <!-- SMANs -->
        <Box v-if="devicesSman?.length" headerText="Smart Manager (SMAN)" :isLoading="!devicesSmanWithRelais" noBody>
            <BoxBody v-if="!devicesSmanWithRelais?.length">
                Keine Geräte vorhanden.
            </BoxBody>
            <div v-for="(sman, i_s) in devicesSmanWithRelais" :key="`sman_${i_s}`">
                <HorizontalSeparator v-if="i_s > 0" />
                <BoxBody>
                    <div class="d-flex">
                        <div>
                            <strong>{{ sman.deviceSuffix }} </strong>
                            <template v-if="sman.factoryNo">{{ sman.factoryNo }}</template>
                            <template v-else>({{ sman.deviceID }})</template>
                            <div>Aktiviert: {{ isActiveOnMsp(sman.deviceID) ? "Ja" : "Nein" }}</div>
                        </div>
                        <div class="ml-auto">
                            <b-button
                                v-if="!sman.iseDeviceID"
                                v-on:click="onClickSmanDelete(sman.deviceID)"
                                variant="primary"
                                class="d-block mb-1"
                            >
                                <font-awesome-icon :icon="['fas', 'trash']" size="lg" />
                            </b-button>
                            <b-button v-on:click="onClickSmanActivate(sman.deviceID)" variant="primary" class="d-block">
                                <font-awesome-icon :icon="['fas', 'play-circle']" size="lg" />
                            </b-button>
                        </div>
                    </div>

                    <div v-if="sman.relloutUninstall" class="mt-2">
                        <b-button @click="onClickSman(sman.deviceID)" variant="primary">
                            Gerät wechseln/demontieren
                        </b-button>
                    </div>

                    <b-spinner v-if="isLoadingSmanFlexibilities" class="d-block m-2" />
                    <!-- Show relais only if: SMAN has factoryNo and is a new one (relloutUninstall=false) -->
                    <div v-else-if="sman.factoryNo && !sman.relloutUninstall">
                        <div v-for="(r, i_r) in sman.cstRelais" class="my-2" :key="`r_${i_r}`">
                            <HorizontalSeparator />
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <div>
                                        <strong class="mr-1">Relais {{ r.relaisNumber + 1 }}:</strong>
                                        <span v-if="r.relaisProgramText">{{ r.relaisProgramText }}</span>
                                        <span v-else>nichts ausgewählt</span>
                                        <div>
                                            <strong class="mr-1">Anschlusstyp:</strong>
                                            <span v-if="r.relaisTypeText">{{ r.relaisTypeText }}</span>
                                            <span v-else>-</span>
                                        </div>
                                    </div>
                                    <div>
                                        <template v-if="false">
                                            <div
                                                v-for="(f, i_f) in r.deviceFlexibilitaets"
                                                class="d-flex my-2"
                                                :key="`f_${i_f}`"
                                            >
                                                <div>ToDo (ID {{ f.deviceFlexibilitaetID }})</div>
                                                <div class="ml-auto">
                                                    <b-button
                                                        class="mr-3"
                                                        @click="onClickSmanFlexiRemove(f.deviceFlexibilitaetID)"
                                                        size="sm"
                                                    >
                                                        X
                                                    </b-button>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="ml-auto">
                                    <b-button variant="primary" @click="onClickRelaisEdit(r.deviceRelaisID)">
                                        <font-awesome-icon :icon="['fas', 'pencil-alt']" size="lg" />
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </BoxBody>
            </div>
        </Box>
        <!-- RSE -->
        <Box
            v-if="devicesSteuergeraet?.length"
            headerText="Rundsteuerempfänger (RSE)"
            :isLoading="!devicesSteuergeraet"
            noBody
        >
            <BoxBody v-if="!devicesSteuergeraet?.length">
                Keine Geräte vorhanden.
            </BoxBody>
            <BoxBody v-else v-for="(d, i_d) in devicesSteuergeraet" :key="`steuer_${i_d}`">
                <div>
                    <strong>{{ d.deviceType }}</strong> <span>{{ d.factoryNo || "-" }}</span>
                </div>
                <div class="mt-2">
                    <b-button @click="onClickSteuergeraet(d)" variant="primary">
                        Gerät wechseln/demontieren
                    </b-button>
                </div>
                <HorizontalSeparator v-if="i_d + 1 !== devicesSteuergeraet.length" />
            </BoxBody>
        </Box>
        <Box>
            <b-button @click="onClickSmanAdd()">
                <font-awesome-icon class="mr-1" :icon="['fas', 'plus']" size="lg" variant="primary" /> Weiteren
                Smartmanager montieren
            </b-button>
        </Box>
    </Main>
</template>

<script>
import ExecHeader from "@/components/execution/ExecHeader";
import constants from "@/constants/constants";
import _ from "lodash";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import auftragItemsAPI from "@/services/api/auftragItems.api";
import auftragsAPI from "@/services/api/auftrags.api";
import deviceRelaisAPI from "@/services/api/deviceRelais.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import devicesAPI from "@/services/api/devices.api";
import moment from "moment";

export default {
    name: "ExecutionRseSman",
    components: { ExecHeader },
    data() {
        return {
            auftragDetails6: [],
            devicePool: null,
            devicePoolId: null,
            devicesSman: [],
            devicesSmanZombie: [],
            devicesSmanWithRelais: [],
            devicesSteuergeraet: [],
            isLoadingSmanFlexibilities: false,
            order: null,
            orderId: null,
        };
    },
    async mounted() {
        this.devicePoolId = this.$route.params.devicePoolId;
        const orderId = this.$route.params.orderId;
        this.orderId = orderId;
        this.order = (await auftragsAPI.getSingle(orderId)).data;
        this.devicePool = (await devicePoolsAPI.getSingle(this.devicePoolId)).data;
        // Load AuftragDetail [6-1-3. 6-2-3, 6-3-2]
        await this.loadAuftragDetailsStep6();
        // Load SMAN just if they are in step 6-4-1
        this.isLoadingSmanFlexibilities = true;
        await this.loadSMANs();
        // Load RSE
        await this.loadRSEs();
        this.isLoadingSmanFlexibilities = false;
    },
    methods: {
        async createAuftragItemAndAuftragDetails(actionItemID, actionDetailIDs, smartManagerNew) {
            const auftragItem = (
                await auftragItemsAPI.post({
                    auftragID: this.orderId,
                    devicePoolID: this.devicePoolId,
                    actionItemID: actionItemID,
                    userID: null,
                })
            ).data;

            for (const actionDetailID of actionDetailIDs) {
                await auftragDetailsAPI.post({
                    auftragItemID: auftragItem.auftragItemID,
                    actionDetailID: actionDetailID,
                    status: 0,
                    executionTime: null,
                    deviceID: smartManagerNew.deviceID,
                });
            }
            return auftragItem;
        },
        async loadAuftragDetailsStep6() {
            this.auftragDetails6 = [];
            this.auftragDetails6 = await auftragDetailsAPI.get({
                auftragID: this.$route.params.orderId,
                devicePoolID: this.devicePoolId,
                actionGroupSequence: 6,
            });
        },
        async loadSMANs() {
            this.devicesSman = [];
            for (const auftragDetail of this.auftragDetails6) {
                // SMAN to Einbau
                if (["6-1-2", "6-2-2"].includes(auftragDetail.stepCurrent)) {
                    // Show active SMANs only
                    const sman = (await devicesAPI.getSingle(auftragDetail.deviceID, { includeDeviceInfos: true }))
                        .data;

                    if (
                        moment()
                            .startOf("day")
                            .isSameOrAfter(moment(sman.fromDate).startOf("day"))
                    ) {
                        this.devicesSman.push(sman);
                    }
                }
                if (auftragDetail.stepCurrent === "6-3-1") {
                    // Manually created SMANs: always show
                    const smanManuallyCreated = (
                        await devicesAPI.getSingle(auftragDetail.deviceID, { includeDeviceInfos: true })
                    ).data;
                    this.devicesSman.push(smanManuallyCreated);
                }
                // SMAN to Ausbau
                if (auftragDetail.stepCurrent === "6-2-1") {
                    const sman = (await devicesAPI.getSingle(auftragDetail.deviceID, { includeDeviceInfos: true }))
                        .data;
                    // skip sman created by the algorithm and not yet activated. To be improved
                    if (!moment(sman.toDate).isBefore(moment().startOf("day"))) {
                        this.devicesSman.push(sman);
                    }
                }
            }
            const devicesSmanWithRelais = [];
            const relaisPromises = [];
            this.devicesSman.forEach((sman) => {
                const relaisPromise = deviceRelaisAPI
                    .get({ deviceSmartManagerID: sman.deviceID, includeDevices: true })
                    .then((resp) => {
                        sman.cstRelais = resp.data;
                        devicesSmanWithRelais.push(sman);
                    });
                relaisPromises.push(relaisPromise);
            });
            await Promise.all(relaisPromises).finally(() => {
                this.devicesSmanWithRelais = devicesSmanWithRelais;
            });
            // Order SMANs
            this.devicesSmanWithRelais = _.orderBy(this.devicesSmanWithRelais, ["deviceID"], ["desc"]);
        },
        async loadRSEs() {
            this.devicesSteuergeraet = await devicesAPI.get({
                devicePoolID: this.devicePoolId,
                type: "Steuereinheit",
                deviceSuffix: "Rundsteuerempfänger",
                includeDeviceInfos: true,
            });
        },
        isActiveOnMsp(smanDeviceID) {
            const aufDetail = this.auftragDetails6.find(
                (ad) =>
                    ad?.deviceID === smanDeviceID &&
                    ad?.status === 202 &&
                    ["6-1-3", "6-2-3", "6-3-2"].includes(ad?.stepCurrent)
            );
            return aufDetail != null;
        },
        async onClickSman(deviceID) {
            const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
                this.$route.params.orderId,
                "6-2-1",
                this.$route.params.devicePoolId,
                deviceID
            );
            // navigate to step 6-2-1 for deviceID
            await this.$router.push({
                name: "step-6-2-1",
                params: {
                    auftragItemId: auftragDetail.auftragItemID,
                    devicePoolId: this.devicePoolId,
                    orderId: this.orderId,
                },
            });
        },
        async onClickSmanActivate(smanDeviceID) {
            const auftragDetailActivation = this.auftragDetails6.find(
                (ad) => ad.deviceID === smanDeviceID && ["6-1-3", "6-2-3", "6-3-2"].includes(ad?.stepCurrent)
            );
            if (auftragDetailActivation) {
                await this.$router.push({
                    name: `step-${auftragDetailActivation.stepCurrent}`,
                    params: {
                        auftragItemId: auftragDetailActivation.auftragItemID,
                        devicePoolId: this.devicePoolId,
                        orderId: this.orderId,
                    },
                });
            } else {
                this.$bvToast.toast(`Activation step nicht löschen`, {
                    title: "Fehler",
                    variant: "warning",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: true,
                    appendToast: true,
                });
            }
        },
        async onClickSmanAdd(factoryNo) {
            // Create SmartManager
            const device = factoryNo
                ? {
                      devicePoolID: this.devicePoolId,
                      factoryNo,
                      internalNo: factoryNo,
                  }
                : {
                      devicePoolID: this.devicePoolId,
                  };
            const smartManagerNew = (await devicesAPI.createSman(device)).data;

            // TODO: abilitare bottone solo se non esite AuftragItem associato a ActionItem 6-3
            // Create AuftragItem related to ActionItem 6-3, AuftragDetail for 6-3-1 and 6-3-2
            const auftragItem63 = await this.createAuftragItemAndAuftragDetails(
                constants.actionItem.ACTION_ITEM_13,
                [constants.auftragDetail.ACTION_DETAIL_18, constants.auftragDetail.ACTION_DETAIL_19],
                smartManagerNew
            );
            // Create AuftragItem related to ActionItem 6-4, AuftragDetail for 6-4-1
            await this.createAuftragItemAndAuftragDetails(
                constants.actionItem.ACTION_ITEM_14,
                [constants.auftragDetail.ACTION_DETAIL_20],
                smartManagerNew
            );
            // navigate to step 6-3-1 (using created)
            await this.$router.push({
                name: "step-6-3-1",
                params: {
                    orderId: this.$route.params.orderId,
                    devicePoolId: this.$route.params.devicePoolId,
                    auftragItemId: auftragItem63.auftragItemID,
                    factoryNo,
                },
            });
        },
        async onClickSmanDelete(deviceID) {
            const toDelete = await this.$bvModal.msgBoxConfirm("Möchten Sie den SMAN löschen?", {
                title: "SMAN löschen",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Ja",
                cancelTitle: "Nein",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
            });
            if (toDelete && deviceID) {
                // Delete AuftragDetails
                const auftragDetails6 = await auftragDetailsAPI.get({
                    auftragID: this.orderId,
                    devicePoolID: this.devicePoolId,
                    deviceID: deviceID,
                    actionGroupSequence: 6,
                });
                const auftragItemSet = new Set(); // use set to discard duplicates
                for (const auftragDetail of auftragDetails6) {
                    auftragItemSet.add(auftragDetail.auftragItemID);
                    await auftragDetailsAPI.delete(auftragDetail.auftragDetailID);
                }
                // Delete AuftragItems
                for (const auftragItemID of auftragItemSet) {
                    await auftragItemsAPI.delete(auftragItemID);
                }
                // Delete SMAN and Relais
                await devicesAPI.delete(deviceID);
                // Problem with Relais update --> Reload the page after delete a SMAN
                this.$router.go(0);
                // Load AuftragDetails BEFORE loading SMANs
                //await this.loadAuftragDetailsStep6();
                // Reload SMANs
                //await this.loadSMANs();
            }
        },
        onClickSmanFlexiRemove(deviceID) {
            alert(`ToDo: remove Flexibilität with deviceID=${deviceID}`);
        },
        onClickRelaisEdit(deviceRelaisID) {
            // navigate to step 6-4-1 for deviceID
            this.$router.push({
                name: "step-6-4-1",
                params: {
                    auftragItemId: deviceRelaisID, // workaround alert
                    devicePoolId: this.devicePoolId,
                    orderId: this.orderId,
                },
            });
        },
        onClickSteuergeraet(device) {
            const deviceID = device.deviceID;
            let errorText, routeName, auftragDetail;
            switch (device.deviceSuffix) {
                case "Rundsteuerempfänger":
                    routeName = "step-6-1-1";
                    auftragDetail = this.auftragDetails6.find(
                        (ad) => ad.deviceID === deviceID && ad.stepCurrent === "6-1-1"
                    );
                    // navigate to the step for deviceID
                    this.$router.push({
                        name: routeName,
                        params: {
                            auftragItemId: auftragDetail.auftragItemID,
                            devicePoolId: this.devicePoolId,
                            orderId: this.orderId,
                        },
                    });
                    break;
                case "Smart Manager":
                    routeName = "step-6-2-1";
                    auftragDetail = this.auftragDetails6.find(
                        (ad) => ad.deviceID === deviceID && ad.stepCurrent === "6-2-1"
                    );
                    // navigate to the step for deviceID
                    this.$router.push({
                        name: routeName,
                        params: {
                            auftragItemId: auftragDetail.auftragItemID,
                            devicePoolId: this.devicePoolId,
                            orderId: this.orderId,
                        },
                    });
                    break;
                default:
                    errorText = `ExecutionRseSman: unhandled scenario device.deviceSuffix='${device.deviceSuffix}'`;
                    alert(errorText);
                    throw new Error(errorText);
            }
        },
    },
};
</script>
