<template>
    <div class="pb-2">
        <b-spinner v-if="assetItemsIsLoading" />
        <template v-else>
            <b-tabs v-if="dateSortedImageList.length > 0" pills card vertical active-nav-item-class="bg-danger">
                <b-tab v-for="object in dateSortedImageList" :key="`image_${object.date}`" :title="object.date">
                    <ImageGallery
                        :imageList="object.items"
                        :assetDelete="assetDelete"
                        :downloadable="downloadable"
                    ></ImageGallery>
                </b-tab>
            </b-tabs>
            <b-alert v-else-if="dateSortedImageList.length === 0 && entity === 'devicepool'" show>
                Keine Fotos für den gewählten Gerätestandort verfügbar.
            </b-alert>
        </template>
    </div>
</template>

<script>
import constants from "@/constants/constants";
import { mapGetters } from "vuex";
import ImageGallery from "../general/ImageGallery.vue";
import assetsApi from "@/services/api/assets.api";

export default {
    name: "DateSortedImageGallery",
    components: {
        ImageGallery,
    },
    props: {
        app: {
            type: String,
            required: true,
        },
        assetDelete: {
            type: Boolean,
            default: false,
        },
        downloadable: {
            type: Boolean,
            default: false,
        },
        entity: {
            type: String,
            required: true,
        },
        entityId: {
            type: Number,
            required: true,
        },
        refEntity: {
            type: String,
            default: "",
        },
        refEntityId: {
            type: Number,
            default: 0,
        },
        refEntityKey: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            assetItemsIsLoading: false,
            assetItems: [],
            documents: [],
            images: [],
            miPortalApiConfig: {
                baseURL: constants.env.API_PORTAL_URL,
            },
        };
    },
    computed: {
        ...mapGetters({
            oidcAccessToken: "oidcAccessToken",
        }),

        dateSortedImageList() {
            const sortedArray = this.images.slice().sort((a, b) => new Date(b.insertDate) - new Date(a.insertDate));
            const returnValue = Object.values(
                sortedArray.reduce((acc, obj) => {
                    const date = new Date(obj.insertDate).toLocaleDateString("de-DE");
                    if (!acc[date]) {
                        acc[date] = { date, items: [] };
                    }
                    acc[date].items.push(obj);
                    return acc;
                }, {})
            );
            return returnValue;
        },
    },
    methods: {
        isAssetTypeCorrect(type) {
            return type === constants.image.assetType.IMAGE;
        },
        async getAssets() {
            this.images = [];
            this.assetItemsIsLoading = true;

            const assetsPromise = assetsApi.get({
                app: this.app,
                isDeleted: false,
                entity: this.entity,
                entityId: this.entityId,
                ...(this.refEntity &&
                    this.refEntityId &&
                    this.refEntityId > 0 && {
                        refEntity: this.refEntity,
                        refEntityId: this.refEntityId,
                        refEntityKey: this.refEntityKey ? this.refEntityKey : "",
                    }),
            });

            const assetsResponse = await Promise.resolve(assetsPromise);
            let assets = [...assetsResponse.data];

            for (const asset of assets) {
                if (this.isAssetTypeCorrect(asset.type)) {
                    this.images.push(asset);
                } else {
                    this.documents.push(asset);
                }
            }

            this.assetItemsIsLoading = false;
            this.$emit("numberOfImages", this.images.length);
        },
    },
    async mounted() {
        await this.getAssets();
    },
};
</script>

<style scoped></style>
