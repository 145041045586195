import { httpClient } from "@/services/api/client";

const endpoint = "/code/postal-data";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(`${endpoint}`, {
            params: params,
        });
    },

    getByStateCode(stateCode) {
        return httpClient.get(`${endpoint}/stateCode/${stateCode}`);
    },
};
