<template>
    <div v-if="imageList">
        <Box :headerText="uploadImageCountHeader" noBody class="mr-md-3">
            <BoxBody>
                <b-button variant="secondary" @click="onClickForceUpload" v-if="!uploadInProgress">
                    <span>Fotos hochladen</span>
                </b-button>
                <b-button variant="secondary" @click="onClickCancelUpload" v-else>
                    <span>Hochladen abbrechen</span>
                </b-button>
                <div
                    class="d-flex justify-content-between align-items-center small text-black-50"
                    v-for="image in imageList"
                    :key="image.Id"
                >
                    <div>
                        <hr />
                        <div class="image-info">
                            <img :src="image.path" class="image" />
                            <div>
                                <div>
                                    {{ truncateText(image.description) }}
                                </div>
                                <div>
                                    <font-awesome-icon class="mr-2" :icon="['fas', 'camera']" />
                                    {{ showDate(image.insertDate) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BoxBody>
        </Box>
    </div>
</template>

<script>
import moment from "moment";
import cacheService from "@/services/cacheService";
import constants from "@/constants/constants";

const { IN_PROGRESS, STOPPED } = constants.upload.status;

export default {
    name: "ImageUploadView",
    props: {
        imageList: {
            type: Array,
            default: null,
        },
        isUploadCurrentlyInProgress: {
            type: Boolean,
            default: STOPPED,
        },
    },
    computed: {
        uploadImageCountHeader() {
            return this.imageList.length === 1
                ? "Ausstehender Upload"
                : `Ausstehende Uploads (${this.imageList.length})`;
        },
    },
    mounted() {
        this.uploadInProgress = this.isUploadCurrentlyInProgress;
    },
    data() {
        return {
            uploadInProgress: null,
        };
    },
    methods: {
        onClickCancelUpload() {
            this.uploadInProgress = STOPPED;
            cacheService.cancelUpload();
        },
        truncateText(text) {
            const { MAX_LENGTH_UPLOAD_VIEW } = constants.upload;
            return text.length > MAX_LENGTH_UPLOAD_VIEW ? text.substring(0, MAX_LENGTH_UPLOAD_VIEW) + "..." : text;
        },
        onClickForceUpload() {
            this.uploadInProgress = IN_PROGRESS;
            this.$emit("forceUpload", true);
        },
        showDate(val) {
            return moment(val).format("DD.MM.YYYY HH:mm:ss");
        },
    },
};
</script>

<style scoped>
.image-info {
    display: flex;
    align-items: center;
}

.image {
    height: 15%;
    width: 15%;
    margin-right: 10px;
}
</style>
