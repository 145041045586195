<template>
    <div>
        <div class="mt-3">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "HelpRoot",
    methods: {},
};
</script>

<style scoped></style>
