import _ from "lodash";
/**
 * Filter an array of NavbarItems
 * @param navbarItems
 * @param pagePermissions
 * @returns filterd navBar permission, or [] in case of no permission
 */
export function filterNavbarItemsByPermissions(navbarItems, pagePermissions) {
    if (!pagePermissions || pagePermissions.length === 0) {
        return [];
    }
    if (hasStarPermission(pagePermissions)) {
        return navbarItems;
    }
    const filteredNavbarItems = [];
    for (const item of navbarItems) {
        let anItem = _.find(pagePermissions, { permissionValue: item.id });
        if (anItem) {
            filteredNavbarItems.push(item);
        }
    }
    return filteredNavbarItems;
}

/**
 * Return true if the user has the permission
 * @param route
 * @param pagePermissions
 * @returns {boolean}
 */
export function hasPagePermissionOuRoute(route, pagePermissions) {
    if (route.meta && route.meta.isPublic) {
        return true;
    }
    if (hasStarPermission(pagePermissions)) {
        return true;
    }
    const anItem = _.find(pagePermissions, function(pp) {
        if (route.name.startsWith(pp.permissionValue)) {
            return true;
        }
    });
    if (anItem) {
        return true;
    }
    return false;
}

/**
 * Return true if the user has start "*" permission
 * @param pagePermissions
 * @returns {boolean}
 */
export function hasStarPermission(pagePermissions) {
    const anItem = _.find(pagePermissions, { permissionValue: "*" });
    if (anItem) {
        return true;
    }
    return false;
}

/**
 * Return true if user has at least 1 of the required roles
 * @param route: the requested router: EG: the "to" route in router.js
 * @param userRoles: The roles of the user. EG: store.getters.oidUser.role
 * @param requiredRoles: required roles to view this app. EG: ["miAdmin", "marketDataUser"]
 * @returns {boolean}
 */
export function isUserAuthorizedByRole(route, userRoles, requiredRoles) {
    if (route.meta && route.meta.isPublic === true) {
        return true;
    }
    if (!Array.isArray(userRoles)) {
        console.error("User roles must be an array", userRoles);
    }
    if (!Array.isArray(requiredRoles)) {
        console.error("Required roles must be an array", requiredRoles);
    }
    for (const role of requiredRoles) {
        if (userRoles.includes(role)) {
            return true;
        }
    }
    console.log("Required role not found", requiredRoles, userRoles);
    return false;
}
