<template>
    <div v-if="mainDevice && order">
        <exec-meter-info
            v-show="this.localMainDevice"
            class="mt-3"
            :mainDevice="this.localMainDevice"
            :order="order"
            @showRelatedDeviceInfo="setShowRelatedDeviceInfo"
            :category="category"
        ></exec-meter-info>
        <div class="d-flex align-items-space-between flex-column">
            <!-- in Category Ablesen or Wechseln -->
            <div v-if="isAblesung || isWechseln" class="button-container">
                <!-- Fernablesung -->
                <template v-if="mainDevice.telemeteringType > 0">
                    <b-row class="mt-2 no-gutters ml-2" v-if="auftragDetail712">
                        <b-col class="col text-left" cols="1">
                            <font-awesome-icon :icon="['fas', 'circle']" :class="compFernablesungStatusVariant" />
                        </b-col>
                        <b-col cols="11" style="font-size:0.875em">
                            Fernablesung:
                            <span v-if="localAuftragDetail712.status === 203">
                                {{ moment(zfaObject.readingDate).format("HH:mm, DD.MM.YYYY") }}</span
                            >
                            <span v-else>
                                {{ fernablesungStatus }}
                            </span>
                            <div class="d-flex flex-row justify-content-between">
                                <b-button
                                    :variant="isAblesung ? 'primary' : 'secondary'"
                                    @click="onClickFernablesung()"
                                    class="button-ablesung"
                                >
                                    (Erneut) starten
                                </b-button>
                                <zfa-button-job-status
                                    ref="zfaButtonJobStatusComponent"
                                    class="button-ablesung"
                                    title="Status prüfen"
                                    :variant="isAblesung ? 'primary' : 'secondary'"
                                    v-if="order && mainDevice.devicePool"
                                    v-bind:auftrag-id="order.auftragID"
                                    v-bind:device-pool-id="mainDevice.devicePool.devicePoolID"
                                    v-bind:device-id="mainDevice.deviceID"
                                    v-bind:auto-start="false"
                                    v-on:checkZfaJobStatusDone="onCheckZfaJobStatusDone"
                                ></zfa-button-job-status>
                            </div>
                        </b-col>
                    </b-row>
                </template>
                <!-- Manual/Optical reading -->
                <b-row class="mt-2 no-gutters ml-2">
                    <b-col class="col text-left" cols="1">
                        <font-awesome-icon :icon="['fas', 'circle']" :class="compManuelleAblesungStatusVariant" />
                    </b-col>
                    <b-col cols="11" v-if="localAuftragDetail714" style="font-size:0.875em">
                        Ablesung vor Ort:
                        <span v-if="[202, 203].includes(localAuftragDetail714.status)">
                            {{ moment(localAuftragDetail714.executionTime).format("HH:mm, DD.MM.YYYY") }}</span
                        ><template v-if="[202, 203].includes(localAuftragDetail714.status)">
                            ({{ localAuftragDetail714.status === 202 ? "opt." : "man." }})</template
                        >
                        <span v-else>
                            nein
                        </span>
                        <div>
                            <b-button
                                v-if="localAuftragDetail714.status != 202"
                                :variant="[202, 203].includes(localAuftragDetail714.status) ? 'secondary' : 'primary'"
                                @click="onClickMeterRead()"
                                class="button-ablesung"
                            >
                                Manuell erfassen
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-button variant="primary" @click="onClickZaehlerReplace()" v-if="isWechseln" class="m-2">
                    Zählerwechseln
                </b-button>
            </div>
            <!-- in Category Verbinden mit Smartmanager -->
            <div v-if="isVerbSmartmanager" class="button-container">
                <b-button variant="primary" @click="onClickZaehlerConfig724(mainDevice.deviceID)" class="m-2">
                    Zähler mit Smartmanager verbinden
                </b-button>
                <b-button @click="onClickZaehlerReplace()" class="m-2" v-if="showRelatedDeviceInfo">
                    Zählerwechsel rückgängig machen
                </b-button>
            </div>
            <!-- in Category Wandler -->
            <div v-if="isWandlerCategory" class="button-container">
                <b-button variant="primary" @click="onClickStartWandlerProcess(mainDevice.deviceID)" class="m-2">
                    Wandlerprozess starten
                </b-button>
            </div>
            <!-- in Category Funktionstest -->
            <div v-if="isFunktionstest" class="button-container">
                <b-button @click="onClickZaehlerConfig724()" class="m-2">
                    Verbindung zum Smartmanager ändern
                </b-button>
                <b-button variant="primary" @click="onClickZaehlerConfig751()" class="m-2">
                    Funktionstest durchführen
                </b-button>
            </div>
            <!-- in Category Abgeschlossen-->
            <div v-if="isAbgeschlossen" class="button-container">
                <b-button @click="onClickZaehlerConfig751()" class="m-2">
                    Funktionstest aufrufen
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import ExecMeterInfo from "@/components/execution/ExecMeterInfo.vue";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";
import zfaService from "@/services/zfaService";
import * as zfaUtils from "@/utils/ZfaUtils";
import ZfaButtonJobStatus from "@/components/execution/ZfaButtonJobStatus";

export default {
    name: "ExecMeterCard",
    components: {
        ExecMeterInfo,
        ZfaButtonJobStatus,
    },
    data() {
        return {
            showRelatedDeviceInfo: false,
            localAuftragDetail712: this.auftragDetail712,
            localAuftragDetail714: this.auftragDetail714,
            localMainDevice: null,
        };
    },
    props: {
        mainDevice: {
            type: Object,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
        category: {
            type: String,
            required: true,
        },
        // Manual/Optical reading
        auftragDetail712: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        // Fernablesung
        auftragDetail714: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    async mounted() {
        this.localMainDevice = await this.getMainDevice();
    },
    computed: {
        compFernablesungStatusVariant() {
            return this.getColorByZaehlerstandablesungStatus(this.fernablesungStatus);
        },
        compManuelleAblesungStatusVariant() {
            return this.getColorByZaehlerstandablesungStatus(this.manuelleAblesungStatus);
        },
        fernablesungStatus() {
            const status = this.localAuftragDetail712?.status;

            switch (status) {
                case 203:
                    return "ja";
                case 202:
                    return "gestarted";
                default:
                    return "nein";
            }
        },
        manuelleAblesungStatus() {
            const status = this.localAuftragDetail714?.status;

            switch (status) {
                case 202:
                    return "ja";
                case 203:
                    return "ja";
                default:
                    return "nein";
            }
        },
        isAblesung() {
            return this.category == "Ablesung";
        },
        isWechseln() {
            return this.category == "Wechseln";
        },
        isFunktionstest() {
            return this.category == "Funktionstest";
        },
        isAbgeschlossen() {
            return this.category == "Abgeschlossen";
        },
        isVerbSmartmanager() {
            return this.category == "Verbinden mit Smartmanager";
        },
        isWandlerCategory() {
            return this.category == "Wandler";
        },
        zfaObject() {
            return zfaUtils.parseAuftragDetailValue(this.auftragDetail712?.value);
        },
        moment() {
            return moment;
        },
    },
    methods: {
        setShowRelatedDeviceInfo(showRelatedDeviceInfo) {
            this.showRelatedDeviceInfo = showRelatedDeviceInfo;
        },
        async getMainDevice() {
            if (this.isWandlerCategory) {
                return this.fetchRelatedDeviceDetails(this.mainDevice);
            }
            return this.mainDevice;
        },
        async fetchRelatedDeviceDetails() {
            const deviceResp = await devicesAPI.getRelatedDevice({
                id: this.mainDevice.deviceID,
                devicePoolId: this.mainDevice.devicePoolID,
                auftragId: this.order.auftragID,
                includeDeviceInfos: true,
            });
            //The deviceID associated with the auftragDetails of this step
            //is the same as that of the old meter. We therefore retrieve the
            //information from the new installed meter.
            return deviceResp?.data?.newDevice;
        },
        getColorByZaehlerstandablesungStatus(status) {
            if (status === "ja") {
                return "text-success";
            }
            if (status == "nein") {
                return "text-danger";
            }
            if (status == "gestarted") {
                return "text-warning";
            }
            return "text-danger";
        },
        async onCheckZfaJobStatusDone() {
            try {
                this.localAuftragDetail712 = await auftragDetailsAPI.getByOrderIdAndStep(
                    this.$route.params.orderId,
                    "7-1-2",
                    this.$route.params.devicePoolId,
                    this.mainDevice.deviceID
                );
            } finally {
                this.isLoadingAuftragDetails712 = false;
            }
        },
        async onClickFernablesung() {
            this.zfaResponse = null;
            this.isLoadingRemote = true;
            try {
                let result = await zfaService.executeZfaJobReadSaveToStep712(
                    this.$route.params.orderId,
                    this.$route.params.devicePoolId,
                    this.mainDevice
                );
                if (result.code === 0) {
                    this.$bvToast.toast(`Fernablesung erfolgreich.`, {
                        title: "Success",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                    });
                    this.remoteStatus = 202;
                } else {
                    let errorHumanReadable = "";
                    // result.code: 0 if success, less than 0 for technical errors, greater than 0 for business error
                    if (result.code < 0) {
                        errorHumanReadable = "Während der Fernablesung ist ein technischer Fehler aufgetreten";
                    } else if (result.code > 0) {
                        errorHumanReadable = "Während der Fernablesung ist ein Business Fehler aufgetreten";
                    }
                    this.$bvToast.toast(`${errorHumanReadable}`, {
                        title: `Fehler`,
                        variant: "warning",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                    });
                    console.log("Error while calling ZFA for meter.factoryNo: " + this.mainDevice.factoryNo, result);
                    // pie (11.11.2021): always set to completed 202
                }
                this.zfaResponse = result;
            } finally {
                this.isLoadingRemote = false;
            }
            console.log("ZFA", this.zfaResponse);
            // Simulate click on button "Check ZFA"
            await this.$refs.zfaButtonJobStatusComponent.checkJobStatus();
        },
        async onClickZaehlerReplace() {
            // navigate to step 7-2-1 or 7-2-0 (ex: 7-4-1)
            if (this.mainDevice.smartType === 0) {
                // old meter
                const auftragDetail721 = await auftragDetailsAPI.getByOrderIdAndStep(
                    this.order.auftragID,
                    "7-2-1",
                    this.mainDevice.devicePoolID,
                    this.mainDevice.deviceID
                );
                await this.$router.push({
                    name: "step-7-2-1",
                    params: {
                        deviceId: this.mainDevice.devicePoolID,
                        devicePoolId: this.mainDevice.devicePoolID,
                        auftragItemId: auftragDetail721.auftragItemID,
                    },
                });
            } else {
                // SMET
                const auftragDetail720 = await auftragDetailsAPI.getByOrderIdAndStep(
                    this.order.auftragID,
                    "7-2-0",
                    this.mainDevice.devicePoolID,
                    this.mainDevice.deviceID
                );
                this.$router.push({
                    name: "step-7-2-0",
                    params: {
                        deviceId: this.mainDevice.deviceID,
                        devicePoolId: this.mainDevice.devicePoolID,
                        auftragItemId: auftragDetail720.auftragItemID,
                    },
                });
            }
        },
        async onClickMeterRead() {
            // navigate to step 7-1-3
            const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
                this.order.auftragID,
                "7-1-3",
                this.mainDevice.devicePoolID,
                this.mainDevice.deviceID
            );
            await this.$router.push({
                name: "step-7-1-3",
                params: {
                    deviceId: this.mainDevice.deviceID,
                    devicePoolId: this.mainDevice.devicePoolID,
                    auftragItemId: auftragDetail.auftragItemID,
                },
            });
        },
        async onClickStartWandlerProcess(deviceId) {
            // navigate to step 7-3-1
            const auftragDetail731 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.order.auftragID,
                "7-3-1",
                this.mainDevice.devicePoolID,
                deviceId
            );
            await this.$router.push({
                name: "step-7-3-1",
                params: {
                    devicePoolId: this.mainDevice.devicePoolID,
                    auftragItemId: auftragDetail731.auftragItemID,
                },
            });
        },
        async onClickZaehlerConfig724() {
            // navigate to step 7-2-4
            const auftragDetail724 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.order.auftragID,
                "7-2-4",
                this.mainDevice.devicePoolID,
                this.mainDevice.deviceID
            );
            await this.$router.push({
                name: "step-7-2-4",
                params: {
                    deviceId: this.mainDevice.deviceID,
                    devicePoolId: this.mainDevice.devicePoolID,
                    auftragItemId: auftragDetail724.auftragItemID,
                },
            });
        },
        async onClickZaehlerConfig751() {
            // navigate to step 7-5-1
            const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
                this.order.auftragID,
                "7-5-1",
                this.mainDevice.devicePoolID,
                this.mainDevice.deviceID
            );
            await this.$router.push({
                name: "step-7-5-1",
                params: {
                    deviceId: this.mainDevice.deviceID,
                    devicePoolId: this.mainDevice.devicePoolID,
                    auftragItemId: auftragDetail.auftragItemID,
                },
            });
        },
    },
};
</script>
<style scoped>
.button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.button-ablesung {
    min-width: 140px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 6px;
}
</style>
