<template>
    <Box headerText="Gerätestandort" :isLoading="!devicePool || !order">
        <div v-if="order" class="d-flex">
            <div>
                <router-link :to="{ name: 'execution-single', params: { orderId: order.auftragID } }">
                    {{ order.hak.tp }}
                </router-link>
            </div>
            <div class="ml-auto font-weight-bold">
                {{ order.hak.address }}
            </div>
        </div>
        <div v-if="order && devicePool" class="d-flex">
            <div>
                <router-link
                    :to="{
                        name: 'execution-geraetestandort',
                        params: {
                            orderId: order.auftragID,
                            devicePoolId: devicePool.devicePoolID,
                        },
                    }"
                >
                    {{ devicePool.position }}
                    <span v-if="devicePool.positionDetail"> - {{ devicePool.positionDetail }}</span>
                </router-link>
            </div>
            <div v-if="compOrderAddress !== compDevicePoolAddress" class="ml-auto font-weight-bold">
                {{ devicePool.address }}
            </div>
        </div>
    </Box>
</template>

<script>
export default {
    name: "ExecHeader",
    props: {
        devicePool: {
            type: Object,
            default: null,
        },
        order: {
            type: Object,
            default: null,
        },
    },
    computed: {
        compOrderAddress() {
            // hack to cleanup third party data #20133
            return this.order ? this.order.hak.address.replace(/[\s.]+/g, "") : "";
        },
        compDevicePoolAddress() {
            // hack to cleanup third party data #20133
            return this.devicePool ? this.devicePool.address.replace(/[\s.]+/g, "") : "";
        },
    },
};
</script>
