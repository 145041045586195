import { httpClient } from "@/services/api/client";

const endpoint = "/net/process";

export default {
    // ************************************
    // Create Auftrag (technischer Auftrag)
    // ************************************
    createAuftrag(payload) {
        return httpClient.post(`${endpoint}/auftrag-create`, payload);
    },

    // *************************************
    // Create Auftrag Plan (Planungsauftrag)
    // *************************************
    createAuftragPlan(payload) {
        return httpClient.post(`${endpoint}/auftrag-plan-create`, payload);
    },

    // *************************************
    // Start Auftrag Optimisation
    // *************************************
    startAuftragOptimisation(payload) {
        return httpClient.post(`${endpoint}/auftrag-optimisation/start`, payload);
    },

    /**
     * POST process/auftrag-complete/{orderId}
     *
     * docs: https://retasks.repower.com/swagger/index.html
     *
     * @param orderId
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    postAuftragRolloutComplete(orderId) {
        return httpClient.post(`${endpoint}/auftrag-complete/${orderId}`);
    },
};
