<template>
    <div>
        <h3>Gruppe bearbeiten</h3>
        <div v-if="group">
            <b-row>
                <!-- GroupID -->
                <b-col cols="12" sm="6" md="3">
                    <b-form-group id="groupid-fg" label="GroupID" label-for="groupid-input">
                        <b-form-input id="groupid-input" v-model="group.groupID" trim disabled></b-form-input>
                    </b-form-group>
                </b-col>
                <!-- Name -->
                <b-col cols="12" sm="6" md="3">
                    <b-form-group id="name-fg" label="Name" label-for="name-input">
                        <b-form-input id="name-input" v-model="group.name" trim></b-form-input>
                    </b-form-group>
                </b-col>
                <!-- Company -->
                <b-col cols="12" sm="6" md="3">
                    <b-form-group id="company-fg" label="Firma" label-for="company-input">
                        <b-form-select
                            id="company-input"
                            v-model="group.companyID"
                            :options="companies"
                            value-field="companyID"
                            text-field="name"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <label>Mitglieder</label>
                    <DxTagBox
                        :search-enabled="true"
                        :items="users"
                        v-model="selectedUsers"
                        valueExpr="userID"
                        displayExpr="fullName"
                    />
                </b-col>
            </b-row>

            <!-- Buttons -->
            <b-row class="mt-3">
                <b-col>
                    <b-button class="ml-2 float-right" @click="onClickSave" :disabled="!isModified" variant="primary"
                        >Speichern</b-button
                    >
                    <b-button class="float-right" @click="$router.push({ name: 'groups' })">Abbrechen</b-button>
                </b-col>
            </b-row>
        </div>
        <re-spinner v-else></re-spinner>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import groupsAPI from "@/services/api/groups.api";
import userGroupsAPI from "@/services/api/userGroupsRepository";
import _ from "lodash";
import ReSpinner from "@/components/energyspace/ReSpinner";
import { DxTagBox } from "devextreme-vue/tag-box";

export default {
    name: "GroupEdit",
    components: {
        ReSpinner,
        DxTagBox,
    },
    data() {
        return {
            group: null,
            originalGroup: null,
            selectedUsers: [],
            originalSelectedUsers: [],
        };
    },
    async mounted() {
        this.group = (await groupsAPI.getSingle(this.groupID)).data;
        this.originalGroup = _.clone(this.group);
        if (this.companies.length === 0) {
            await this.fetchCompanies();
        }
        if (this.users.length === 0) {
            await this.fetchUsers();
        }
        // Populate selectedUser
        for (const user of this.group.users) {
            this.selectedUsers.push(user.userID);
            this.originalSelectedUsers.push(user.userID);
        }
    },
    computed: {
        ...mapGetters(["companies", "users"]),
        filteredUsers() {
            if (this.group.companyID) {
                return this.users.filter((user) => user.companyID === this.group.companyID);
            }
            return this.users;
        },
        groupID() {
            return this.$route.params.groupID;
        },
        isModified() {
            return !(
                _.isEqual(this.group, this.originalGroup) && _.isEqual(this.selectedUsers, this.originalSelectedUsers)
            );
        },
    },
    methods: {
        ...mapActions(["fetchCompanies", "fetchUsers"]),
        async onClickSave() {
            // Save group (if modified)
            if (!_.isEqual(this.group, this.originalGroup)) {
                const patchDoc = [
                    {
                        op: "replace",
                        path: "/name",
                        value: this.group.name,
                    },
                    {
                        op: "replace",
                        path: "/companyID",
                        value: this.group.companyID,
                    },
                ];
                await groupsAPI.patch(this.groupID, patchDoc);
            }
            // Save selected users (if modified)
            if (!_.isEqual(this.selectedUsers, this.originalSelectedUsers)) {
                await userGroupsAPI.deleteMultiple(this.groupID);
                const payload = [];
                for (const userID of this.selectedUsers) {
                    payload.push({ userID: userID, groupID: this.groupID });
                }
                await userGroupsAPI.postMultiple(payload);
            }
            this.$bvToast.toast(`Gruppe erfolgreich gespeichert.`, {
                title: "Success",
                variant: "success",
                toaster: "b-toaster-bottom-right",
                noAutoHide: false,
                appendToast: true,
            });
            await this.$router.push({ name: "groups" });
        },
    },
};
</script>

<style scoped></style>
