import moment from "moment";

/**
 *
 * @param aDate
 * @returns {boolean}
 */
export function isToday(aDate) {
    if (!aDate) {
        return false;
    }
    return moment(0, "HH").diff(moment(aDate), "days") === 0;
}

/**
 * Retunrs true if firstDate is after secondDate
 * @param firstDate
 * @param secondDate
 */
export function isAfter(firstDate, secondDate) {
    if (!firstDate || !secondDate) {
        return false;
    }
    return moment(firstDate).isAfter(moment(secondDate));
}
