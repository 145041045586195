import { eventBus } from "@/utils/eventBus";
import constants from "@/constants/constants";
import cacheService from "@/services/cacheService";
import _ from "lodash";
const { DEFAULT, DONE } = constants.upload.icon;
export default {
    async updateUploadStatus(status) {
        if ([DEFAULT, DONE].includes(status)) {
            status = await this.setUploadStatusAfterUpload(status);
        }
        eventBus.$emit("uploadStatus", status);
    },
    async setUploadStatusAfterUpload(status) {
        if (_.isEqual(status, DONE)) {
            const photosCountInCache = await cacheService.getAllPhotosCount();
            return photosCountInCache ? DEFAULT : DONE;
        }
        return status;
    },
};
