import { httpClient } from "@/services/api/client";

const endpoint = "/net/auftrag-details-view";

export default {
    // Returns true in case meter is still installed
    validateFactoryNoIsStillInstalled(auftragID, arrStepCurrent, factoryNo) {
        return httpClient.get(endpoint + "/validate-still-installed", {
            params: {
                auftragID: auftragID,
                stepCurrent: arrStepCurrent,
                factoryNo: factoryNo,
            },
        });
    },
    // Returns true in case meter is already installed
    validateFactoryNoAlreadyExists(auftragID, arrStepCurrent, factoryNo, excludeAuftragDetailID) {
        return httpClient.get(endpoint + "/validate-already-exists", {
            params: {
                auftragID: auftragID,
                stepCurrent: arrStepCurrent,
                factoryNo: factoryNo,
                excludeAuftragDetailID: excludeAuftragDetailID,
            },
        });
    },
};
