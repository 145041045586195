<template>
    <div>
        <svg height="20" width="20" xmlns:xlink="http://www.w3.org/1999/xlink">
            <use :xlink:href="wifiStatusIcon"></use>
        </svg>
    </div>
</template>

<script>
import constants from "@/constants/constants";
import pingAPI from "@/services/api/ping.api";
export default {
    name: "ConnectivityNetwork",
    data() {
        return {
            wifiStatusIcon: "",
            recentResponseTimes: [],
            currentResponseTime: 0,
        };
    },
    mounted() {
        this.updateNetworkStatus();
    },
    computed: {
        delayAfterRequest() {
            const { TIMEOUT_AFTER_FAILED_REQUEST, TIMEOUT_AFTER_SUCCEEDED_REQUEST } = constants.connectivity;
            // If the request failed, the app will retry after 5 seconds
            // If the request succeeded, the app will send another one after 30 seconds
            return this.currentResponseTime ? TIMEOUT_AFTER_SUCCEEDED_REQUEST : TIMEOUT_AFTER_FAILED_REQUEST;
        },
        averageResponseTime() {
            return this.recentResponseTimes.reduce((sum, time) => sum + time, 0) / this.recentResponseTimes.length;
        },
        wifiIconType() {
            const { TOO_HIGH_RESPONSETIME, IDEAL_RESPONSETIME } = constants.connectivity;

            // Determine the quality of the averageResponseTime
            // Up to 150ms is ideal, 150ms - 1000ms is mid, 1000ms and above is considered a bad quality
            return !this.averageResponseTime
                ? "#wifi-none"
                : this.averageResponseTime < IDEAL_RESPONSETIME
                ? "#wifi-good"
                : this.averageResponseTime < TOO_HIGH_RESPONSETIME
                ? "#wifi-mid"
                : "#wifi-low";
        },
    },
    methods: {
        async updateNetworkStatus() {
            const startTime = new Date();
            try {
                await pingAPI.sendPing();
                const endTime = new Date();
                this.currentResponseTime = endTime - startTime;
                this.updateRecentResponseTimesArray();
                this.wifiStatusIcon = require("@/assets/images/wifi/wifi_status.svg") + this.wifiIconType;
            } catch {
                this.currentResponseTime = null;
                this.wifiStatusIcon = require("@/assets/images/wifi/wifi_status.svg") + "#wifi-none";
            } finally {
                this.$emit("networkResponseTime", this.averageResponseTime);
                setTimeout(this.updateNetworkStatus, this.delayAfterRequest);
            }
        },
        updateRecentResponseTimesArray() {
            this.recentResponseTimes.push(this.currentResponseTime);
            if (this.recentResponseTimes.length > constants.connectivity.MAX_AMMOUNT_OF_RESPONSE_TIMES) {
                this.recentResponseTimes.shift();
            }
        },
    },
};
</script>
