import { httpClient } from "@/services/api/client";

const endpoint = "/msp";

export default {
    // **************
    // asset methods
    // **************

    getAssetByFactoryNo(factoryNo) {
        return httpClient.get(`${endpoint}/asset/${factoryNo}`);
    },

    updateByFactoryNo(factoryNo, payload) {
        return httpClient.put(`${endpoint}/asset/${factoryNo}`, payload);
    },

    // **************
    // smartmanager methods
    // **************

    activateSman(payload) {
        return httpClient.post(
            `${endpoint}/smartmanager/activate?hakID=${payload.hakID}&idSmartManager=${payload.idSmartManager}`
        );
    },

    getSmartManagerByFactoryNo(factoryNo) {
        return httpClient.get(`${endpoint}/smartmanager/${factoryNo}`);
    },

    postSmartmeterBySmanID(smanID, config) {
        return httpClient.post(`${endpoint}/smartmanager/${smanID}/smartmeter`, config).then((resp) => {
            return resp.data;
        });
    },

    getMeterRegisterObisValues(smartManagerFactoryNo, deviceFactoryNo, fromDate, toDate) {
        return httpClient.get(
            `${endpoint}/smartmanager/${smartManagerFactoryNo}/meter/${deviceFactoryNo}/meter-registers/${fromDate}/${toDate}`
        );
    },

    // **************
    // relais methods
    // **************

    postTestSwitch(payload) {
        return httpClient.post(
            `${endpoint}/relais/test-switch?idSmartManager=${payload.idSmartManager}&relayPort=${payload.relayPort}&buttonState=${payload.buttonState}&minutes=${payload.minutes}`
        );
    },

    // **************
    // custom methods
    // **************

    //Sets the Wandlerfaktor_I of a smartmeter
    postWandlerFactorCurrent(factoryNo, payload) {
        return httpClient.post(`${endpoint}/smartmeter/${factoryNo}/wandler/factorCurrent`, payload);
    },
};
