<template>
    <div class="mb-4">
        <slot />
    </div>
</template>

<script>
export default {
    name: "MainComponent",
};
</script>
