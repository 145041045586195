import { httpClient } from "@/services/api/client";

const endpoint = "/app/user-groups";

export default {
    // ********************
    // default REST methods
    // ********************
    /**
     * @param params: groupID
     * @returns {Promise<AxiosResponse<any>>}
     */
    get(params) {
        return httpClient.get(endpoint, { params: params });
    },
    // **************
    // custom methods
    // **************
    postMultiple(userGroupsArray) {
        return httpClient.post(`${endpoint}/group`, userGroupsArray);
    },

    deleteMultiple(groupID) {
        return httpClient.delete(`${endpoint}?groupID=${groupID}`);
    },
};
