<template>
    <div v-if="canEdit()">
        <h3>Unternehmen bearbeiten</h3>
        <div v-if="company">
            <hr />
            <h5>Unternehmen</h5>
            <b-row>
                <b-col cols="12" sm="6" md="3">
                    <b-form-group label="Name des Unternehmens">
                        <b-form-input v-model="company.name" trim placeholder="Beispielfirma"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <b-form-group label="Gemeinden">
                        <DxTagBox
                            :data-source="municipalities"
                            :search-enabled="true"
                            searchExpr="community"
                            value-expr="communityCode"
                            display-expr="community"
                            v-model="selectedMunicipalities"
                            :show-clear-button="true"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <b-form-group id="manager-fg" label="Manager">
                        <b-form-select
                            v-model="company.managerID"
                            :options="managerOptions"
                            :disabled="!managerAvailable"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" sm="6" md="3">
                    <b-form-group label="Latitude">
                        <b-form-input v-model="company.latitude" trim></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <b-form-group label="Longitude">
                        <b-form-input v-model="company.longitude" trim></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" sm="6" md="3">
                    <b-form-group label="Standort">
                        <b-button variant="secondary" @click="onClickSetCurrentLocation">Mein Standort</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
            <l-map
                style="height: 300px; width: 100%"
                :zoom="14"
                :center="[this.company.latitude, this.company.longitude]"
                :maxZoom="18"
                :minZoom="5"
                ref="map"
            >
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-circle-marker :lat-lng="[this.company.latitude, this.company.longitude]" :radius="10" color="red" />
            </l-map>
            <!-- Buttons -->
            <b-row>
                <b-col>
                    <b-button
                        class="ml-2 float-right"
                        @click="onClickSaveCompanyData()"
                        :disabled="!isModified"
                        variant="primary"
                        >Speichern</b-button
                    >
                    <b-button class="float-right" @click="navigateToCompanies()" v-if="!newCompany">{{
                        isModified ? "Änderungen verwerfen" : "Abbrechen"
                    }}</b-button>
                    <b-button class="float-right" v-if="newCompany" @click="deleteCompany()">
                        Unternehmen löschen
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <re-spinner v-else></re-spinner>
        <div v-if="company && managerAvailable">
            <hr />
            <h5>Arbeitszeiten</h5>
            <BusinessHour
                v-if="company"
                :business-hours="company.businessHours"
                :business-hours-key="businessHoursKey"
                :companyID="company.companyID"
            ></BusinessHour>
            <hr />
        </div>
        <div v-if="company && managerAvailable" style="padding-bottom: 2%;">
            <h5>Absenzen</h5>
            <AbsenceScheduler
                :time-zone="'Europe/Zurich'"
                :current-date="currentDate"
                height="50vh"
                :start-day-hour="0"
                current-view="month"
                :views="views"
                :cell-duration="60"
                :companyID="company.companyID"
            />
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { DxTagBox } from "devextreme-vue/tag-box";
import { LCircleMarker, LMap, LTileLayer } from "vue2-leaflet";

import ReSpinner from "@/components/energyspace/ReSpinner";
import companiesAPI from "../../services/api/companies.api";
import usersAPI from "../../services/api/users.api";
import postalDataAPI from "../../services/api/postalData.api";
import AbsenceScheduler from "@/components/user/AbsenceScheduler";
import companyPostalDataAPI from "../../services/api/companyPostalData.api";
import userCompanyPermissionService from "../../services/userCompanyPermissionService";
import BusinessHour from "@/components/user/BusinessHour.vue";
import constants from "@/constants/constants";

export default {
    name: "CompanyEdit",
    components: {
        BusinessHour,
        ReSpinner,
        LCircleMarker,
        LMap,
        LTileLayer,
        DxTagBox,
        AbsenceScheduler,
    },
    data() {
        return {
            businessHoursKey: "businessHourCompanyGrid",
            managerAvailable: true,
            businessHourData: [],
            originalManager: null,
            managerOptions: [],
            municipalities: [],
            selectedMunicipalities: [],
            views: ["week", "workWeek", "month"],
            currentDate: new Date(),
            selectedTimeStartAM: null,
            selectedTimeEndAM: null,
            originalCompany: null,
            company: null,
            url:
                "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWktcmVwb3dlciIsImEiOiJja2t6anF6dnUzMXB4MnFxbm16ejVyMXJlIn0.Wf_akGZUp0tghsSMYJRf4g",
            attribution:
                '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> | &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> | <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>',
        };
    },
    computed: {
        ...mapGetters(["companies", "currentUser"]),
        companyID() {
            return parseInt(this.$route.params?.companyID);
        },
        constants() {
            return constants;
        },
        isModified() {
            const communityCodesObject = this.company.municipalities.map((municipality) => municipality.CommunityCode);
            return (
                !_.isEqual(this.company, this.originalCompany) ||
                this.company.managerID !== this.originalManager ||
                !(
                    this.selectedMunicipalities.length === this.company.municipalities.length &&
                    this.selectedMunicipalities.every((code) => communityCodesObject.includes(code))
                )
            );
        },
        newCompany() {
            return this.$route.query.newCompany;
        },
    },
    async mounted() {
        try {
            this.company = (await companiesAPI.getSingle(this.companyID)).data;
            this.municipalities = (await postalDataAPI.getByStateCode("GR")).data;
            this.selectedMunicipalities = this.company.municipalities.map(
                (municipality) => municipality.CommunityInfoDTO.communityCode
            );
            const users = (await usersAPI.get({ companyID: this.companyID })).data;
            if (this.company.latitude === 0 && this.company.longitude === 0) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.company.latitude = position.coords.latitude;
                    this.company.longitude = position.coords.longitude;
                });
            }
            this.managerOptions =
                users.length !== 0
                    ? users.map((manager) => ({ value: manager.userID, text: manager.fullName }))
                    : [{ value: this.company.managerID, text: this.company.creationName }];
            this.managerAvailable = users.length !== 0;
            this.originalCompany = _.cloneDeep(this.company);
            this.originalManager = this.company.managerID;
        } catch (err) {
            await this.navigateToCompanies();
            this.displayToast(
                "danger",
                err?.response?.status === constants.NOT_FOUND_CODE ? constants.NOT_FOUND_MESSAGE : err?.message
            );
        }
    },
    methods: {
        ...mapActions(["fetchCompanies"]),
        arraysEqual(array1, array2) {
            // gae: I would use lodash here
            return JSON.stringify(array1) === JSON.stringify(array2);
        },
        canEdit() {
            if (!this.company) return;
            const { managerID } = this.company;
            const currentUser = this.currentUser;
            if (userCompanyPermissionService.validateEditingPermission(managerID, currentUser)) {
                return true;
            } else {
                this.navigateToCompanies().then(() => {
                    this.displayToast("danger", "Sie haben keine Berechtigung, dieses Unternehmen zu bearbeiten.");
                });
            }
        },
        async deleteCompany() {
            await companiesAPI.delete(this.companyID);
            await this.navigateToCompanies();
            this.displayToast("success", "Unternehmen erfolgreich gelöscht.");
        },
        async navigateToCompanies() {
            await this.$router.push({ name: "companies" });
        },
        async onClickSaveCompanyData() {
            if (!this.arraysEqual(this.company.municipalities, this.selectedMunicipalities)) {
                await companyPostalDataAPI.updateCompanyPostalData(this.companyID, this.selectedMunicipalities);
            }
            const patchDoc = [
                { op: "replace", path: "/name", value: this.company.name },
                { op: "replace", path: "/latitude", value: this.company.latitude },
                { op: "replace", path: "/longitude", value: this.company.longitude },
                { op: "replace", path: "/managerID", value: this.company.managerID },
                { op: "replace", path: "/revisionName", value: this.currentUser.fullName },
                { op: "replace", path: "/revisionDate", value: new Date() },
            ];
            await companiesAPI.patch(this.companyID, patchDoc);
            await this.navigateToCompanies();
            this.displayToast("success", "Unternehmen erfolgreich gespeichert.");
        },
        onClickSetCurrentLocation() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.company.latitude = position.coords.latitude;
                this.company.longitude = position.coords.longitude;
            });
        },
        displayToast(variant = "INFO", message) {
            let noAutoHide = false;
            let title;
            switch (variant.toUpperCase()) {
                case "DANGER":
                    noAutoHide = true;
                    title = "Fehler";
                    break;
                case "INFO":
                    title = "INFO";
                    break;
                case "SUCCESS":
                    title = "SUCCESS";
                    break;
                default:
                    variant = "info";
                    title = "INFO";
            }
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
    },
};
</script>

<style scoped></style>
