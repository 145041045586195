<template>
    <div>
        <Box :headerText="image.description" noBody class="mr-md-3">
            <img
                v-if="imgSrc"
                :src="imgSrc"
                style="width: 100%; max-width: 500px; max-height: 500px;"
                @dblclick="handleDoubleClick"
            />
            <div v-else-if="!isImageAvailable" class="p-5 m-5 text-center text-black-50">
                <font-awesome-icon :icon="['fas', 'images']" size="xl" />
                <div v-if="!image.isUploaded">Bild noch nicht hochgeladen!</div>
                <div v-else>Bild nicht vorhanden!</div>
            </div>
            <div v-else class="d-flex justify-content-center p-5 m-5">
                <b-spinner></b-spinner>
            </div>
            <BoxBody>
                <div class="d-flex justify-content-between align-items-center small text-black-50">
                    <div>
                        <div>
                            <font-awesome-icon class="mr-2" :icon="['fas', 'user']" />
                            {{ image.insertUser }}
                        </div>
                        <div>
                            <font-awesome-icon class="mr-2" :icon="['fas', 'camera']" />
                            {{ showDate(image.insertDate) }}
                        </div>
                        <div v-if="!image.isUploaded">
                            <font-awesome-icon class="mr-2" :icon="['fas', 'cloud-arrow-up']" />{{
                                cacheLocalImageMessage
                            }}
                        </div>
                        <div v-if="image.isUploadSkipped && !image.isUploaded">
                            <font-awesome-icon class="mr-2" :icon="['fas', 'circle-exclamation']" />{{
                                cacheSkippedImageMessage
                            }}
                        </div>
                    </div>
                    <div v-if="isAssetDeletable || !image.isUploaded">
                        <a
                            v-if="!image.isUploaded && isImageAvailable && !isUploadInProgress"
                            :class="{ disabled: isDisabled }"
                            :disabled="isDisabled"
                            @click="uploadClicked(image)"
                            class="btn btn-secondary btn-sm"
                            title="Upload"
                        >
                            <font-awesome-icon :icon="['fas', 'file-upload']" />
                        </a>
                        <a
                            v-if="!image.isUploaded && isImageAvailable && isUploadInProgress"
                            :class="{ disabled: isDisabled }"
                            :disabled="isDisabled"
                            @click="cancelUpload()"
                            class="btn btn-secondary btn-sm"
                            title="Upload"
                        >
                            <font-awesome-icon :icon="['fas', 'times']" />
                        </a>
                        <b-button
                            v-if="isAssetDeletable && isImageAvailable"
                            :class="{ disabled: isDisabled }"
                            :disabled="isDisabled"
                            title="Löschen"
                            class="ml-2"
                            variant="primary"
                            size="sm"
                            v-b-modal="'asset-delete' + image.id"
                        >
                            <font-awesome-icon :icon="['fas', 'trash-alt']" />
                        </b-button>
                        <b-modal
                            :id="'asset-delete' + image.id"
                            title="Anhang löschen"
                            content-class="shadow"
                            cancel-title="Abbrechen"
                            ok-variant="danger"
                            ok-title="LÖSCHEN"
                            @ok="deleteAsset(image)"
                            :hide-footer="isDeletionDetailShown"
                        >
                            <b-alert variant="warning" show v-if="isDeletionDetailShown">
                                <p class="mb-1">{{ assetNotDeletableMsg }}</p>
                            </b-alert>
                            <template v-else>
                                <p class="mb-1">Möchten Sie das Foto wirklich löschen?</p>
                                <small>Anm.: Das Foto wird unwiderruflich gelöscht.</small>
                            </template>
                        </b-modal>
                    </div>
                </div>
            </BoxBody>
        </Box>
    </div>
</template>

<script>
import constants from "@/constants/constants";
import { mapGetters } from "vuex";
import moment from "moment";
import saveAs from "file-saver";
import cacheService from "@/services/cacheService";
import assetsApi from "@/services/api/assets.api";

const { IN_PROGRESS, STOPPED } = constants.upload.status;

export default {
    name: "ImageBox",
    props: {
        isAssetDeletable: {
            type: Boolean,
            default: false,
        },
        image: {
            type: Object,
            default: null,
        },
        isDisabled: {
            type: Boolean,
            defaults: true,
        },
        isImageUploaded: {
            type: Boolean,
            default: false,
        },
        isUploadCurrentlyInProgress: {
            type: Boolean,
            default: STOPPED,
        },
        isDeletionDetailShown: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isUploadInProgress: false,
            assetItemsIsLoading: false,
            imgSrc: undefined,
            downloadSrc: undefined,
            isImageAvailable: true,
            cacheLocalImageMessage: "Lokales Foto",
            cacheSkippedImageMessage: "Upload übersprungen",
            assetNotDeletableMsg: "Um dieses Foto zu löschen, lade zuerst ein Ersatzfoto hoch!",
        };
    },
    mounted() {
        this.isUploadInProgress = this.isUploadCurrentlyInProgress;
    },
    computed: {
        ...mapGetters({
            oidcAccessToken: "oidcAccessToken",
        }),
    },
    methods: {
        showDate(val) {
            return moment(val).format("DD.MM.YYYY HH:mm:ss");
        },
        deleteAsset(asset) {
            if (!asset.isUploaded) {
                cacheService.deletePhoto(asset.id);
            }
            assetsApi.delete(asset.id, asset.isUploaded).then(() => {
                this.$emit("assetIsDeleted", "assetIsDeleted");
            });
        },
        cancelUpload() {
            cacheService.cancelUpload();
            this.$emit("animationStatus", STOPPED);
        },
        uploadClicked(asset) {
            this.isUploadInProgress = IN_PROGRESS;
            this.$emit("animationStatus", IN_PROGRESS);
            cacheService
                .uploadSinglePhotoAndHandleError(asset)
                .then((resp) => {
                    this.$emit("assetIsUploaded", resp);
                })
                .catch((err) => {
                    this.displayToast("danger", err);
                })
                .finally(() => {
                    this.isUploadInProgress = STOPPED;
                    this.$emit("animationStatus", STOPPED);
                });
        },
        downloadClicked(asset) {
            let downloadPromise;

            if (asset.isUploaded) {
                downloadPromise = assetsApi.downloadAsset(asset.id, { responseType: "arraybuffer" });
            } else {
                // Local images have their image data stored as a Base64 string in the 'path' property
                downloadPromise = assetsApi.transformBase64ToArrayBuffer(asset.path);
            }
            downloadPromise
                .then((r) => {
                    this.setSource(r.data, "downloadSrc");
                    var assetFiletype = asset.filename.split(".").pop();
                    saveAs(this.downloadSrc, `${asset.description}.${assetFiletype}`);
                })
                .catch(() => {
                    const errorMessage = "Download fehlgeschlagen.";
                    this.displayToast("danger", errorMessage);
                });
        },
        setSource(data, src) {
            const d = Buffer.from(data).toString("base64");
            this[src] = `data:image/png;base64, ${d}`;
        },
        displayToast(variant = "INFO", message) {
            let noAutoHide = false;
            let title;
            switch (variant.toUpperCase()) {
                case "DANGER":
                    noAutoHide = true;
                    title = "Fehler";
                    break;
                case "INFO":
                    title = "INFO";
                    break;
                case "SUCCESS":
                    title = "SUCCESS";
                    break;
                default:
                    variant = "info";
                    title = "INFO";
            }
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
        handleDoubleClick() {
            this.downloadClicked(this.image);
        },
    },
    beforeMount() {
        // download preview and handle exception
        let previewPromise;

        if (this.image.isUploaded) {
            previewPromise = assetsApi.previewAsset(this.image.id, { responseType: "arraybuffer" });
        } else {
            // Local images have their image data stored as a Base64 string in the 'path' property
            previewPromise = assetsApi.transformBase64ToArrayBuffer(this.image.path);
        }
        previewPromise
            .then((r) => {
                this.setSource(r.data, "imgSrc");
                this.isImageAvailable = true;
            })
            .catch(() => {
                this.isImageAvailable = false;
                if (this.image.isUploaded) {
                    const errorMessage = "Laden der Preview fehlgeschlagen.";
                    this.displayToast("danger", errorMessage);
                }
            });
    },
};
</script>

<style scoped></style>
