<template>
    <div>
        <h6 v-if="false" class="font-weight-bold mb-0">
            REtasks Zähler
            <span v-b-tooltip.hover title="Gruppiert pro Gerätestandort (GS)" class="mr-1">
                <font-awesome-icon :icon="['fas', 'info-circle']" />
            </span>
        </h6>
        <div v-if="isLoadingDevicepools" class="font-italic"><b-spinner small /> Gerätestandorte werden geladen...</div>
        <template v-else>
            <div v-if="devicepools?.length === 0">
                <b-alert variant="info" show class="p-1 mb-1 font-italic">
                    <font-awesome-icon :icon="['fas', 'info-circle']" />
                    Keine Gerätestandorte gefunden.
                </b-alert>
            </div>
            <div v-for="(dp, dp_i) in devicepools" :key="`dp_${dp.devicePoolID}`">
                <hr v-if="dp_i > 0 && false" class="my-1" />
                <div class="font-weight-bold mt-1">GS: {{ composeGeraetestandortName(dp) }}</div>
                <div v-for="(d, d_i) in dp.devices" :key="`device_${d.deviceID}`">
                    <div v-if="d.type === 'Elektrizitätszähler'" class="mb-1 px-2 py-1 small re-meter">
                        <div class="font-weight-bold">
                            {{ diExtractObjektstandort(d?.deviceInfos) }}
                        </div>
                        <div class="d-flex">
                            <img src="@/assets/meter-icon.png" alt="Meter Icon" class="re-meter-icon" />
                            <b-container class="ml-1">
                                <b-row>
                                    <b-col class="col-4">Werk-Nr.:</b-col>
                                    <b-col class="font-weight-bold">{{ d?.internalNo ? d.internalNo : "null" }}</b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="col-4">Fabrik-Nr.:</b-col>
                                    <b-col>{{ d?.factoryNo ? d.factoryNo : "null" }}</b-col>
                                </b-row>
                            </b-container>
                        </div>
                        <div class="text-secondary small">
                            {{ d?.deviceType ? d?.deviceType : "null" }}
                        </div>
                        <hr v-if="dp.devices.length < d_i + 1 && false" class="mt-1 mb-2" />
                    </div>
                </div>
                <hr v-if="dp_i + 1 < devicepools.length" class="mt-2 mb-0" />
            </div>
        </template>
    </div>
</template>

<script>
/**
 * @name CheckRetasksDbMeters
 * displays all meters of REtasks DB (Device.Type='Elektrizitätszähler')
 */
export default {
    name: "CheckRetasksDbMeters",
    props: {
        devicepools: {
            type: Array,
            default: () => [],
        },
        isLoadingData: {
            type: Boolean,
            default: false,
        },
        isLoadingDevicepools: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        composeGeraetestandortName(dp) {
            const dpPositionUnknown = dp.position === null || dp.position === "";
            const dpPositionDetailUnknown = dp.positionDetail === null || dp.positionDetail === "";
            if (dpPositionUnknown && dpPositionDetailUnknown) {
                return "unbekannt";
            }
            if (dpPositionUnknown) {
                return dp.positionDetail;
            }
            if (dpPositionDetailUnknown) {
                return dp.position;
            }
            return `${dp.position} - ${dp.positionDetail}`;
        },
        diExtractObjektstandort(di) {
            /**
             * extract objektstandort from deviceInfos array
             *
             * @param {array} di - deviceInfos array
             * @returns {string} - extracted objektstandort
             */
            const _diObjektstandorte = di.filter((i) => i?.name?.toUpperCase() === "OBJEKTSTANDORT");
            let _message;
            const _osDuplicates = [];
            const _diOsLength = _diObjektstandorte.length;
            switch (_diOsLength) {
                case 0:
                    _message = "Kein Objektstandort definiert";
                    break;
                case 1:
                    _message = this.stringSplitCleanJoin(_diObjektstandorte[0].value);
                    break;
                default:
                    // note: this scenario should not happen if data import works correct -> better safe than sorry
                    _message = "Es gibt mehr als 1 Objektstandort";
                    _diObjektstandorte.forEach((os) => {
                        _osDuplicates.push(this.stringSplitCleanJoin(os.value));
                    });
                    console.warn(_message, _osDuplicates);
                    this.$bvToast.toast(`${_message} -> details: ${JSON.stringify(_osDuplicates)}`, {
                        title: "Fehler",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    });
            }
            return _message;
        },
        stringSplitCleanJoin(str, config = {}) {
            /**
             * @param {string} str - string to process
             * @param {object} config - configuration object
             * @param {boolean} config.debug - debug mode
             * @param {number} config.removeLastXelements - remove last X elements
             * @param {string} config.separator - separator
             * @param {boolean} config.trim - trim elements
             * @returns {string} - processed string
             */
            const _debug = config.debug ?? false;
            const _removeLastXelements = config.removeLastXelements ?? 2;
            const _separator = config.separator ?? ", ";
            const _trim = config.trim ?? true;

            _debug && console.log("before cleanup:", str);
            let _strCleaned;
            const _stringSnippets = [];

            str.split(_separator).forEach((s) => {
                if (_trim) {
                    const sTrimmed = s.trim();
                    if (sTrimmed.length > 0) {
                        _stringSnippets.push(sTrimmed);
                    }
                } else {
                    _stringSnippets.push(s);
                }
            });
            let loopXtimes = _removeLastXelements;
            // remove last X elements (for loopXtimes=2 -> remove street and city, e.g. "Street 1, 1234 City")
            while (loopXtimes--) _stringSnippets.pop();
            _strCleaned = _stringSnippets.join(", ");
            _debug && console.log("after cleanup:", _strCleaned);
            return _strCleaned;
        },
    },
};
</script>

<style scoped>
/* IMPORTANT: do not remove the following css class (even if it looks like it is not used -> it is) */
.col {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.re-meter {
    background-color: lightgrey;
    border-radius: 5px;
}
.re-meter-icon {
    width: 30px;
    height: 35px;
}
</style>
