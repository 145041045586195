import { httpClient } from "@/services/api/client";
const endpoint = "/app/absences";

export default {
    // ********************
    // default REST methods
    // ********************

    patch(absenceID, dataToUpdate) {
        return httpClient.patch(`${endpoint}/${absenceID}`, dataToUpdate);
    },

    delete(absenceID) {
        return httpClient.delete(`${endpoint}/${absenceID}`);
    },

    post(newAbsenceData) {
        return httpClient.post(endpoint, newAbsenceData);
    },
};
