<template>
    <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Callback",
    methods: {
        ...mapActions(["oidcSignInCallback"]),
    },
    mounted() {
        this.oidcSignInCallback()
            .then((redirectPath) => {
                this.$router.push(redirectPath);
            })
            .catch((err) => {
                // Handle errors like user is not authorized
                console.error(err);
                this.$router.push({ name: "unauthorized", query: { reason: "oidc-error" } });
            });
    },
};
</script>
