const execution = {
    namespaced: true,
    state: {
        // active auftragDetail record
        actAuftragDetail: null,
        // active auftragDetail index
        actAuftragDetailIndex: null,
        // array of auftragDetails
        auftragDetails: null,
    },
    actions: {
        setActAuftragDetail({ commit }, data) {
            commit("SET_ACT_AUFTRAG_DETAIL", data);
        },
        setActAuftragDetailIndex({ commit }, data) {
            commit("SET_ACT_AUFTRAG_DETAIL_INDEX", data);
        },
        setAuftragDetails({ commit }, data) {
            commit("SET_AUFTRAG_DETAILS", data);
        },
    },
    mutations: {
        SET_ACT_AUFTRAG_DETAIL(state, data) {
            state.actAuftragDetail = data;
        },
        SET_ACT_AUFTRAG_DETAIL_INDEX(state, data) {
            state.actAuftragDetailIndex = data;
        },
        SET_AUFTRAG_DETAILS(state, data) {
            state.auftragDetails = data;
        },
    },
    getters: {
        storeActAuftragDetail: (state) => state.actAuftragDetail,
        storeActAuftragDetailIndex: (state) => state.actAuftragDetailIndex,
        storeAuftragDetails: (state) => state.auftragDetails,
    },
};
export default execution;
