<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <b-alert v-if="!actAuftragDetail.isExecutable" class="my-1 p-2" show>
                Schritt nicht ausführbar (
                <i>isExecutable={{ actAuftragDetail.isExecutable }}</i>
                ).
            </b-alert>
            <b-spinner v-if="!device" />
            <div v-else class="mt-2">
                <div>
                    <strong>{{ device.factoryNo }}</strong> ({{ device.deviceType }})
                </div>
                <div>{{ device.deviceSuffix }}</div>
            </div>
            <div class="d-flex mt-3">
                <b-button
                    v-if="isUnmountableWithoutReplaceSman"
                    v-b-modal.rse-no-replacement
                    :disabled="compIsAlreadySkipped"
                    variant="primary"
                >
                    Demontiert <br />
                    ohne SMAN Einbau
                </b-button>
                <b-button class="ml-auto" @click="onClickRemoved" :disabled="compErledigtIsDisabled" variant="primary">
                    Ausgebaut
                </b-button>
            </div>
            <b-modal
                id="rse-no-replacement"
                title="Demontiert ohne SMAN Einbau"
                content-class="shadow"
                cancel-title="Abbrechen"
                ok-variant="danger"
                ok-title="Ausbau"
                @ok="onClickRemoveNoReplacement"
            >
                <div class="font-weight-bold">Kein Ersatz</div>
                <small class="mt-1 font-italic text-danger">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-1" />
                    <strong>ACHTUNG:</strong> Dieser Vorgang kann nicht rückgängig gemacht werden
                </small>
                <div class="mt-2">Möchten Sie den RSE wirklich ohne SMAN Einbau demontieren?</div>
            </b-modal>
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";

export default {
    name: "Step_6_1_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            device: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            isUnmountableWithoutReplaceSman: false,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compErledigtIsDisabled() {
            // ToDo: add all required conditions to disable button erledigt
            return this.actAuftragDetail.isExecutable === false;
        },
        compIsAlreadySkipped() {
            return this.actAuftragDetail.status === 300;
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        // Get the auftragDetail
        const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId
        );
        // Set the auftragDetail
        await this.setActAuftragDetail(auftragDetail);

        // this is handled here because deviceID changes in step 6-1-1 (coming from step 6-1-2).
        // gae 20230316: It does not work in case of multiple SMAN in the devicePool
        /*if (parseInt(this.$route.params.deviceId) !== parseInt(this.actAuftragDetail.deviceID)) {
            await this.$router.push({
                name: "step-6-1-1",
                params: {
                    deviceId: this.actAuftragDetail.deviceID,
                    devicePoolId: this.$route.params.devicePoolId,
                    orderId: this.$route.params.orderId,
                },
            });
        }*/
        // Store the device
        this.device = (
            await devicesAPI.getSingle(auftragDetail.deviceID, { params: { includeDeviceInfos: true } })
        ).data;
        // Unmount RSE without replacement button
        const rseInfo = await devicesAPI.getRseInfo(this.$route.params.orderId, this.device.deviceID);
        this.isUnmountableWithoutReplaceSman = rseInfo.data?.isUnmountableWithoutReplaceSman ?? false;
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        onClickNext(step) {
            // ToDo: do whatever is necessary before moving to next step
            // on SUCCESS, navigate to next step
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            // ToDo: do whatever is necessary before moving to previous step
            // on SUCCESS, navigate to previous step
            this.navigateToStepPrevious = step;
        },
        async onClickRemoved() {
            console.log(`Device ${this.device.deviceID} will be deactivate later`);
            const payloadAuftragDetails = [
                {
                    op: "replace",
                    path: "/status",
                    value: 200,
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetails);
            // on SUCCESS, navigate to next step
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        async onClickRemoveNoReplacement() {
            await devicesAPI.unmountRseNoReplacement(this.$route.params.orderId, this.device.deviceID);
            await this.$router.push({
                name: "execution-rse-sman",
                params: {
                    orderId: this.$route.params.orderId,
                    devicePoolId: this.$route.params.devicePoolId,
                },
            });
            this.$bvToast.toast(`RSE wurde ohne Installation von SMAN demontiert`, {
                title: "RSE demontiert",
                variant: "success",
                toaster: "b-toaster-bottom-right",
                noAutoHide: true,
                appendToast: true,
            });
        },
    },
};
</script>
