import { httpClient } from "@/services/api/client";
import constants from "@/constants/constants";

const endpoint = "/app/companies";

export default {
    // ********************
    // methods designed for datagrid
    // ********************
    getCompaniesForGridUrl() {
        return constants.env.API_ROOT_URL + endpoint + "/grid";
    },

    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, {
            params: params,
        });
    },

    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },

    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload);
    },

    delete(id) {
        return httpClient.delete(`${endpoint}/${id}`);
    },

    post(payload) {
        return httpClient.post(endpoint, payload);
    },
};
