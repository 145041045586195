<template>
    <div>
        <h1>Diagnostic data</h1>
        <h3>Environment</h3>
        <code>
            {{ constants.env.NAME }}
        </code>
        <h3>Access Token</h3>
        <b-form-textarea id="textarea" v-model="swaggerEntry" rows="8" readonly></b-form-textarea>
    </div>
</template>

<script>
import constants from "@/constants/constants";
import { mapGetters } from "vuex";
export default {
    name: "Env",
    data() {
        return {
            constants: constants,
        };
    },
    computed: {
        ...mapGetters(["oidcAccessToken"]),
        swaggerEntry() {
            return `Bearer ${this.oidcAccessToken}`;
        },
    },
};
</script>

<style scoped></style>
