<template>
    <div>
        <div class="bg-warning p-2" v-if="isManualReadingOlderThanOneHour || isFernablesungOlderThanOneHour">
            Folgende Ablesung ist älter als eine Stunde oder ist nicht vorhanden:
            <ul>
                <!-- Fernablesung Reading -->
                <li v-if="device.telemeteringType > 0">
                    <!-- Reading Time of Fernablesung reading stays in JSON inside auftragDetail712.auftragDetail712 -->
                    Fernablesung:
                    <span v-if="zfaObject.status === 'DONE' && isFernablesungOlderThanOneHour"
                        >{{ moment(zfaObject.readingDate).format("DD.MM.YYYY HH:mm") }} Uhr</span
                    >
                    <span v-else>{{ zfaObject.statusText }}</span>
                </li>
                <!-- Manual/Optical Reading -->
                <li v-if="isManualReadingOlderThanOneHour">
                    <!-- Reading Time of manual/optical reading stays in auftragDetail714.executionTime -->
                    Manuell/Optical:
                    <span v-if="auftragDetail714.executionTime"
                        >{{ moment(auftragDetail714.executionTime).format("DD.MM.YYYY HH:mm") }} Uhr</span
                    ><span v-else>nicht gemacht</span>
                </li>
            </ul>
            <p>
                Bitte nehmen Sie eine neue Ablesung vor, bevor Sie den Zähler demontieren!
            </p>
            <b-checkbox v-model="isReadingOk" v-on:change="onIsReadingOk"
                >Es kann keine erneute Ablesung des Zählers vorgenommen werden.</b-checkbox
            >
        </div>
        <div class="bg-success p-2" v-else>
            Die Ablesung(en) sind junger als eine Stunde.
        </div>
    </div>
</template>

<script>
import moment from "moment";
import * as zfaUtils from "@/utils/ZfaUtils";

export default {
    name: "ExecMeterReadingCheck",
    props: {
        device: {
            type: Object,
            required: true,
        },
        auftragDetail714: {
            type: Object,
            required: true,
        },
        auftragDetail712: {
            type: Object,
            required: false,
        },
        auftragDetailAusbau: {
            // 7-2-1 or 7-2-0
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isReadingOk: false,
            zfaObject: { status: "NOT STARTED" }, // initial value
        };
    },
    mounted() {
        // If both readings are earlier than 1 hour, emit isReadingOk = true
        if (!this.isManualReadingOlderThanOneHour && !this.isFernablesungOlderThanOneHour) {
            this.isReadingOk = true;
        }
        this.$emit("isReadingOk", this.isReadingOk);
        console.log("isReadingOk: ", this.isReadingOk);
        this.zfaObject = zfaUtils.parseAuftragDetailValue(this.auftragDetail712.value);
        console.log("this.zfaObject", this.zfaObject);
    },
    computed: {
        moment() {
            return moment;
        },
        isManualReadingOlderThanOneHour() {
            if ([202, 203].includes(this.auftragDetail714.status)) {
                const diff = moment().diff(moment(this.auftragDetail714.executionTime), "minutes");
                return diff > 60;
            }
            return true;
        },
        isFernablesungOlderThanOneHour() {
            if (203 === this.auftragDetail712.status) {
                const diff = moment().diff(moment(this.zfaObject.readingDate), "minutes");
                return diff > 60;
            }
            if (this.device.telemeteringType === 0) {
                return false; // No telemetering for this device. Hide telemetering bullet
            }
            return true;
        },
    },
    methods: {
        onIsReadingOk() {
            this.$emit("isReadingOk", this.isReadingOk);
        },
    },
};
</script>

<style scoped></style>
