<template>
    <div></div>
</template>

<script>
import constants from "@/constants/constants";

import { vuexOidcProcessSilentSignInCallback } from "vuex-oidc";

// See: https://www.scottbrady91.com/OpenID-Connect/Silent-Refresh-Refreshing-Access-Tokens-when-using-the-Implicit-Flow
// See also: https://github.com/perarnborg/vuex-oidc/wiki (punto 9)
export default {
    name: "silentRefresh",
    methods: {
        silentRefresh: async function() {
            try {
                console.log("Silent refresh");
                await vuexOidcProcessSilentSignInCallback(constants.identity.settings);
            } catch (err) {
                console.error("Silent refresh", err);
            }
        },
    },
    async mounted() {
        await this.silentRefresh();
    },
};
</script>
