<template>
    <div class="pb-2">
        <b-alert
            class="mb-2 px-2 py-1 font-italic small"
            variant="warning"
            v-if="compPhotoUploadDisabledDetails?.length && !isStartingStepException"
            show
        >
            <div>
                <b>
                    PhotoUpload ist nicht verfügbar:
                </b>
            </div>
            <ul class="pl-3 mb-0">
                <li v-for="(p, i) in compPhotoUploadDisabledDetails" :key="i">{{ p.message }}</li>
            </ul>
        </b-alert>
        <div class="d-flex flex-row justify-content-between">
            <div class="d-lg-none" v-for="captureType in captureTypes" :key="captureType">
                <file-upload
                    :input-id="refEntityKey + captureType"
                    class="btn btn-primary"
                    extensions="jpg,jpeg,png"
                    accept="image/png,image/jpeg"
                    :multiple="false"
                    :size="1024 * 1024 * 30"
                    ref="upload"
                    :class="{ disabled: compPhotoUploadDisabled }"
                    :disabled="compPhotoUploadDisabled"
                    @input-filter="inputFilter"
                    :capture="captureType === 'camera' ? 'environment' : undefined"
                    @input-file="onStartUpload"
                >
                    <span v-if="captureType === 'camera'">
                        <font-awesome-icon :icon="['fas', 'camera']" size="lg" class="pl-1 pr-1" />
                        {{ showLabelForCaptureTypes ? "Kamera" : "" }}
                    </span>
                    <span v-else>
                        <font-awesome-icon :icon="['fas', 'plus']" size="lg" class="pl-1 pr-1" />
                        {{ showLabelForCaptureTypes ? "Fotogalerie" : "" }}
                    </span>
                </file-upload>
            </div>
            <div class="d-none d-lg-block">
                <file-upload
                    :input-id="refEntityKey"
                    class="btn btn-primary"
                    extensions="jpg,jpeg,png"
                    accept="image/png,image/jpeg"
                    :multiple="false"
                    :size="1024 * 1024 * 30"
                    ref="upload"
                    :class="{ disabled: compPhotoUploadDisabled }"
                    :disabled="compPhotoUploadDisabled"
                    @input-filter="inputFilter"
                    @input-file="onStartUpload"
                >
                    <span> <font-awesome-icon :icon="['fas', 'plus']" size="lg" /> Datei hochladen </span>
                </file-upload>
            </div>
        </div>
        <template v-if="isCurrentStepEqualToEndingStep">
            <b-progress class="mt-2" show-value :max="maxProgressBarValue">
                <b-progress-bar
                    :value="progressBarValue"
                    :variant="progressBarVariant"
                    v-if="isProgressBarUpdated"
                    :animated="isAnimated"
                >
                    <span>
                        <strong>{{ currentUploadPercentage }}</strong>
                    </span>
                </b-progress-bar>
            </b-progress>
            <template v-if="isUploadInProgress">
                <small> {{ currentUploadProgress }} </small>
            </template>
            <ImageUploadView
                :imageList="localImages"
                :isUploadCurrentlyInProgress="isUploadCurrentlyInProgress"
                @forceUpload="onClickForceUpload"
                v-if="localImages.length"
            ></ImageUploadView>
        </template>
        <b-spinner v-if="assetsItemsIsLoading" />
        <template v-else-if="showImages">
            <ImageGallery
                :imageList="images"
                :isAssetDeletable="assetDelete"
                :key="refEntityKey"
                :isDisabled="compPhotoUploadDisabled"
                :isUploadCurrentlyInProgress="isUploadCurrentlyInProgress"
                @assetIsUploaded="onClickManualUpload"
                @assetIsDeleted="onClickDeleteAsset"
                @animationStatus="onUpdateAnimationStatus"
            ></ImageGallery>
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import FileUpload from "vue-upload-component";

import constants from "@/constants/constants";
import ImageGallery from "./ImageGallery.vue";
import ImageUploadView from "./ImageUploadView.vue";
import cacheService from "@/services/cacheService";
import devicepoolsApi from "@/services/api/devicepools.api";
import assetsApi from "@/services/api/assets.api";
import { eventBus } from "@/utils/eventBus";
import auftragsApi from "../../services/api/auftrags.api";
import auftragDetailsApi from "../../services/api/auftragDetails.api";
import { isDesktop } from "../../utils/energyspace/ScreenHelper";

import _ from "lodash";

export default {
    name: "PhotoUpload",
    components: {
        FileUpload,
        ImageGallery,
        ImageUploadView,
    },
    props: {
        app: {
            type: String,
            required: true,
        },
        // option which enables delete functionality of an asset
        assetDelete: {
            type: Boolean,
            default: false,
        },
        captureTypes: {
            type: Array,
            defaults: [""],
        },
        entity: {
            type: String,
            required: true,
        },
        entityId: {
            type: Number,
            required: true,
        },
        fileDescription: {
            type: String,
            default: "",
        },
        folder: {
            type: String,
            required: true,
        },
        refEntity: {
            type: String,
            default: "",
        },
        refEntityId: {
            type: Number,
            default: 0,
        },
        refEntityKey: {
            type: String,
            default: null,
        },
        showImages: {
            type: Boolean,
            default: false,
        },
        showLabelForCaptureTypes: {
            type: Boolean,
            default: false,
        },
        downloadable: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: true,
        },
        currentStep: {
            type: String,
            default: "",
        },
        disabledDetails: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isProgressBarUpdated: false,
            isAnimated: false,
            assetsItemsIsLoading: false,
            assetsItems: [],
            images: [],
            localImages: [],
            currentUploadedPhotos: 0,
            totalPhotos: 0,
            hakId: 0,
            auftragStatusID: null,
            orderId: this.$route.params?.orderId ?? null,
            deviceAssetIds: [], // Asset ID of devices (steps 5-1-1, 7-2-1) - Populated in step 9-1-1 only
        };
    },
    created() {
        // adds the event listener that handles the photoUpdate event
        eventBus.$on("photoUpdate", async (value) => {
            await this.handleAssetUpload(value);
            this.reloadArrays();
        });
        eventBus.$on("photoSkipped", async (id) => {
            this.skipArrayItem(this.images, id);
            this.reloadArrays();
        });
    },
    async mounted() {
        if (this.isCurrentStepEqualToEndingStep) {
            // hakId will be 0 if the current step is not 9-1-1
            const hakResponse = await devicepoolsApi.getSingle(this.entityId);
            this.hakId = hakResponse.data.hakID;
        }

        if (this.orderId) {
            const orderResponse = await auftragsApi.getSingle(this.orderId);
            this.auftragStatusID = orderResponse.data.auftragStatusID;
        }

        await this.updateProgressBarAndAssets();
    },
    computed: {
        ...mapGetters({
            oidcAccessToken: "oidcAccessToken",
        }),
        isUploadCurrentlyInProgress() {
            return this.isAnimated;
        },
        uploadPercentage() {
            return `${Math.round((this.currentUploadedPhotos / this.totalPhotos) * 100)}%`;
        },
        progressBarValue() {
            const { FULL_PROGRESS_BAR } = constants.upload;
            return this.isProgressBarFull
                ? FULL_PROGRESS_BAR
                : this.currentUploadedPhotos > 0
                ? this.currentUploadedPhotos
                : FULL_PROGRESS_BAR;
        },
        maxProgressBarValue() {
            const { FULL_PROGRESS_BAR } = constants.upload;
            return this.isProgressBarFull ? FULL_PROGRESS_BAR : this.totalPhotos;
        },
        progressBarVariant() {
            return this.isProgressBarFull ? "success" : this.currentUploadedPhotos ? "" : "warning";
        },
        currentUploadPercentage() {
            return this.isProgressBarFull
                ? `${this.uploadPercentage} hochgeladen`
                : this.currentUploadedPhotos > 0
                ? this.uploadPercentage
                : "Keine Fotos hochgeladen";
        },
        currentUploadProgress() {
            return `Insgesamt ${this.currentUploadedPhotos} / ${this.totalPhotos} Fotos hochgeladen`;
        },
        isCurrentStepEqualToEndingStep() {
            return this.currentStep === constants.steps.STEP_9_1_1;
        },
        isCurrentStepEqualToStartingStep() {
            const { STEP_1_1_1, STEP_2_2_1 } = constants.steps;
            // Steps 1-1-1 and 2-2-1 are considered starting steps since they set an auftrag to 'in progress' if it's 'accepted'
            return [STEP_1_1_1, STEP_2_2_1].includes(this.currentStep);
        },
        isAuftragAccepted() {
            return this.auftragStatusID && this.auftragStatusID.toUpperCase() === "ACCEPTED";
        },
        isStartingStepException() {
            return this.isAuftragAccepted && this.isCurrentStepEqualToStartingStep;
        },
        isUploadCompleted() {
            const isUploadCompleted = _.isEqual(this.currentUploadedPhotos, this.totalPhotos);
            this.$emit("uploadCompletionStatus", isUploadCompleted);
            return isUploadCompleted;
        },
        isProgressBarFull() {
            return this.currentUploadedPhotos > 0 && this.isUploadCompleted;
        },
        isUploadInProgress() {
            return !this.isUploadCompleted && this.currentUploadedPhotos > 0;
        },
        isAuftragNotInProgress() {
            return this.auftragStatusID && this.auftragStatusID.toUpperCase() !== "IN PROGRESS";
        },
        compAuftragStatusDetailMsg() {
            return `Auftragsstatus IST: '${this.auftragStatusID}' (a.AuftragStatusID); SOLL: 'in progress'`;
        },
        compPhotoUploadDisabledDetails() {
            const details = this.disabledDetails;
            if (this.isAuftragNotInProgress) {
                // checks if compAuftragStatusDetailMsg is alr shown (had a bug of it displaying multiple times)
                if (!details.some((d) => d.message === this.compAuftragStatusDetailMsg)) {
                    details.unshift({
                        message: this.compAuftragStatusDetailMsg,
                    });
                }
            }
            return details;
        },
        compPhotoUploadDisabled() {
            return (
                (this.isDisabled || this.compPhotoUploadDisabledDetails?.length > 0) && !this.isStartingStepException
            );
        },
    },
    methods: {
        async onClickForceUpload(status) {
            await this.handleForceUpload(status);
            this.reloadArrays();
        },
        onUpdateAnimationStatus(status) {
            this.isAnimated = status;
        },
        async onClickDeleteAsset(value) {
            await this.handleAssetDeleteById(value);
        },
        async onClickManualUpload(value) {
            this.isAnimated = false;
            await this.handleAssetUpload(value);
            this.reloadArrays();
        },
        reloadArrays() {
            this.images = [...this.images];
            this.localImages = [...this.localImages];
        },
        async onStartUpload(file) {
            await this.saveFile(file);
        },
        findAssetInListById(array, id) {
            const index = array.findIndex((img) => img.id === id);
            return index !== -1 ? index : null;
        },
        removeAssetFromListById(array, id) {
            try {
                const index = this.findAssetInListById(array, id);
                if (index >= 0) {
                    array.splice(index, 1);
                }
                return !!index;
            } catch {
                return false;
            }
        },
        async handleAssetDeleteById(id) {
            this.removeAssetFromListById(this.localImages, id);
            this.removeAssetFromListById(this.images, id);
            await this.updateProgressBarAndAssets();
            this.$emit("assetDeleted", { assetId: id });
        },
        skipArrayItem(array, id) {
            const index = this.findAssetInListById(array, id);
            if (index !== -1) {
                array[index].isUploadSkipped = true;
            }
        },
        updateArrayItem(array, photo) {
            const index = this.findAssetInListById(array, photo.id);
            if (index !== -1) {
                array[index] = cacheService.updatePhoto(array[index], photo.img ?? photo);
            }
        },
        async handleAssetUpload(photo) {
            this.updateArrayItem(this.images, photo);
            this.removeAssetFromListById(this.localImages, photo.id);
            await this.updateProgressBarAndAssets();
        },
        createAssetsPayload(filename) {
            return {
                description: this.fileDescription,
                entity: this.entity,
                entityId: this.entityId,
                isUploaded: false,
                filename: filename,
                ...(this.refEntity &&
                    this.refEntityId &&
                    this.refEntityId > 0 && {
                        refEntity: this.refEntity,
                        refEntityId: this.refEntityId,
                        refEntityKey: this.refEntityKey,
                    }),
            };
        },
        async createImageURL(file) {
            const blob = new Blob([file.file], { type: "application/octet-stream" });
            const imageURL = await cacheService.convertBlobToImageURL(blob);
            return imageURL;
        },
        async savePhotoLocally(imageData, path) {
            const { assetsPayload, asset } = imageData;
            const createdPhoto = await cacheService.savePhoto(
                {
                    folder: this.folder,
                    assetsPayload,
                    ...asset,
                    path,
                    isUploadSkipped: false,
                },
                asset.id
            );
            return createdPhoto;
        },
        async saveImageLocallyAndPrepareForImageArray(imageData, path) {
            this.savePhotoLocally(imageData, path);
        },
        async saveFile(file) {
            try {
                if (!file) return;
                const filename = file.file.name;
                const assetsPayload = this.createAssetsPayload(filename);
                const imageUrl = await this.createImageURL(file);
                const response = await cacheService.uploadFile(assetsPayload);
                const asset = response.data;
                if (this.isAssetTypeCorrect(asset?.type)) {
                    await this.saveImageLocallyAndPrepareForImageArray(
                        {
                            folder: this.folder,
                            assetsPayload,
                            asset,
                        },
                        imageUrl
                    );
                }
                if (isDesktop()) {
                    cacheService.uploadOldestPhoto();
                }
                await this.updateProgressBarAndAssets();
            } catch (err) {
                console.error(err);
                this.displayToast("danger", "Fehler beim Speichern des Fotos");
            }
        },
        async handleForceUpload(status) {
            this.isAnimated = status;
            const uploadPromises = this.localImages.map((asset) =>
                cacheService
                    .uploadSinglePhotoAndHandleError(asset)
                    .then((resp) => {
                        eventBus.$emit("photoUpdate", resp);
                    })
                    .catch((err) => {
                        this.displayToast("danger", err);
                    })
            );

            await Promise.all(uploadPromises);
            this.isAnimated = !status;
        },
        async updateProgressBarAndAssets() {
            try {
                this.assetsItemsIsLoading = true;
                // rewritten due to race conditions #19601
                const devicePoolAssetsResponse = await assetsApi.get({
                    app: this.app,
                    isDeleted: false,
                    entity: this.entity,
                    entityId: this.entityId,
                });
                let assets = [...devicePoolAssetsResponse.data];

                if (this.isCurrentStepEqualToEndingStep) {
                    // used to get hak-images to track progress using progressbar on 9-1-1
                    const hakAssetsResponse = await assetsApi.get({
                        app: this.app,
                        isDeleted: false,
                        entity: "hak",
                        entityId: this.hakId,
                    });
                    assets = [...assets, ...hakAssetsResponse.data];
                    // Get Device related Assets
                    const devicePoolID = this.entityId; // true when step 9-1-1
                    // get Assets of steps 7-1-3 and 5-1-1
                    const steps713 = await auftragDetailsApi.getByOrderIdAndStep(
                        this.orderId,
                        "7-1-3",
                        devicePoolID,
                        null,
                        null,
                        true
                    );
                    const steps511 = await auftragDetailsApi.getByOrderIdAndStep(
                        this.orderId,
                        "5-1-1",
                        devicePoolID,
                        null,
                        null,
                        true
                    );
                    let stepAssetPromises = [];
                    for (const step of [...steps713, ...steps511]) {
                        if (!this.deviceAssetIds.includes(step.deviceID)) {
                            // avoid add 2 times the same device in case it has mutliple images
                            stepAssetPromises.push(
                                assetsApi.get({
                                    app: this.app,
                                    isDeleted: false,
                                    entity: "device",
                                    entityId: step.deviceID,
                                })
                            );
                        }
                    }
                    const deviceAssetsResponse = await Promise.all(stepAssetPromises);
                    for (const deviceAssetResponse of deviceAssetsResponse) {
                        assets = [...assets, ...deviceAssetResponse.data];
                        deviceAssetResponse.data.forEach((asset) => this.deviceAssetIds.push(asset.id));
                    }
                }
                await this.updateAssets(assets);
                await this.updateProgressBar(assets);
                this.assetsItems = assets;
            } catch (e) {
                console.error(e);
                throw new Error("Fehler beim Aktualisieren der Progressbar und Assets");
            } finally {
                this.assetsItemsIsLoading = false;
                this.$emit("assetItems", { images: this.images });
            }
        },
        async updateAssets(data) {
            this.images = [];
            for (const asset of data) {
                if (!this.isAssetTypeCorrect(asset.type)) continue;

                if (!this.refEntity || !this.refEntityId) continue;

                if (
                    asset.refEntity === this.refEntity &&
                    Number(asset.refEntityId) === this.refEntityId &&
                    this.compareValuesIfRefEntityKeyTruthy(asset.refEntityKey, this.refEntityKey)
                ) {
                    await this.addAssetToArray(asset);
                }
            }
        },
        compareValuesIfRefEntityKeyTruthy(val1, val2) {
            return val2 ? val1 === val2 : true;
        },
        async updateProgressBar(assets) {
            this.currentUploadedPhotos = assets.filter((asset) => asset.isUploaded).length;
            this.totalPhotos = assets.length;
            this.isProgressBarUpdated = true;
            await this.loadCacheIntoLocalImages();
        },
        async loadCacheIntoLocalImages() {
            // Load Hak Images
            const localHaksPromise = cacheService.getPhotosByFilter({
                entity: "hak",
                entityId: this.hakId, // hakID
            });
            // Load DevicePool images
            const localDevicePoolsPromise = cacheService.getPhotosByFilter({
                entity: "devicepool",
                entityId: this.entityId, // devicePoolID
            });
            const [localDevicePoolResponse, localHakResponse] = await Promise.all([
                localDevicePoolsPromise,
                localHaksPromise,
            ]);
            // Load Device images
            const localDevicePromises = [];
            let localDeviceResponses = [];
            if (this.deviceAssetIds.length > 0) {
                for (const assetID of this.deviceAssetIds) {
                    const cachedDeviceImage = await cacheService.getPhotoByID(assetID);
                    if (cachedDeviceImage) localDevicePromises.push(cachedDeviceImage);
                }
                localDeviceResponses = await Promise.all(localDevicePromises);
                localDeviceResponses = localDeviceResponses.flat(); // flatten localDeviceResponses because is an array of array
            }
            this.localImages = [...localDevicePoolResponse, ...localHakResponse, ...localDeviceResponses];
        },
        async addAssetToArray(asset) {
            const image = await cacheService.getPhotoByID(asset.id);
            const modifiedImage = image ? { id: asset.id, ...this.getModifiedImage(image) } : null;
            this.images.push(modifiedImage ?? asset);
        },
        isAssetTypeCorrect(type) {
            return type === constants.image.assetType.IMAGE;
        },
        getModifiedImage(img) {
            delete img.payload;
            delete img.assetsPayload;
            return img;
        },

        // oldFile must stay, it is injected by the control
        inputFilter(newFile, oldFile, prevent) {
            // show error if non-image file
            if (!/\.(gif|jpg|jpeg|png)$/i.test(newFile.name)) {
                this.$bvToast.toast(
                    "Die ausgewählte Datei ist leider nicht gültig. Gültige Dateiendungen: *.jpg, *.jpeg, *.png",
                    {
                        title: "Fehler",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    }
                );
                return prevent();
            }
        },
        displayToast(variant = "INFO", message) {
            let noAutoHide = false;
            let title;
            switch (variant.toUpperCase()) {
                case "DANGER":
                    noAutoHide = true;
                    title = "Fehler";
                    break;
                case "INFO":
                    title = "INFO";
                    break;
                case "SUCCESS":
                    title = "SUCCESS";
                    break;
                default:
                    variant = "info";
                    title = "INFO";
            }
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
    },
    beforeDestroy() {
        eventBus.$off("photoUpdate", "photoSkipped");
    },
};
</script>
