/**
 * convert from "1-0:1.8.1*255" to "1.8.1" - but WARNING meterRegisterMsp.obisCode could also be just "1.8.1"
 * @param obisCodeExtendedLabel EG: "1-0:1.8.1*255"
 */
export function getIdentifierLabel(obisCodeExtendedLabel) {
    if (!obisCodeExtendedLabel) {
        return null;
    }
    // from "1-0:1.8.1*255" to "1.8.1"
    if (obisCodeExtendedLabel.includes(":") && obisCodeExtendedLabel.includes("*")) {
        return obisCodeExtendedLabel.split(":")[1].split("*")[0];
    }
    // from "1-0:1.8.1" to "1.8.1"
    if (obisCodeExtendedLabel.includes(":")) {
        return obisCodeExtendedLabel.split(":")[1];
    }
    // from "1.8.1*255" to "1.8.1"
    if (obisCodeExtendedLabel.includes("*")) {
        return obisCodeExtendedLabel.split("*")[0];
    }
    // from "1.8.1" to "1.8.1"
    return obisCodeExtendedLabel;
}

/**
 * convert from "1-0:1.8.1*255" to "1-0:1.8.1" - but WARNING meterRegisterMsp.obisCode could also be just "1.8.1"
 * @param obisCodeExtendedLabel EG: "1-0:1.8.1*255"
 */
export function getObisLabel(obisCodeExtendedLabel) {
    if (!obisCodeExtendedLabel) {
        return null;
    }
    // from "1-0:1.8.1*255" to "1-0:1.8.1"
    if (obisCodeExtendedLabel.includes(":") && obisCodeExtendedLabel.includes("*")) {
        return obisCodeExtendedLabel.split("*")[0];
    }
    // from "1.8.1*255" to "1.8.1"
    if (obisCodeExtendedLabel.includes("*")) {
        return obisCodeExtendedLabel.split("*")[0];
    }
    // from "1.8.1" to "1.8.1"
    return obisCodeExtendedLabel;
}
