<!-- This is a virtual step (never displayed)
The auftragDetail.value of this step is populated by:
- 2-1-1
- 7-1-1
- 7-1-3
-->
<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <b-alert v-if="!actAuftragDetail.isExecutable" class="my-1 p-2" show>
                Sie werden zum Zählermenü weitergeleitet
            </b-alert>
            <b-alert class="mt-2 mb-0" show>This step is skipped automatically</b-alert>
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";

export default {
    name: "Step_7_1_4",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            navigateToStepNext: null,
            navigateToStepPrevious: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        await auftragDetailsAPI
            .getByOrderIdAndStep(
                this.$route.params.orderId,
                step,
                this.$route.params.devicePoolId,
                null,
                this.$route.params.auftragItemId
            )
            .then((res) => {
                this.setActAuftragDetail(res);
            });
        // This step is skipped. Status is already saved by step 2-1-1, 7-1-1 or 7-1-3. Goto Zähler menu
        this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        async onClickErledigt() {
            await this.save();
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        onClickNext(step) {
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
    },
};
</script>
