<template>
    <div>
        <div v-if="displaySectionErrorCreate">
            <hr v-if="comment.length" class="my-2" />
            <div class="text-nowrap">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="text-danger" />
                <span class="ml-1 font-weight-bold">Fehler Auftragerstellung (Auftrag Create)</span> -
                <b-link :href="`/hangfire/jobs/${asq.auftragCreateJobId}`">
                    Hangfire Job ID {{ asq.auftragCreateJobId }}
                </b-link>
            </div>
            <OrchestratorStatus :asq="asq" :displayOrchestratorCreate="true" />
            <div v-if="asq.etl451aStatus === 'ERROR'">
                <hr class="my-2" />
                <div class="d-flex flex-shrink-0">
                    <OrchestratorEtlStatus name="ETL451a" :asq="asq" />
                </div>
                <div class="mb-2" v-html="asq.etl451aStatusDetail" />
            </div>
            <div v-if="asq.etl480Status === 'ERROR'">
                <hr class="my-2" />
                <div class="d-flex flex-shrink-0">
                    <OrchestratorEtlStatus name="ETL480" :asq="asq" />
                </div>
                <div class="mb-2" v-html="asq.etl480StatusDetail" />
            </div>
            <div v-if="asq.etl453Status === 'ERROR'">
                <hr class="my-2" />
                <div class="d-flex flex-shrink-0">
                    <OrchestratorEtlStatus name="ETL453" :asq="asq" />
                </div>
                <div class="mb-2" v-html="asq.etl453StatusDetail" />
            </div>
        </div>
        <div v-if="displaySectionErrorComplete">
            <hr v-if="displaySectionErrorCreate || comment.length" class="my-2" />
            <div class="text-nowrap">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="text-danger" />
                <span class="ml-1 font-weight-bold">Fehler Auftragabschluss (Auftrag Complete)</span> -
                <b-link :href="`/hangfire/jobs/${asq.auftragCompleteJobId}`">
                    Hangfire Job ID {{ asq.auftragCompleteJobId }}
                </b-link>
            </div>
            <OrchestratorStatus :asq="asq" :displayOrchestratorComplete="true" />
            <div v-if="asq.etl451bStatus === 'ERROR'">
                <hr class="my-2" />
                <div class="d-flex flex-shrink-0">
                    <OrchestratorEtlStatus name="ETL451b" :asq="asq" />
                </div>
                <div class="mb-2" v-html="asq.etl451bStatusDetail" />
            </div>
            <div v-if="asq.etl481Status === 'ERROR'">
                <hr class="my-2" />
                <div class="d-flex flex-shrink-0">
                    <OrchestratorEtlStatus name="ETL481" :asq="asq" />
                </div>
                <div class="mb-2" v-html="asq.etl481StatusDetail" />
            </div>
            <div v-if="asq.etl454Status === 'ERROR'">
                <hr class="my-2" />
                <div class="d-flex flex-shrink-0">
                    <OrchestratorEtlStatus name="ETL454" :asq="asq" />
                </div>
                <div class="mb-2" v-html="asq.etl454StatusDetail" />
            </div>
        </div>
    </div>
</template>

<script>
import OrchestratorEtlStatus from "@/components/order/OrchestratorEtlStatus.vue";
import OrchestratorStatus from "@/components/order/OrchestratorStatus.vue";

export default {
    name: "OrchestratorInfoErrors",
    components: { OrchestratorStatus, OrchestratorEtlStatus },
    props: {
        asq: {
            type: Object,
        },
        comment: {
            type: String,
            default: "",
        },
    },
    computed: {
        displaySectionErrorComplete() {
            return (
                this.asq.auftragCompleteStatus === "ERROR" ||
                this.asq.etl451bStatus === "ERROR" ||
                this.asq.etl454Status === "ERROR" ||
                this.asq.etl481Status === "ERROR"
            );
        },
        displaySectionErrorCreate() {
            return (
                this.asq.auftragCreateStatus === "ERROR" ||
                this.asq.etl451aStatus === "ERROR" ||
                this.asq.etl480Status === "ERROR" ||
                this.asq.etl453Status === "ERROR"
            );
        },
    },
};
</script>
