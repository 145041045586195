import ExecutionCallcenter from "@/views/execution/ExecutionCallcenter";
import ExecutionFlexibilities from "@/views/execution/ExecutionFlexibilities";
import ExecutionGeraetestandort from "@/views/execution/ExecutionGeraetestandort";
import ExecutionList from "@/views/execution/ExecutionList";
import ExecutionMeters from "@/views/execution/ExecutionMeters";
import ExecutionOpticalReader from "@/views/execution/ExecutionOpticalReader";
import ExecutionOtherDevices from "@/views/execution/ExecutionOtherDevices";
import ExecutionRseSman from "@/views/execution/ExecutionRseSman";
import ExecutionSingleNew from "@/views/execution/ExecutionSingleNew";
import RawReads from "@/views/execution/RawReads";
import StepUnknown from "@/views/execution/StepUnknown";
import Step_1 from "@/views/execution/Step_1";
import Step_1_1_1 from "@/views/execution/Step_1_1_1";
import Step_1_1_2 from "@/views/execution/Step_1_1_2";
import Step_1_2_1 from "@/views/execution/Step_1_2_1";
import Step_1_3_1 from "@/views/execution/Step_1_3_1";
import Step_2 from "@/views/execution/Step_2";
import Step_2_1_1 from "@/views/execution/Step_2_1_1";
import Step_2_2_1 from "@/views/execution/Step_2_2_1";
import Step_2_3_1 from "@/views/execution/Step_2_3_1";
import Step_2_4_1 from "@/views/execution/Step_2_4_1";
import Step_2_5_1 from "@/views/execution/Step_2_5_1";
import Step_2_6_1 from "@/views/execution/Step_2_6_1";
import Step_5 from "@/views/execution/Step_5";
import Step_5_1_1 from "@/views/execution/Step_5_1_1";
import Step_6 from "@/views/execution/Step_6";
import Step_6_1_1 from "@/views/execution/Step_6_1_1";
import Step_6_1_2 from "@/views/execution/Step_6_1_2";
import Step_6_1_3 from "@/views/execution/Step_6_1_3";
import Step_6_2_1 from "@/views/execution/Step_6_2_1";
import Step_6_2_2 from "@/views/execution/Step_6_2_2";
import Step_6_2_3 from "@/views/execution/Step_6_2_3";
import Step_6_3_1 from "@/views/execution/Step_6_3_1";
import Step_6_3_2 from "@/views/execution/Step_6_3_2";
import Step_6_4_1 from "@/views/execution/Step_6_4_1";
import Step_7 from "@/views/execution/Step_7";
import Step_7_1_1 from "@/views/execution/Step_7_1_1";
import Step_7_1_2 from "@/views/execution/Step_7_1_2";
import Step_7_1_3 from "@/views/execution/Step_7_1_3";
import Step_7_1_4 from "@/views/execution/Step_7_1_4";
import Step_7_2_0 from "@/views/execution/Step_7_2_0";
import Step_7_2_1 from "@/views/execution/Step_7_2_1";
import Step_7_2_2 from "@/views/execution/Step_7_2_2";
import Step_7_2_3 from "@/views/execution/Step_7_2_3";
import Step_7_2_4 from "@/views/execution/Step_7_2_4";
import Step_7_3_1 from "@/views/execution/Step_7_3_1";
import Step_7_3_2 from "@/views/execution/Step_7_3_2";
import Step_7_3_3 from "@/views/execution/Step_7_3_3";
import Step_7_3_4 from "@/views/execution/Step_7_3_4";
import Step_7_5_1 from "@/views/execution/Step_7_5_1";
import Step_8 from "@/views/execution/Step_8";
import Step_8_1_1 from "@/views/execution/Step_8_1_1";
import Step_9 from "@/views/execution/Step_9";
import Step_9_1_1 from "@/views/execution/Step_9_1_1";

export default [
    {
        path: "/execution/raw-reads",
        name: "execution-raw-reads",
        component: RawReads,
    },
    {
        path: "/execution/list",
        name: "execution-list",
        component: ExecutionList,
    },
    {
        path: "/execution/:orderId",
        name: "execution-single",
        component: ExecutionSingleNew,
    },
    {
        path: "/execution/:orderId/callcenter",
        name: "execution-callcenter",
        component: ExecutionCallcenter,
    },
    {
        path: "/execution/:orderId/:devicePoolId",
        name: "execution-geraetestandort",
        component: ExecutionGeraetestandort,
    },
    {
        path: "/execution/:orderId/:devicePoolId/flexibilities",
        name: "execution-flexibilities",
        component: ExecutionFlexibilities,
    },
    {
        path: "/execution/:orderId/:devicePoolId/others",
        name: "execution-other-devices",
        component: ExecutionOtherDevices,
    },
    {
        path: "/execution/:orderId/:devicePoolId/rse-sman",
        name: "execution-rse-sman",
        component: ExecutionRseSman,
    },
    {
        path: "/execution/:orderId/:devicePoolId/zaehler",
        name: "execution-meters",
        component: ExecutionMeters,
    },
    {
        path: "/execution/:orderId/:devicePoolId/optical-reader",
        name: "execution-optical-reader",
        component: ExecutionOpticalReader,
    },
    {
        path: "/execution/?-?-?",
        name: "step-unknown",
        component: StepUnknown,
    },
    {
        path: "/execution/:orderId/step",
        name: "step-1",
        component: Step_1,
        redirect: { name: "step-1-1-1" },
        children: [
            {
                path: "1-1-1",
                name: "step-1-1-1",
                component: Step_1_1_1,
            },
            {
                path: "1-1-2",
                name: "step-1-1-2",
                component: Step_1_1_2,
            },
            {
                path: "1-2-1",
                name: "step-1-2-1",
                component: Step_1_2_1,
            },
            {
                path: "1-3-1",
                name: "step-1-3-1",
                component: Step_1_3_1,
            },
            {
                path: "*",
                redirect: { name: "step-unknown" },
            },
        ],
    },
    {
        path: "/execution/:orderId/:devicePoolId/step",
        // Gerätestandorte
        name: "step-2",
        component: Step_2,
        redirect: { name: "step-2-1-1" },
        children: [
            {
                path: "2-1-1",
                name: "step-2-1-1",
                component: Step_2_1_1,
            },
            {
                path: "2-2-1",
                name: "step-2-2-1",
                component: Step_2_2_1,
            },
            {
                path: "2-3-1",
                name: "step-2-3-1",
                component: Step_2_3_1,
            },
            {
                path: "2-4-1",
                name: "step-2-4-1",
                component: Step_2_4_1,
            },
            {
                path: "2-5-1",
                name: "step-2-5-1",
                component: Step_2_5_1,
            },
            {
                path: "2-6-1",
                name: "step-2-6-1",
                component: Step_2_6_1,
            },
            {
                path: "*",
                redirect: { name: "step-unknown" },
            },
        ],
    },
    {
        path: "/execution/:orderId/:devicePoolId/:deviceId/step",
        // Flexibilität
        name: "step-5",
        component: Step_5,
        redirect: { name: "step-5-1-1" },
        children: [
            {
                path: "5-1-1",
                name: "step-5-1-1",
                component: Step_5_1_1,
            },
            {
                path: "*",
                redirect: { name: "step-unknown" },
            },
        ],
    },
    {
        path: "/execution/:orderId/:devicePoolId/:auftragItemId/step-6",
        // RSE-SMAN
        name: "step-6",
        component: Step_6,
        redirect: { name: "step-6-1-1" },
        children: [
            {
                path: "6-1-1",
                name: "step-6-1-1",
                component: Step_6_1_1,
            },
            {
                path: "6-1-2",
                name: "step-6-1-2",
                component: Step_6_1_2,
            },
            {
                path: "6-1-3",
                name: "step-6-1-3",
                component: Step_6_1_3,
            },
            {
                path: "6-2-1",
                name: "step-6-2-1",
                component: Step_6_2_1,
            },
            {
                path: "6-2-2",
                name: "step-6-2-2",
                component: Step_6_2_2,
            },
            {
                path: "6-2-3",
                name: "step-6-2-3",
                component: Step_6_2_3,
            },
            {
                path: "6-3-1/:factoryNo?",
                name: "step-6-3-1",
                component: Step_6_3_1,
            },
            {
                path: "6-3-2",
                name: "step-6-3-2",
                component: Step_6_3_2,
            },
            {
                path: "6-4-1",
                name: "step-6-4-1",
                component: Step_6_4_1,
            },
            {
                path: "*",
                redirect: { name: "step-unknown" },
            },
        ],
    },
    {
        path: "/execution/:orderId/:devicePoolId/:auftragItemId/step-7",
        // Zähler
        name: "step-7",
        component: Step_7,
        redirect: { name: "step-7-1-1" },
        children: [
            {
                path: "7-1-1",
                name: "step-7-1-1",
                component: Step_7_1_1,
            },
            {
                path: "7-1-2",
                name: "step-7-1-2",
                component: Step_7_1_2,
            },
            {
                path: "7-1-3",
                name: "step-7-1-3",
                component: Step_7_1_3,
            },
            {
                path: "7-1-4",
                name: "step-7-1-4",
                component: Step_7_1_4,
            },
            {
                path: "7-2-1",
                name: "step-7-2-1",
                component: Step_7_2_1,
            },
            {
                path: "7-2-2",
                name: "step-7-2-2",
                component: Step_7_2_2,
            },
            {
                path: "7-2-3",
                name: "step-7-2-3",
                component: Step_7_2_3,
            },
            {
                path: "7-2-4",
                name: "step-7-2-4",
                component: Step_7_2_4,
            },
            {
                path: "7-2-0",
                name: "step-7-2-0",
                component: Step_7_2_0,
            },
            {
                path: "7-3-1",
                name: "step-7-3-1",
                component: Step_7_3_1,
            },
            {
                path: "7-3-2",
                name: "step-7-3-2",
                component: Step_7_3_2,
            },
            {
                path: "7-3-3",
                name: "step-7-3-3",
                component: Step_7_3_3,
            },
            {
                path: "7-3-4",
                name: "step-7-3-4",
                component: Step_7_3_4,
            },
            {
                path: "7-5-1",
                name: "step-7-5-1",
                component: Step_7_5_1,
            },
            {
                path: "*",
                redirect: { name: "step-unknown" },
            },
        ],
    },
    {
        path: "/execution/:orderId/:devicePoolId/:deviceId/step-8",
        // RSE-SMAN
        name: "step-8",
        component: Step_8,
        redirect: { name: "step-8-1-1" },
        children: [
            {
                path: "8-1-1",
                name: "step-8-1-1",
                component: Step_8_1_1,
            },
            {
                path: "*",
                redirect: { name: "step-unknown" },
            },
        ],
    },
    {
        path: "/execution/:orderId/:devicePoolId/step-9",
        name: "step-9",
        component: Step_9,
        redirect: { name: "step-9-1-1" },
        children: [
            {
                path: "9-1-1",
                name: "step-9-1-1",
                component: Step_9_1_1,
            },
            {
                path: "*",
                redirect: { name: "step-unknown" },
            },
        ],
    },
];
