import { httpClient } from "@/services/api/client";

const endpoint = "/net/auftrag-statuses";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, {
            params: params,
        });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    delete(id) {
        return httpClient.delete(`${endpoint}/${id}`);
    },
    post(payload) {
        return httpClient.post(endpoint, payload);
    },
    // **************
    // custom methods
    // **************
};
