<template>
    <b-badge v-if="statusCode == 100" variant="primary" v-b-tooltip.hover :title="'Statuscode: ' + statusCode">
        In Arbeit
    </b-badge>
    <b-badge
        v-else-if="statusCode >= 200 && statusCode <= 299"
        variant="success"
        v-b-tooltip.hover
        :title="'Statuscode: ' + statusCode"
    >
        Erledigt
    </b-badge>
    <b-badge v-else-if="statusCode == 300" variant="secondary" v-b-tooltip.hover :title="'Statuscode: ' + statusCode">
        Übersprungen
    </b-badge>
    <b-badge v-else-if="statusCode == 500" variant="warning" v-b-tooltip.hover :title="'Statuscode: ' + statusCode">
        Blockiert
    </b-badge>
    <b-badge v-else-if="statusCode == -1" variant="danger" v-b-tooltip.hover :title="'Statuscode: ' + statusCode">
        Fehler
    </b-badge>
</template>

<script>
export default {
    name: "StatusBadge",
    props: {
        statusCode: {
            type: Number,
            required: true,
        },
    },
    computed: {},
};
</script>
