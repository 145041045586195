import { httpClient } from "@/services/api/client";

const endpoint = "/app/business-hours";

export default {
    // ********************
    // default REST methods
    // ********************

    delete(id) {
        return httpClient.delete(`${endpoint}/${id}`);
    },

    post(payload) {
        return httpClient.post(endpoint, payload);
    },

    put(BusinessHourID, payload) {
        return httpClient.put(`${endpoint}/${BusinessHourID}`, payload);
    },
};
