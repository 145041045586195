import { render, staticRenderFns } from "./ReNotAuth.vue?vue&type=template&id=5add81af&scoped=true"
import script from "./ReNotAuth.vue?vue&type=script&lang=js"
export * from "./ReNotAuth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5add81af",
  null
  
)

export default component.exports