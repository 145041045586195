<template>
    <b-button :variant="variant" v-on:click="checkJobStatus" :disabled="isLoading">
        {{ title }}
        <b-spinner v-if="isLoading" small />
    </b-button>
</template>

<script>
import zfaService from "@/services/zfaService";

export default {
    name: "ZfaButtonJobStatus",
    data() {
        return {
            isLoading: false,
        };
    },
    props: {
        auftragId: {
            type: Number,
            required: true,
        },
        devicePoolId: {
            type: Number,
            required: true,
        },
        deviceId: {
            type: Number,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            required: true,
        },
        autoStart: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    async mounted() {
        if (this.autoStart) {
            await this.checkJobStatus();
        }
    },
    methods: {
        async checkJobStatus() {
            this.isLoading = true;
            try {
                if (this.deviceId) {
                    await zfaService.checkJobStatusFromStep712ByDevice(
                        this.auftragId,
                        this.devicePoolId,
                        this.deviceId
                    );
                } else {
                    await zfaService.checkJobStatusFromStep211ByDevicePool(this.auftragId, this.devicePoolId);
                }
                this.$emit("checkZfaJobStatusDone", {
                    auftragID: this.auftragId,
                    devicePoolID: this.devicePoolId,
                    deviceID: this.deviceId,
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped></style>
