import moment from "moment";

/**
 * Example of value in AuftragDetail.value:
 *
 * {
 *   "deviceID": 43938,
 *   "factoryNo": "03530900",
 *   "date": "28.02.2023 09:39:18",
 *   "jobID": "1484518980-2072416140",
 *   "code": 0,
 *   "zfaCodeNr": null,
 *   "message": "OK",
 *   "detail": null,
 *   "checkJobStatus": {
 *     "jobStatus": "succeeded",
 *     "date": "01.03.2023 08:07:23"
 *   }
 * }
 * @param valueString
 *
 * @returns {Object} { status: "DONE" | "ERROR" | "STARTED" | "NOT STARTED", ...value }
 */
export function parseAuftragDetailValue(valueString) {
    if (!valueString) {
        return { status: "NOT STARTED", statusText: "nicht gestartet" };
    }
    const valueJson = JSON.parse(valueString);
    if (valueJson?.checkJobStatus?.jobStatus === "succeeded") {
        return {
            status: "DONE",
            statusText: "gemacht",
            ...valueJson,
            readingDate: moment(valueJson.date, "DD.MM.YYYYY HH:mm:ss"),
        };
    }
    if (valueJson?.code !== 0) {
        return {
            status: "ERROR",
            statusText: "fehler",
            ...valueJson,
            readingDate: moment(valueJson.date, "DD.MM.YYYYY HH:mm:ss"),
        };
    }
    return {
        status: "STARTED",
        statusText: "gestartet",
        ...valueJson,
        readingDate: moment(valueJson.date, "DD.MM.YYYYY HH:mm:ss"),
    };
}
