<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div>
                <p>
                    Folgende Zähler befinden sich in einem <strong>gesperrten Los</strong>. Bitte markieren Sie diese
                    Zähler jetzt mit einem "Sperrlager-Kleber" und bringen Sie den/die Zähler nach der Demontage ins
                    "Sperrlager"!
                </p>
                <div class="font-weight-bold">Zähler-Nr. / Zählertyp</div>
                <b-spinner v-if="!devices" class="d-block" small />
                <template v-else>
                    <b-alert v-if="devices.length === 0" class="my-1 p-2" show>
                        <template v-if="actAuftragDetail.status === 200">Schritt wurde bereits erledigt.</template>
                        <template v-else>Keine aktiven Zähler gefunden.</template>
                    </b-alert>
                    <template v-else>
                        <b-form-checkbox
                            v-for="d in devices"
                            v-model="selectedDeviceIds"
                            :key="d.deviceID"
                            :value="d.deviceID"
                        >
                            {{ d.factoryNo }} / {{ d.deviceType }}
                        </b-form-checkbox>
                    </template>
                </template>
            </div>
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";

import constants from "@/constants/constants";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import devicesAPI from "@/services/api/devices.api";

export default {
    name: "Step_2_6_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            areDangersDevicePool: false,
            devices: null,
            devicePool: null,
            isSavingDevices: false,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            selectedDeviceIds: [],
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compErledigtIsDisabled() {
            if (this.devices === null) {
                // "erledigt" is disabled if devices are not loaded
                return true;
            }
            // Make step executable if no Zaehler found. Adrian 16.03.2022
            /*if (this.devices.length === 0) {
                // "erledigt" is disabled if there are 0 devices
                return true;
            }*/
            if (this.selectedDeviceIds.length !== this.devices.length) {
                // "erledigt" is disabled if not all devices are selected
                return true;
            }
            if (this.isSavingDevices) {
                // "erledigt" is disabled when saving
                return true;
            }
            // "erledigt" is disabled if isExecutable === false
            return this.actAuftragDetail.isExecutable === false;
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.compErledigtIsDisabled) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Lokale Vorbedingungen nicht erfüllt." });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Lokale Vorbedingungen erfüllt." });
            }

            return preconditionsLocalDetails;
        },
    },
    async mounted() {
        const devicePoolId = this.$route.params.devicePoolId;
        this.devices = await this.getData(devicePoolId);

        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        await auftragDetailsAPI
            .getByOrderIdAndStep(this.$route.params.orderId, step, this.$route.params.devicePoolId)
            .then((res) => {
                this.setActAuftragDetail(res);
            });
        // Skip step if no Zaehler found.
        // No problem with Zurück loop since step-2-6-1 is not a predecessor
        if (this.devices && this.devices.length === 0) {
            console.log(`Skip step 2-6-1. Devices: ${this.devices.length}`);
            if (!this.compErledigtIsDisabled && this.actAuftragDetail.status !== 200) {
                await this.onClickErledigt();
            } else {
                // Erledigt already done
                this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            }
        }
        // Check if there are Dangers (from step 2-3-1)
        this.devicePool = (await devicePoolsAPI.getSingle(this.$route.params.devicePoolId)).data;
        this.areDangersDevicePool = this.areDangers(this.devicePool);
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        areDangers(devicePool) {
            if (
                devicePool.asbestKeyValueOptionText === "Nein" &&
                devicePool.neutralleiterAbschlaufungKeyValueOptionText === "Nein" &&
                devicePool.offeneSpannnungsFuehrendeTeileKeyValueOptionText === "Nein" &&
                !devicePool.andereGefahren
            ) {
                return false;
            }
            return true;
        },
        getData(dpId) {
            return devicesAPI
                .get({
                    devicePoolID: dpId,
                    type: "Elektrizitätszähler",
                    includeDeviceInfos: true,
                })
                .then((resp) => {
                    const devices = [];
                    resp.forEach((d) => {
                        const deviceInfo = d.deviceInfos.find((di) => di.name === "LosGesperrt" && di.value === "1");
                        if (deviceInfo) {
                            devices.push(d);
                        }
                    });
                    return devices;
                });
        },
        async onClickErledigt() {
            this.isSavingDevices = true;
            try {
                const payloadAuftragDetail = [
                    {
                        op: "replace",
                        path: "/status",
                        value: constants.auftragDetailStatus.DONE_CLOSED,
                    },
                ];
                await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail);
            } finally {
                this.isSavingDevices = false;
            }
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        onClickNext(step) {
            // on SUCCESS, navigate to next step
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            // on SUCCESS, navigate to previous step
            // In case of no Dangers go to 2-3-1, otherwise go back normally to 2-5-1
            if (!this.areDangersDevicePool) {
                this.$router.push({
                    name: "step-2-3-1",
                    params: {
                        orderId: this.$route.params.orderId,
                        devicePoolId: this.$route.params.devicePoolId,
                    },
                });
            } else {
                this.navigateToStepPrevious = step;
            }
        },
    },
};
</script>
