<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext
                    class="float-right"
                    @clickNext="onClickNext"
                    :navigateToStep="navigateToStepNext"
                    :disabled="!allowToNavigateWeiter"
                />
            </div>
            <div>
                <b-spinner v-if="!device" />
                <div v-else>
                    <exec-meter-info
                        v-if="oldDevice"
                        :mainDevice="oldDevice"
                        :order="order"
                        :devicePool="devicePool"
                        category="'Wechseln'"
                    />
                    <b-form-group label="Smart Meter Fabriknummer hinzufügen" label-for="input-factory-nr" class="mb-0">
                        <b-form-input
                            id="input-factory-nr"
                            v-model="inputFactoryNo"
                            placeholder="Bitte Fabriknummer eingeben..."
                            :readonly="compIsMeterStays"
                            @input="setInputFactoryNo"
                        />
                    </b-form-group>
                </div>
                <div class="mt-2 mb-3">
                    <ScanQrBarCode
                        close-after-read
                        @scanQrBarCodeActive="onScanQrBarCodeActive"
                        @scanQrBarCodeDecodedString="onScanDecodedString"
                    />
                </div>
            </div>
            <!-- Check FactoryNo in ISE -->
            <b-alert variant="danger" show v-if="displayMeterTypeWarning">
                {{ meterTypeWarningMessage }}
            </b-alert>
            <b-alert :variant="isDeviceTypeValid ? 'success' : 'danger'" show v-if="displayMeterTypeInfo">
                {{
                    isDeviceTypeValid
                        ? "Typ: " + deviceFeatureResponse?.feat?.deviceType
                        : deviceTypeNotAvailableWarningMessage
                }}
            </b-alert>
            <!-- Error Messages in case a scanned / entered device is already / still installed #17566 -->
            <b-alert variant="danger" show v-if="meterAlrExists && showFactoryNoError">
                Der erfasste Zähler mit der FabrikNr. <strong>{{ this.factoryNo }}</strong>
                wurde bereits installiert! Bitte erfassen Sie ein anderes Gerät.
            </b-alert>

            <b-alert variant="danger" show v-if="meterStillInstalled && showFactoryNoError">
                Der erfasste Zähler mit der FabrikNr. <strong>{{ this.factoryNo }}</strong>
                ist noch installiert! Bitte erfassen Sie ein anderes Gerät.
            </b-alert>

            <div>
                <!-- Zaehlersteckklemmen -->
                <b-form-group label="Zählersteckklemme Typ" v-slot="{ ariaDescribedby }" class="mt-2">
                    <b-form-radio-group
                        v-model="zaehlersteckklemmeSelected"
                        :aria-describedby="ariaDescribedby"
                        name="zaehlersteckklemme-form"
                        stacked
                    >
                        <b-form-radio
                            v-for="keyValueOption in zaehlersteckklemmeOptions"
                            :key="keyValueOption.text"
                            :value="keyValueOption.text"
                            >{{ keyValueOption.text }}</b-form-radio
                        >
                    </b-form-radio-group>
                </b-form-group>
                <!-- Zaehlersteckklemmen Eigentum -->
                <b-form-group
                    v-if="
                        (!!zaehlersteckklemmeSelected && zaehlersteckklemmeSelected !== keineZaehlersteckklemme) ||
                            (zaehlersteckklemmeEigentumOnLoadValue &&
                                zaehlersteckklemmeSelected !== keineZaehlersteckklemme)
                    "
                    label="Zählersteckklemme Eigentum"
                    class="mt-2"
                >
                    <b-form-radio-group
                        v-model="zaehlersteckklemmeEigentumSelectedValue"
                        name="zaehlersteckklemme-eigentum-form"
                        stacked
                    >
                        <b-form-radio
                            v-for="zahlerSteckklemmeOption in zaehlersteckklemmeEigentumOptions"
                            :key="zahlerSteckklemmeOption.id"
                            :value="zahlerSteckklemmeOption.text"
                            >{{ zahlerSteckklemmeOption.text }}</b-form-radio
                        >
                    </b-form-radio-group>
                </b-form-group>
                <b-alert
                    variant="warning"
                    show
                    v-if="
                        !!zaehlersteckklemmeSelected &&
                            zaehlersteckklemmeSelected !== keineZaehlersteckklemme &&
                            !zaehlersteckklemmeEigentumSelectedValue
                    "
                >
                    Bitte wählen Sie den Eigentümer der Zählersteckklemme aus.
                </b-alert>
            </div>
            <b-alert variant="warning" show v-if="!compIsOldMeterRemoved"> Alter Zähler/SMET nicht demontiert </b-alert>
            <ExecSectionErledigt
                :isBusy="isBusy"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import constants from "@/constants/constants";
import { isEmptyArray } from "@/utils/CommonUtils";

import ExecMeterInfo from "@/components/execution/ExecMeterInfo.vue";
import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import ScanQrBarCode from "@/components/general/ScanQrBarCode";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import deviceInfosAPI from "@/services/api/deviceInfos.api";
import devicesAPI from "@/services/api/devices.api";
import iseAPI from "@/services/api/ise.api";
import keyValueOptionsAPI from "@/services/api/keyValueOptions.api";
import auftragDetailsViewApi from "@/services/api/auftragDetailsView.api";

export default {
    name: "Step_7_2_3",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecMeterInfo,
        ExecSectionErledigt,
        ScanQrBarCode,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
        devicePool: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            allDevicesFromAuftrag: null,
            allowToNavigateWeiter: true,
            auftragItemId: null,
            auftragDetail720: null, // Ausbau SMET
            auftragDetail721: null, // Ausbau Zähler
            auftragDetail722: null, // Ausbau Communication Module
            auftragDetail723: null,
            device: null,
            deviceFeatureResponse: null, // Contains device feature response. Business info in
            devicePoolId: null,
            displayMeterTypeInfo: false,
            displayMeterTypeWarning: false,
            factoryNo: null,
            inputFactoryNo: null,
            keineZaehlersteckklemme: "Keine",
            showFactoryNoError: false,
            meterAlrExists: false,
            meterStillInstalled: false,
            meterTypeWarningMessage: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            oldDeviceID: null,
            oldDevice: null,
            prevFactoryNo: null,
            zaehlersteckklemmeDeviceInfo: null, // value previously stored in DB
            zaehlersteckklemmeEigentumOnLoad: null,
            zaehlersteckklemmeEigentumOnLoadValue: null,
            zaehlersteckklemmeEigentumOptions: [], // key value options for the Zählersteckklemme Eigentum
            zaehlersteckklemmeEigentumSelectedValue: null,
            zaehlersteckklemmeEigentumSubcategory: "Zählersteckklemme Eigentum",
            zaehlersteckklemmeOptions: [], // options loaded from KeyValueOptions
            zaehlersteckklemmeSelected: null, // value selected in this page
            deviceTypeNotAvailableWarningMessage:
                "Der DeviceType muss in REtasks auf den DeviceFeatures erfasst werden!",
            isBusy: false,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compErledigtIsDisabled() {
            return (
                this.actAuftragDetail.isExecutable === false ||
                !this.factoryNo ||
                !this.zaehlersteckklemmeSelected ||
                !this.compIsOldMeterRemoved || // prevent Erleding if old meter is not removed;
                (this.actAuftragDetail.status !== 200 && !this.deviceFeatureResponse) || // prevent Erledig if Step not executed and deviceFeatureResponse is empty
                (!!this.zaehlersteckklemmeSelected &&
                    this.zaehlersteckklemmeSelected !== this.keineZaehlersteckklemme &&
                    !this.zaehlersteckklemmeEigentumSelectedValue)
            );
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.compErledigtIsDisabled) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Lokale Vorbedingungen nicht erfüllt." });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Lokale Vorbedingungen erfüllt." });
            }
            if (this.inputFactoryNo?.length) {
                if (this.deviceFeatureResponse?.code < 0) {
                    preconditionsLocalDetails.push({ status: "OPEN", message: this.meterTypeWarningMessage });
                } else {
                    preconditionsLocalDetails.push({
                        status: "FULFILLED",
                        message: `Zähler in ISE gefunden: (${this.deviceFeatureResponse?.message})`,
                    });
                }
            } else {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Fabriknummer nicht erfasst. Lokale Vorbedingung erfüllt.",
                });
            }

            return preconditionsLocalDetails;
        },
        compIsMeterStays() {
            // Return true if SMET/Zähler stays
            return this.auftragDetail720?.status === 203 || this.auftragDetail721?.status === 203;
        },
        compIsOldMeterRemoved() {
            return (
                [202, 203].includes(this.auftragDetail720?.status) || [202, 203].includes(this.auftragDetail721?.status)
            );
        },
        isDeviceTypeValid() {
            return this.deviceFeatureResponse?.feat?.deviceType;
        },
    },
    async mounted() {
        this.devicePoolId = this.$route.params.devicePoolId;
        this.orderId = this.$route.params.orderId;
        this.auftragItemId = this.$route.params.auftragItemId;

        this.allDevicesFromAuftrag = await devicesAPI.get({
            devicePoolID: this.devicePoolId,
            type__in: "Elektrizitätszähler",
            includeDeviceInfos: true,
        });

        this.auftragDetail720 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.orderId,
            "7-2-0",
            this.devicePoolId,
            null,
            this.auftragItemId,
            false
        );
        this.auftragDetail720 = isEmptyArray(this.auftragDetail720) ? null : this.auftragDetail720;

        this.auftragDetail721 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.orderId,
            "7-2-1",
            this.devicePoolId,
            null,
            this.auftragItemId,
            false
        );
        this.auftragDetail721 = isEmptyArray(this.auftragDetail721) ? null : this.auftragDetail721;

        this.auftragDetail722 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.orderId,
            "7-2-2",
            this.devicePoolId,
            null,
            this.auftragItemId,
            false
        );
        this.auftragDetail722 = isEmptyArray(this.auftragDetail722) ? null : this.auftragDetail722;

        this.auftragDetail723 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.orderId,
            "7-2-3",
            this.devicePoolId,
            null,
            this.auftragItemId,
            false
        );
        this.auftragDetail723 = isEmptyArray(this.auftragDetail723) ? null : this.auftragDetail723;

        await this.setActAuftragDetail(this.auftragDetail723);

        if (this.auftragDetail723?.deviceID) {
            this.device = (
                await devicesAPI.getSingle(this.auftragDetail723.deviceID, { params: { includeDeviceInfos: true } })
            ).data;
            this.factoryNo = this.device?.factoryNo;
            this.inputFactoryNo = this.factoryNo;
        }

        this.oldDeviceID = this.auftragDetail721?.deviceID ?? this.auftragDetail720?.deviceID; // DeviceID of the old Zähler/SMET
        this.oldDevice = (await devicesAPI.getSingle(this.oldDeviceID, { includeDeviceInfos: true })).data;

        this.zaehlersteckklemmeOptions = (await keyValueOptionsAPI.get("meter", "Zaehlersteckklemmen")).data;
        // Load Zählersteckklemme from deviceInfo
        let responseData = await deviceInfosAPI.get({ deviceID: this.device.deviceID, name: "Zaehlersteckklemmen" }); //this.device.deviceInfos.find((di) => di.name === "Zählersteckklemme");
        // In case of meter stays and new meter has not Zählersteckklemme: take it from the old meter
        if (this.compIsMeterStays && responseData.length === 0) {
            console.log(`Loading Zaehlersteckklemmen from the old meter ${this.oldDeviceID}`);
            responseData = await deviceInfosAPI.get({ deviceID: this.oldDeviceID, name: "Zaehlersteckklemmen" });
        }
        this.zaehlersteckklemmeDeviceInfo = responseData.length > 0 ? responseData[0] : null;
        // prepopulate radio button
        if (this.oldDevice?.requireWandler === true) {
            this.zaehlersteckklemmeSelected = this.keineZaehlersteckklemme;
        } else {
            this.zaehlersteckklemmeSelected = this.zaehlersteckklemmeDeviceInfo?.value;
        }

        // Zählersteckklemme Eigentum
        this.assignZaehlersteckklemmeEigentumOnLoad();

        // In case meter stays automatically check FactoryNo in ISE while loading
        if (this.compIsMeterStays) {
            await this.onClickCheckFactoryNo();
        }
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        setInputFactoryNo() {
            this.debounceSetFactoryNo();
            this.showFactoryNoError = false;
        },
        debounceSetFactoryNo: _.debounce(function() {
            this.factoryNo = this.inputFactoryNo;
            // Check meter existence in ISE and get DeviceType information
            this.onClickCheckFactoryNo();
        }, 800),
        async onClickCheckFactoryNo() {
            this.displayMeterTypeWarning = false;
            this.displayMeterTypeInfo = false;
            if (this.factoryNo) {
                this.deviceFeatureResponse = (await iseAPI.getDeviceFeatures(this.factoryNo)).data;
                // Check meter existence in ISE and get DeviceType information
                const code = this.deviceFeatureResponse.code;
                if (code < 0) {
                    this.displayMeterTypeWarning = true;
                    switch (code) {
                        case -1:
                            this.meterTypeWarningMessage =
                                "Ein Smart Meter mit der erfassten Fabriknummer ist in IS-E nicht an Lager";
                            break;
                        case -2:
                            this.meterTypeWarningMessage = `Der Zähler mit der Fabriknummer "${this.factoryNo}" muss in IS-E  erfasst werden!`;
                            break;
                        case -3:
                            this.meterTypeWarningMessage =
                                "Der DeviceType muss in REtasks auf den DeviceFeatures erfasst werden!";
                            break;
                        case -5:
                            // this case should never get chosen, since we check for this.factoryNo to be truthy
                            this.meterTypeWarningMessage =
                                "Die Fabriknummer ist ein Pflichtfeld und darf nicht leer sein";
                            break;
                        default:
                            this.meterTypeWarningMessage = this.deviceFeatureResponse.message;
                            break;
                    }
                } else {
                    this.displayMeterTypeInfo = true;
                }
                // console.log("Meter found in ISE", this.deviceFeatureResponse?.feat);
            }
        },
        async onClickErledigt() {
            this.isBusy = true;

            try {
                if (this.compIsMeterStays) {
                    this.meterAlrExists = false;
                    this.meterStillInstalled = false;
                    console.warn("Meter stays, no need to check for existence");
                } else {
                    const [
                        validateFactoryNoAlreadyExistsResponse,
                        validateFactoryNoIsStillInstalledResponse,
                    ] = await Promise.all([
                        // Check if new meter is already existing - Expected: false
                        auftragDetailsViewApi.validateFactoryNoAlreadyExists(
                            this.orderId,
                            "7-2-3",
                            this.factoryNo,
                            this.actAuftragDetail.auftragDetailID
                        ),
                        // Check if old meter is already installed - Expected: false
                        auftragDetailsViewApi.validateFactoryNoIsStillInstalled(
                            this.orderId,
                            "7-2-0,7-2-1",
                            this.factoryNo
                        ),
                    ]);
                    this.meterAlrExists = validateFactoryNoAlreadyExistsResponse.data;
                    this.meterStillInstalled = validateFactoryNoIsStillInstalledResponse.data;
                }

                this.showFactoryNoError = this.meterAlrExists || this.meterStillInstalled;

                // OnClick erledigt check if was previously already erledigt
                if (!this.meterAlrExists && !this.meterStillInstalled) {
                    if (this.actAuftragDetail.status === 0) {
                        await this.save();
                        // if step-7-2-0: uninstall device in 7-2-0
                        if (this.auftragDetail720) {
                            console.log(`Deactivating Meter ${this.auftragDetail720.deviceID} - step 7-2-0`);
                            if (this.auftragDetail720.deviceID) {
                                await devicesAPI.uninstall(this.auftragDetail720.deviceID);
                            } else {
                                console.warn("DeviceID is null in step 7-2-0", this.auftragDetail720);
                                this.$bvToast.toast(`In Step 7-2-0 wurde keine DeviceID definiert`, {
                                    title: "Fehler",
                                    variant: "danger",
                                    toaster: "b-toaster-bottom-right",
                                    noAutoHide: true,
                                    appendToast: true,
                                });
                                return;
                            }
                        } else {
                            // else (step-7-2-1): deinstall device in 7-2-1
                            console.log(`Deactivating Meter ${this.auftragDetail721?.deviceID} - step 7-2-1`);
                            if (this.auftragDetail721) {
                                if (this.auftragDetail721.deviceID) {
                                    await devicesAPI.uninstall(this.auftragDetail721.deviceID);
                                } else {
                                    console.error("DeviceID is null in step 7-2-1", this.auftragDetail720);
                                    this.$bvToast.toast(`In Step 7-2-1 wurde keine DeviceID definiert`, {
                                        title: "Fehler",
                                        variant: "danger",
                                        toaster: "b-toaster-bottom-right",
                                        noAutoHide: true,
                                        appendToast: true,
                                    });
                                    return;
                                }
                            } else {
                                console.log(`No Meter to deactivate found`);
                            }
                        }
                    } else {
                        // Step erledigt was previously executed, then save only ZSK
                        await this.saveDeviceInfo();
                    }
                    this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                }
            } catch (error) {
                console.error("Error in onClickErledigt", error);
                this.$bvToast.toast(`Es ist ein Fehler während der Erledigung aufgetreten.`, {
                    title: "Fehler",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: true,
                    appendToast: true,
                });
            } finally {
                this.isBusy = false;
            }
        },
        async onClickNext(step) {
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            let stepPrevious = step; // step 7-2-2
            if (this.auftragDetail720) {
                stepPrevious = "7-2-0"; // step 7-2-0
            } else {
                // in case no communication module found: click "Zurück" -> 7-2-3 -> 7-2-1
                if (this.auftragDetail722 && !this.auftragDetail722.deviceID) {
                    stepPrevious = "7-2-1"; // step 7-2-1
                }
            }
            this.navigateToStepPrevious = stepPrevious;
        },
        async save() {
            await this.saveDevice(); // save device and deviceFeature info
            await this.saveDeviceInfo();
            await this.saveAuftragDetail();
        },
        async saveDeviceInfo() {
            if (
                this.zaehlersteckklemmeSelected &&
                this.zaehlersteckklemmeSelected !== this.zaehlersteckklemmeDeviceInfo?.value
            ) {
                this.zaehlersteckklemmeDeviceInfo
                    ? await deviceInfosAPI.patch(this.zaehlersteckklemmeDeviceInfo.deviceInfoID, [
                          { op: "replace", path: "/value", value: this.zaehlersteckklemmeSelected },
                      ])
                    : await deviceInfosAPI.post({
                          deviceID: this.device.deviceID,
                          name: "Zaehlersteckklemmen",
                          dataType: "Alphanumerisch",
                          value: this.zaehlersteckklemmeSelected,
                          fromDate: moment().format(constants.DATE_FORMAT_ISO),
                          toDate: moment("2050-12-31").format(constants.DATE_FORMAT_ISO),
                      });
            }
            // Zählersteckklemme Eigentum
            var hasNoInitialEigentum = !this.zaehlersteckklemmeEigentumOnLoadValue;
            var isEigentumChanged =
                this.zaehlersteckklemmeEigentumOnLoadValue &&
                this.zaehlersteckklemmeEigentumOnLoadValue !== this.zaehlersteckklemmeEigentumSelectedValue;

            if (hasNoInitialEigentum && this.zaehlersteckklemmeEigentumSelectedValue) {
                await this.createZaehlersteckklemmeEigentum();
            } else if (isEigentumChanged) {
                await this.updateZaehlersteckkleimmeEigentum();
            }
        },
        async saveDevice() {
            const payload = [
                {
                    op: "replace",
                    path: "/factoryNo",
                    value: this.factoryNo,
                },
                // internalNo = factoryNo only if internalNo is null #15403
                // source: pie (e-mail Mon 07.03.2022 14:49)
                {
                    op: "replace",
                    path: "/internalNo",
                    value: (this.device.internalNo =
                        this.device.internalNo === null || this.device.internalNo == this.prevFactoryNo
                            ? this.factoryNo
                            : this.device.internalNo),
                },
                {
                    op: "replace",
                    path: "/fromDate",
                    value: moment().format("YYYY-MM-DD"),
                },
                {
                    op: "replace",
                    path: "/toDate",
                    value: "2050-12-31",
                },
            ];
            // If any add DeviceFeature information to PATCH - deviceType is the main info
            if (this.deviceFeatureResponse && this.deviceFeatureResponse?.code === 0) {
                payload.push({
                    op: "replace",
                    path: "/deviceSuffix",
                    value: this.deviceFeatureResponse.feat.deviceSuffix,
                });
                payload.push({
                    op: "replace",
                    path: "/deviceType",
                    value: this.deviceFeatureResponse.feat.deviceType,
                });
                payload.push({
                    op: "replace",
                    path: "/description",
                    value: this.deviceFeatureResponse.feat.description,
                });
            }
            const deviceResponse = (await devicesAPI.patch(this.actAuftragDetail.deviceID, payload)).data;
            this.factoryNo = deviceResponse.factoryNo;
        },
        async saveAuftragDetail() {
            const payloadAuftragDetail = [
                {
                    op: "replace",
                    path: "/status",
                    value: 200,
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail);
        },
        onScanQrBarCodeActive() {},
        onScanDecodedString(decodedString) {
            this.factoryNo = decodedString;
            this.inputFactoryNo = this.factoryNo;
            // Check meter existence in ISE and get DeviceType information
            this.onClickCheckFactoryNo();
        },
        async assignZaehlersteckklemmeEigentumOnLoad() {
            var [zaehlersteckklemmeEigentumOptionsResult, zaehlersteckklemmeEigentumsResult] = await Promise.all([
                keyValueOptionsAPI.get("meter", this.zaehlersteckklemmeEigentumSubcategory),
                deviceInfosAPI.get({
                    deviceID: this.device.deviceID,
                    name: this.zaehlersteckklemmeEigentumSubcategory,
                }),
            ]);

            // sort options by sort order of is-e
            this.zaehlersteckklemmeEigentumOptions = zaehlersteckklemmeEigentumOptionsResult.data
                .filter((eigentum) => eigentum.active)
                .sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                });

            var zaehlersteckklemmeEigentums = zaehlersteckklemmeEigentumsResult;
            if (zaehlersteckklemmeEigentums && zaehlersteckklemmeEigentums.length > 0) {
                var currentDate = moment().format(constants.DATE_FORMAT_ISO);

                var validEigentums = zaehlersteckklemmeEigentums.filter((eigentum) => {
                    var fromDate = moment(eigentum.fromDate).format(constants.DATE_FORMAT_ISO);
                    var toDate = moment(eigentum.toDate).format(constants.DATE_FORMAT_ISO);
                    return currentDate >= fromDate && currentDate <= toDate;
                });
                // we need to take the most recent one, discussed with Dirk
                validEigentums.sort((a, b) =>
                    moment(b.fromDate, constants.DATE_FORMAT_ISO).diff(moment(a.fromDate, constants.DATE_FORMAT_ISO))
                );

                this.zaehlersteckklemmeEigentumOnLoad = validEigentums?.length > 0 ? validEigentums[0] : null;
                this.zaehlersteckklemmeEigentumOnLoadValue = this.zaehlersteckklemmeEigentumOnLoad.value;
                this.zaehlersteckklemmeEigentumSelectedValue = this.zaehlersteckklemmeEigentumOnLoad.value;
            }
        },
        async createZaehlersteckklemmeEigentum() {
            var postData = {
                deviceID: this.device.deviceID,
                name: this.zaehlersteckklemmeEigentumSubcategory,
                dataType: "Varchar",
                value: this.zaehlersteckklemmeEigentumSelectedValue,
                fromDate: moment().format(constants.DATE_FORMAT_ISO),
                toDate: moment("2050-12-31").format(constants.DATE_FORMAT_ISO), // not sure aboute the 2050.. some magic "end"-date; tbd
            };
            await deviceInfosAPI.post(postData);
        },
        async updateZaehlersteckkleimmeEigentum() {
            var patchData = [{ op: "replace", path: "/value", value: this.zaehlersteckklemmeEigentumSelectedValue }];
            await deviceInfosAPI.patch(this.zaehlersteckklemmeEigentumOnLoad.deviceInfoID, patchData);
        },
    },
    watch: {
        factoryNo(newFactoryNo, oldFactoryNo) {
            this.prevFactoryNo = oldFactoryNo ?? newFactoryNo;
            if (!newFactoryNo) this.factoryNo = oldFactoryNo;
        },
        inputFactoryNo(newFactoryNumValue) {
            if (newFactoryNumValue !== this.device?.factoryNo) {
                this.allowToNavigateWeiter = false;
                if (!newFactoryNumValue) {
                    newFactoryNumValue = this.device?.factoryNo;
                    this.allowToNavigateWeiter = true;
                }
            }
        },
        zaehlersteckklemmeSelected(newKlemmeValue) {
            if (newKlemmeValue !== this.zaehlersteckklemmeDeviceInfo?.value) {
                this.allowToNavigateWeiter = false;
            }
            if (
                !!newKlemmeValue &&
                (newKlemmeValue === this.keineZaehlersteckklemme ||
                    (newKlemmeValue !== this.keineZaehlersteckklemme && !this.zaehlersteckklemmeEigentumOnLoadValue))
            ) {
                this.zaehlersteckklemmeEigentumSelectedValue = null; // Reset the Zählersteckklemme Eigentum, as dicussed with Drik
            }
        },
        zaehlersteckklemmeEigentumSelectedValue(newZaehlersteckklemmeEigentum) {
            this.zaehlersteckklemmeEigentumSelectedValue =
                newZaehlersteckklemmeEigentum ?? this.zaehlersteckklemmeEigentumOnLoadValue;
        },
    },
};
</script>
