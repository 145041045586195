<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div>
                <b-spinner v-if="!device" />
                <template v-else>
                    <b>{{ device.factoryNo ? device.factoryNo : "NULL" }}</b> ({{ device.deviceType }})
                    <div>{{ device.type }}</div>
                    <HorizontalSeparator />
                    <div class="font-weight-bold">Relais {{ deviceRelais.relaisNumber + 1 }}</div>
                    <div>Schaltprogramm:</div>
                    <b-form-select
                        v-model="schaltprogrammId"
                        :disabled="!actAuftragDetail?.isExecutable"
                        :options="compSchaltprogramme"
                        :state="compSchaltprogrammValidation.length === 0"
                        @change="onSchaltpgroammChange"
                    />
                    <b-form-invalid-feedback v-if="compSchaltprogrammValidation.length">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                        {{ compSchaltprogrammValidation }}
                    </b-form-invalid-feedback>

                    <div class="mt-2">MSP Lastenbezeichnung:</div>
                    <b-form-select
                        v-model="keyValueOptionIdOfConsumerClass"
                        :disabled="!actAuftragDetail?.isExecutable || compSelectedSchaltprogrammKeinSchaltprogramm"
                        :options="lastenBezeichnungenMsp"
                        :state="compMspLastenbezeichnungValidation.length === 0"
                        @change="onLastenBezeichnungChange"
                    />
                    <b-form-invalid-feedback v-if="compMspLastenbezeichnungValidation.length">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                        {{ compMspLastenbezeichnungValidation }}
                    </b-form-invalid-feedback>

                    <div class="mt-2">Relais Anschlusstyp:</div>
                    <b-form-select
                        v-model="anschlusstypId"
                        :disabled="!actAuftragDetail?.isExecutable || compSelectedSchaltprogrammKeinSchaltprogramm"
                        :options="anschlusstypen"
                        :state="compAnschlusstypValidation.length === 0"
                    />
                    <b-form-invalid-feedback v-if="compAnschlusstypValidation.length">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                        {{ compAnschlusstypValidation }}
                    </b-form-invalid-feedback>

                    <b-alert v-show="!actAuftragDetail?.isExecutable" class="p-2 mt-2 font-italic" show>
                        <div class="font-size">
                            INFO: Schritt nicht ausführbar (isExecutable={{ actAuftragDetail?.isExecutable }}) ->
                            Formularfelder können nicht geändert werden.
                        </div>
                    </b-alert>
                    <div v-show="compIsSavingDelayed" class="my-2 font-italic">
                        <b-spinner small /> Daten werden in MSP und REtasks gespeichert.
                    </div>

                    <hr class="mb-1" />
                    <div class="mt-2">Schaltzustand:</div>
                    <MspRelaisTest
                        :data="{
                            mi: deviceRelais,
                            msp: devicesSmanWithRelais.mspData,
                            index: deviceRelais.relaisNumber,
                        }"
                        :key="`relais_${deviceRelais.relaisNumber}`"
                    />
                </template>
            </div>
            <ExecFlexibilities
                :order="order"
                :relaisNo="deviceRelais.relaisNumber"
                :currentStep="currentStep"
                :deviceRelaisID="deviceRelais.deviceRelaisID"
                :relaisProgramSecondExternalID="deviceRelais.relaisProgramSecondExternalID"
                :allowedToConnect="allowedToConnect"
                @save-values="handleSaving"
                noBody
            />
            <ExecSectionErledigt
                :isBusy="compIsBusy"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import constants from "@/constants/constants";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecFlexibilities from "@/components/execution/ExecFlexibilities";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import MspRelaisTest from "@/components/msp/MspRelaisTest";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import mspAPI from "@/services/api/msp.api";
import deviceRelaisAPI from "@/services/api/deviceRelais.api";
import devicesAPI from "@/services/api/devices.api";
import keyValueOptionsAPI from "@/services/api/keyValueOptionsScd.api";

export default {
    name: "Step_6_4_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecFlexibilities,
        ExecSectionErledigt,
        MspRelaisTest,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            alertAnschlusstypText: "",
            alertAnschlusstypTextInfo: "",
            alertSchaltprogrammText: "",
            alertSchaltprogrammTextInfo: "",
            anschlusstypen: [],
            // ToDo(refactor): this should contain the selected object (and collateral code could be simplified a lot)
            anschlusstypId: null,
            auftragDetails6: [],
            currentStep: null,
            device: null,
            deviceRelais: [],
            deviceRelaisID: null,
            devicesSman: [],
            devicesSmanWithRelais: [],
            // info (business/dirk):
            // the IS-E value for 'Kein Schaltprogramm' is always 700 (independent of the environment)
            // stored in IS-E DB in anlagetrezuord.ID_TREKommando=700
            iseKeinSchaltprogramm: 700,
            isSaving: false,
            // a helper flag to give the user a bit more time to read the saving message (do NOT(!) use this for other purposes)
            isSavingDelayed: false,
            isSecondExternalIdMissing: false,
            // ToDo(clarify): why such a cryptic name? -> rename?
            // ToDo(refactor): this should contain the selected object (and collateral code could be simplified a lot)
            keyValueOptionIdOfConsumerClass: null,
            lastenBezeichnungenMsp: [],
            mspSmanData: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            schaltprogramme: [],
            // ToDo(refactor): this should contain the selected object (and collateral code could be simplified a lot)
            schaltprogrammId: null,
            selectedLastenBezeichnungenMspId: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compAlertAnschlusstyp() {
            return {
                text: this.alertAnschlusstypText,
                textInfo: this.alertAnschlusstypTextInfo,
            };
        },
        compAlertSchaltprogramm() {
            return {
                text: this.alertSchaltprogrammText,
                textInfo: this.alertSchaltprogrammTextInfo,
            };
        },
        compAnschlusstypValidation() {
            const spSelected = this.compSchaltprogrammSelected;
            // ToDo: possibly use this.compSchaltprogrammValidation.length after #19938 has been clarified (and potentially additional validations have been added)
            if (!spSelected) {
                return "Bitte zuerst ein Schaltprogramm auswählen.";
            }

            if (this.anschlusstypId === null) {
                return "Bitte einen Anschlusstyp auswählen.";
            }

            // IMPORTANT: this has to stay before other checks because the latter are only relevant if an anschlusstyp is selected
            const aa = this.compAlertAnschlusstyp;
            if (aa.text.length || aa.textInfo.length) {
                let aaMessage = aa.text.length ? aa.text : "";
                aaMessage += aa.textInfo.length ? ` -> ${aa.textInfo}` : "";

                return aaMessage;
            }

            const selectedProgramNumber = parseInt(spSelected?.text);
            let at; // anschlusstyp

            // handle specific validation for the selected schaltprogramm
            switch (selectedProgramNumber) {
                case 89099:
                    // requirement: #19924
                    at = this.getAnschlusstypByExternalId(1);
                    return this.anschlusstypId === at?.value
                        ? ""
                        : `Ist Schaltprogramm '${spSelected.text}' ausgewählt, muss Anschlusstyp '${at?.text}' ausgewählt werden.`;
                default:
                    return "";
            }
        },
        compIsBusy() {
            return this.isSaving;
        },
        compIsSavingDelayed() {
            return this.isSavingDelayed;
        },
        compMspLastenbezeichnungValidation() {
            let selectedMspLastenbezeichnung = this.lastenBezeichnungenMsp.find(
                (lb) => lb.value === this.keyValueOptionIdOfConsumerClass
            );

            switch (this.keyValueOptionIdOfConsumerClass) {
                case null:
                case undefined:
                    return "Bitte MSP Lastenbezeichnung auswählen.";
                case constants.KEY_VALUE_OPTION_ID_OF_CONSUMERCLASS_WITH_KEIN_SCHALTPROGRAMM:
                    if (this.compSelectedSchaltprogrammKeinSchaltprogramm) {
                        // if selected schaltprogramm is 'Kein Schaltprogramm' it is allowed to select lastenbezeichnung 'Keine MSP Lastenbezeichnung'
                        return "";
                    } else {
                        return `MSP Lastenbezeichnung '${this.keyValueOptionIdOfConsumerClass} (${selectedMspLastenbezeichnung.text})' ist nicht zulässig wenn ein Schaltprogramm ausgewählt wurde. Bitte eine gültige MSP Lastenbezeichnung auswählen.`;
                    }
                default:
                    return "";
            }
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.isSaving) {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Daten werden gespeichert. Bitte warten...",
                });
            }

            if (this.compSchaltprogrammValidation.length) {
                preconditionsLocalDetails.push({ status: "OPEN", message: `Bitte Schaltprogramm auswählen.` });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Schaltprogramm ausgewählt." });
            }

            if (this.compMspLastenbezeichnungValidation.length) {
                preconditionsLocalDetails.push({ status: "OPEN", message: `Bitte MSP Lastenbezeichnung auswählen.` });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "MSP Lastenbezeichnung ausgewählt." });
            }

            if (this.compAnschlusstypValidation.length) {
                preconditionsLocalDetails.push({ status: "OPEN", message: `Bitte Anschlusstyp auswählen.` });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Anschlusstyp ausgewählt." });
            }

            return preconditionsLocalDetails;
        },
        compSchaltprogramme() {
            // ToDo: refactor this to a more robust/readable version -> also consider not manipulating the original array...
            return this.schaltprogramme
                .map((option) => {
                    const textParts = option.text.split(" - ");
                    return {
                        value: option.value,
                        text: option.text,
                        schaltprogrammTextId: parseInt(textParts[0]), // extract number from schaltprogramm.text,
                        disabled: option.disabled,
                    };
                })
                .sort((a, b) => a.schaltprogrammTextId - b.schaltprogrammTextId) // orders array by newly created schaltprogrammTextId
                .map((option) => {
                    return {
                        value: option.value,
                        text: option.text,
                        disabled: option.disabled,
                    };
                });
        },
        compSchaltprogrammSelected() {
            /**
             * returns the selected schaltprogramm (object)
             * IMPORTANT: this is a workaround due to v-model="schaltprogrammId" in the b-form-select -> ToDo: refactor
             */
            return this.schaltprogramme.find((s) => s.value === this.schaltprogrammId);
        },
        compSelectedSchaltprogrammKeinSchaltprogramm() {
            /**
             * returns true if the selected schaltprogramm is 'Kein Schaltprogramm'
             */
            return this.schaltprogrammId === this.findKvoIdOfSchaltprogrammKeinSchaltprogrammBySecondExternalId();
        },
        compSchaltprogrammValidation() {
            if (!this.schaltprogrammId) {
                return "Bitte Schaltprogramm auswählen.";
            }

            // IMPORTANT: this has to stay before check for 'this.isSecondExternalIdMissing' because the latter is only relevant if a schaltprogramm is selected
            const as = this.compAlertSchaltprogramm;
            if (as.text.length || as.textInfo.length) {
                let asMessage = as.text.length ? as.text : "";
                asMessage += as.textInfo.length ? ` -> ${as.textInfo}` : "";

                return asMessage;
            }

            if (this.isSecondExternalIdMissing) {
                const spSelected = this.compSchaltprogrammSelected;
                let spSelectedMessage =
                    "Schaltprogramm in IS-E nicht erfasst -> Bitte eine für den Rollout verantwortliche Person kontaktieren.";
                if (spSelected) {
                    spSelectedMessage += ` INFO(REtasks): kvo.id=${spSelected.keyValueOptionID} ${spSelected.secondSystem}.${spSelected.secondExternalTableName}.${spSelected.secondExternalFieldName}=${spSelected.secondExternalID}`;
                }

                return spSelectedMessage;
            }

            return "";
        },
        allowedToConnect() {
            return (
                this.schaltprogrammId !== null &&
                !this.compSelectedSchaltprogrammKeinSchaltprogramm &&
                this.keyValueOptionIdOfConsumerClass !== null
            );
        },
    },
    async mounted() {
        // ToDo: too much synchronous code here -> refactor
        await this.loadAuftragDetailsStep6();
        await this.loadSMANs();
        const pathSplit = this.$route.fullPath.split("/");
        const step = (this.currentStep = pathSplit[pathSplit.length - 1]);
        this.deviceRelaisID = this.$route.params.auftragItemId; // workaround alert
        /* KeyValueOptionID: start */
        // wait for this to finish because we need the result in the call following this one
        await keyValueOptionsAPI.get({ category: "relais", subCategory: "type" }).then((resp) => {
            const anschlusstypen = [
                {
                    value: null,
                    text: "Bitte einen Anschlusstyp wählen",
                    externalid: null,
                    disabled: true,
                },
            ];
            resp.data.forEach((s) => {
                anschlusstypen.push({
                    value: s.keyValueOptionID,
                    text: s.text,
                    externalid: s.externalID,
                    disabled: false,
                });
            });
            this.anschlusstypen = anschlusstypen;
        });
        await this.loadDeviceRelaisAndDevice();
        /* KeyValueOptionID: end */
        // wait for this to finish because we need the result in the call following this one
        await keyValueOptionsAPI.get({ category: "relais", subCategory: "program" }).then((resp) => {
            const schaltprogramme = [
                {
                    value: null,
                    text: "Bitte ein Schaltprogramm wählen",
                    keyValueOptionID: null,
                    externalid: null,
                    disabled: true,
                    parentId: null,
                    secondSystem: null,
                    secondExternalTableName: null,
                    secondExternalFieldName: null,
                    secondExternalID: null,
                },
            ];
            resp.data.forEach((s) => {
                schaltprogramme.push({
                    value: s.keyValueOptionID,
                    text: s.text,
                    keyValueOptionID: s.keyValueOptionID,
                    externalid: s.externalID,
                    // ToDo(clarify): why is s.active not used?
                    disabled: this.deviceRelais.deviceFlexibilitaets.length > 0 && s.secondExternalID == 700,
                    parentId: s.parentID, // equal to the keyValueOptionId of the subCategory 'consumerClass'
                    secondSystem: s.secondSystem,
                    secondExternalTableName: s.secondExternalTableName,
                    secondExternalFieldName: s.secondExternalFieldName,
                    secondExternalID: s.secondExternalID,
                });
            });
            this.schaltprogramme = schaltprogramme;
        });

        await keyValueOptionsAPI.get({ category: "relais", subCategory: "consumerClass" }).then((resp) => {
            const lastenBezeichnungenMsp = [
                {
                    value: null,
                    text: "Bitte eine MSP Lastenbezeichnung wählen",
                    mspId: null,
                    disabled: true,
                },
                {
                    value: constants.KEY_VALUE_OPTION_ID_OF_CONSUMERCLASS_WITH_KEIN_SCHALTPROGRAMM,
                    text: "Keine MSP Lastenbezeichnung",
                    mspId: null,
                    disabled: false,
                },
            ];
            resp.data.forEach((s) => {
                lastenBezeichnungenMsp.push({
                    value: s.keyValueOptionID,
                    text: s.text,
                    mspId: s.externalID,
                    disabled: false,
                });
            });
            this.lastenBezeichnungenMsp = lastenBezeichnungenMsp;
        });

        const schaltprogrammId = this.deviceRelais.relaisProgramKeyValueOptionID;
        if (schaltprogrammId) {
            this.schaltprogrammId = schaltprogrammId;
            const schaltprogrammExists = this.schaltprogramme.find((s) => s.value === schaltprogrammId);
            if (schaltprogrammExists) {
                this.alertSchaltprogrammText = "";
                this.alertSchaltprogrammTextInfo = "";
                this.keyValueOptionIdOfConsumerClass = schaltprogrammExists.parentId;
                this.selectedLastenBezeichnungenMspId = this.getMspId(this.keyValueOptionIdOfConsumerClass);
                if (!schaltprogrammExists.secondExternalID) {
                    this.isSecondExternalIdMissing = true;
                }
            } else {
                this.alertSchaltprogrammText = `Schaltprogramm Id ${schaltprogrammId} wurde in der drop-down Liste nicht gefunden.`;
                this.alertSchaltprogrammTextInfo = `Info: businessData.net.DeviceRelais.RelaisProgramKeyValueOptionID = ${schaltprogrammId}`;
                this.schaltprogrammId = null;
            }
        }

        const anschlusstypId = this.deviceRelais.relaisTypeKeyValueOptionID;
        if (anschlusstypId) {
            this.anschlusstypId = anschlusstypId;
            const anschlusstypExists = this.anschlusstypen.find((a) => a.value === anschlusstypId);
            if (anschlusstypExists) {
                this.alertAnschlusstypText = "";
                this.alertAnschlusstypTextInfo = "";
            } else {
                this.alertAnschlusstypText = `Anschlusstyp Id ${anschlusstypId} wurde in der drop-down Liste nicht gefunden.`;
                this.alertAnschlusstypTextInfo = `Info: businessData.net.DeviceRelais.RelaisTypeKeyValueOptionID =${anschlusstypId}`;
                this.anschlusstypId = null;
            }
        }

        mspAPI.getAssetByFactoryNo(this.device.factoryNo).then((resp) => {
            // ToDo(clarify): this looks odd... -> why like this?
            // ToDo(clarify): what if the response is not 200? (e.g. 204?)
            if (resp.status === 200) {
                this.mspSmanData = resp.data;
                this.assignMspLastenBezeichnungOnLoad();
            }
        });

        // Load Auftrag Detail step 6-4-1 of current SMAN
        const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            this.deviceRelais.deviceSmartManagerID
        );
        await this.setActAuftragDetail(auftragDetail);
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        findKvoIdOfSchaltprogrammKeinSchaltprogrammBySecondExternalId() {
            /**
             * find KeyValueOption.KeyValueOptionID of Schaltprogramm 'Kein Schaltprogramm' by KeyValueOption.SecondExternalID
             * requirement: #19955 (to make sure to retrieve the correct KeyValueOption.KeyValueOptionID of 'Kein Schaltprogramm' independently of the environment)
             */

            return this.schaltprogramme.find((s) => s.secondExternalID === this.iseKeinSchaltprogramm)?.value;
        },
        getAnschlusstypByExternalId(id) {
            return this.anschlusstypen.find((a) => a.externalid === id);
        },
        async loadDeviceRelaisAndDevice() {
            await deviceRelaisAPI.getSingle(this.deviceRelaisID, { params: { includeDevices: true } }).then((resp) => {
                this.deviceRelais = resp.data;
            });
            await devicesAPI
                .getSingle(this.deviceRelais.deviceSmartManagerID, { params: { includeDeviceInfos: true } })
                .then((resp) => {
                    this.device = resp.data;
                });
        },
        // ToDo(#19949): this is called from onSchaltpgroammChange and onLastenBezeichnungChange
        async updateConnectionConditions() {
            if (
                this.allowedToConnect &&
                // fix #19948: this.compMspLastenbezeichnungValidation.length === 0 was introduced to prevent 500 errors
                //   in the backend (because an invalid field combination was selected and sent to the backend)
                // fix #19949: only allow saving if there are no lastenbezeichnung validation errors
                this.compMspLastenbezeichnungValidation.length === 0
            ) {
                this.handleSaving();
                await this.loadDeviceRelaisAndDevice();
            }
        },
        async loadAuftragDetailsStep6() {
            this.auftragDetails6 = [];
            this.auftragDetails6 = await auftragDetailsAPI.get({
                auftragID: this.$route.params.orderId,
                devicePoolID: this.$route.params.devicePoolId,
                actionGroupSequence: 6,
            });
        },
        async loadSMANs() {
            // ToDo: this is bad/confusing code -> just have a look at what happens to "this.devicesSmanWithRelais" -> refactor
            this.devicesSman = [];
            for (const auftragDetail of this.auftragDetails6) {
                // SMAN to Einbau
                const sman = (await devicesAPI.getSingle(auftragDetail.deviceID, { includeDeviceInfos: true })).data;
                // skip sman created by the algorithm and not yet activated. To be improved
                if (moment(sman.fromDate).isAfter(moment().startOf("day"))) {
                    // ToDo(clarify): why console.log this?
                    console.log(`SMAN ${sman.deviceID} is not yet activated, hiding`);
                } else {
                    this.devicesSman.push(sman);
                }
            }
            const devicesSmanWithRelais = [];
            const relaisPromises = [];
            this.devicesSman.forEach((sman) => {
                const relaisPromise = deviceRelaisAPI
                    .get({ deviceSmartManagerID: sman.deviceID, includeDevices: true })
                    .then((resp) => {
                        sman.cstRelais = resp.data;
                        sman.mspData = null;
                        devicesSmanWithRelais.push(sman);
                    });
                relaisPromises.push(relaisPromise);
            });
            await Promise.all(relaisPromises).finally(() => {
                this.devicesSmanWithRelais = devicesSmanWithRelais;
            });
            // Order SMANs
            this.devicesSmanWithRelais = _.orderBy(this.devicesSmanWithRelais, ["deviceID"], ["desc"]);

            this.devicesSmanWithRelais = this.devicesSmanWithRelais.find((obj) =>
                obj.cstRelais.some((relais) => relais.deviceRelaisID === parseInt(this.$route.params.auftragItemId))
            );

            if (this.devicesSmanWithRelais?.factoryNo) {
                await mspAPI.getSmartManagerByFactoryNo(this.devicesSmanWithRelais.factoryNo).then((resp) => {
                    // ToDo(clarify): this looks odd... -> why like this?
                    // ToDo(clarify): what if the response is not 200? (e.g. 204?)
                    if (resp.status === 200) {
                        this.devicesSmanWithRelais.mspData = resp.data;
                    }
                });
            }
        },
        handleSaving() {
            // ToDo(clarify): looks pretty much the same as onClickErledigt...
            this.isSaving = true;
            this.isSavingDelayed = true;
            const payload = [
                {
                    op: "replace",
                    path: "/relaisProgramKeyValueOptionID",
                    value: this.schaltprogrammId,
                },
                {
                    op: "replace",
                    path: "/relaisTypeKeyValueOptionID",
                    value: this.anschlusstypId,
                },
                {
                    op: "replace",
                    path: "/relaisConsumerClassKeyValueOptionID",
                    value:
                        this.keyValueOptionIdOfConsumerClass ===
                        constants.KEY_VALUE_OPTION_ID_OF_CONSUMERCLASS_WITH_KEIN_SCHALTPROGRAMM
                            ? null
                            : this.keyValueOptionIdOfConsumerClass, // #20007
                },
            ];
            deviceRelaisAPI
                .patch(this.deviceRelaisID, payload)
                .then(() => {
                    const payloadAuftragDetail = [
                        {
                            op: "replace",
                            path: "/status",
                            value: 200,
                        },
                    ];
                    auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail);
                })
                .finally(() => {
                    this.isSaving = false;
                    setTimeout(() => {
                        this.isSavingDelayed = false;
                    }, 2000);
                });

            var myestid_schalt = 0;
            var myestid_anschlus = 0;
            for (var i = 0; i < this.schaltprogramme.length; i++) {
                if (this.schaltprogramme[i].value === this.schaltprogrammId) {
                    myestid_schalt = this.schaltprogramme[i].externalid;
                }
            }
            for (var j = 0; j < this.anschlusstypen.length; j++) {
                if (this.anschlusstypen[j].value === this.anschlusstypId) {
                    myestid_anschlus = this.anschlusstypen[j].externalid;
                }
            }
            var relaisInd = parseInt(this.deviceRelais.relaisNumber);
            this.mspSmanData.switchConfigurations[relaisInd].switchingTableId = myestid_schalt;
            this.mspSmanData.switchConfigurations[relaisInd].isNormallyClosed = myestid_anschlus !== 0;
            this.mspSmanData.switchConfigurations[relaisInd].consumerClassId = this.selectedLastenBezeichnungenMspId;
            this.mspSmanData.switchConfigurations[relaisInd].name = this.getBezeichnungKundeForMsp();

            mspAPI.updateByFactoryNo(this.device.factoryNo, this.mspSmanData);
        },
        onClickErledigt() {
            // ToDo(clarify): looks pretty much the same as handleSaving...
            this.isSaving = true;
            this.isSavingDelayed = true;
            const payload = [
                {
                    op: "replace",
                    path: "/relaisProgramKeyValueOptionID",
                    value: this.schaltprogrammId,
                },
                {
                    op: "replace",
                    path: "/relaisTypeKeyValueOptionID",
                    value: this.anschlusstypId,
                },
                {
                    op: "replace",
                    path: "/relaisConsumerClassKeyValueOptionID",
                    // ToDo(clarify): this looks confusing...
                    // If 'Kein Schaltprogramm' has been selected, the relaisConsumerClassKeyValueOptionID defaults to null.
                    // However, it can be overridden. This check ensures the relaisConsumerClassKeyValueOptionID is appropriately set.
                    value:
                        this.keyValueOptionIdOfConsumerClass ===
                        constants.KEY_VALUE_OPTION_ID_OF_CONSUMERCLASS_WITH_KEIN_SCHALTPROGRAMM
                            ? null
                            : this.keyValueOptionIdOfConsumerClass,
                },
            ];
            deviceRelaisAPI.patch(this.deviceRelaisID, payload).then(() => {
                const payloadAuftragDetail = [
                    {
                        op: "replace",
                        path: "/status",
                        value: 200,
                    },
                ];
                auftragDetailsAPI
                    .patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail)
                    .then(() => {
                        // on SUCCESS, navigate to next step
                        this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                    })
                    .finally(() => {
                        this.isSaving = false;
                        setTimeout(() => {
                            this.isSavingDelayed = false;
                        }, 2000);
                    });
            });
            // calling put /msp/asset to save data in msp
            // find externalid for schaltprogrammId and anschlusstypId
            var myestid_schalt = 0;
            var myestid_anschlus = 0;
            for (var i = 0; i < this.schaltprogramme.length; i++) {
                if (this.schaltprogramme[i].value === this.schaltprogrammId) {
                    myestid_schalt = this.schaltprogramme[i].externalid;
                }
            }
            for (var j = 0; j < this.anschlusstypen.length; j++) {
                if (this.anschlusstypen[j].value === this.anschlusstypId) {
                    myestid_anschlus = this.anschlusstypen[j].externalid;
                }
            }
            var relaisInd = parseInt(this.deviceRelais.relaisNumber);
            this.mspSmanData.switchConfigurations[relaisInd].switchingTableId = myestid_schalt;
            this.mspSmanData.switchConfigurations[relaisInd].isNormallyClosed = myestid_anschlus !== 0;
            this.mspSmanData.switchConfigurations[relaisInd].consumerClassId = this.selectedLastenBezeichnungenMspId;
            this.mspSmanData.switchConfigurations[relaisInd].name = this.getBezeichnungKundeForMsp();

            mspAPI.updateByFactoryNo(this.device.factoryNo, this.mspSmanData);
        },
        onClickNext(step) {
            // do whatever is necessary before moving to next step
            // on SUCCESS, navigate to next step
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            // do whatever is necessary before moving to previous step
            // on SUCCESS, navigate to previous step
            this.navigateToStepPrevious = step;
        },
        // ToDo: this looks super hacky -> refactor
        onSchaltpgroammChange(schaltprogrammId) {
            var foundSchaltProgramm = this.schaltprogramme.find((s) => s.value === schaltprogrammId);
            this.keyValueOptionIdOfConsumerClass = foundSchaltProgramm?.parentId;
            if (
                !this.keyValueOptionIdOfConsumerClass &&
                foundSchaltProgramm?.secondExternalID === this.iseKeinSchaltprogramm
            ) {
                this.keyValueOptionIdOfConsumerClass =
                    constants.KEY_VALUE_OPTION_ID_OF_CONSUMERCLASS_WITH_KEIN_SCHALTPROGRAMM;
            }
            this.selectedLastenBezeichnungenMspId = this.getMspId(this.keyValueOptionIdOfConsumerClass);
            if (!!foundSchaltProgramm?.value && !foundSchaltProgramm?.secondExternalID) {
                this.isSecondExternalIdMissing = true;
            } else {
                this.isSecondExternalIdMissing = false;
            }
            // ToDo(clarify): why is this done on change?
            //   -> IMPORTANT: updateConnectionConditions() is potentially saving data to MSP
            this.updateConnectionConditions();
        },
        onLastenBezeichnungChange(keyValueOptionConsumerClassId) {
            this.selectedLastenBezeichnungenMspId = this.getMspId(keyValueOptionConsumerClassId);
            // ToDo(clarify): why is this done on change?
            //   -> IMPORTANT: updateConnectionConditions() is potentially saving data to MSP
            this.updateConnectionConditions();
        },
        getMspId(keyValueOptionConsumerClassId) {
            return this.lastenBezeichnungenMsp.find((kvo) => kvo.value === keyValueOptionConsumerClassId)?.mspId;
        },
        assignMspLastenBezeichnungOnLoad() {
            if (this.deviceRelais) {
                var relaisIndex = parseInt(this.deviceRelais.relaisNumber);
                if (
                    this.mspSmanData &&
                    this.mspSmanData.switchConfigurations &&
                    this.mspSmanData.switchConfigurations.length >= relaisIndex &&
                    this.mspSmanData.switchConfigurations[relaisIndex]
                ) {
                    var relais = this.mspSmanData.switchConfigurations[relaisIndex];
                    //Schaltprogramme
                    this.schaltprogrammId = this.schaltprogramme.find(
                        (kvo) => kvo.externalid === relais.switchingTableId
                    )?.value;
                    // MSP Lastenbezeichnung
                    this.selectedLastenBezeichnungenMspId = relais.consumerClassId;
                    this.keyValueOptionIdOfConsumerClass = this.lastenBezeichnungenMsp.find(
                        (kvo) => kvo.mspId === this.selectedLastenBezeichnungenMspId
                    )?.value;
                    if (this.isSelectedProgramKeinSchaltProgramm() && !this.keyValueOptionIdOfConsumerClass) {
                        this.keyValueOptionIdOfConsumerClass =
                            constants.KEY_VALUE_OPTION_ID_OF_CONSUMERCLASS_WITH_KEIN_SCHALTPROGRAMM;
                    }
                    //Relais Anschlusstyp
                    this.anschlusstypId = this.anschlusstypen.find(
                        (kvo) => kvo.externalid == relais.isNormallyClosed
                    )?.value;
                }
            }
        },
        getBezeichnungKundeForMsp() {
            this.lastenBezeichnungenMsp.find((kvo) => kvo.value === this.keyValueOptionIdOfConsumerClass)?.text;
        },
        isSelectedProgramKeinSchaltProgramm() {
            return (
                this.schaltprogramme.find((s) => s.value === this.schaltprogrammId)?.secondExternalID ===
                this.iseKeinSchaltprogramm
            );
        },
    },
};
</script>
