<template>
    <div class="d-flex flex-column mt-2 mb-2" v-if="mainDevice">
        <div v-if="standortBezuegeranlage && !compIsDeviceObjectLocationSameAsDevicePoolAddress" class="d-flex mb-1">
            <b>{{ deviceObjectLocation }}</b>
        </div>
        <div>
            <div class="meter-container ">
                <div class=" d-flex " style="height: 68px;">
                    <div class=" d-flex flex-column " style="width: 85%">
                        <div class=" d-flex ">
                            <div class="left-icon-container p-1">
                                <img
                                    src="../../assets/meter-icon.png"
                                    alt="Meter Icon"
                                    style="width: 30px; height: 35px;"
                                    v-if="!categoryWithRelatedDeviceInfo.includes(this.category)"
                                />
                                <img
                                    src="../../assets/smartmeter-icon.png"
                                    alt="Smartmeter Icon"
                                    style="width: 30px; height: 35px;"
                                    v-if="categoryWithRelatedDeviceInfo.includes(this.category)"
                                />
                            </div>
                            <div class="device-identification-container">
                                <div>
                                    <b-row class="text-left no-gutters">
                                        <b-col cols="5">Werk-Nr.:</b-col>
                                        <b-col
                                            cols="6"
                                            v-if="mainDevice?.internalNo"
                                            v-bind:style="{
                                                'font-weight': categoryWithRelatedDeviceInfo.includes(this.category)
                                                    ? 'normal'
                                                    : 'bold',
                                            }"
                                            >{{ mainDevice?.internalNo }}</b-col
                                        ><b-col v-else>-</b-col>
                                    </b-row>
                                    <b-row class="text-left no-gutters">
                                        <b-col cols="5">Fabrik-Nr.:</b-col>
                                        <b-col
                                            cols="6"
                                            v-if="mainDevice?.factoryNo"
                                            v-bind:style="{
                                                'font-weight': categoryWithRelatedDeviceInfo.includes(this.category)
                                                    ? 'bold'
                                                    : 'normal',
                                            }"
                                            >{{ mainDevice?.factoryNo }}</b-col
                                        ><b-col v-else>-</b-col>
                                    </b-row>
                                </div>
                            </div>
                        </div>
                        <b-row class="text-left no-gutters pl-1">
                            <b-col
                                v-if="mainDevice?.deviceType && mainDevice?.internalNo && mainDevice?.internalNo"
                                class="text-small grayscale-text"
                                >{{ mainDevice?.deviceType }}</b-col
                            ><b-col v-else>-</b-col>
                        </b-row>
                    </div>

                    <div class="right-icon-container p-1">
                        <div v-if="categoryWithRelatedDeviceInfo.includes(this.category) && mainDevice?.internalNo">
                            <img
                                class="grayscale"
                                src="../../assets/meter-icon-grey.png"
                                alt="Meter Icon"
                                style="width: 30px; height: 35px;"
                            />
                            <div class="toggle-container">
                                <BIconToggleOff
                                    class="h3 no-gutters m-0"
                                    v-if="!showRelatedDeviceInfo"
                                    @click="onClick()"
                                ></BIconToggleOff>
                                <BIconToggleOn
                                    class="h3 no-gutters m-0"
                                    v-if="showRelatedDeviceInfo"
                                    @click="onClick()"
                                ></BIconToggleOn>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-1">
                    <div v-if="smartManagerFactoryNo">Smartmanager: {{ smartManagerFactoryNo }}</div>
                    <div v-else></div>
                </div>
            </div>
            <div v-if="showRelatedDeviceInfo && relatedDevice">
                <div class="meter-container">
                    <div class="d-flex " style="height: 68px;">
                        <div class="d-flex flex-column" style="width: 85%">
                            <div class="d-flex">
                                <div class="left-icon-container p-1">
                                    <img
                                        src="../../assets/meter-icon-grey.png"
                                        alt="Meter Icon"
                                        style="width: 30px; height: 35px; margin-bottom:10px"
                                    />
                                </div>
                                <div class="device-identification-container">
                                    <div>
                                        <b-row class="text-left no-gutters grayscale-text">
                                            <b-col cols="5">Werk-Nr.:</b-col>
                                            <b-col
                                                cols="6"
                                                v-if="showRelatedDeviceInfo && relatedDevice?.internalNo"
                                                style="font-weight: bold"
                                                >{{ relatedDevice?.internalNo }}</b-col
                                            ><b-col v-else></b-col>
                                        </b-row>
                                        <b-row class="text-left no-gutters grayscale-text">
                                            <b-col cols="5">Fabrik-Nr.:</b-col>
                                            <b-col cols="6" v-if="showRelatedDeviceInfo && relatedDevice?.factoryNo">{{
                                                relatedDevice.factoryNo
                                            }}</b-col
                                            ><b-col v-else></b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </div>
                            <b-row class="text-left no-gutters pl-1 mt-0">
                                <b-col
                                    v-if="
                                        relatedDevice?.deviceType &&
                                            relatedDevice?.internalNo &&
                                            relatedDevice?.internalNo
                                    "
                                    class="text-small grayscale-text "
                                    >{{ relatedDevice?.deviceType }}</b-col
                                ><b-col v-else>-</b-col>
                            </b-row>
                        </div>
                        <div class="right-icon-container p-1"></div>
                    </div>
                </div>
            </div>
            <b-spinner v-if="showRelatedDeviceInfo && !relatedDevice" small />
        </div>
    </div>
</template>

<script>
import { BIconToggleOff, BIconToggleOn } from "bootstrap-vue";
import devicesAPI from "@/services/api/devices.api";

import { mapGetters } from "vuex";

export default {
    name: "ExecMeterInfo",
    components: {
        BIconToggleOff,
        BIconToggleOn,
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            showRelatedDeviceInfo: false,
            relatedDevice: null,
            categoryWithRelatedDeviceInfo: ["Abgeschlossen", "Funktionstest", "Verbinden mit Smartmanager", "Wandler"],
        };
    },
    props: {
        mainDevice: {
            type: Object,
            required: true,
        },
        order: {
            type: Object,
            required: true,
            default: null,
        },
        category: {
            type: String,
            required: true,
        },
        devicePool: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isBezuegeranlageSameAsDevicePoolAddress() {
            let devicePoolAddress = this.devicePool ? this.devicePool.address : this.mainDevice.devicePool.address;
            if (devicePoolAddress.split(" ").length > 2) {
                const devicepoolArray = devicePoolAddress
                    .replace(/\s+/g, " ")
                    .trim()
                    .split(" ");
                return devicepoolArray.every((str) => this.standortBezuegeranlage.includes(str));
            } else {
                return this.standortBezuegeranlage.includes(devicePoolAddress);
            }
        },
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        smartManagerFactoryNo() {
            const deviceInfo = this.mainDevice?.deviceInfos.find((di) => di.name === "RS485.SMAN");
            return deviceInfo ? deviceInfo?.value : null;
        },
        standortBezuegeranlage() {
            const standortAddress = this.mainDevice.deviceInfos.find((di) => di.name === "Standort");
            return standortAddress ? standortAddress.value : "";
        },
        deviceObjectLocation() {
            if (this.isBezuegeranlageSameAsDevicePoolAddress) {
                const objectStandortAddress = this.mainDevice?.deviceInfos.find((di) => di.name === "Objektstandort");
                return objectStandortAddress ? this.extractAndRemoveAddress(objectStandortAddress.value) : "";
            } else {
                const objectStandortAddress = this.mainDevice?.deviceInfos.find((di) => di.name === "Objektstandort");
                return objectStandortAddress ? objectStandortAddress.value : "";
            }
        },
        compDeviceObjectLocationNoSpace() {
            // hack to cleanup third party data #20133
            return this.deviceObjectLocation.replace(/[\s.]+/g, "");
        },
        compDevicePoolAddressNoSpace() {
            // hack to cleanup third party data #20133
            return this.mainDevice.devicePool.address.replace(/[\s.]+/g, "");
        },
        compIsDeviceObjectLocationSameAsDevicePoolAddress() {
            // hack to cleanup third party data #20133
            let similarity = false;
            if (this.compDeviceObjectLocationNoSpace !== this.compDevicePoolAddressNoSpace) {
                similarity = false;
            } else {
                similarity = true;
            }
            return similarity;
        },
    },
    methods: {
        onClick() {
            this.showRelatedDeviceInfo = !this.showRelatedDeviceInfo;
            this.$emit("showRelatedDeviceInfo", this.showRelatedDeviceInfo);
            if (this.showRelatedDeviceInfo) {
                this.fetchRelatedDeviceDetails();
            }
        },
        async fetchRelatedDeviceDetails() {
            const deviceResp = await devicesAPI.getRelatedDevice({
                id: this.mainDevice.deviceID,
                devicePoolId: this.mainDevice.devicePoolID,
                auftragId: this.order.auftragID,
                includeDeviceInfos: true,
            });
            //In this component, "related device" refers to the old replaced device,
            //since we are only fetching the data when the main device is the new installed device/ smartmeter.
            this.relatedDevice = deviceResp?.data?.oldDevice;
        },
        extractAndRemoveAddress(address) {
            let objectStandortAddressArray = address.split(", ");
            // Extract street and town using regex pattern
            const bezuegeranlageAddressArray = this.standortBezuegeranlage.split(", ");

            // Remove all entries that correspond to the address of the bezuegeranlage
            const filteredAddress = objectStandortAddressArray.filter(
                (element) => !bezuegeranlageAddressArray.includes(element)
            );

            return filteredAddress.join(", ");
        },
    },
};
</script>
<style scoped>
.text-small {
    font-size: small;
}
.meter-container {
    border-radius: 10px;
    background-color: lightgrey;
    padding: 10px;
    margin-top: 5px;
    width: 100%;
}
.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .left-icon-container {
        width: 18%;
    }
    .right-icon-container {
        width: 15%;
    }
}

.device-identification-container {
    width: 82%;
    padding-left: 4px;
}
.toggle-container {
    margin: 0px;
    display: flex;
    justify-content: center;
}
.grayscale-text {
    color: grey;
}
</style>
