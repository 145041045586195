<!-- THIS PAGE IS NEVER DISPLAYED
 The status of step 7-1-1 will be set by /unimod/raw-reads/{meterID}/process API -->
<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <b-alert class="mt-2 mb-0" show>ToDo: Add page content</b-alert>
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";

export default {
    name: "Step_7_1_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            navigateToStepNext: null,
            navigateToStepPrevious: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        await auftragDetailsAPI
            .getByOrderIdAndStep(
                this.$route.params.orderId,
                step,
                this.$route.params.devicePoolId,
                null,
                this.$route.params.auftragItemId
            )
            .then((res) => {
                this.setActAuftragDetail(res);
            });
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        onClickErledigt() {
            alert("ToDo");
        },
        onClickNext(step) {
            // ToDo: do whatever is necessary before moving to next step
            // on SUCCESS, navigate to next step
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            // ToDo: do whatever is necessary before moving to previous step
            // on SUCCESS, navigate to previous step
            this.navigateToStepPrevious = step;
        },
    },
};
</script>
