<template>
    <div id="app" class="repower">
        <Root></Root>
    </div>
</template>

<script>
import { detectIE } from "@/utils/BrowserUtils";
import Root from "./views/Root";

if (detectIE()) {
    console.error("IE detected", window.navigator.userAgent);
    window.location.href = "sorry.html";
}

export default {
    name: "App",
    components: {
        Root,
    },
};
</script>
