<!-- pie: lo step 2.4.1 si fa se c'é almeno un pericolo (amianto oppure no), lo step 2.5.1 si fa solo se almeno uno dei pericoli è aminato -->
<!-- This view call interface 9b of ISE -->
<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !$attrs.devicePool">
        <template v-if="actAuftragDetail && $attrs.devicePool">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <p class="my-2">
                Es wird eine Mitteilung an Elsibe zur Beurteilung der Gefahren gemacht. Hieraus können weiter Massnahmen
                wie eine Stichprobenkontrolle oder die Infomation des Kunden mit einem Brief betreffend der
                Asbestbelastung erfolgen.
            </p>
            <b-form-group label="Zusätzlicher Hinweis an Elsibe:">
                <b-form-textarea v-model="textareaElsibe" rows="9" placeholder="Indiv. Text" class="font-italic" />
            </b-form-group>
            <b-button class="my-3" variant="primary" @click="saveActivityOnIse" :disabled="isLoadingRemote">
                Aktivität an ISE senden
                <b-spinner v-if="isLoadingRemote" small />
            </b-button>
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";

import constants from "@/constants/constants";
import * as iseUtils from "@/utils/IseUtils";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import auftragsAPI from "@/services/api/auftrags.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import iseAPI from "@/services/api/ise.api";

export default {
    name: "Step_2_4_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
    },
    data() {
        return {
            devicePool: null, // cannot use compAttrsDevicePool because this page needs updated data
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            textareaElsibe: null,
            iseActivityID: null,
            isLoadingRemote: false,
            order: null,
        };
    },
    computed: {
        ...mapGetters({
            actAuftragDetail: "execution/storeActAuftragDetail",
            oidcUser: "oidcUser",
        }),
        compAttrsDevicePool() {
            return this.$attrs.devicePool;
        },
        compIsErledigtEnabled() {
            return (
                this.actAuftragDetail.isExecutable &&
                (this.actAuftragDetail.status === constants.auftragDetailStatus.DONE_CONDITION_1 ||
                    this.actAuftragDetail.status === constants.auftragDetailStatus.DONE_CONDITION_2 ||
                    this.iseActivityID)
            );
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.compIsErledigtEnabled) {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Lokale Vorbedingungen erfüllt." });
            } else {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Lokale Vorbedingungen nicht erfüllt." });
            }

            return preconditionsLocalDetails;
        },
        status() {
            if (!this.iseActivityID) {
                return constants.auftragDetailStatus.NOT_STARTED;
            }
            if (this.devicePool.asbestKeyValueOptionText === "Nein") {
                return constants.auftragDetailStatus.DONE_CONDITION_1; // 202 - Done (ohne Asbset)
            }
            return constants.auftragDetailStatus.DONE_CONDITION_2; // 203 - Done (mit Asbet)
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        await auftragDetailsAPI
            .getByOrderIdAndStep(this.$route.params.orderId, step, this.$route.params.devicePoolId)
            .then((res) => {
                this.setActAuftragDetail(res);
            });
        const actAdValue = JSON.parse(this.actAuftragDetail.value);
        this.devicePool = (await devicePoolsAPI.getSingle(this.$route.params.devicePoolId)).data;
        if (actAdValue) {
            this.textareaElsibe = actAdValue.textareaElsibe ? actAdValue.textareaElsibe : null;
            this.iseActivityID = actAdValue.iseActivityID ? actAdValue.iseActivityID : null;
        }
        this.order = (await auftragsAPI.getSingle(this.$route.params.orderId)).data;
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        goNext() {
            this.navigateToStepNext =
                this.status === constants.auftragDetailStatus.DONE_CONDITION_2
                    ? this.actAuftragDetail.stepNextTrue // 203 - Done (mit Asbet) --> 2-5-1
                    : this.actAuftragDetail.stepNextFalse; // 202 - Done (ohne Asbset) --> 2-6-1
        },
        async onClickErledigt() {
            await this.save(this.status);
            // 202 - Done (ohne Asbset) --> 2-6-1 (stepNextFalse)
            // 203 - Done (mit Asbet) --> 2-5-1 (stepNextTrue)
            if (this.status === constants.auftragDetailStatus.DONE_CONDITION_1) {
                // skip 2-5-1
                await auftragDetailsAPI.setAuftragDetailSkipped(
                    this.$route.params.orderId,
                    "2-5-1",
                    this.compAttrsDevicePool.devicePoolID
                );
                console.log("Skipping 2-5-1");
            }
            this.goNext();
        },
        async onClickNext() {
            await this.save(); // do not set status
            this.goNext();
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        async save(status) {
            const valueOld = this.actAuftragDetail.value;
            const valueNew = { textareaElsibe: this.textareaElsibe, iseActivityID: this.iseActivityID };
            return await auftragDetailsAPI.patchValueAndStatusOnDiff(
                this.actAuftragDetail.auftragDetailID,
                valueOld,
                valueNew,
                status
            );
        },
        composeElsibeMessage() {
            // Dirk 16.03.2022: message must be empty - Dirk (later): this message must not be empty
            let message = `Bitte entscheiden Sie anhand der erfassten Gefahren und Fotos, ob eine Stichprobenkontrolle vorgenommen werden muss und veranlassen Sie die entsprechenden Massnahmen.
- Die Gefahren wurden zuletzt geprüft am ${moment().format("DD.MM.YYYY")} durch den Monteur ${
                this.oidcUser?.given_name
            } ${this.oidcUser?.family_name}
- Sicherungsanordnung = ${
                this.devicePool.hasSingleMeterFuse ? "Einzelsicherung pro Zähler" : "Gesamtsicherung pro Tableau"
            }
- Asbest = ${this.devicePool.asbestKeyValueOptionText ?? "<ja/Verdacht/nein>"}
- Neutralleiterabschlaufung = ${this.devicePool.neutralleiterAbschlaufungKeyValueOptionText ?? "<ja/nein>"}
- Offene spannungsführende Teile = ${this.devicePool.offeneSpannnungsFuehrendeTeileKeyValueOptionText ?? "<ja/nein>"}
- Andere Gefahren = ${this.devicePool.otherDangers ?? "nein"}
Nachricht des Monteurs:

`;
            if (this.textareaElsibe && this.textareaElsibe !== "") {
                return message + this.textareaElsibe;
            } else {
                return message + "Es wurde keine Nachricht erfasst.";
            }
        },
        // Save activity to ISE and return activityID
        async saveActivityOnIse() {
            this.isLoadingRemote = true;

            try {
                const iseActivityTypeID = iseUtils.getActivityTypeIDFromAreaID(this.order?.hak?.iseAreaID);
                if (iseActivityTypeID) {
                    const payload = {
                        activityTypeID: iseActivityTypeID,
                        referenceNumber: this.compAttrsDevicePool.iseDevicePoolID, // iseID of the devicePool
                        startDate: this.order.fromDate,
                        endDate: this.order.toDate,
                        message: this.composeElsibeMessage(),
                    };
                    const isDev = ["development", "integration"].includes(constants.env.NAME);
                    if (!isDev) {
                        const response = await iseAPI.postActivity(payload);
                        this.iseActivityID = response.data.activityID;
                        console.log("activityID", response.data.activityID, "status", this.status);
                        await this.save(this.status);
                    } else {
                        console.log(`Skipping call to ISE - Env: ${constants.env.NAME}`);
                        return 1;
                    }
                } else {
                    this.$bvToast.toast(
                        `HAK ${this.order?.hak?.hakID} hat eine ungültige iseAreaID ${this.order?.hak?.iseAreaID}`,
                        {
                            title: "Fehler",
                            variant: "danger",
                            toaster: "b-toaster-bottom-right",
                            noAutoHide: true,
                            appendToast: true,
                        }
                    );
                }
            } finally {
                this.isLoadingRemote = false;
            }
        },
    },
};
</script>
