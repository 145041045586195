import { httpClient } from "@/services/api/client";

const endpoint = "/net/device-infos";

export default {
    // ********************
    // default REST methods
    // ********************
    delete(id) {
        return httpClient.delete(`${endpoint}/${id}`);
    },
    get(params) {
        return httpClient
            .get(endpoint, {
                params: params,
            })
            .then((resp) => {
                // ToDo(#19895): return the promise -> refactor where used
                return resp.data;
            });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload).then((resp) => {
            // ToDo(#19894): return the promise -> refactor where used
            return resp.data;
        });
    },
    post(payload) {
        return httpClient.post(endpoint, payload);
    },
    // **************
    // custom methods
    // **************
};
