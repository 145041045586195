import { httpClient } from "@/services/api/client";

const endpoint = "/net/device-flexibilitaets";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, { params: params });
    },
    getSingle(id, params = null) {
        return httpClient.get(`${endpoint}/${id}`, { params: params });
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload);
    },
    post(payload) {
        return httpClient.post(endpoint, payload);
    },
    // **************
    // custom methods
    // **************
};
