<template>
    <b-row class="mt-4">
        <b-col class="text-center">
            <h1>User logged out</h1>
            <p>Please close your browser window.</p>
            <a href="/">Or click here to login again.</a>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "LoggedOut",
};
</script>

<style scoped></style>
