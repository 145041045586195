import { httpClient } from "@/services/api/client";

const endpoint = "/zfa/job-read";
const endpointDeviceMasterData = "/zfa/device-masterdata";

export default {
    // ********************
    // default REST methods
    // ********************
    get(jobID) {
        return httpClient.get(`${endpoint}/${jobID}`);
    },
    post(factoryNo) {
        return httpClient.post(`${endpoint}/${factoryNo}`);
    },

    // **************
    // custom methods
    // **************
    getDeviceMasterData(factoryNo) {
        return httpClient.get(`${endpointDeviceMasterData}/${factoryNo}`);
    },
};
