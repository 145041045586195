<template>
    <Main>
        <Box headerText="Flexibilitäten" :isLoading="isLoading" noBody>
            <template v-if="!isLoading">
                <BoxBody v-if="!auftragDetails511.length">
                    Keine Flexibilitäten erfasst.
                </BoxBody>
                <BoxBody
                    v-else
                    v-for="(od, od_i) in auftragDetails511"
                    :key="`od_${od_i}`"
                    :variant="statusVariant(od.status)"
                >
                    <div>
                        <strong>{{ od.device?.anlagetypID ? od.device.anlagetyp.text : "N/A" }}</strong>
                        <span v-if="od.device?.anlagetyp && !od.device.anlagetyp.active"> [inaktiv]</span>
                    </div>
                    <div class="d-flex">
                        <div>
                            <div>Status: {{ getStatusText(od.status) }}</div>
                            <div>Foto aufgenommen: {{ od.fotoAufgenommen ? "ja" : "nein" }}</div>
                            <div>
                                Leistung (Bezug): {{ od.device.leistungBezug ? `${od.device.leistungBezug} kW` : "" }}
                            </div>
                            <div>
                                Leistung (Produktion):
                                {{ od.device.leistungProduktion ? `${od.device.leistungProduktion} kW` : "" }}
                            </div>
                            <div>Zähler: {{ od.device.deviceMeterID ? od.device.deviceMeter.factoryNo : "N/A" }}</div>
                            <div>
                                Steuergerät:
                                {{
                                    od.device.deviceRelaisID && od.device.deviceRelais.deviceSmartManager.factoryNo
                                        ? od.device.deviceRelais.deviceSmartManager.factoryNo
                                        : "nicht verbunden"
                                }}
                            </div>
                            <div v-if="od.device.deviceRelaisID">
                                Relais:
                                {{ od.device.deviceRelais.relaisNumber + 1 }}
                            </div>
                            <div v-if="od.device.deviceRelaisID">
                                <span class="font-italic pl-2">{{
                                    od.device.deviceRelaisID && od.device.deviceRelais.relaisProgramText
                                        ? od.device.deviceRelais.relaisProgramText
                                        : "N/A"
                                }}</span>
                            </div>
                            <div>
                                Gültig ab: {{ od.device.deviceFlexibilitaet.fromDate | formatDate("DD.MM.YYYY") }}
                            </div>
                        </div>
                        <div class="ml-auto" v-if="currentStep">
                            <!-- Needs to be adjusted if Flexibilities are used on another step / page -->
                            <b-button
                                variant="primary"
                                class="mr-2 mb-3"
                                size="sm"
                                v-on:click="onClickEdit(od.deviceID)"
                            >
                                Ändern
                            </b-button>
                            <b-button
                                :variant="
                                    (isFlexConnectedToRelais(od.device?.deviceRelais?.deviceRelaisID) ||
                                        (relaisProgramSecondExternalID !== 700 &&
                                            relaisProgramSecondExternalID != null)) &&
                                    allowedToConnect
                                        ? 'primary'
                                        : 'secondary'
                                "
                                class="mr-2 mb-3"
                                size="sm"
                                v-on:click="connectFlexRelais(od.device?.deviceFlexibilitaetID)"
                                :disabled="
                                    isFlexConnectedToRelais(od.device?.deviceRelais?.deviceRelaisID) ||
                                        relaisProgramSecondExternalID === 700 ||
                                        relaisProgramSecondExternalID == null ||
                                        !allowedToConnect
                                "
                            >
                                {{
                                    isFlexConnectedToRelais(od.device?.deviceRelais?.deviceRelaisID)
                                        ? "Verbunden"
                                        : "Verbinden"
                                }}
                            </b-button>
                            <b-button
                                :variant="
                                    isFlexConnectedToRelais(od.device?.deviceRelais?.deviceRelaisID) ||
                                    (relaisProgramSecondExternalID !== 700 && relaisProgramSecondExternalID != null)
                                        ? 'primary'
                                        : 'secondary'
                                "
                                class="mr-2 mb-3"
                                size="sm"
                                v-on:click="disconnectFlexRelais(od.device?.deviceFlexibilitaetID)"
                                :disabled="!isFlexConnectedToRelais(od.device?.deviceRelais?.deviceRelaisID)"
                            >
                                {{
                                    isFlexConnectedToRelais(od.device?.deviceRelais?.deviceRelaisID)
                                        ? "Trennen"
                                        : "Getrennt"
                                }}
                            </b-button>
                        </div>
                        <div class="ml-auto" v-else>
                            <b-button @click="onClickEdit(od.deviceID)">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" size="lg" />
                            </b-button>
                        </div>
                    </div>
                </BoxBody>
            </template>
        </Box>
        <div class="d-flex justify-content-end py-3">
            <b-button v-if="!isLoading" @click="onClickFlexibilitaetAdd" :disabled="disabledFlexibilitaetAdd">
                <font-awesome-icon :icon="['fas', 'plus']" size="lg" /> Weitere Flexibilität erfassen
            </b-button>
        </div>
        <!-- #19291 hide checkbox in currentStep=6-4-1: display only in "ExecutionFlexibilities" when currentStep is null -->
        <b-form-checkbox v-if="!currentStep" v-model="areFlexibilitiesConfigured">
            Es hat keine (weiteren) Flexibilitäten, die über einen Zähler an diesem Gerätestandort angeschlossen sind.
        </b-form-checkbox>
    </Main>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import constants from "@/constants/constants";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import auftragItemsAPI from "@/services/api/auftragItems.api";

import deviceFlexibilitaetsAPI from "@/services/api/deviceFlexibilitaets.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import devicesAPI from "@/services/api/devices.api";
import assetsAPI from "@/services/api/assets.api";

export default {
    name: "ExecFlexibilities",
    props: {
        order: {
            type: Object,
            default: null,
        },
        relaisNo: {
            type: Number,
            default: null,
        },
        currentStep: {
            type: String,
            default: null,
        },
        deviceRelaisID: {
            type: Number,
            default: null,
        },
        relaisProgramSecondExternalID: {
            type: Number,
            default: null,
        },
        allowedToConnect: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        areFlexibilitiesConfigured(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.setAreFlexibilitiesConfigured();
            }
        },
    },
    data() {
        return {
            auftragDetails511: [],
            areFlexibilitiesConfigured: false,
            auftragDetail911ID: null,
            deviceFlexibilitaets: [],
            deviceFlexibilitaet: [],
            disabledFlexibilitaetAdd: false,
            isLoading: true,
            newDeviceId: null,
            devicePool: null,
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            this.auftragDetails511 = await this.fetchAuftragDetails().then((resp) =>
                resp.filter((ad) => ad.deviceID !== null)
            );
            await auftragDetailsAPI
                .getByOrderIdAndStep(
                    this.$route.params.orderId,
                    "9-1-1",
                    this.$route.params.devicePoolId,
                    null,
                    null,
                    true
                )
                .then((resp) => {
                    this.auftragDetail911ID = resp[0]?.auftragDetailID;
                    const parsedJSON = JSON.parse(resp[0].value);
                    this.areFlexibilitiesConfigured = parsedJSON?.areFlexibilitiesConfigured ?? false;
                });
            for (const ad of this.auftragDetails511) {
                // Set fotoAufgenommen
                ad.fotoAufgenommen = await this.hasAssets(ad.deviceID, ad.auftragDetailID);
                // Load flexibilitaets
                if (ad.deviceID) {
                    const deviceFlex = (await deviceFlexibilitaetsAPI.getSingle(ad.deviceID, { includeDevices: true }))
                        .data;
                    this.deviceFlexibilitaets.push(deviceFlex);
                    ad.device = deviceFlex ? _.clone(deviceFlex) : ad.device;
                } else {
                    console.warn(`AuftragDetail ${ad.auftragDetailID} has no deviceID`); // should never happend
                }
            }
            this.devicePool = await devicePoolsAPI.getSingle(this.$route.params.devicePoolId).data;
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        async fetchAuftragDetails() {
            try {
                return await auftragDetailsAPI.get({
                    auftragID: this.$route.params.orderId,
                    devicePoolID: this.$route.params.devicePoolId,
                    actionGroupSequence: 5,
                    actionItemSequence: 1,
                    actionDetailSequence: 1,
                });
            } catch (error) {
                const errorMessage = "Fehler beim Abrufen von auftragDetails";
                this.$bvToast.toast(errorMessage, {
                    title: "Fehler",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: true,
                    appendToast: true,
                });
                throw SyntaxError(errorMessage);
            }
        },
        async hasAssets(deviceID, auftragDetailID) {
            if (!deviceID || !auftragDetailID) {
                return false; // should never happend
            }
            const resp = assetsAPI.get({
                app: "rellout",
                entity: "device",
                entityId: deviceID,
                refEntity: "auftragdetail",
                refEntityId: auftragDetailID,
            });
            return resp.data?.length > 0;
        },
        getStatusText(statusCode) {
            switch (statusCode) {
                case constants.auftragDetailStatus.DONE_REPEATABLE:
                    return "Geprüft";
                case constants.auftragDetailStatus.DONE_CONDITION_1:
                    return "Beendet";
                default:
                    return "Zu prüfen";
            }
        },
        statusVariant(statusCode) {
            switch (statusCode) {
                case constants.auftragDetailStatus.IN_PROGRESS:
                    return "warning";
                case constants.auftragDetailStatus.DONE_REPEATABLE:
                    return "success";
                case constants.auftragDetailStatus.DONE_CONDITION_1:
                    return "light";
                default:
                    return "";
            }
        },
        onClickEdit(deviceId) {
            this.$emit("save-values");
            this.$router.push({
                name: "step-5-1-1",
                params: {
                    orderId: this.$route.params.orderId,
                    devicePoolId: this.$route.params.devicePoolId,
                    deviceId,
                },
            });
        },
        setAreFlexibilitiesConfigured() {
            const payload = [
                {
                    op: "replace",
                    path: "/value",
                    value: JSON.stringify({
                        areFlexibilitiesConfigured: this.areFlexibilitiesConfigured,
                    }),
                },
            ];
            auftragDetailsAPI.patch(this.auftragDetail911ID, payload);
        },
        async onClickFlexibilitaetAdd() {
            this.disabledFlexibilitaetAdd = true;
            // note: payloads based on example gea (e-mail Tue 15.02.2022 12:23)

            const payloadDevicesAPI = {
                devicePoolID: this.$route.params.devicePoolId,
                type: "Flexibilität",
                deviceSuffix: null,
                deviceType: null,
                description: null,
                // format: 2022-01-01T00:00:00
                fromDate: moment().format("YYYY-MM-DD[T00:00:00]"),
                toDate: "2050-12-31T00:00:00",
            };

            await devicesAPI.post(payloadDevicesAPI).then((resp) => {
                this.newDeviceId = resp.data.deviceID;
            });

            // note: payloads based on example gea (e-mail Tue 15.02.2022 12:23)
            const payloadDeviceFlexibilitaet = {
                // use previously created deviceId
                deviceFlexibilitaetID: this.newDeviceId,
            };

            // create DeviceFlexibilitaet
            await deviceFlexibilitaetsAPI.post(payloadDeviceFlexibilitaet);

            // note: payloads based on example gea (e-mail Tue 15.02.2022 12:23)
            auftragItemsAPI
                .get({
                    auftragID: this.$route.params.orderId,
                    devicePoolID: this.$route.params.devicePoolId,
                    actionGroupID: 5,
                    actionItemID: 10,
                })
                .then((resp) => {
                    if (resp.data.length > 0) {
                        const auftragItemId = resp.data[0].auftragItemID;
                        const payloadAuftragDetails = {
                            auftragItemID: auftragItemId,
                            actionDetailID: constants.auftragDetail.AUFTRAG_DETAIL_11,
                            status: 0,
                            // use previously created deviceId
                            deviceID: this.newDeviceId,
                        };
                        // create auftragDetail
                        auftragDetailsAPI.post(payloadAuftragDetails).then(() => {
                            // if succesful, navigate to step 5-1-1
                            this.$router.push({
                                name: "step-5-1-1",
                                params: {
                                    orderId: this.$route.params.orderId,
                                    devicePoolId: this.$route.params.devicePoolId,
                                    // use previously created deviceId
                                    deviceId: this.newDeviceId,
                                },
                            });
                        });
                    }
                })
                .finally(async () => {
                    // ToDo: re-enable button
                    await new Promise((res) => setTimeout(res, 2000));
                    this.disabledFlexibilitaetAdd = false;
                });
        },
        isFlexConnectedToRelais(deviceRelaisID) {
            return this.deviceRelaisID === deviceRelaisID;
        },
        async updateFlexRelais(deviceID, relaisID = null) {
            const payload = [
                {
                    op: "replace",
                    path: "/deviceRelaisID",
                    value: relaisID,
                },
            ];

            this.isDisabledBtnSave = true;

            try {
                await deviceFlexibilitaetsAPI.patch(deviceID, payload);

                this.$bvToast.toast(`Daten gespeichert.`, {
                    title: "Success",
                    variant: "success",
                    toaster: "b-toaster-bottom-right",
                    autoHideDelay: 3000,
                    appendToast: true,
                });
            } finally {
                try {
                    this.isLoading = true;
                    this.deviceFlexibilitaets = [];
                    for (const ad of this.auftragDetails511) {
                        if (ad.deviceID) {
                            const deviceFlex = (
                                await deviceFlexibilitaetsAPI.getSingle(ad.deviceID, { includeDevices: true })
                            ).data;
                            this.deviceFlexibilitaets.push(deviceFlex);
                            ad.device = deviceFlex ? _.clone(deviceFlex) : ad.device;
                        } else {
                            console.warn(`AuftragDetail ${ad.auftragDetailID} has no deviceID`); // should never happend
                        }
                        if (!relaisID) {
                            if (
                                ad.statusCode !== constants.auftragDetailStatus.NOT_STARTED &&
                                ad.device.deviceRelaisID === null
                            ) {
                                ad.statusCode = constants.auftragDetailStatus.NOT_STARTED;
                            }
                        }
                    }
                } finally {
                    this.isLoading = false;
                }
            }
        },

        async connectFlexRelais(deviceID) {
            this.$emit("save-values");
            await this.updateFlexRelais(deviceID, parseInt(this.deviceRelaisID, 10));
        },

        async disconnectFlexRelais(deviceID) {
            this.$emit("save-values");
            await this.updateFlexRelais(deviceID);
        },
    },
};
</script>
