/**
 * Return true if in both obj1 and obj2 all the properties with the same name have the same values
 * See also CommonUtils.spec.js
 */
export function arePropertiesWithSameNameEqual(obj1, obj2) {
    for (const p in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj1, p) && Object.prototype.hasOwnProperty.call(obj2, p)) {
            if (obj1[p] !== obj2[p]) {
                return false;
            }
        }
    }
    return true;
}

export function isEmptyArray(arr) {
    return !arr || arr.length === 0;
}
