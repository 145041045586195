<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <ExecConnectSmanInMsp
                :deviceId="actAuftragDetail.deviceID"
                :hakId="order.hak.hakID"
                :disabled="!actAuftragDetail.isExecutable"
                @smanActivateResp="onSmanActivateResp"
            />
            <ExecSectionErledigt
                :isBusy="false"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecConnectSmanInMsp from "@/components/execution/ExecConnectSmanInMsp";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";

export default {
    name: "Step_6_1_3",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecConnectSmanInMsp,
        ExecSectionErledigt,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            smanActivateResp: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.actAuftragDetail.status === 202) {
                // step has been completed -> "erledigt" is disabled
                preconditionsLocalDetails.push({ status: "OPEN", message: "Schritt wurde bereits abgeschlossen." });
            } else {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Schritt wurde noch nicht abgeschlossen.",
                });
            }

            if (this.smanActivateResp === null) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "SMAN nicht aktiviert." });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "SMAN aktiviert." });
            }

            return preconditionsLocalDetails;
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        // Get the auftragDetail
        const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId
        );
        // Set the auftragDetail
        await this.setActAuftragDetail(auftragDetail);
        // this is handled here because deviceID changes in step 6-1-2 (coming from step 6-1-1).
        // gae 20230316: It does not work in case of multiple SMAN in the devicePool
        /*if (parseInt(this.$route.params.deviceId) !== parseInt(this.actAuftragDetail.deviceID)) {
            await this.$router.push({
                name: "step-6-1-3",
                params: {
                    deviceId: this.actAuftragDetail.deviceID,
                    devicePoolId: this.$route.params.devicePoolId,
                    orderId: this.$route.params.orderId,
                },
            });
        }*/
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        onClickErledigt() {
            let statusCompleted = 500; // 500 = nein
            if (this.smanActivateResp.status === 200) {
                statusCompleted = 202; // 202 - ja
            }

            const payloadAuftragDetail = [
                {
                    op: "replace",
                    path: "/status",
                    value: statusCompleted,
                },
            ];
            auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail).then(() => {
                // on SUCCESS, navigate to next step
                this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            });
        },
        onClickNext(step) {
            // do whatever is necessary before moving to next step
            // on SUCCESS, navigate to next step
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            // do whatever is necessary before moving to previous step
            // on SUCCESS, navigate to previous step
            this.navigateToStepPrevious = step;
        },
        onSmanActivateResp(val) {
            this.smanActivateResp = val;
        },
    },
};
</script>
