<template>
    <div>
        <div class="d-flex">
            <div v-if="showAbbrechen">
                <b-button @click="onClickAbbrechen" variant="secondary">
                    Abbrechen
                </b-button>
            </div>
            <b-button-group class="ml-auto">
                <template
                    v-if="
                        !isExecutable ||
                            compPreconditionsLocalDetailsNOK.length ||
                            preconditionsLocalDetails.length === 0
                    "
                >
                    <!-- hack to have rounded left corners of button group in case the 1st button of the group is not displayed -->
                    <b-button v-b-toggle.is-executable-details variant="primary">
                        <font-awesome-icon :icon="['fas', compIsExecutableInfoIcon]" />
                    </b-button>
                </template>
                <b-button @click="onClickErledigt" :disabled="compDisabled" variant="primary">
                    Erledigt
                    <b-spinner v-if="compIsBusy" small />
                </b-button>
            </b-button-group>
        </div>
        <b-collapse id="is-executable-details" class="w-100 mt-1">
            <b-alert
                v-show="preconditionsLocalDetails.length === 0"
                class="mb-0 px-2 py-1 font-italic small"
                variant="danger"
                show
            >
                <div>
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                    <b>
                        Vorbedingungen (
                        <span title="lokal"><font-awesome-icon :icon="['fas', 'helmet-safety']"/></span>):
                    </b>
                </div>
                <div>
                    In diesem Schritt werden keine(!) lokalen Vorbedingungen geprüft.
                </div>
            </b-alert>
            <b-alert
                v-show="!isExecutable || compPreconditionsLocalDetailsNOK.length"
                class="mb-0 px-2 py-1 font-italic small"
                variant="warning"
                show
            >
                <div v-show="!isExecutable">
                    <div>
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                        <b>
                            Vorbedingungen NOK (
                            <span title="Konfiguration"><font-awesome-icon :icon="['fas', 'gears']"/></span>):
                        </b>
                    </div>
                    <span v-if="isExecutableDetails.length">
                        {{ isExecutableDetails }}
                    </span>
                    <span v-else>
                        Keine Informationen verfügbar (ad.isExecutableDetails="").
                    </span>
                </div>
                <div v-show="isExecutable && compPreconditionsLocalDetailsNOK.length">
                    <div>
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                        <b>
                            Vorbedingungen NOK (
                            <span title="lokal"><font-awesome-icon :icon="['fas', 'helmet-safety']"/></span>):
                        </b>
                    </div>
                    <ul class="pl-3 mb-0">
                        <li v-for="(p, i) in compPreconditionsLocalDetailsNOK" :key="i">{{ p.message }}</li>
                    </ul>
                </div>
            </b-alert>
            <b-alert
                v-show="isExecutable && (compPreconditionsLocalDetailsNOK.length || isExecutableDetails.length)"
                class="mt-1 mb-0 px-2 py-1 font-italic small"
                variant="info"
                show
            >
                <div><font-awesome-icon :icon="['fas', 'info-circle']" /> <b>Vorbedingungen OK</b></div>
                <div v-show="compPreconditionsLocalDetailsOK.length">
                    <div class="font-weight-bold">Lokal ( <font-awesome-icon :icon="['fas', 'helmet-safety']" />):</div>
                    <ul class="pl-3 mb-0">
                        <li v-for="(p, i) in compPreconditionsLocalDetailsOK" :key="i">{{ p.message }}</li>
                    </ul>
                </div>
                <div>
                    <div class="font-weight-bold">Konfiguration ( <font-awesome-icon :icon="['fas', 'gears']" />):</div>
                </div>
                <div v-if="isExecutable">Schritt ist ausführbar (isExecutable={{ isExecutable.toString() }})</div>
                <span v-if="isExecutableDetails.length">
                    {{ isExecutableDetails }}
                </span>
                <span v-else>
                    Keine Informationen verfügbar (ad.isExecutableDetails="").
                </span>
            </b-alert>
        </b-collapse>
    </div>
</template>

<script>
/**
 * this component was introduced to handle the "Erledigt" button in a dedicated section, including indicating missing
 * preconditions (which until now were unclear to the user/technician and hard to guess).
 *
 * note: for a complete implementation, see the following steps
 *   step 9-1-1 (no button "Abbrechen")
 *   step 7-1-3 (with button "Abbrechen")
 */
export default {
    name: "ExecSectionErledigt",
    props: {
        isBusy: {
            type: Boolean,
            default: true,
        },
        isExecutable: {
            type: Boolean,
            default: false,
        },
        isExecutableDetails: {
            type: String,
            default: "",
        },
        preconditionsLocalDetails: {
            type: Array,
            default: () => [],
        },
        showAbbrechen: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        compDisabled() {
            return !this.isExecutable || this.compPreconditionsLocalDetailsNOK.length > 0 || this.isBusy;
        },
        compIsBusy() {
            return this.isBusy;
        },
        compIsExecutableInfoIcon() {
            if (this.isExecutable && this.preconditionsLocalDetails.length === 0) {
                return "info-circle";
            } else {
                return "circle-question";
            }
        },
        compPreconditionsLocalDetailsNOK() {
            return this.preconditionsLocalDetails.filter((p) => p.status.toUpperCase() === "OPEN");
        },
        compPreconditionsLocalDetailsOK() {
            return this.preconditionsLocalDetails.filter((p) => p.status.toUpperCase() === "FULFILLED");
        },
    },
    methods: {
        onClickAbbrechen() {
            this.$emit("click-abbrechen");
        },
        onClickErledigt() {
            this.$emit("click-erledigt");
        },
    },
};
</script>
