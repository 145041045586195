import { httpClient } from "@/services/api/client";
import constants from "@/constants/constants";

const applicationSettingUrl = `${constants.env.API_PORTAL_URL}/system/applicationsetting`;

export default {
    put(name, payload) {
        return httpClient.put(`${applicationSettingUrl}/${name}`, payload);
    },

    getAll() {
        return httpClient.get(applicationSettingUrl);
    },
};
