<!-- TODO scd and gae: Maybe is useless to duplicate Step_2.vue if header information are the same -->
<template>
    <Main>
        <Box headerText="Gerätestandort" :isLoading="!devicePool || !order">
            <div v-if="order" class="d-flex">
                <div>
                    <router-link :to="{ name: 'execution-single', params: { orderId: order.auftragID.toString() } }">
                        {{ order.hak.tp }}
                    </router-link>
                </div>
                <div class="ml-auto font-weight-bold">
                    {{ order.hak.address }}
                    <div v-if="false">{{ order.hak.postalCode }} {{ order.hak.city }}</div>
                </div>
            </div>
            <div v-if="devicePool && order" class="d-flex">
                <div>
                    <router-link
                        :to="{
                            name: 'execution-geraetestandort',
                            params: {
                                orderId: this.$route.params.orderId,
                                devicePoolId: this.$route.params.devicePoolId,
                            },
                        }"
                    >
                        {{ devicePool.position }}
                        <span v-if="devicePool.positionDetail"> - {{ devicePool.positionDetail }}</span>
                    </router-link>
                </div>
                <div v-if="order.hak.address !== devicePool.address" class="ml-auto font-weight-bold">
                    {{ devicePool.address }}
                </div>
            </div>
        </Box>
        <router-view :devicePool="devicePool" :order="order" />
    </Main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import auftragsAPI from "@/services/api/auftrags.api";
import devicePoolsAPI from "@/services/api/devicepools.api";

export default {
    name: "Step_5",
    data() {
        return {
            devicePool: null,
            order: null,
        };
    },
    computed: {
        ...mapGetters({
            storeActAuftragDetail: "execution/storeActAuftragDetail",
        }),
    },
    mounted() {
        auftragsAPI.getSingle(this.$route.params.orderId).then((resp) => {
            this.order = resp.data;
        });
        devicePoolsAPI.getSingle(this.$route.params.devicePoolId).then((resp) => {
            this.devicePool = resp.data;
        });
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
            setAuftragDetails: "execution/setAuftragDetails",
        }),
    },
};
</script>
