/**
 * See interface 9b
 * @param iseAreaID
 * @returns {null|*}
 */
export function getActivityTypeIDFromAreaID(iseAreaID) {
    if (!iseAreaID) {
        return null;
    }
    const dictionary = {
        1012: 10499, // (Rheintal/Herrschaft)
        1011: 10505, // (Prättigau)
        983: 10506, // (Surselva)
        982: 10507,
    }; // (Engadin/Puschlav)
    return dictionary[iseAreaID];
}
