import { httpClient } from "@/services/api/client";
import constants from "@/constants/constants";

const endpoint = "/app/qualification";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, {
            params: params,
        });
    },
    // ********************
    // methods designed for datagrid
    // ********************
    getQualificationsForGridUrl() {
        return constants.env.API_ROOT_URL + endpoint;
    },
};
