var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxDataGrid',{ref:_vm.businessHoursKey,attrs:{"allow-column-reordering":true,"data-source":_vm.businessHourManipulatedData,"width":'100%',"show-borders":true},on:{"saved":_vm.saveTiming,"row-updating":_vm.onRowUpdating,"row-inserting":_vm.onRowInserting},scopedSlots:_vm._u([{key:"weekdayTemplate",fn:function({ data }){return [_c('div',[_vm._v(_vm._s(_vm.getWeekDayName(data)))])]}}])},[_c('DxEditing',{attrs:{"allow-updating":true,"allow-adding":true,"allow-deleting":true,"mode":"popup"}},[_c('DxForm',{attrs:{"label-location":"top"}},[_c('DxGroupItem',{attrs:{"item-type":"group","col-span":2,"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":"weekday.id","col-span":2,"editor-type":"dxSelectBox","editor-options":{
                            dataSource: this.daysArray,
                            displayExpr: 'name',
                            valueExpr: 'id',
                        }}}),_c('DxSimpleItem',{attrs:{"data-field":"from","editor-type":'dxDateBox',"editor-options":{
                            type: 'time',
                        }}}),_c('DxSimpleItem',{attrs:{"data-field":"to","editor-type":'dxDateBox',"editor-options":{
                            type: 'time',
                        }}})],1)],1),_c('DxPopup',{attrs:{"show-title":true,"title":"Arbeitszeit","height":"33vh","min-height":"310px","width":"350px"}})],1),_c('DxColumn',{attrs:{"data-field":"weekday.id","caption":"Wochentag","calculate-sort-value":"weekday.sorter","group-index":0,"calculate-group-value":"weekday.sorter","group-cell-template":"weekdayTemplate"}}),_c('DxColumn',{attrs:{"data-field":"from","caption":"Von","sort-order":"asc","editor-options":{ placeholder: '00:00:00' },"format":"HH:mm","data-type":"date"}}),_c('DxColumn',{attrs:{"data-field":"to","caption":"Bis","editor-options":{ placeholder: '00:00:00' },"format":"HH:mm","data-type":"date"}}),_c('DxColumn',{attrs:{"type":"buttons","width":100,"allow-sorting":false,"allow-grouping":false}},[_c('DxButton',{attrs:{"name":"edit","icon":"edit"}}),_c('DxButton',{attrs:{"icon":"save","name":"save"}}),_c('DxButton',{attrs:{"icon":"revert","name":"cancel"}}),_c('DxButton',{attrs:{"icon":"trash","name":"delete"}})],1),_c('DxGroupPanel',{attrs:{"visible":true}}),_c('DxGrouping',{attrs:{"auto-expand-all":true}}),_c('DxPaging',{attrs:{"page-size":30}}),_c('DxSearchPanel',{attrs:{"visible":true}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }