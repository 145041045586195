<template>
    <b-alert v-if="apiStatus === 404" variant="danger" show>
        <div class="font-weight-bold">Fehler</div>
        Flexibilität {{ rpDeviceId }} nicht gefunden.
    </b-alert>
    <Box v-else :headerText="actAuftragDetail?.action?.title" :isLoading="isLoading">
        <template v-if="!isLoading">
            <div class="font-weight-bold my-1">{{ actionTitle }}</div>
            <div class="my-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div v-if="deviceFlexibilitaet?.deviceFlexibilitaet">
                <strong>{{ deviceFlexibilitaet.deviceFlexibilitaet.deviceType }}</strong>
            </div>
            <b-form>
                <div>
                    <PhotoUpload
                        v-if="this.deviceFlexibilitaet"
                        :key="actAuftragDetail.stepCurrent"
                        :assetDelete="true"
                        :entityId="deviceFlexibilitaet.deviceFlexibilitaetID"
                        :fileDescription="`Auftrag ${rpOrderId} - ${actAuftragDetail.action.title}`"
                        :refEntityId="actAuftragDetail.auftragDetailID"
                        :showImages="true"
                        app="rellout"
                        entity="device"
                        folder="devices"
                        refEntity="auftragdetail"
                        @assetItems="onAssetItems"
                        :isDisabled="compPhotoUploadDisabled"
                        :showLabelForCaptureTypes="true"
                        :captureTypes="['camera', 'gallery']"
                        :disabledDetails="compPhotoUploadDisabledDetails"
                    />
                </div>
                <b-form-checkbox
                    v-model="form.notPossibleToTakePhoto"
                    :disabled="compNotPossibleToTakePhotoDisabled || isFlexRemoved"
                >
                    Es kann kein Foto erfasst werden
                </b-form-checkbox>
                <!-- AnlageArt -->
                <b-form-group class="mt-2" label="Anlage Art" label-class="font-weight-bold pb-0">
                    <b-form-select
                        v-model="form.anlageartID"
                        :options="anlageartOptions"
                        text-field="text"
                        value-field="keyValueOptionID"
                        v-on:change="form.anlagetypID = null"
                        :disabled="deviceFlexibilitaet && deviceFlexibilitaet.anlageartID !== null"
                        disabled-field="disabled"
                        :state="compAnlageArtValidation.length === 0"
                    />
                    <b-form-invalid-feedback v-if="compAnlageArtValidation.length">
                        {{ compAnlageArtValidation }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <!-- AnlageTyp (filtered by AnlageArt) -->
                <b-form-group label="Anlage Typ" label-class="font-weight-bold pb-0">
                    <b-form-select
                        v-model="form.anlagetypID"
                        :options="compAnlagetypOptions"
                        text-field="text"
                        value-field="keyValueOptionID"
                        disabled-field="disabled"
                        :disabled="isFlexRemoved"
                        :state="compAnlageTypValidation.length === 0"
                    />
                    <b-form-invalid-feedback v-if="compAnlageTypValidation.length">
                        {{ compAnlageTypValidation }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Leistung" label-class="font-weight-bold pb-0">
                    <b-input-group prepend="Bezug" append="kW">
                        <b-form-input
                            v-model="form.leistungBezug"
                            number
                            :disabled="isFlexRemoved"
                            class="text-right"
                        />
                    </b-input-group>
                    <b-input-group prepend="Produktion" append="kW" class="mt-1">
                        <b-form-input
                            v-model="form.leistungProduktion"
                            number
                            :disabled="isFlexRemoved"
                            class="text-right"
                        />
                    </b-input-group>
                </b-form-group>
                <!-- Gemessen von Zähler -->
                <b-form-group class="mt-2" label="Gemessen von Zähler" label-class="font-weight-bold pb-0">
                    <b-form-select
                        v-model="form.deviceMeterID"
                        :options="compZaehlerOptions"
                        text-field="text"
                        value-field="deviceID"
                        :disabled="isFlexRemoved"
                    />
                </b-form-group>
                <!-- Gesteuert über SMAN / Relais. Always enabled due to Dirk request: "Click on 'nicht gesteuert' is not possible after having assigned a SMAN" -->
                <b-form-group class="mt-3" label="Gesteuert über SMAN / Relais" label-class="font-weight-bold pb-0">
                    <b-form-checkbox v-model="form.isNotControlled" :disabled="isFlexRemoved">
                        nicht gesteuert
                    </b-form-checkbox>
                </b-form-group>
            </b-form>
            <!-- SmartManager -->
            <div v-if="smartManagerDeviceRelaisArray.length > 0">
                <b-card
                    v-for="smanRelais in smartManagerDeviceRelaisArray"
                    v-bind:key="smanRelais.smartManagerID"
                    :header="`SmartManager: ${smanRelais.smartManagerFactoryNo}`"
                    header-class="p-2"
                    body-class="p-2"
                >
                    <div
                        v-for="(dr, drIndex) in smanRelais.deviceRelaisArray"
                        v-bind:key="dr.deviceRelaisID"
                        class="mb-1"
                        :style="
                            isFlexConnectedToRelais(dr.deviceRelaisID) && !form.isNotControlled
                                ? 'font-weight: bold'
                                : 'font-weight: normal'
                        "
                    >
                        <strong>Relais {{ dr.relaisNumber + 1 }}</strong>
                        <!-- ToDo(clarify): why disabled? -> delete? -->
                        <template v-if="false">{{ concatFlexNames(dr.deviceFlexibilitaets) }}</template>
                        <div class="float-right">
                            <b-button
                                variant="primary"
                                class="mr-2"
                                size="sm"
                                v-on:click="gotoConfigSman(dr.deviceRelaisID)"
                                :disabled="isFlexRemoved"
                            >
                                ändern
                            </b-button>
                            <b-button
                                :variant="
                                    isFlexConnectedToRelais(dr.deviceRelaisID) ||
                                    (dr.relaisProgramSecondExternalID !== 700 &&
                                        dr.relaisProgramSecondExternalID != null)
                                        ? 'primary'
                                        : 'secondary'
                                "
                                size="sm"
                                v-on:click="connectFlexRelais(dr.deviceRelaisID)"
                                :disabled="
                                    isFlexConnectedToRelais(dr.deviceRelaisID) ||
                                        isDisabledBtnSave ||
                                        form.isNotControlled ||
                                        dr.relaisProgramSecondExternalID === 700 ||
                                        dr.relaisProgramSecondExternalID == null ||
                                        isFlexRemoved
                                "
                            >
                                {{ isFlexConnectedToRelais(dr.deviceRelaisID) ? "verbunden" : "verbinden" }}
                            </b-button>
                        </div>
                        <div>
                            Schaltprogramm:
                            <template v-if="dr.relaisProgramText">{{ dr.relaisProgramText }}</template>
                            <template v-else>-</template>
                        </div>
                        <div>
                            Anschlusstyp:
                            <template v-if="dr.relaisTypeText">{{ dr.relaisTypeText }}</template>
                            <template v-else>-</template>
                        </div>
                        <div>
                            Lasten Bezeichnung MSP:
                            <template v-if="dr.relaisConsumerClassText">{{ dr.relaisConsumerClassText }}</template>
                            <template v-else>-</template>
                        </div>
                        <hr v-if="drIndex < smanRelais.deviceRelaisArray.length - 1" class="my-2" />
                    </div>
                </b-card>
            </div>
            <b-alert v-else class="my-1 p-2" show>
                Kein SMAN gefunden.
            </b-alert>
            <template>
                <div class="d-flex mt-2">
                    <b-button class="d-block" variant="primary" @click="onClickSave" :disabled="compBtnSaveDisabled">
                        Speichern
                    </b-button>
                </div>
                <ExecSectionErledigt
                    :isBusy="false"
                    :isExecutable="actAuftragDetail.isExecutable"
                    :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                    :preconditionsLocalDetails="compPreconditionsLocalDetails"
                    @click-erledigt="onClickErledigt"
                    class="mt-2"
                />
                <!-- Delete is possible only if Device is not in IS-E -->
                <b-button
                    v-if="deviceFlexibilitaet && !deviceFlexibilitaet.deviceFlexibilitaet.iseDeviceID"
                    v-b-modal.flex-delete
                    class="mt-2 d-block"
                    variant="primary"
                >
                    Flexibilität aus Auftrag löschen
                </b-button>
                <b-modal
                    id="flex-delete"
                    title="Flexibilität löschen"
                    content-class="shadow"
                    cancel-title="Abbrechen"
                    ok-variant="danger"
                    ok-title="LÖSCHEN"
                    @ok="onClickDelete"
                >
                    <div class="font-weight-bold">Flexibilität aus REtasks Auftrag löschen.</div>
                    <small class="mt-1 font-italic">
                        Anmerkung: Wenn Sie die Flexibilität bei der Auftragsausführung versehentlich erstellt haben, so
                        können Sie diese nun wieder entfernen.
                    </small>
                    <div class="mt-2">Möchten Sie die Flexibilität wirklich löschen?</div>
                </b-modal>
                <!-- Ausbau only possible if flex is in IS-E -->
                <b-button
                    v-if="deviceFlexibilitaet && !!deviceFlexibilitaet.deviceFlexibilitaet.iseDeviceID"
                    v-b-modal.flex-uninstall
                    class="mt-2 d-block"
                    variant="primary"
                    :disabled="isFlexRemoved"
                >
                    Flexibilität in IS-E beenden
                </b-button>
                <b-modal
                    id="flex-uninstall"
                    title="Flexibilität beenden"
                    content-class="shadow"
                    cancel-title="Abbrechen"
                    ok-variant="danger"
                    ok-title="Flexibilität in IS-E beenden"
                    @ok="onClickBeenden"
                >
                    <div class="font-weight-bold">Flexibilität in IS-E beenden.</div>
                    <small class="mt-1 font-italic text-danger">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-1" />
                        <strong>ACHTUNG:</strong> Die Flexibilität wird <strong>sofort(!)</strong> in IS-E gültig-bis
                        gesetzt. Dieser Schritt kann nicht rückgängig gemacht werden! Ein irrtümliches Beenden kann nur
                        über das <strong>IS-E Backoffice</strong> nach dem Abschluss dieses Auftrages in IS-E korrigiert
                        werden.
                    </small>
                    <div class="mt-2">Möchten Sie die Flexibilität in IS-E wirklich beenden?</div>
                </b-modal>
            </template>
        </template>
    </Box>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import constants from "@/constants/constants";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import PhotoUpload from "@/components/general/PhotoUpload";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import deviceFlexibilitaetsAPI from "@/services/api/deviceFlexibilitaets.api";
import deviceRelaisAPI from "@/services/api/deviceRelais.api";
import devicesAPI from "@/services/api/devices.api";
import iseAPI from "@/services/api/ise.api";
import keyValueOptionsAPI from "@/services/api/keyValueOptions.api";

export default {
    name: "Step_5_1_1",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        PhotoUpload,
    },
    data: function() {
        return {
            anlageartOptions: [],
            anlagetypOptions: [],
            apiStatus: 0,
            assetItems: null,
            auftragDetails720: [],
            auftragDetails721: [],
            auftragDetails723: [],
            deviceFlexibilitaet: null,
            devicePool: null,
            form: {
                anlageartID: null, // parent of AnlageTyp
                anlagetypID: null, // child of: AnlageArt
                deviceMeterID: null, // measured by meter
                isNotControlled: false, // nicht gesteuert: not controlled by SMAN
                leistungBezug: null, // performance reference
                leistungProduktion: null,
                notPossibleToTakePhoto: false, // not possible to take photo -> https://repowerag.visualstudio.com/REtasks/_workitems/edit/14238
            },
            isDisabledBtnSave: false,
            isLoading: true,
            metersNew: [], // meters to install
            metersOld: [], // meters to uninstall
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            rpDevicePoolId: this.$route.params.devicePoolId,
            rpDeviceId: this.$route.params.deviceId,
            rpOrderId: this.$route.params.orderId,
            smartManagerDeviceRelaisArray: [], // foreach smartManager of the devicePool contains the list of the device-relais (array of array)
        };
    },
    computed: {
        ...mapGetters({
            actAuftragDetail: "execution/storeActAuftragDetail",
        }),
        actionTitle() {
            return this.actAuftragDetail?.action?.title;
        },
        compBtnSaveDisabled() {
            return this.isDisabledBtnSave || this.isFlexRemoved;
        },
        compAnlageArtValidation() {
            let selectedAnlageArt = [];
            if (this.form?.anlageartID) {
                // note(udo): still a bit hacky -> should be simplified
                selectedAnlageArt = this.anlageartOptions.filter(
                    (kvo) => kvo.keyValueOptionID === this.form.anlageartID
                );
            } else {
                // user has not selected a value from the list
                return "Bitte einen Wert aus der Liste auswählen!";
            }
            switch (selectedAnlageArt.length) {
                case 0:
                    return "Keine Auswahl möglich -> bitte Backoffice informieren!";
                case 1:
                    // IMPORTANT: on SUCCESS the return is an empty string ("")
                    // note: selecting a "disabled" value should not happen -> if it does, it is covered
                    return selectedAnlageArt[0]?.disabled ? "Ausgewählter Wert ist inaktiv!" : "";
                default:
                    // this should not happen but if it does, it is a bug
                    return "UNBEHANDELTES SZENARIO: Mehrere Werte gefunden -> bitte Backoffice informieren!";
            }
        },
        compAnlageTypValidation() {
            let selectedAnlageTyp = [];
            if (this.form?.anlagetypID) {
                // note(udo): still a bit hacky -> should be refactored
                selectedAnlageTyp = this.compAnlagetypOptions.filter(
                    (x) => x.keyValueOptionID === this.form.anlagetypID
                );
            } else {
                // user has not selected a value from the list
                return "Bitte einen Wert aus der Liste auswählen!";
            }
            switch (selectedAnlageTyp.length) {
                case 0:
                    return "Keine Auswahl möglich -> bitte Backoffice informieren!";
                case 1:
                    // IMPORTANT: on SUCCESS the return is an empty string ("")
                    // note: selecting a "disabled" value should not happen -> if it does, it is covered
                    return selectedAnlageTyp[0]?.disabled ? "Ausgewählter Wert ist inaktiv!" : "";
                default:
                    // this should not happen but if it does, it is a bug
                    return "UNBEHANDELTES SZENARIO: Mehrere Werte gefunden -> bitte Backoffice informieren!";
            }
        },
        compAttrsDevicePool() {
            return this.$attrs.devicePool;
        },
        compNotPossibleToTakePhotoDisabled() {
            return this.assetItems !== null ? this.assetItems?.images.length !== 0 : true;
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            // handle AuftragDetail.Status
            switch (this.actAuftragDetail.status) {
                case 0:
                    // the step has not been started/completed yet -> do not disable erledigt
                    break;
                case constants.auftragDetailStatus.DONE_REPEATABLE:
                    preconditionsLocalDetails.push({
                        status: "FULFILLED",
                        message: `Der Schritt wurde bereits beendet, darf jedoch in diesem Status wiederholt werden. -> ad.status=${this.actAuftragDetail.status}`,
                    });
                    break;
                default:
                    preconditionsLocalDetails.push({
                        status: "OPEN",
                        message: `Der Schritt kann in diesem Status nicht(!) wiederholt werden. anm.: ad.status=${this.actAuftragDetail.status} -> ad.status muss ${constants.auftragDetailStatus.DONE_REPEATABLE} sein um wiederholt werden zu können.`,
                    });
                    break;
            }
            // requirement: https://repowerag.visualstudio.com/REtasks/_workitems/edit/14238
            if (this.form.notPossibleToTakePhoto) {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Es kann kein Foto erfasst erfasst werden.",
                });
            } else {
                if (this.assetItems === null || this.assetItems.images.length === 0) {
                    preconditionsLocalDetails.push({
                        status: "OPEN",
                        message: "Bitte ein Foto erfassen.",
                    });
                } else {
                    preconditionsLocalDetails.push({
                        status: "FULFILLED",
                        message: "Foto(s) wurde(n) erfasst.",
                    });
                }
            }
            if (this.isFlexRemoved) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Flexibilität wurde entfernt." });
            }
            if (this.compAnlageArtValidation === "") {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Gültige Anlage Art ausgewählt." });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Bitte eine gültige Anlage Art auswählen.",
                });
            }
            if (this.compAnlageTypValidation === "") {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Gültiger Anlage Typ ausgewählt." });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Bitte einen gültigen Anlage Typ auswählen",
                });
            }
            // Dirk (Schulung): 1 of the 2 fields has to be populated (the other can be NULL)
            if (this.form.leistungBezug || this.form.leistungProduktion) {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Gültiger Wert in 'Leistung Bezug' bzw. 'Leistung Produktion' eingegeben.",
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Bitte gültige Werte in 'Leistung Bezug' und/oder 'Leistung Produktion' eingeben.",
                });
            }
            if (this.form.isNotControlled) {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: "Flexibilität ist nicht gesteuert.",
                });
            } else {
                // ToDo: to be analyzed if this is redundant to what is done in the next if -> if (smanRelais) { ... }
                if (this.deviceFlexibilitaet?.deviceRelaisID) {
                    preconditionsLocalDetails.push({
                        status: "FULFILLED",
                        message: `Flexibilität ist mit SMAN ${
                            this.deviceFlexibilitaet.deviceRelais.deviceSmartManager.internalNo
                        } an Relais ${this.deviceFlexibilitaet.deviceRelais.relaisNumber + 1}
                         verbunden (DeviceRelais.DeviceRelaisID=${this.deviceFlexibilitaet?.deviceRelaisID})`,
                    });

                    // SMAN relais checks - BEGIN
                    // ToDo(clarify): not completely clear... tbd -> analyze -> simplify -> improve OPEN/FULFILLED messages
                    // find SMAN relais which is connected to this flexibility (there is 1 step 6-4-1 ad record for each SMAN)
                    const smanRelais = this.smartManagerDeviceRelaisArray.find(
                        (sr) => sr.smartManagerID === this.deviceFlexibilitaet.deviceRelais.deviceSmartManagerID
                    );
                    if (smanRelais) {
                        if (smanRelais.auftragDetail641.status === constants.auftragDetailStatus.DONE_CLOSED) {
                            //console.log("smanRelais.auftragDetail641:", smanRelais.auftragDetail641);
                            // ToDo: improve FULFILLED message
                            // the SMAN connected to this FLEX has step-6-4-1 in status=200
                            preconditionsLocalDetails.push({
                                status: "FULFILLED",
                                message: `SMAN step-6-4-1 status: smanRelais.auftragDetail641.status=${smanRelais.auftragDetail641.status}`,
                            });
                        } else {
                            preconditionsLocalDetails.push({
                                status: "OPEN",
                                message: `SMAN step-6-4-1 status: smanRelais.auftragDetail641.status=${smanRelais.auftragDetail641.status} (SOLL: ${constants.auftragDetailStatus.DONE_CLOSED})`,
                            });
                        }
                    } else {
                        preconditionsLocalDetails.push({
                            status: "OPEN",
                            message: `Kein Relais für SMAN ID ${this.deviceFlexibilitaet.deviceRelais.deviceSmartManagerID} gefunden.`,
                        });
                    }
                    // SMAN relais checks - END
                } else {
                    preconditionsLocalDetails.push({
                        status: "OPEN",
                        message: "Bitte Flexibilität mit einem SMAN verbinden.",
                    });
                }
            }
            if (this.form.deviceMeterID) {
                preconditionsLocalDetails.push({
                    status: "FULFILLED",
                    message: `Zähler ausgewählt.`,
                });
            } else {
                preconditionsLocalDetails.push({
                    status: "OPEN",
                    message: "Bitte einen Zähler auswählen (Eingabefeld 'Gemessen von Zähler').",
                });
            }

            return preconditionsLocalDetails;
        },
        compZaehlerOptions() {
            return this.metersNew;
        },
        compAnlagetypOptions() {
            if (this.form?.anlageartID) {
                return this.anlagetypOptions.filter((kvo) => kvo.parentID === this.form.anlageartID);
            }
            return [];
        },
        isFlexRemoved() {
            if (this.deviceFlexibilitaet) {
                return moment(this.deviceFlexibilitaet.deviceFlexibilitaet.toDate).isBefore(moment());
            }
            return false;
        },
        compPhotoUploadDisabled() {
            // disable photoupload if isExecutable is false and (step has been completed or deviceFlexibilitaet has been removed)
            return this.actAuftragDetail.isExecutable ? false : this.isFlexRemoved;
        },
        compPhotoUploadDisabledDetails() {
            const photoUploadDisabledDetails = [];
            if (this.isFlexRemoved) {
                photoUploadDisabledDetails.push({
                    message: `Flexibilität wurde entfernt; isFlexRemoved: ${this.isFlexRemoved}`,
                });
            }

            return photoUploadDisabledDetails;
        },
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        concatFlexNames(flexibiliaets) {
            let flexNames = "";
            for (const deviceFlexibilitaet of flexibiliaets) {
                flexNames = `${flexNames} ${deviceFlexibilitaet.deviceFlexibilitaetID} -`;
            }
            return flexNames.replace(/.$/, ""); // remove last character
        },
        async connectFlexRelais(deviceRelaisID) {
            // build payload with flexibilität data to save
            const payload = this.getPayloadDeviceFlexibilitaets();
            this.form.isNotControlled = false;
            // add relaisId to update
            payload.push(
                {
                    op: "replace",
                    path: "/deviceRelaisID",
                    value: deviceRelaisID,
                },
                {
                    op: "replace",
                    path: "/isNotControlled",
                    value: false,
                }
            );
            this.isDisabledBtnSave = true;
            // ToDo: refactor this to use promise instead of try/catch/finally
            try {
                await deviceFlexibilitaetsAPI.patch(this.deviceFlexibilitaet.deviceFlexibilitaetID, payload);
                this.$bvToast.toast(`Daten gespeichert.`, {
                    title: "Success",
                    variant: "success",
                    toaster: "b-toaster-bottom-right",
                    autoHideDelay: 3000,
                    appendToast: true,
                });
                // reload
                await this.loadFlexAndSmanRelais();
            } finally {
                this.isDisabledBtnSave = false;
            }
        },
        gotoConfigSman(deviceRelaisID) {
            // note: data is saved before navigating to step 6-4-1 (confirmed by dirk, 04.06.2024)
            this.save().then(() => {
                this.$router.push({
                    name: "step-6-4-1",
                    params: {
                        orderId: this.rpOrderId,
                        devicePoolId: this.rpDevicePoolId,
                        auftragItemId: deviceRelaisID,
                    },
                });
            });
        },
        updateNotPossibleToTakePhoto() {
            if (this.form.notPossibleToTakePhoto !== this.actAuftragDetail?.value) {
                const payloadAd = [
                    {
                        op: "replace",
                        path: "/value",
                        value: JSON.stringify({ notPossibleToTakePhoto: this.form.notPossibleToTakePhoto }),
                    },
                ];
                auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAd).then();
            }
        },
        isFlexConnectedToRelais(deviceRelaisID) {
            return this.deviceFlexibilitaet.deviceRelaisID === deviceRelaisID;
        },
        async loadAuftragDetail() {
            const pathSplit = this.$route.fullPath.split("/");
            const step = pathSplit[pathSplit.length - 1];
            await auftragDetailsAPI
                .getByOrderIdAndStep(this.rpOrderId, step, this.rpDevicePoolId, this.rpDeviceId)
                .then((res) => {
                    this.setActAuftragDetail(res);
                });
        },
        async loadMeters() {
            // Old Zähler
            this.auftragDetails721 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.rpOrderId,
                "7-2-1",
                this.rpDevicePoolId,
                null,
                null,
                true
            );
            for (let ad of this.auftragDetails721) {
                const meter = (await devicesAPI.getSingle(ad.deviceID)).data;
                this.metersOld.push(meter);
            }
            // Old SMET
            this.auftragDetails720 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.rpOrderId,
                "7-2-0",
                this.rpDevicePoolId,
                null,
                null,
                true
            );
            for (let ad of this.auftragDetails720) {
                const meter = (await devicesAPI.getSingle(ad.deviceID)).data;
                this.metersOld.push(meter);
            }
            // New SMET
            this.auftragDetails723 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.rpOrderId,
                "7-2-3",
                this.rpDevicePoolId,
                null,
                null,
                true
            );
            for (let ad of this.auftragDetails723) {
                const newMeter = (await devicesAPI.getSingle(ad.deviceID)).data;
                const oldMeter = this.getOldMeterByIseDeviceID(newMeter.iseDeviceID);
                newMeter.text = `${newMeter.factoryNo ?? "nicht installiert"} (alt: ${oldMeter?.factoryNo})`;
                this.metersNew.push(newMeter);
            }
        },
        async loadDeviceFlexibilitaet() {
            // ToDo: refactor this to use promise instead of try/catch/finally
            try {
                const response = await deviceFlexibilitaetsAPI.getSingle(this.rpDeviceId, {
                    includeDevices: true,
                });
                this.deviceFlexibilitaet = response.data;
                this.apiStatus = response.status;
                // Map response fields to form fields
                if ([200, 201, 202].includes(this.actAuftragDetail.status)) {
                    // This step has already been "Erledigt" - Take data from DB
                    this.form.deviceMeterID = this.deviceFlexibilitaet.deviceMeterID;
                } else {
                    // Not yet Erledigt: deviceFlexibilitaet.deviceMeterID contains the old meter. Replace by new one
                    this.form.deviceMeterID = this.getNewMeterByIseDeviceID(
                        this.deviceFlexibilitaet?.deviceMeter?.iseDeviceID
                    )?.deviceID;
                }
                this.form.anlageartID = this.deviceFlexibilitaet.anlageartID;
                this.form.anlagetypID = this.deviceFlexibilitaet.anlagetypID;
                this.form.leistungBezug = this.deviceFlexibilitaet.leistungBezug;
                this.form.leistungProduktion = this.deviceFlexibilitaet.leistungProduktion;
                this.form.isNotControlled = this.deviceFlexibilitaet.isNotControlled;
            } catch (err) {
                this.apiStatus = err.response.status;
                if (err.response.status !== 404) {
                    throw err;
                }
            }
        },
        async loadKeyValueOptions() {
            // Load AnlageArt values
            const anlageArtResponse = await keyValueOptionsAPI.get("flexibilitaet", "anlageart", true);
            const anlageArtKeyValueOptions = anlageArtResponse.data.filter(
                (k) => k.keyValueOptionID === this.deviceFlexibilitaet.anlageartID || k.active
            );
            anlageArtKeyValueOptions.forEach((k) => {
                k.disabled = !k.active;
                k.text = k.active ? k.text : `${k.text} - inaktiv`;
            });
            this.anlageartOptions = _.orderBy(anlageArtKeyValueOptions, ["text"], ["asc"]);
            // Load AnlageTyp values (will be filtered by compAnlagetypOptions computed prop)
            const anlageTypResponse = await keyValueOptionsAPI.get("flexibilitaet", "anlagetyp", true);
            const anlageTypKeyValueOptions = anlageTypResponse.data.filter(
                (k) => k.keyValueOptionID === this.deviceFlexibilitaet.anlagetypID || k.active
            );
            anlageTypKeyValueOptions.forEach((k) => {
                k.disabled = !k.active;
                k.text = k.active ? k.text : `${k.text} - inaktiv`;
            });
            this.anlagetypOptions = _.orderBy(anlageTypKeyValueOptions, ["text"], ["asc"]);
        },
        async loadFlexAndSmanRelais() {
            await this.loadDeviceFlexibilitaet();
            await this.loadSmartManagerRelais(this.compAttrsDevicePool.devicePoolID);
        },
        async loadSmartManagerRelais(devicePoolId) {
            this.smartManagerDeviceRelaisArray = [];
            // TODO: to implement (SmartManager table)
            const smartManagers = await devicesAPI.get({
                devicePoolID: devicePoolId,
                type: "Steuereinheit",
                deviceSuffix: "Smart Manager", // rename to deviceSuffix
                fromDate__lte: moment().format("YYYY-MM-DD"),
                toDate__gte: moment().format("YYYY-MM-DD"),
            });
            for (const smartManager of smartManagers) {
                let response = await deviceRelaisAPI.get({
                    deviceSmartManagerID: smartManager.deviceID,
                    includeDevices: true,
                });
                // Step 6-4-1 enables Erledig button
                let auftragDetail641 = await auftragDetailsAPI.getByOrderIdAndStep(
                    this.rpOrderId,
                    "6-4-1",
                    this.rpDevicePoolId,
                    smartManager.deviceID
                );
                // Create a 2-dimensional array. First level are the smartManagers, second level are the deviceRelais related to that smartManager
                this.smartManagerDeviceRelaisArray.push({
                    smartManagerID: smartManager.deviceID,
                    smartManagerFactoryNo: smartManager.factoryNo,
                    auftragDetail641: auftragDetail641,
                    deviceRelaisArray: response.data,
                });
            }
        },
        onAssetItems(items) {
            this.assetItems = items;
            if (items.images.length) {
                // set flag to false in case of images (photos)
                this.form.notPossibleToTakePhoto = false;
            } else {
                const actAdValue = this.actAuftragDetail?.value ? JSON.parse(this.actAuftragDetail.value) : {};
                this.form.notPossibleToTakePhoto = actAdValue?.notPossibleToTakePhoto
                    ? actAdValue.notPossibleToTakePhoto
                    : false;
            }
        },
        onClickErledigt() {
            this.save(constants.auftragDetailStatus.DONE_REPEATABLE).then(() => {
                this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            });
        },
        onClickNext(step) {
            this.navigateToStepNext = step;
        },
        onClickPrevious(step) {
            // on SUCCESS, navigate to previous step
            this.navigateToStepPrevious = step;
        },
        getKeyValueOptionByID(keyValueOptionID) {
            if (!keyValueOptionID) {
                return null;
            }
            let foundKVO = this.anlageartOptions.find((a) => a.keyValueOptionID === keyValueOptionID);
            if (!foundKVO) {
                // if not found in AnlageArt search in AnlageTyp (keyValueOptionID is unique)
                foundKVO = this.anlagetypOptions.find((a) => a.keyValueOptionID === keyValueOptionID);
            }
            return foundKVO;
        },
        getPayloadDeviceFlexibilitaets() {
            const payload = [];
            if (this.form.anlageartID !== this.deviceFlexibilitaet.anlageartID) {
                payload.push({
                    op: "replace",
                    path: "/anlageartID",
                    value: this.form.anlageartID,
                });
            }
            if (this.form.anlagetypID !== this.deviceFlexibilitaet.anlagetypID) {
                payload.push({
                    op: "replace",
                    path: "/anlagetypID",
                    value: this.form.anlagetypID,
                });
            }
            if (this.form.leistungBezug !== this.deviceFlexibilitaet.leistungBezug) {
                payload.push({
                    op: "replace",
                    path: "/leistungBezug",
                    value: this.form.leistungBezug,
                });
            }
            if (this.form.leistungProduktion !== this.deviceFlexibilitaet.leistungProduktion) {
                payload.push({
                    op: "replace",
                    path: "/leistungProduktion",
                    value: this.form.leistungProduktion,
                });
            }
            if (this.form.deviceMeterID !== this.deviceFlexibilitaet.deviceMeterID) {
                payload.push({
                    op: "replace",
                    path: "/deviceMeterID",
                    value: this.form.deviceMeterID,
                });
            }
            // Not controlled / nicht gesteurt flag
            if (this.form.isNotControlled !== this.deviceFlexibilitaet.isNotControlled) {
                payload.push({
                    op: "replace",
                    path: "/isNotControlled",
                    value: this.form.isNotControlled,
                });
            }
            // If IsNotControlled changed from FALSE to TRUE: disconnect
            if (this.form.isNotControlled && !this.deviceFlexibilitaet.isNotControlled) {
                payload.push({
                    op: "remove",
                    path: "/deviceRelaisID",
                });
            }
            return payload;
        },
        async onClickDelete() {
            // Delete Device (DeviceFlexibilitaet also deleted by cascading)
            await devicesAPI.delete(this.deviceFlexibilitaet.deviceFlexibilitaetID);
            // Delete step 5-1-1
            await auftragDetailsAPI.delete(this.actAuftragDetail.auftragDetailID);
            // Go back to Flex menu
            this.onClickPrevious(this.actAuftragDetail.stepPrevious);
        },
        async onClickBeenden() {
            await devicesAPI.uninstall(this.deviceFlexibilitaet.deviceFlexibilitaetID);
            // sync Flex with IS-E
            await iseAPI.postFlexibilitaet(this.deviceFlexibilitaet.deviceFlexibilitaetID);
            // Update Status
            await this.updateStatus(constants.auftragDetailStatus.DONE_CONDITION_1);
            // Navigate to next Step
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        showSavingSuccessToast() {
            this.$bvToast.toast(`Daten gespeichert.`, {
                title: "Success",
                variant: "success",
                toaster: "b-toaster-bottom-right",
                autoHideDelay: 3000,
                appendToast: true,
            });
        },
        async onClickSave() {
            const payload = this.getPayloadDeviceFlexibilitaets();
            if (!payload.length && this.form.notPossibleToTakePhoto === this.actAuftragDetail?.value) {
                this.$bvToast.toast(`Keine Daten verändert. Nichts gespeichert.`, {
                    title: "Info",
                    variant: "info",
                    toaster: "b-toaster-bottom-right",
                    autoHideDelay: 3000,
                    appendToast: true,
                });
            }

            await this.save();

            return Promise.resolve();
        },
        async save(statusNew = null) {
            this.updateNotPossibleToTakePhoto();
            const payload = this.getPayloadDeviceFlexibilitaets();
            // Save data to /device-flexibilitaets - Only if modified
            if (payload.length > 0) {
                await deviceFlexibilitaetsAPI
                    .patch(this.deviceFlexibilitaet.deviceFlexibilitaetID, payload)
                    .then(() => {
                        this.showSavingSuccessToast();
                    });
            }
            if (Number.isInteger(statusNew)) {
                // this should be done in the backend
                if ([constants.auftragDetailStatus.DONE_REPEATABLE].includes(statusNew)) {
                    await this.updateStatus(statusNew);
                }
            }
        },
        async updateStatus(status) {
            // Save status to /auftrag-details - Only if not null
            if (status) {
                const auftragDetailsPayload = [
                    {
                        op: "replace",
                        path: "/status",
                        value: status,
                    },
                ];
                await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, auftragDetailsPayload);
            }
        },
        getOldMeterByIseDeviceID(iseDeviceID) {
            return !iseDeviceID ? null : this.metersOld.find((m) => m.iseDeviceID === iseDeviceID);
        },
        getNewMeterByIseDeviceID(iseDeviceID) {
            return !iseDeviceID ? null : this.metersNew.find((m) => m.iseDeviceID === iseDeviceID);
        },
    },
    async mounted() {
        await this.loadAuftragDetail();
        await this.loadMeters();
        await this.loadFlexAndSmanRelais();
        await this.loadKeyValueOptions();
        this.isLoading = false;
    },
};
</script>
