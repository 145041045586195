const state = {
    hakIds: [],
    haks: [],
    filter: null,
};

const actions = {
    setHakIds({ commit }, data) {
        commit("SET_HAK_IDS", data);
    },
    setHaks({ commit }, data) {
        commit("SET_HAKS", data);
    },
    setFilter({ commit }, data) {
        commit("SET_FILTERS", data);
    },
};

const mutations = {
    SET_HAK_IDS(state, data) {
        state.hakIds = data;
    },
    SET_HAKS(state, data) {
        state.haks = data;
    },
    SET_FILTERS(state, data) {
        state.filter = data;
    },
};

const getters = {
    storeHakIds: (state) => state.hakIds,
    storeHaks: (state) => state.haks,
    storeFilter: (state) => state.filter,
};

export default {
    state,
    actions,
    mutations,
    getters,
};
