import { httpClient } from "@/services/api/client";

const endpoint = "/net/meters-by-category";

export default {
    /// **************
    // custom methods
    // **************
    getMetersByCategory(auftragId, devicepoolId) {
        const endpointURL = `${endpoint}/${auftragId}/${devicepoolId}`;
        return httpClient.get(endpointURL);
    },
};
