<template>
    <iframe class="hangfire-frame" :src="hangfireUrl"></iframe>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Hangfire",

    data: () => ({
        hangfireBaseUrl: process.env.VUE_APP_API_ROOT_URL.slice(0, -3), // remove 'api' from base url
    }),

    computed: {
        ...mapGetters(["oidcAccessToken"]),

        hangfireUrl() {
            const details = this.$route.params.jobId ? `jobs/details/${this.$route.params.jobId}` : "";
            return `${this.hangfireBaseUrl}jobs/${details}?token=${this.oidcAccessToken}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.hangfire-frame {
    border: 0;
    width: 100%;
    height: 90vh;
}
</style>
