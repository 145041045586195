<template>
    <div>
        <svg height="20" width="24" xmlns:xlink="http://www.w3.org/1999/xlink">
            <use :xlink:href="cacheStatus"></use>
        </svg>
    </div>
</template>

<script>
import constants from "@/constants/constants";
import cacheService from "@/services/cacheService";
import { eventBus } from "@/utils/eventBus";
export default {
    name: "CacheManager",
    data() {
        return {
            currentStatus: "",
        };
    },
    props: {
        networkResponseTime: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    computed: {
        isNetworkResponseTimeAcceptable() {
            return (
                !isNaN(this.networkResponseTime) &&
                this.networkResponseTime <= constants.connectivity.IDEAL_RESPONSETIME
            );
        },
        cacheStatus() {
            // return current Status of Cache using an svg image inlcuding different symbols identified by this.currentStatus
            return this.currentStatus
                ? require("@/assets/images/cache/cache.svg") + this.currentStatus
                : this.currentStatus;
        },
    },
    async mounted() {
        // Starts Upload automatically if networkResponseTime is below or equal to 150ms
        await this.autoUpload();
    },
    created() {
        eventBus.$on("uploadStatus", (status) => {
            this.currentStatus = status;
        });
    },
    methods: {
        async autoUpload() {
            const { TIMEOUT_AFTER_AUTO_UPLOAD } = constants.upload;
            try {
                const uploadablePhotoCount = await cacheService.getUploadablePhotosCount();
                if (uploadablePhotoCount) {
                    if (this.isNetworkResponseTimeAcceptable) {
                        await cacheService.uploadOldestPhoto();
                    }
                }
            } finally {
                setTimeout(async () => {
                    await this.autoUpload();
                }, TIMEOUT_AFTER_AUTO_UPLOAD);
            }
        },
    },
    beforeDestroy() {
        eventBus.$off("uploadStatus");
    },
};
</script>
