<template>
    <div>
        <b-navbar type="light" variant="LIGHT" class="p-0">
            <b-navbar-nav>
                <b-nav-item :to="{ name: 'orders-list' }" :active="$route.name === 'orders-list'">Übersicht</b-nav-item>
                <b-nav-item :to="{ name: 'orders-analysis' }" :active="$route.name === 'orders-analysis'">
                    Analyse
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
        <router-view />
    </div>
</template>

<script>
export default {
    name: "Orders",
    data() {
        return {};
    },
};
</script>

<style scoped></style>
