import { httpClient } from "@/services/api/client";

const endpoint = "/net/haks";

export default {
    // ********************
    // default REST methods
    // ********************
    get(params) {
        return httpClient.get(endpoint, { params: params });
    },
    getSingle(id) {
        return httpClient.get(`${endpoint}/${id}`);
    },
    patch(id, payload) {
        return httpClient.patch(`${endpoint}/${id}`, payload).then((resp) => {
            // ToDo: return complete promise and not only "data"! -> refactor where used!!!
            return resp.data;
        });
    },
    // **************
    // custom methods
    // **************

    bulkPatch(payload) {
        return httpClient.patch(`${endpoint}/bulk`, payload).then((resp) => {
            return resp.data;
        });
    },
};
