<template>
    <div>
        <h1>Auftrag Detail</h1>
        <hr />
        <re-spinner v-if="isLoadingOrder" />
        <template v-else>
            <div v-if="!auftragExists">Auftrag {{ $route.params.id }} existiert nicht</div>
            <template v-else>
                <b-row>
                    <b-col cols="1" col-md="3" />
                    <b-col cols="3">
                        <label>HAK TP:</label>
                        <b-input-group>
                            <template v-if="order.hak.tp" #append>
                                <b-input-group-text>
                                    <router-link :to="{ name: 'hak-details', params: { id: order.hakID } }">
                                        <i class="fas fa-link" />
                                    </router-link>
                                </b-input-group-text>
                            </template>
                            <b-form-input v-model="order.hak.tp" disabled />
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <label>Auftrag ID:</label>
                        <b-input-group>
                            <b-form-input v-model="order.auftragID" disabled />
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Beschreibung" active>
                        <b-row class="mt-1">
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Auftragstyp:">
                                    <b-form-input v-model="order.auftragType" disabled />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Adresse:">
                                    <b-form-input v-model="order.hak.address" trim disabled />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Ort:">
                                    <b-form-input v-model="order.hak.city" trim disabled />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="PLZ:">
                                    <b-form-input v-model="order.hak.postalCode" trim disabled />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Firma:">
                                    <b-form-select
                                        v-model="companyId"
                                        :options="companies"
                                        value-field="companyID"
                                        text-field="name"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Leiter:">
                                    <b-form-select
                                        v-model="order.managerID"
                                        :options="users"
                                        value-field="userID"
                                        text-field="fullName"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Techniker:">
                                    <b-form-select
                                        v-model="order.technicianID"
                                        :options="users"
                                        value-field="userID"
                                        text-field="fullName"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Status:">
                                    <b-form-select
                                        v-model="order.auftragStatusID"
                                        :options="orderStatusesBase"
                                        value-field="auftragStatusID"
                                        text-field="label"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Ab:">
                                    <b-form-datepicker
                                        v-model="order.fromDate"
                                        required
                                        locale="de"
                                        :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Bis:">
                                    <b-form-datepicker
                                        v-model="order.toDate"
                                        required
                                        locale="de"
                                        :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Geplante Ausführung:">
                                    <b-form-datepicker
                                        v-model="executionPlanned.date"
                                        required
                                        locale="de"
                                        :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                                        disabled
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Uhrzeit:">
                                    <b-form-input v-model="executionPlanned.time" type="time" disabled />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <hr />
                        <b-card class="mb-0">
                            <b-row>
                                <b-col cols="12" sm="6" md="3">
                                    <label>Firma:</label>
                                    <b-form-select
                                        v-model="formOrder.companyID"
                                        :options="companies"
                                        value-field="companyID"
                                        text-field="name"
                                        required
                                    />
                                </b-col>
                                <b-col cols="12" sm="6" md="3">
                                    <label>Leiter:</label>
                                    <b-form-select
                                        v-model="formOrder.managerID"
                                        :options="managers"
                                        value-field="userID"
                                        text-field="fullName"
                                        required
                                        :state="compManagerValidationSuccess"
                                    />
                                    <b-form-invalid-feedback id="input-live-feedback">
                                        "Leiter" ist ein Pflichtfeld
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" sm="6" md="3">
                                    <label>Techniker:</label>
                                    <b-form-select
                                        v-model="formOrder.technicianID"
                                        :options="technicians"
                                        value-field="userID"
                                        text-field="fullName"
                                        required
                                        :state="compTechnikerValidationSuccess"
                                    />
                                    <b-form-invalid-feedback id="input-live-feedback">
                                        "Techniker" ist ein Pflichtfeld
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" sm="6" md="3">
                                    <label class="mr-2">Status:</label>
                                    <b-form-select
                                        v-model="formOrder.auftragStatusID"
                                        :options="compOrderStatuses"
                                        value-field="auftragStatusID"
                                        text-field="label"
                                        required
                                        :disabled="!compFormAuftragStatusCanBeChanged"
                                    />
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col cols="12" sm="6" md="3">
                                    <label>Ab:</label>
                                    <b-form-datepicker
                                        v-model="formOrder.fromDate"
                                        required
                                        locale="de"
                                        :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                                    />
                                </b-col>
                                <b-col cols="12" sm="6" md="3">
                                    <label>Bis:</label>
                                    <b-form-datepicker
                                        v-model="formOrder.toDate"
                                        required
                                        locale="de"
                                        :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                                    />
                                </b-col>
                                <b-col cols="12" sm="6" md="3">
                                    <label>Geplante Ausführung:</label>
                                    <b-form-datepicker
                                        v-model="formOrder.executionPlannedDate"
                                        required
                                        locale="de"
                                        :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                                    />
                                </b-col>
                                <b-col cols="12" sm="6" md="3">
                                    <label>Uhrzeit:</label>
                                    <b-form-input
                                        v-model="formOrder.executionPlannedTime"
                                        type="time"
                                        :state="compExecutionPlannedTimeValidationSuccess"
                                        :disabled="[null, undefined, ''].includes(this.formOrder.executionPlannedDate)"
                                    />
                                    <b-form-invalid-feedback id="input-live-feedback">
                                        Wenn das Datum der geplanten Ausführung gesetzt ist, ist die Uhrzeit ein
                                        Pflichtfeld.
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>

                            <b-button
                                class="mt-2"
                                variant="primary"
                                @click="onOrderSave"
                                :disabled="compButtonOrderSaveDisabled"
                            >
                                Speichern
                            </b-button>
                            <b-alert
                                v-if="!compAuftragDataCanBeEdited"
                                variant="info"
                                class="mt-1 mb-0 font-italic text-black-50"
                                show
                            >
                                <div>
                                    Der Auftrag darf in diesem Status nicht geändert werden.
                                </div>
                            </b-alert>
                            <template v-if="compAuftragDataCanBeEdited">
                                <b-alert
                                    v-if="compFormOrderChangesToSave"
                                    variant="info"
                                    class="mt-1 mb-0 font-italic text-black-50"
                                    show
                                >
                                    <div>Änderungen:</div>
                                    <div class="pl-2">
                                        <div v-if="compAuftragStatusIDEdited">
                                            Status: {{ formOrder.auftragStatusID }}
                                        </div>
                                        <div v-if="compManagerIdEdited">Leiter ID: {{ formOrder.managerID }}</div>
                                        <div v-if="compTechnicianIdEdited">
                                            Techniker ID: {{ formOrder.technicianID }}
                                        </div>
                                        <div v-if="compFromDateEdited">Ab: {{ formOrder.fromDate }}</div>
                                        <div v-if="compToDateEdited">Bis: {{ formOrder.toDate }}</div>
                                        <div v-if="compExecutionDatePlannedEdited">
                                            Geplante Ausführung: {{ formOrder.executionPlannedDate }}
                                            {{ formOrder.executionPlannedTime }}
                                        </div>
                                    </div>
                                </b-alert>
                                <b-alert v-else variant="info" class="mt-1 mb-0 font-italic text-black-50" show>
                                    Es gibt keine Änderungen zu speichern.
                                </b-alert>
                            </template>
                        </b-card>
                    </b-tab>
                </b-tabs>
                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Kontakt" active>
                        <Contacts v-if="contact" app="rellout" :entityID="order.hakID" :entity="'hak'" />
                    </b-tab>
                </b-tabs>

                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Aktionen auf dem HAK">
                        <OrderDetailsProcessOverviewTable :orderedAuftragDetails="auftragDetailsStart" />
                    </b-tab>
                </b-tabs>
                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Aktionen auf dem Gerätestandort">
                        <b-tabs
                            content-class="mt-3"
                            class="mt-3 pb-3 border-bottom"
                            active-nav-item-class="font-weight-bold cursor-default"
                        >
                            <b-tab
                                :title="orderedArray.devicepoolName"
                                v-for="orderedArray in auftragDetailsOther"
                                :key="orderedArray.devicepool"
                            >
                                <OrderDetailsProcessOverviewTable :orderedAuftragDetails="orderedArray.data" />
                            </b-tab>
                        </b-tabs>
                    </b-tab>
                </b-tabs>
                <b-tabs
                    content-class="mt-3"
                    class="mt-3 pb-3 border-bottom"
                    active-nav-item-class="font-weight-bold cursor-default"
                >
                    <b-tab title="Abschluss">
                        <OrderDetailsProcessOverviewTable :orderedAuftragDetails="auftragDetailsEnd" />
                    </b-tab>
                </b-tabs>
            </template>
        </template>
    </div>
</template>

<script>
import _ from "lodash";

import Contacts from "@/components/general/Contacts";
import usersAPI from "@/services/api/users.api";
import constants from "@/constants/constants";
import ReSpinner from "@/components/energyspace/ReSpinner";
import OrderDetailsProcessOverviewTable from "@/views/orders/OrderDetailsProcessOverviewTable";
import devicePoolsAPI from "@/services/api/devicepools.api";
import contactsAPI from "@/services/api/contacts.api";
import auftragStatusesAPI from "@/services/api/auftragStatuses.api";
import companiesAPI from "@/services/api/companies.api";
import auftragsAPI from "@/services/api/auftrags.api";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";

export default {
    name: "OrderDetails",
    components: {
        Contacts,
        ReSpinner,
        OrderDetailsProcessOverviewTable,
    },

    data() {
        return {
            auftragDetails: [],
            auftragExists: true,
            companies: [],
            companyId: null,
            contact: null,
            // helper to handle date and time changes for order.executionDatePlanned
            // note: date and time had to be split into separate fields because chrome lost support for datetime in version 26, Opera in version 15, and Safari in iOS 7
            //   source: https://bootstrap-vue.org/docs/components/form-input#input-type
            executionPlanned: {
                date: null,
                time: null,
            },
            formOrder: {
                companyID: null,
                managerID: null,
                technicianID: null,
                auftragStatusID: null,
                fromDate: null,
                toDate: null,
                executionPlannedDate: null,
                executionPlannedTime: null,
            },
            isLoadingOrder: true,
            isSavingContact: false,
            isSavingOrder: false,
            managers: [{ userID: null, fullName: "" }],
            order: null,
            orderStatuses: [],
            // all possible values
            orderStatusesBase: [],
            // for simplicity this is hard coded -> ToDo: populate this in a "dynamic" way
            orderStatusesRestricted: [
                { auftragStatusID: "created", label: "Erstellt" },
                { auftragStatusID: "assigned", label: "Zugewiesen" },
                { auftragStatusID: "accepted", label: "Angenommen" },
            ],
            tableFields: [
                { key: "action.step", label: "Schritt" },
                { key: "action.group.name", label: "Aktionsgruppe" },
                { key: "action.item.name", label: "Aktion" },
                { key: "status", label: "Status" },
            ],
            technicians: [{ userID: null, fullName: "" }],
            tableFieldsHAK: [
                { key: "action.step", label: "Schritt" },
                { key: "action.group.name", label: "Aktionsgruppe" },
                { key: "action.item.name", label: "Aktion" },
                { key: "status", label: "Status" },
            ],
            tabTitleArray: [],
            users: [],
        };
    },
    computed: {
        auftragDetailsStart() {
            const filteredAuftragDetails = _.filter(this.auftragDetails, function(as) {
                // 13: Inspektion / Vorarbeiten
                // 14: HAK Vorbereitung
                return [1, 2, 3, 4].includes(as.action.group.id);
            });
            return this.sortByStep(filteredAuftragDetails);
        },
        auftragDetailsOther() {
            const filteredAuftragDetails = _.filter(this.auftragDetails, function(as) {
                // 15: Rundsteuerungsmanagement
                // 16: Zählermanagement
                // 20: Andere gerätemanagement
                return [5, 6, 7, 8].includes(as.action.group.id);
            });
            const auftragDetailsSortedByStep = this.sortByStep(filteredAuftragDetails);
            const auftragDetailsSortedByDevicepool = this.sortByDevicepool(auftragDetailsSortedByStep);
            let sortedArray = Object.entries(auftragDetailsSortedByDevicepool).map(([devicepool, data]) => ({
                devicepool,
                data,
            }));
            sortedArray = this.setTabTitle(sortedArray);
            return sortedArray;
        },
        auftragDetailsEnd() {
            const filteredAuftragDetails = _.filter(this.auftragDetails, function(as) {
                // 18: Abschluss
                return [9].includes(as.action.group.id);
            });
            return this.sortByStep(filteredAuftragDetails);
        },
        compOrderStatuses() {
            return this.orderStatuses;
        },
        orderID() {
            return Number(this.$route.params.id);
        },
        compAuftragDataCanBeEdited() {
            return ["NEW", "ERROR", "CREATED", "ASSIGNED", "ACCEPTED"].includes(
                this.formOrder.auftragStatusID?.toUpperCase()
            );
        },
        compFormAuftragStatusCanBeChanged() {
            return ["CREATED", "ASSIGNED", "ACCEPTED"].includes(this.formOrder.auftragStatusID?.toUpperCase());
        },
        compFormOrderChangesToSave() {
            return (
                !!(
                    this.compAuftragStatusIDEdited ||
                    this.compManagerIdEdited ||
                    this.compFromDateEdited ||
                    this.compTechnicianIdEdited ||
                    this.compToDateEdited ||
                    this.compExecutionDatePlannedEdited
                ) && this.compExecutionPlannedTimeValidationSuccess
            );
        },
        compButtonOrderSaveDisabled() {
            if (this.isSavingOrder) {
                return true;
            }
            // disable button if the auftrag is NOT in the following states
            if (!this.compAuftragDataCanBeEdited) {
                return true;
            }
            // form validation checks
            if (!this.compManagerValidationSuccess || !this.compTechnikerValidationSuccess) {
                return true;
            }

            if (!this.compFormOrderChangesToSave) {
                // disabled if there are no changes
                return true;
            }

            // if the code arrives here, all is good and button is enabled
            return false;
        },
        compManagerValidationSuccess() {
            switch (this.formOrder.auftragStatusID.toUpperCase()) {
                case "NEW":
                case "ERROR":
                case "CREATED":
                    return true;
                default:
                    return this.formOrder.managerID !== null;
            }
        },
        compExecutionPlannedTimeValidationSuccess() {
            if ([null, undefined, ""].includes(this.formOrder.executionPlannedDate)) {
                return true;
            } else {
                return ![null, undefined, ""].includes(this.formOrder.executionPlannedTime);
            }
        },
        compTechnikerValidationSuccess() {
            switch (this.formOrder.auftragStatusID.toUpperCase()) {
                case "NEW":
                case "ERROR":
                case "CREATED":
                    return true;
                default:
                    return this.formOrder.technicianID !== null;
            }
        },
        compAuftragStatusIDEdited() {
            return this.formOrder.auftragStatusID !== this.order.auftragStatusID;
        },
        compExecutionDatePlannedEdited() {
            return this.compExecutionDatePlannedDateEdited || this.compExecutionDatePlannedTimeEdited;
        },
        compExecutionDatePlannedDateEdited() {
            // note(udo): I'm sorry this got so hacky :| -> I was unable to find a better solution
            if ([null, undefined, ""].includes(this.formOrder.executionPlannedDate)) {
                // checked like this because we can have "null", "undefined" and(!) "" as values
                return ![null, undefined, ""].includes(this.executionPlanned.date);
            }
            return this.formOrder.executionPlannedDate !== this.executionPlanned.date;
        },
        compExecutionDatePlannedTimeEdited() {
            // note(udo): I'm sorry this got so hacky :| -> I was unable to find a better solution
            if ([null, undefined, ""].includes(this.formOrder.executionPlannedTime)) {
                // checked like this because we can have "null", "undefined" and(!) "" as values
                return ![null, undefined, ""].includes(this.executionPlanned.time);
            }
            return this.formOrder.executionPlannedTime !== this.executionPlanned.time;
        },
        compFromDateEdited() {
            return this.formOrder.fromDate !== this.order.fromDate;
        },
        compManagerIdEdited() {
            return this.formOrder.managerID !== this.order.managerID;
        },
        compTechnicianIdEdited() {
            return this.formOrder.technicianID !== this.order.technicianID;
        },
        compToDateEdited() {
            return this.formOrder.toDate !== this.order.toDate;
        },
    },
    methods: {
        getContacts(parentTable = "hak", parentId) {
            return contactsAPI.get({ parentTable: parentTable, parentID: parentId }).then((resp) => {
                this.contact = resp[0] ?? null;
            });
        },
        getTabTitle(devicePoolId) {
            if (devicePoolId) {
                return devicePoolsAPI.getSingle(devicePoolId).then((devicepool) => {
                    let position = devicepool.data.position || "";
                    let positionDetail = devicepool.data.positionDetail || "";
                    let address = devicepool.data.address || "";

                    if (this.isHakSameAsDevicePoolAddress(devicepool, this.order)) {
                        address = "";
                    }

                    return [position, positionDetail, address].filter(Boolean).join(", ");
                });
            }
            return "";
        },
        isHakSameAsDevicePoolAddress(devicepool, order) {
            if (devicepool?.data?.address && order?.hak?.address) {
                let hakAddress = order.hak.address;
                let devicePoolAddress = devicepool.data.address;
                hakAddress = hakAddress.toLowerCase().replace(/\s/g, "");
                devicePoolAddress = devicePoolAddress.toLowerCase().replace(/\s/g, "");
                return hakAddress == devicePoolAddress;
            }

            return true;
        },
        setTabTitle(sortedArray) {
            return sortedArray.map((item) => {
                if (item.devicepool) {
                    this.tabTitleArray.filter((tab) => {
                        if (tab.devicepoolId.toString() === item.devicepool) {
                            item.devicepoolName = tab.title;
                        }
                    });
                }
                return item;
            });
        },
        // initial data loading (note: it is a function because we call it in mounted() and after auftrag save)
        initLoadData() {
            this.loadOrder(this.orderID).then(() => {
                this.getContacts("hak", this.order.hakID);
                this.loadAuftragDetails(this.order.auftragID);

                // ToDo: review this -> looks a bit hacky -> companyID should be on table auftrag -> Auftrag.CompanyID
                if (this.order.managerID) {
                    usersAPI.getSingle(this.order.managerID).then((resp) => {
                        // get the companyID of the manager
                        const companyId = resp.data.company.companyID;
                        this.companyId = companyId;
                        this.formOrder.companyID = companyId;

                        // populate form data
                        this.formOrder.companyID = companyId;
                        // load managers and technicians based on determined company
                        this.loadManagers(companyId);
                        this.loadTechnicians(companyId);
                    });
                }
            });
        },
        loadAuftragStatuses() {
            auftragStatusesAPI.get().then((resp) => {
                this.orderStatusesBase = resp.data;
                this.orderStatuses = resp.data;
            });
        },
        loadCompanies() {
            companiesAPI.get().then((resp) => {
                this.companies = resp.data;
            });
        },
        loadUsers(companyId) {
            return usersAPI
                .get({
                    companyID: companyId,
                })
                .then((resp) => {
                    this.users = resp.data;
                    return resp;
                });
        },
        loadManagers(companyId) {
            return usersAPI
                .get({
                    companyID: companyId,
                    roleID: constants.LEITER_ROLE_ID,
                })
                .then((resp) => {
                    this.managers.push(...resp.data);
                    return resp;
                })
                .then(() => {
                    const managerFound = !!this.managers.find((m) => m.userID === this.order.managerID);
                    if (managerFound === false) {
                        // manager not found -> remove manager and technician from form
                        this.formOrder.managerID = null;
                        this.formOrder.technicianID = null;
                    }
                });
        },
        loadTechnicians(companyId) {
            return usersAPI
                .get({
                    companyID: companyId,
                    roleID: constants.TECHNICIAN_ROLE_ID,
                })
                .then((resp) => {
                    this.technicians.push(...resp.data);
                    return resp;
                })
                .then(() => {
                    const technicianFound = !!this.technicians.find((m) => m.userID === this.order.technicianID);
                    if (technicianFound === false) {
                        // technician not found -> remove technician from form
                        this.formOrder.technicianID = null;
                    }
                });
        },
        loadOrder(id) {
            this.isLoadingOrder = true;
            return auftragsAPI
                .getSingle(id)
                .then((resp) => {
                    const respData = JSON.parse(JSON.stringify(resp.data));
                    this.order = respData;
                    this.order.fromDate = respData.fromDate.slice(0, 10);
                    this.order.toDate = respData.toDate.slice(0, 10);
                    this.executionPlanned.date = respData.executionDatePlanned?.slice(0, 10);
                    this.executionPlanned.time = respData.executionDatePlanned?.slice(11, 16);

                    // populate form data with order props
                    this.formOrder.managerID = respData.managerID;
                    this.formOrder.technicianID = respData.technicianID;
                    this.formOrder.auftragStatusID = respData.auftragStatusID;
                    this.formOrder.fromDate = this.order.fromDate;
                    this.formOrder.toDate = this.order.toDate;
                    this.formOrder.executionPlannedDate = this.executionPlanned.date
                        ? this.executionPlanned.date
                        : null;
                    this.formOrder.executionPlannedTime = this.executionPlanned.time
                        ? this.executionPlanned.time
                        : null;
                })
                .catch((e) => {
                    // ToDo: currently the endpoint responds with a 404 if no record is found. this of course should be a 204...
                    if ([204, 404].includes(e.response.status)) {
                        this.auftragExists = false;
                    }
                })
                .finally(() => {
                    this.isLoadingOrder = false;
                });
        },
        loadAuftragDetails(auftragID) {
            auftragDetailsAPI.get({ includeAuftragChecks: true, auftragID: auftragID }).then((resp) => {
                this.auftragDetails = resp;
                this.populateTabTitleArray();
            });
        },
        populateTabTitleArray() {
            const devicepoolIdArray = [...new Set(this.auftragDetails.map((obj) => obj.devicePoolID))];
            devicepoolIdArray.forEach((devicepoolId) => {
                if (devicepoolId !== null) {
                    this.getTabTitle(devicepoolId).then((title) =>
                        this.tabTitleArray.push({ devicepoolId: devicepoolId, title: title })
                    );
                }
            });
        },
        sortByStep(unsortedAuftragDetails) {
            return Object.entries(
                unsortedAuftragDetails.reduce((acc, obj) => {
                    const key = obj.action.step;
                    if (!acc[key]) {
                        acc[key] = { step: key, data: [] };
                    }
                    acc[key].data.push(obj);
                    return acc;
                }, {})
            ).map(([key, value]) => ({ step: key, data: value.data }));
        },
        sortByDevicepool(auftragDetailsSortedByStep) {
            return auftragDetailsSortedByStep.reduce((acc, obj) => {
                obj.data.forEach((item) => {
                    const devicepoolId = item.devicePoolID;
                    if (!acc[devicepoolId]) {
                        acc[item.devicePoolID] = [];
                        acc[item.devicePoolID].push({ step: obj.step, data: [] });
                        acc[item.devicePoolID].find((entry) => entry.step === item.stepCurrent).data.push(item);
                    } else {
                        if (!acc[item.devicePoolID].some((entry) => entry.step === obj.step)) {
                            acc[item.devicePoolID].push({ step: obj.step, data: [] });
                            acc[item.devicePoolID].find((entry) => entry.step === item.stepCurrent).data.push(item);
                        } else {
                            acc[item.devicePoolID].find((entry) => entry.step === item.stepCurrent).data.push(item);
                        }
                    }
                });
                return acc;
            }, {});
        },
        onOrderSave() {
            const payload = [];
            // build payload - BEGIN
            if (this.compExecutionDatePlannedEdited) {
                // note: form validation is quite tricky (and can be easily messed up). to prevent "odd" things from happening (as in #19113), we send "null" if the date is null.
                let executionDatePlannedValue = this.formOrder.executionPlannedDate
                    ? `${this.formOrder.executionPlannedDate}T${this.formOrder.executionPlannedTime}`
                    : null;
                payload.push({
                    op: "replace",
                    path: "/executionDatePlanned",
                    value: executionDatePlannedValue,
                });
            }
            if (this.compAuftragStatusIDEdited) {
                payload.push({
                    op: "replace",
                    path: "/auftragStatusID",
                    value: this.formOrder.auftragStatusID,
                });
            }
            if (this.compFromDateEdited) {
                payload.push({
                    op: "replace",
                    path: "/fromDate",
                    value: this.formOrder.fromDate,
                });
            }
            if (this.compToDateEdited) {
                payload.push({
                    op: "replace",
                    path: "/toDate",
                    value: this.formOrder.toDate,
                });
            }
            if (this.compManagerIdEdited) {
                payload.push({
                    op: "replace",
                    path: "/managerID",
                    value: this.formOrder.managerID,
                });
            }
            if (this.compTechnicianIdEdited) {
                payload.push({
                    op: "replace",
                    path: "/technicianID",
                    value: this.formOrder.technicianID,
                });
            }
            // build payload - END

            // ToDo: disable button if there are no changes to save
            if (!payload.length) {
                this.$bvToast.toast(`Es gibt keine Änderungen zu speichern.`, {
                    title: "INFO",
                    variant: "info",
                    toaster: "b-toaster-bottom-right",
                    autoHideDelay: 5000,
                    appendToast: true,
                });
                return true;
            }

            this.isSavingOrder = true;
            auftragsAPI
                .patch(this.orderID, payload)
                .then((resp) => {
                    this.$bvToast.toast(`Auftrag erfolgreich gespeichert.`, {
                        title: "Success",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                    return resp;
                })
                .then(() => {
                    // do this because we saved the data
                    this.initLoadData();
                })
                .finally(() => {
                    this.isSavingOrder = false;
                });
        },
    },
    mounted() {
        this.loadUsers();
        this.loadCompanies();
        this.loadAuftragStatuses();
        this.initLoadData();
    },
    watch: {
        "formOrder.companyID": {
            handler: function(compIdNew, compIdBefore) {
                if (compIdBefore !== null && compIdNew !== compIdBefore) {
                    // clear fields of manager and technicians
                    this.formOrder.managerID = null;
                    this.formOrder.technicianID = null;
                    // refresh data
                    this.loadManagers(compIdNew);
                    this.loadTechnicians(compIdNew);
                    // set the form data to the original value
                    this.formOrder.auftragStatusID = this.order.auftragStatusID;
                }
            },
            deep: true,
        },
        "formOrder.technicianID": {
            handler: function(techId) {
                if (techId && this.formOrder.auftragStatusID === "created") {
                    this.formOrder.auftragStatusID = "assigned";
                }
            },
            deep: true,
        },
        "formOrder.auftragStatusID": {
            handler: function(auftragStatusID) {
                if (["CREATED", "ASSIGNED", "ACCEPTED"].includes(auftragStatusID.toUpperCase())) {
                    this.orderStatuses = this.orderStatusesRestricted;
                } else {
                    this.orderStatuses = this.orderStatusesBase;
                }
            },
            deep: true,
            init: true,
        },
    },
};
</script>
