<template v-if="this.id </template>> 0">
    <b-table :items="items" :fields="fields" small bordered>
        <template #head(address)="data">
            <span class="float-left">{{ data.label }}</span>
        </template>
        <template #cell(address)="data">
            <span class="float-left">{{ compAddress(data) }}</span>
        </template>
    </b-table>
</template>

<script>
import devicePoolsAPI from "@/services/api/devicepools.api";
import devicesAPI from "@/services/api/devices.api";
import deviceFlexibilitaetsAPI from "@/services/api/deviceFlexibilitaets.api";

export default {
    name: "OrderDetailsRowTable",
    data() {
        return {
            items: [],
            fields: [],
        };
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    methods: {
        compAddress(data) {
            return data.item.address + ", " + data.item.postalCode + " " + data.item.city;
        },
    },
    async mounted() {
        if (this.id > 0) {
            switch (this.type) {
                case "devicePool":
                    devicePoolsAPI.getSingle(this.id).then((response) => {
                        this.items = [response.data];
                    });
                    this.fields = [
                        {
                            key: "devicePoolID",
                            label: "Gerätestandort ID",
                            thStyle: {
                                width: "27%",
                                "padding-top": "0rem !important",
                                "padding-bottom": "0rem !important",
                            },
                        },
                        {
                            key: "address",
                            label: "Adresse",
                            thStyle: {
                                width: "45%",
                                "padding-top": "0rem !important",
                                "padding-bottom": "0rem !important",
                            },
                        },
                        {
                            key: "positionDetail",
                            label: "Position Detail",
                            thStyle: {
                                width: "28%",
                                "padding-top": "0rem !important",
                                "padding-bottom": "0rem !important",
                            },
                        },
                    ];
                    break;
                case "device":
                    devicesAPI.getSingle(this.id).then((response) => {
                        this.items = [response.data];
                    });
                    this.fields = [
                        {
                            key: "deviceID",
                            label: "Geräte ID",
                            thStyle: {
                                width: "25%",
                                "padding-top": "0rem !important",
                                "padding-bottom": "0rem !important",
                            },
                        },
                        {
                            key: "factoryNo",
                            label: "Factory No",
                            thStyle: {
                                width: "25%",
                                "padding-top": "0rem !important",
                                "padding-bottom": "0rem !important",
                            },
                        },
                        {
                            key: "deviceType",
                            label: "Gerätetyp",
                            thStyle: {
                                width: "50%",
                                "padding-top": "0rem !important",
                                "padding-bottom": "0rem !important",
                            },
                        },
                    ];
                    break;
                case "flexibility":
                    deviceFlexibilitaetsAPI
                        .getSingle(this.id, {
                            includeDevices: true,
                        })
                        .then((response) => {
                            this.items = [response.data];
                        });
                    this.fields = [
                        {
                            key: "deviceFlexibilitaet.iseDeviceID",
                            label: "ISE ID",
                            thStyle: {
                                "padding-top": "0rem !important",
                                "padding-bottom": "0rem !important",
                                width: "20%",
                            },
                        },
                        {
                            key: "anlagetyp.text",
                            label: "Anlagetyp",
                            thStyle: {
                                "padding-top": "0rem !important",
                                "padding-bottom": "0rem !important",
                                width: "80%",
                            },
                        },
                    ];
                    break;
                default:
                    break;
            }
        }
    },
};
</script>
