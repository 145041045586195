<template>
    <div>
        <div v-if="isLoading" style="color: #ff463c" class="d-flex justify-content-center">
            <b-spinner></b-spinner>
        </div>
        <DxScheduler
            ref="schedulerRefKey"
            :time-zone="timeZone"
            :current-date="currentDate"
            :height="height"
            :start-day-hour="startDayHour"
            :current-view="currentView"
            :views="views"
            :cell-duration="cellDuration"
            :data-source="dataSource"
            :on-appointment-updating="updateAppointment"
            :on-appointment-form-opening="onOpenAppointmentForm"
            :on-appointment-adding="addAppointment"
            appointment-template="appointment"
            appointment-tooltip-template="appointmentTooltipTemplate"
        >
            <DxEditing :allow-dragging="false" :allow-resizing="false" />
            <template #appointment="{ data }">
                <div
                    :style="{
                        backgroundColor:
                            data.appointmentData.absenceType === constants.userCompanyEnum.Company
                                ? constants.calendarColor.Company
                                : constants.calendarColor.User,
                    }"
                >
                    {{ data.appointmentData.text }}
                </div>
            </template>
            <template #appointmentTooltipTemplate="{ data }">
                <Tooltip
                    :data="data"
                    :is-delete-button-exist="isDeleteButtonExist(data)"
                    @delete-button-click="onDeleteButtonClick($event, data)"
                />
            </template>
        </DxScheduler>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import { DxEditing, DxScheduler } from "devextreme-vue/scheduler";

import absenceAPI from "../../services/api/absences.api";
import companiesAPI from "../../services/api/companies.api";
import usersAPI from "../../services/api/users.api";
import constants from "@/constants/constants";
import Tooltip from "@/components/user/AbsenceSchedulerTooltip.vue";

export default {
    data() {
        return {
            isLoading: true,
            dataSource: [],
            company: null,
            user: null,
            editedAppointment: {
                allDay: false,
                startDate: "",
                endDate: "",
                text: "",
            },
        };
    },
    props: {
        timeZone: String,
        currentDate: { type: Date },
        height: { type: String },
        startDayHour: { type: Number },
        currentView: { type: String },
        views: { type: Array },
        cellDuration: { type: Number },
        companyID: { type: Number, required: true },
        userID: { type: Number, required: false }, // null in case of Company page
    },
    components: {
        DxScheduler,
        DxEditing,
        Tooltip,
    },
    async mounted() {
        await this.loadData();
    },
    computed: {
        ...mapGetters(["currentUser"]),
        isUserView() {
            return !!this.userID; // if userID is not null, then the component is in the User view
        },
        constants() {
            return constants;
        },
        scheduler: function() {
            return this.$refs["schedulerRefKey"].instance;
        },
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            this.dataSource = [];
            let companyPromise = companiesAPI.getSingle(this.companyID);
            let userPromise = this.userID ? usersAPI.getSingle(this.userID) : await Promise.resolve();
            let [userResponse, companyResponse] = await Promise.all([userPromise, companyPromise]);
            this.company = companyResponse.data;
            if (!this.company) {
                this.displayToast("danger", "Es wurde kein Benutzer mit dieser ID gefunden.");
                return;
            }
            this.dataSource = this.company?.absences ?? [];
            if (this.userID) {
                this.user = userResponse.data;
                this.dataSource = [...this.dataSource, ...this.user?.absences];
                if (!this.user) {
                    this.displayToast("danger", "Es wurde keine Firma mit dieser ID gefunden.");
                }
            }
            this.isLoading = false;
        },
        onOpenAppointmentForm(e) {
            const { form } = e;

            this.editedAppointment = {
                allDay: e.appointmentData.allDay,
                startDate: moment(e.appointmentData.startDate).toDate(),
                endDate: moment(e.appointmentData.endDate).toDate(),
                text: e.appointmentData.text || "",
            };
            let toolbarItems = e.popup.option("toolbarItems");
            toolbarItems.forEach((item) => {
                if (item.options && item.shortcut === "done") {
                    item.options.text = "Speichern";
                    // disable Save icon if we are in User view and the appointment is a Company one. See: https://js.devexpress.com/Vue/Documentation/ApiReference/UI_Components/dxPopup/Configuration/toolbarItems/
                    item.options.disabled = this.isCompanyDataDisabled(e.appointmentData);
                }
            });
            e.popup.option("toolbarItems", toolbarItems);

            form.option("items", [
                {
                    label: "Name:",
                    dataField: "text",
                    editorType: "dxTextBox",
                    colSpan: 2,
                    disabled: this.isCompanyDataDisabled(e.appointmentData),
                    editorOptions: {
                        width: "100%",
                        value: this.editedAppointment.text,
                    },
                },
                {
                    label: "Start Date:",
                    dataField: "startDate",
                    editorType: "dxDateBox",
                    disabled: this.isCompanyDataDisabled(e.appointmentData),
                    editorOptions: {
                        width: "100%",
                        type: "datetime",
                        onValueChanged: (args) => {
                            this.editedAppointment.startDate = moment(args.value)
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();
                            form.updateData("startDate", this.editedAppointment.startDate);
                        },
                        value: this.editedAppointment.startDate,
                    },
                },
                {
                    label: "End Date:",
                    dataField: "endDate",
                    editorType: "dxDateBox",
                    disabled: this.isCompanyDataDisabled(e.appointmentData),
                    editorOptions: {
                        width: "100%",
                        type: "datetime",
                        onValueChanged: (args) => {
                            this.editedAppointment.endDate = moment(args.value)
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();
                            form.updateData("endDate", this.editedAppointment.endDate);
                        },
                        value: this.editedAppointment.endDate,
                    },
                },
            ]);
        },
        extractRelevantData(appointmentData) {
            const { startDate, endDate, text } = appointmentData;
            return { startDate, endDate, text };
        },
        async addAppointment(e) {
            const dataToPost = {
                ...this.extractRelevantData(e.appointmentData),
                companyID: this.companyID,
                userID: this.userID || null,
            };
            const newStartDate = new Date(e.appointmentData.startDate);
            const newEndDate = new Date(e.appointmentData.endDate);

            if (newEndDate - newStartDate >= 900000 || newStartDate < newEndDate) {
                await absenceAPI.post(dataToPost);
            } else if (newStartDate > newEndDate) {
                e.cancel = true;
                this.displayToast(
                    "danger",
                    "Absenz konnten nicht gespeichert werden! Grund: Start Datum ist grösser als das End Datum"
                );
            } else {
                e.cancel = true;
                this.displayToast(
                    "danger",
                    "Absenz konnten nicht gespeichert werden! Grund: Die Mindestdauer von 15 Minuten wurde nicht erreicht."
                );
            }
            await this.loadData();
            this.resetEditedAppointment();
        },
        updateAppointment(e) {
            if (e && e.oldData && e.newData) {
                const newStartDate = moment(e.newData.startDate).toDate();
                const newEndDate = moment(e.newData.endDate).toDate();

                if (!_.isEqual(e.oldData, e.newData)) {
                    if (newEndDate - newStartDate >= 900000) {
                        // minimum duration of 15 minutes
                        const patchPayload = this.generatePatchPayload(e.oldData, e.newData);
                        const matchingAppointment = this.findAbsenceID(e);
                        if (matchingAppointment) {
                            const absenceIDToUpdate = matchingAppointment.absenceID;
                            if (absenceIDToUpdate) {
                                absenceAPI.patch(absenceIDToUpdate, patchPayload);
                            } else {
                                this.displayToast(
                                    "danger",
                                    "Änderungen konnten nicht gespeichert werden. Bitte versuchen sie es später erneut!"
                                );
                            }
                        } else {
                            this.displayToast(
                                "danger",
                                "Absenz konnte nicht gefunden werden. Bitte versuchen sie es später erneut!"
                            );
                        }
                    } else {
                        e.cancel = true;
                        this.displayToast(
                            "danger",
                            "Absenz konnten nicht gespeichert werden! Grund: Die Mindestdauer von 15 Minuten wurde nicht erreicht."
                        );
                    }
                }
            }
            this.resetEditedAppointment();
        },
        async deleteAppointment(appointmentData) {
            if (appointmentData) {
                // Bin icon is hidden (it should never happen)
                if (this.isUserView && appointmentData.absenceType === constants.userCompanyEnum.Company) {
                    this.displayToast("danger", "Sie können die Absenzen der Firma nicht löschen.");
                    return;
                }
                if (appointmentData?.absenceID) {
                    // deleting an appointment already persisted
                    await absenceAPI.delete(appointmentData.absenceID);
                }
            } else {
                this.displayToast("danger", "Änderungen konnten nicht gespeichert werden.");
            }
        },
        isDeleteButtonExist(data) {
            if (!this.isUserView) {
                return true;
            }
            return data?.appointmentData?.absenceType !== constants.userCompanyEnum.Company;
        },
        isCompanyDataDisabled(appointmentData) {
            return this.isUserView && appointmentData?.absenceType === constants.userCompanyEnum.Company;
        },
        async onDeleteButtonClick(e, data) {
            e.event.stopPropagation();
            await this.deleteAppointment(data?.appointmentData);
            console.log("Data to delete", data);
            // Since using TooltipTemplate we need to manually delete the appointment from the DataSource
            this.scheduler.deleteAppointment(data?.appointmentData);
            this.scheduler.hideAppointmentTooltip();
        },
        generatePatchPayload(oldData, newData) {
            const patchPayload = [];
            for (const key in newData) {
                if (oldData[key] !== newData[key]) {
                    patchPayload.push({
                        op: "replace",
                        path: `/${key}`,
                        value: newData[key],
                    });
                }
            }
            return patchPayload;
        },
        findAbsenceID(e) {
            return this.dataSource.find((item) => {
                if (e.appointmentData) {
                    return (
                        item.startDate === e.appointmentData.startDate &&
                        item.endDate === e.appointmentData.endDate &&
                        item.text === e.appointmentData.text
                    );
                } else if (e.oldData && e.newData) {
                    return (
                        item.startDate === e.oldData.startDate &&
                        item.endDate === e.oldData.endDate &&
                        item.text === e.oldData.text
                    );
                }
            });
        },
        displayToast(variant = "INFO", message) {
            let noAutoHide = false;
            let title;
            switch (variant.toUpperCase()) {
                case "DANGER":
                    noAutoHide = true;
                    title = "Fehler";
                    break;
                case "INFO":
                    title = "INFO";
                    break;
                case "SUCCESS":
                    title = "SUCCESS";
                    break;
                default:
                    variant = "info";
                    title = "INFO";
            }
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
        resetEditedAppointment() {
            this.editedAppointment = {
                allDay: false,
                startDate: "",
                endDate: "",
                text: "",
            };
        },
    },
};
</script>
<style>
.dx-overlay-wrapper {
    z-index: 1000 !important;
}
</style>
