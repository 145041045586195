<template>
    <b-row class="mt-4">
        <b-col class="text-center">
            <h3>Page not found</h3>
            <a href="#" v-on:click="goBack()">Back</a>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "ReNotFound",
    methods: {
        goBack: function() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
div {
    padding: 2rem;
}
</style>
