import { httpClient } from "@/services/api/client";
import constants from "@/constants/constants";

const resource = `${constants.env.API_PORTAL_URL}/emails`;

export default {
    // ********************
    // default REST methods
    // ********************
    /**
     *
     * @param deviceID: 12345
     */
    sendEdmAusbauEmail(deviceID, payload) {
        return httpClient.post(`${resource}/ausbau/${deviceID}`, payload);
    },

    sendChefmonteurMeldungEmail(orderID, payload) {
        return httpClient.post(`${resource}/meldung/${orderID}`, payload);
    },
    // **************
    // custom methods
    // **************
};
