<template>
    <div>
        <h1>HAK map</h1>
        <router-link :to="prevRoutePath">
            <b-button variant="primary">Zurück</b-button>
        </router-link>
        <hr />
        <div style="height: calc(100vh - 250px);">
            <l-map
                v-if="hak.hakID"
                style="height: 100%; width: 100%"
                :zoom="zoom"
                :center="center"
                ref="map"
                @ready="ehMapReady"
                @update:bounds="ehMapBoundsUpdated"
                @update:zoom="ehMapZoomUpdated"
            >
                <l-control-scale position="topright" :imperial="false" :metric="true"></l-control-scale>
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-circle-marker
                    v-for="h in haks"
                    :key="h.hakID"
                    :lat-lng="{ lat: h.latitude, lng: h.longitude }"
                    :color="getCircleColorByStatus(h.activeAuftrag)"
                    :fill-color="getCircleFillColorByStatus(h.activeAuftrag)"
                />
                <!-- default marker -->
                <!--
                <l-marker v-if="hak.hakID" :lat-lng="{ lat: hak.latitude, lng: hak.longitude }" />
                -->
                <!-- custom marker -->
                <l-marker v-if="hak.hakID" :lat-lng="{ lat: hak.latitude, lng: hak.longitude }">
                    <l-icon :icon-anchor="[24, 48]">
                        <img
                            :src="`${constants.env.WEB_APP_ROOT}/img/markers/map-marker-red_48.png`"
                            alt="marker icon"
                        />
                    </l-icon>
                </l-marker>
                <l-circle-marker
                    v-if="hak.hakID"
                    :lat-lng="{ lat: hak.latitude, lng: hak.longitude }"
                    color="red"
                    fill-color="red"
                />
                <div class="map-text">Zoom: {{ zoom }}</div>
            </l-map>
        </div>
    </div>
</template>

<script>
import "leaflet/dist/leaflet.css";

import { Icon } from "leaflet";
import { LCircleMarker, LIcon, LMap, LMarker, LTileLayer, LControlScale } from "vue2-leaflet";
import haksAPI from "@/services/api/haks.api";

import constants from "@/constants/constants";

export default {
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    computed: {
        prevRoutePath() {
            return this.prevRoute ? this.prevRoute.path : "/";
        },
    },
    name: "HakMap",
    components: {
        LCircleMarker,
        LIcon,
        LMap,
        LMarker,
        LTileLayer,
        LControlScale,
    },
    data() {
        return {
            constants: constants,
            hak: [],
            haks: [],
            url:
                "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWktcmVwb3dlciIsImEiOiJja2t6anF6dnUzMXB4MnFxbm16ejVyMXJlIn0.Wf_akGZUp0tghsSMYJRf4g",
            attribution:
                '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> | &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a> | <a href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>',
            //url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            //attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 18,
            zoomSelected: 18,
            center: [],
        };
    },
    methods: {
        boundsToBbox(bounds) {
            return [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
        },
        async ehMapBoundsUpdated(bounds) {
            const bbox = this.boundsToBbox(bounds);
            this.haks = await this.haksGetByBbox(bbox);
        },
        async ehMapReady(obj) {
            obj.doubleClickZoom.disable();
            const bounds = obj.getBounds();
            const bbox = this.boundsToBbox(bounds);
            this.haks = await this.haksGetByBbox(bbox);
        },
        ehMapZoomUpdated(zoom) {
            this.zoomSelected = zoom;
        },
        getCircleColorByStatus(obj) {
            return this.getColorByStatus(obj);
        },
        getCircleFillColorByStatus(obj) {
            return this.getColorByStatus(obj);
        },
        getColorByStatus(obj) {
            if (!obj || !obj.auftragStatus) {
                return "skyblue";
            }
            return obj.auftragStatus.color;
        },
        haksGetByBbox(bbox) {
            return haksAPI.get({ bbox: bbox.toString() }).then((response) => response.data);
        },
        hakGetById(id) {
            return haksAPI.getSingle(id).then((response) => response.data);
        },
        async getData(id) {
            const hak = await this.hakGetById(id);
            this.center = [hak.latitude, hak.longitude];
            this.hak = hak;
        },
    },
    async mounted() {
        // https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
        delete Icon.Default.prototype._getIconUrl;
        Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        });
        const hakId = this.$route.params.id;
        await this.getData(hakId);
    },
};
</script>

<style scoped>
.map-text {
    position: absolute !important;
    z-index: 999;
    top: 1em !important;
    left: 5em !important;
    padding: 2px 6px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 2px;
}
</style>
