<template>
    <div v-if="meterRegisters.length">
        <b-row class="font-weight-bold">
            <b-col class="cst-col-name"></b-col>
            <b-col class="cst-col-data">Summe</b-col>
            <b-col class="cst-col-data">HT</b-col>
            <b-col class="cst-col-data">NT</b-col>
            <b-col class="cst-col-data">Tarif3</b-col>
            <b-col class="cst-col-data">Tarif4</b-col>
        </b-row>
        <b-row>
            <b-col class="cst-col-name">Wirkenergie (LGS)</b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.lgs.summe.id"
                    v-model="mrModel.wirkenergie.lgs.summe.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    disabled
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.lgs.ht.id"
                    v-model="mrModel.wirkenergie.lgs.ht.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.lgs.nt.id"
                    v-model="mrModel.wirkenergie.lgs.nt.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.lgs.tarif3.id"
                    v-model="mrModel.wirkenergie.lgs.tarif3.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.egs.tarif4.id"
                    v-model="mrModel.wirkenergie.egs.tarif4.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col class="cst-col-name">Wirkenergie (EGS)</b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.egs.summe.id"
                    v-model="mrModel.wirkenergie.egs.summe.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    disabled
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.egs.ht.id"
                    v-model="mrModel.wirkenergie.egs.ht.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.egs.nt.id"
                    v-model="mrModel.wirkenergie.egs.nt.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.egs.tarif3.id"
                    v-model="mrModel.wirkenergie.egs.tarif3.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.wirkenergie.egs.tarif4.id"
                    v-model="mrModel.wirkenergie.egs.tarif4.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col class="cst-col-name">Blindenergie Q1 ind+</b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q1.summe.id"
                    v-model="mrModel.blindenergie.q1.summe.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    disabled
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q1.ht.id"
                    v-model="mrModel.blindenergie.q1.ht.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q1.nt.id"
                    v-model="mrModel.blindenergie.q1.nt.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col class="cst-col-name">Blindenergie Q2 kap+</b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q2.summe.id"
                    v-model="mrModel.blindenergie.q2.summe.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    disabled
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q2.ht.id"
                    v-model="mrModel.blindenergie.q2.ht.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q2.nt.id"
                    v-model="mrModel.blindenergie.q2.nt.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col class="cst-col-name">Blindenergie Q3 ind-</b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q3.summe.id"
                    v-model="mrModel.blindenergie.q3.summe.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    disabled
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q3.ht.id"
                    v-model="mrModel.blindenergie.q3.ht.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q3.nt.id"
                    v-model="mrModel.blindenergie.q3.nt.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col class="cst-col-name">Blindenergie Q4 kap-</b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q4.summe.id"
                    v-model="mrModel.blindenergie.q4.summe.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    disabled
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q4.ht.id"
                    v-model="mrModel.blindenergie.q4.ht.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.blindenergie.q4.nt.id"
                    v-model="mrModel.blindenergie.q4.nt.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col class="cst-col-name">Leistungsspitze (LGS)</b-col>
            <b-col class="cst-col-data"></b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.leistungsspitze.lgs.ht.id"
                    v-model="mrModel.leistungsspitze.lgs.ht.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.leistungsspitze.lgs.nt.id"
                    v-model="mrModel.leistungsspitze.lgs.nt.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col class="cst-col-name">Leistungsspitze (EGS)</b-col>
            <b-col class="cst-col-data"></b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.leistungsspitze.egs.ht.id"
                    v-model="mrModel.leistungsspitze.egs.ht.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-if="mrModel.leistungsspitze.egs.nt.id"
                    v-model="mrModel.leistungsspitze.egs.nt.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    :disabled="!allowTelemetering || isSavingMeterRegisters"
                />
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col class="cst-col-name">Rückstellung</b-col>
            <b-col class="cst-col-data">
                <b-form-input
                    v-model="mrModel.rueckstellung.total.summe.readValue"
                    size="sm"
                    type="number"
                    step="any"
                    disabled
                />
            </b-col>
        </b-row>
        <b-button
            class="mt-2"
            variant="primary"
            @click="onMeterRegistersSave"
            :disabled="!allowTelemetering || isSavingMeterRegisters"
        >
            Speichern
        </b-button>
    </div>
</template>

<script>
import moment from "moment";

import meterRegistersAPI from "@/services/api/meterRegisters.api";

export default {
    name: "MeterRegister",
    props: {
        allowTelemetering: {
            type: Boolean,
            required: true,
        },
        deviceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isSavingMeterRegisters: false,
            meterRegisters: [],
            // mrModel - meter register model
            mrModel: {
                wirkenergie: {
                    lgs: {
                        summe: {
                            id: null,
                            readValue: 0,
                        },
                        ht: {
                            id: null,
                            readValue: 0,
                        },
                        nt: {
                            id: null,
                            readValue: 0,
                        },
                        tarif3: {
                            id: null,
                            readValue: 0,
                        },
                        tarif4: {
                            id: null,
                            readValue: 0,
                        },
                    },
                    egs: {
                        summe: {
                            id: null,
                            readValue: 0,
                        },
                        ht: {
                            id: null,
                            readValue: 0,
                        },
                        nt: {
                            id: null,
                            readValue: 0,
                        },
                        tarif3: {
                            id: null,
                            readValue: 0,
                        },
                        tarif4: {
                            id: null,
                            readValue: 0,
                        },
                    },
                },
                blindenergie: {
                    q1: {
                        summe: {
                            id: null,
                            readValue: 0,
                        },
                        ht: {
                            id: null,
                            readValue: 0,
                        },
                        nt: {
                            id: null,
                            readValue: 0,
                        },
                    },
                    q2: {
                        summe: {
                            id: null,
                            readValue: 0,
                        },
                        ht: {
                            id: null,
                            readValue: 0,
                        },
                        nt: {
                            id: null,
                            readValue: 0,
                        },
                    },
                    q3: {
                        summe: {
                            id: null,
                            readValue: 0,
                        },
                        ht: {
                            id: null,
                            readValue: 0,
                        },
                        nt: {
                            id: null,
                            readValue: 0,
                        },
                    },
                    q4: {
                        summe: {
                            id: null,
                            readValue: 0,
                        },
                        ht: {
                            id: null,
                            readValue: 0,
                        },
                        nt: {
                            id: null,
                            readValue: 0,
                        },
                    },
                },
                leistungsspitze: {
                    lgs: {
                        ht: {
                            id: null,
                            readValue: 0,
                        },
                        nt: {
                            id: null,
                            readValue: 0,
                        },
                    },
                    egs: {
                        ht: {
                            id: null,
                            readValue: 0,
                        },
                        nt: {
                            id: null,
                            readValue: 0,
                        },
                    },
                },
                rueckstellung: {
                    // key "total" has been introduced so the data is nested at the same level as the other
                    // important for "onMeterRegistersSave"
                    total: {
                        summe: {
                            id: null,
                            readValue: 0,
                        },
                    },
                },
            },
        };
    },
    computed: {
        compMrModel() {
            return this.mrModel;
        },
    },
    methods: {
        loadData() {
            meterRegistersAPI
                .get({
                    deviceID: this.deviceId,
                    readingDate: "last",
                })
                .then((resp) => {
                    this.meterRegisters = resp.data;
                })
                .then(() => {
                    this.populateMrModelObject();
                });
        },
        onMeterRegistersSave() {
            const payloads = [];
            const readingDate = moment().format("YYYY-MM-DD");
            for (const property_l1 in this.mrModel) {
                for (const property_l2 in this.mrModel[property_l1]) {
                    for (const property_l3 in this.mrModel[property_l1][property_l2]) {
                        //console.log(`${JSON.stringify(this.mrModel[property_l1][property_l2][property_l3])}`);
                        const id = this.mrModel[property_l1][property_l2][property_l3].id;
                        const readValue = this.mrModel[property_l1][property_l2][property_l3].readValue;
                        if (id) {
                            // id is not null -> get the original record
                            const mrRecordOrig = this.meterRegisters.find((mr) => mr.meterRegisterID === id);
                            // clone original record
                            const mrRecordNew = JSON.parse(JSON.stringify(mrRecordOrig));
                            // remove fields which should not be part of the payload -> ToDo: remove when api is fixed
                            delete mrRecordNew.meterRegisterID;
                            delete mrRecordNew.creationName;
                            delete mrRecordNew.creationDate;
                            delete mrRecordNew.revisionName;
                            delete mrRecordNew.revisionDate;
                            // update properties
                            mrRecordNew.readingDate = readingDate;
                            mrRecordNew.readValue = Number(readValue);
                            // add record to payloads array
                            payloads.push(mrRecordNew);
                        }
                    }
                }
            }

            this.isSavingMeterRegisters = true;
            const promisesMeterRegistersPost = [];
            payloads.forEach((payload) => {
                promisesMeterRegistersPost.push(meterRegistersAPI.post(payload));
            });
            Promise.all(promisesMeterRegistersPost)
                .catch((err) => {
                    this.$bvToast.toast(`${err.response?.data?.Message}`, {
                        title: "Fehler",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    });
                })
                .finally(() => {
                    this.isSavingMeterRegisters = false;
                })
                .then(() => {
                    this.loadData();
                });
        },
        populateMrModelObject() {
            this.meterRegisters.forEach((mr) => {
                switch (mr.obis) {
                    // rückstellung
                    case "1-1:0.1.0":
                        this.mrModel.rueckstellung.total.summe.id = mr.meterRegisterID;
                        this.mrModel.rueckstellung.total.summe.readValue = mr.readValue;
                        break;
                    // leistungsspitze (lgs)
                    case "1-1:1.6.1":
                        this.mrModel.leistungsspitze.lgs.ht.id = mr.meterRegisterID;
                        this.mrModel.leistungsspitze.lgs.ht.readValue = mr.readValue;
                        break;
                    case "1-1:1.6.2":
                        this.mrModel.leistungsspitze.lgs.nt.id = mr.meterRegisterID;
                        this.mrModel.leistungsspitze.lgs.nt.readValue = mr.readValue;
                        break;
                    // leistungsspitze (egs)
                    case "1-1:2.6.1":
                        this.mrModel.leistungsspitze.egs.ht.id = mr.meterRegisterID;
                        this.mrModel.leistungsspitze.egs.ht.readValue = mr.readValue;
                        break;
                    case "1-1:2.6.2":
                        this.mrModel.leistungsspitze.egs.nt.id = mr.meterRegisterID;
                        this.mrModel.leistungsspitze.egs.nt.readValue = mr.readValue;
                        break;
                    // wirkenergie (lgs)
                    case "1-1:1.8.0":
                        this.mrModel.wirkenergie.lgs.summe.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.lgs.summe.readValue = mr.readValue;
                        break;
                    case "1-1:1.8.1":
                        this.mrModel.wirkenergie.lgs.ht.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.lgs.ht.readValue = mr.readValue;
                        break;
                    case "1-1:1.8.2":
                        this.mrModel.wirkenergie.lgs.nt.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.lgs.nt.readValue = mr.readValue;
                        break;
                    case "1-1:1.8.3":
                        this.mrModel.wirkenergie.lgs.tarif3.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.lgs.tarif3.readValue = mr.readValue;
                        break;
                    case "1-1:1.8.4":
                        this.mrModel.wirkenergie.lgs.tarif4.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.lgs.tarif4.readValue = mr.readValue;
                        break;
                    // wirkenergie (egs)
                    case "1-1:2.8.0":
                        this.mrModel.wirkenergie.egs.summe.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.egs.summe.readValue = mr.readValue;
                        break;
                    case "1-1:2.8.1":
                        this.mrModel.wirkenergie.egs.ht.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.egs.ht.readValue = mr.readValue;
                        break;
                    case "1-1:2.8.2":
                        this.mrModel.wirkenergie.egs.nt.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.egs.nt.readValue = mr.readValue;
                        break;
                    case "1-1:2.8.3":
                        this.mrModel.wirkenergie.egs.tarif3.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.egs.tarif3.readValue = mr.readValue;
                        break;
                    case "1-1:2.8.4":
                        this.mrModel.wirkenergie.egs.tarif4.id = mr.meterRegisterID;
                        this.mrModel.wirkenergie.egs.tarif4.readValue = mr.readValue;
                        break;
                    // blindenergie q1
                    case "1-1:5.8.0":
                        this.mrModel.blindenergie.q1.summe.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q1.summe.readValue = mr.readValue;
                        break;
                    case "1-1:5.8.1":
                        this.mrModel.blindenergie.q1.ht.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q1.ht.readValue = mr.readValue;
                        break;
                    case "1-1:5.8.2":
                        this.mrModel.blindenergie.q1.nt.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q1.nt.readValue = mr.readValue;
                        break;
                    // blindenergie q2
                    case "1-1:6.8.0":
                        this.mrModel.blindenergie.q2.summe.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q2.summe.readValue = mr.readValue;
                        break;
                    case "1-1:6.8.1":
                        this.mrModel.blindenergie.q2.ht.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q2.ht.readValue = mr.readValue;
                        break;
                    case "1-1:6.8.2":
                        this.mrModel.blindenergie.q2.nt.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q2.nt.readValue = mr.readValue;
                        break;
                    // blindenergie q3
                    case "1-1:7.8.0":
                        this.mrModel.blindenergie.q3.summe.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q3.summe.readValue = mr.readValue;
                        break;
                    case "1-1:7.8.1":
                        this.mrModel.blindenergie.q3.ht.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q3.ht.readValue = mr.readValue;
                        break;
                    case "1-1:7.8.2":
                        this.mrModel.blindenergie.q3.nt.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q3.nt.readValue = mr.readValue;
                        break;
                    // blindenergie q4
                    case "1-1:8.8.0":
                        this.mrModel.blindenergie.q4.summe.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q4.summe.readValue = mr.readValue;
                        break;
                    case "1-1:8.8.1":
                        this.mrModel.blindenergie.q4.ht.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q4.ht.readValue = mr.readValue;
                        break;
                    case "1-1:8.8.2":
                        this.mrModel.blindenergie.q4.nt.id = mr.meterRegisterID;
                        this.mrModel.blindenergie.q4.nt.readValue = mr.readValue;
                        break;
                    default:
                        break;
                }
            });
        },
    },
    mounted() {
        this.loadData();
    },
    watch: {
        compMrModel: {
            deep: true,
            handler() {
                // update fields "summe" when a value has changed
                this.mrModel.wirkenergie.lgs.summe.readValue =
                    Number(this.mrModel.wirkenergie.lgs.ht.readValue) +
                    Number(this.mrModel.wirkenergie.lgs.nt.readValue) +
                    Number(this.mrModel.wirkenergie.lgs.tarif3.readValue) +
                    Number(this.mrModel.wirkenergie.lgs.tarif4.readValue);
                this.mrModel.wirkenergie.egs.summe.readValue =
                    Number(this.mrModel.wirkenergie.egs.ht.readValue) +
                    Number(this.mrModel.wirkenergie.egs.nt.readValue) +
                    Number(this.mrModel.wirkenergie.egs.tarif3.readValue) +
                    Number(this.mrModel.wirkenergie.egs.tarif4.readValue);
                this.mrModel.blindenergie.q1.summe.readValue =
                    Number(this.mrModel.blindenergie.q1.ht.readValue) +
                    Number(this.mrModel.blindenergie.q1.nt.readValue);
                this.mrModel.blindenergie.q2.summe.readValue =
                    Number(this.mrModel.blindenergie.q2.ht.readValue) +
                    Number(this.mrModel.blindenergie.q2.nt.readValue);
                this.mrModel.blindenergie.q3.summe.readValue =
                    Number(this.mrModel.blindenergie.q3.ht.readValue) +
                    Number(this.mrModel.blindenergie.q3.nt.readValue);
                this.mrModel.blindenergie.q4.summe.readValue =
                    Number(this.mrModel.blindenergie.q4.ht.readValue) +
                    Number(this.mrModel.blindenergie.q4.nt.readValue);
            },
        },
    },
};
</script>

<style scoped>
.cst-col-name {
    font-weight: bold;
    max-width: 200px;
}
.cst-col-data {
    max-width: 130px;
    padding: 0 10px 0 0;
}
</style>
