<template>
    <div>
        <span v-b-tooltip.hover :title="compTooltipContent" :class="compClass">
            <font-awesome-icon :icon="['fas', compFontAwesomeIcon]" /> {{ compStatus }}
        </span>
        <span
            v-if="displayTooltipTodo"
            v-b-tooltip.hover
            title="ToDo (#18013): add code to ETL to display 'QUEUED', 'RUNNING', 'ERROR' or 'SUCCESS'"
            class="ml-1"
        >
            <font-awesome-icon :icon="['fas', 'person-digging']" />
        </span>
    </div>
</template>

<script>
export default {
    name: "EtlStatus",
    props: {
        detail: {
            type: String,
            default: "",
        },
        displayTooltipTodo: {
            type: Boolean,
            default: false,
        },
        // ETL short name
        name: {
            type: String,
            default: "ETL",
        },
        status: {
            type: String,
            default: "",
        },
    },
    computed: {
        compClass() {
            let compClass = "re-link-deco";
            switch (this.compStatus) {
                case "QUEUED":
                    compClass += " text-info";
                    break;
                case "RUNNING":
                    compClass += " text-warning";
                    break;
                case "SUCCESS":
                    // do nothing
                    break;
                case "ERROR":
                    compClass += " text-danger";
                    break;
                default:
                    compClass += " text-danger";
                    break;
            }
            return compClass;
        },
        compFontAwesomeIcon() {
            let fontAwesomeIcon = "";
            switch (this.compStatus) {
                case "QUEUED":
                    fontAwesomeIcon = "hourglass-start";
                    break;
                case "RUNNING":
                    fontAwesomeIcon = "rotate";
                    break;
                case "ERROR":
                    fontAwesomeIcon = "exclamation-triangle";
                    break;
                case "SUCCESS":
                    fontAwesomeIcon = "circle-check";
                    break;
                default:
                    fontAwesomeIcon = "exclamation-triangle";
                    break;
            }
            return fontAwesomeIcon;
        },
        compStatus() {
            return [null, ""].includes(this.status) ? "QUEUED" : this.status.toUpperCase();
        },
        compTooltipContent() {
            let tooltipContent = `'${this.name}' `;
            switch (this.compStatus) {
                case "QUEUED":
                    tooltipContent += `is QUEUED but not started yet`;
                    break;
                case "RUNNING":
                    tooltipContent += `is currently RUNNING (but not completed yet)`;
                    break;
                case "ERROR":
                    tooltipContent = [null, ""].includes(this.detail) ? "NO detail provided..." : this.detail;
                    break;
                case "SUCCESS":
                    tooltipContent += `has completed SUCCESSfully`;
                    break;
                default:
                    tooltipContent += `has an UNKNOWN STATUS -> please check!`;
                    break;
            }
            return tooltipContent;
        },
    },
};
</script>
