/* Bootstrap 4 breakoints */
const LARGE_PHONE_BREAKPOINT = 576;
// eslint-disable-next-line no-unused-vars
const TABLET_BREAKPOINT = 768;
const LAPTOP_BREAKPOINT = 992;
// eslint-disable-next-line no-unused-vars
const DESKTOP_BREAKPOINT = 1200;
/* The following functions use a simplified version */
/**
 * Return true if the resolution of the current window is less than the LARGE_PHONE_BREAKPOINT.
 * Important: this function use browser API, use it only in "beforeMount" and "mount" functions in order to use the "vuepress build" as static site generator
 * https://vuepress.vuejs.org/guide/using-vue.html#browser-api-access-restrictions
 * @returns {boolean}
 */
export function isPhone() {
    return window.screen.width < LARGE_PHONE_BREAKPOINT;
}

export function isTablet() {
    return window.screen.width >= LARGE_PHONE_BREAKPOINT && window.screen.width < LAPTOP_BREAKPOINT;
}

export function isDesktop() {
    return window.screen.width >= LAPTOP_BREAKPOINT;
}

export function isMobile() {
    let userAgent = navigator.userAgent || window.opera;
    let mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobileRegex.test(userAgent);
}
