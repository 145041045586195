import _ from "lodash";

export function keyValueOptionsById(options, keyValueOptionID) {
    let errorText = "";
    const optionsFiltered = options.find((elem) => elem.active && elem.keyValueOptionID === keyValueOptionID);
    if (!optionsFiltered) {
        errorText = `ERROR: no record found for keyValueOptionID=${keyValueOptionID}`;
        alert(errorText);
        throw new Error(errorText);
    }
    return optionsFiltered;
}

export function keyValueOptionsByIdReturnValue(options, keyValueOptionID) {
    let errorText = "";
    const optionsFiltered = options.find((elem) => elem.active && elem.keyValueOptionID === keyValueOptionID);
    if (!optionsFiltered) {
        errorText = `ERROR: no record found for keyValueOptionID=${keyValueOptionID}`;
        alert(errorText);
        throw new Error(errorText);
    }

    return optionsFiltered.text === "Ja";
}

export function keyValueOptionsByType(
    options,
    type = "JaNein",
    value,
    category = null,
    subcategory = null,
    valueDefault = false
) {
    const predicate = { active: true, category, subcategory };
    if (value === null) {
        // assign default value to value
        value = valueDefault;
    }
    if (type === "JaNein") {
        predicate.text = value ? "Ja" : "Nein";
    }

    let errorText = "";
    const optionsFiltered = _.filter(options, predicate);

    switch (optionsFiltered.length) {
        case 1:
            // ok: exactly 1 record retrieved
            break;
        default:
            errorText = `ERROR: ${optionsFiltered.length} options found. Expected result is exactly 1`;
            alert(errorText);
            throw new Error(errorText);
    }
    return optionsFiltered[0];
}

export function keyValueOptionsIdMapper(options = [], category = null, subcategory = null, valueDefault = null) {
    const predicate = { active: true };
    if (category) {
        predicate.category = category;
    }
    if (subcategory) {
        predicate.subcategory = subcategory;
    }
    if (valueDefault) {
        predicate.text = valueDefault;
    }

    const optionsFiltered = _.filter(options, predicate);
    let result = null;
    let errorText = "";

    if (["Ja", "Nein"].includes(valueDefault)) {
        switch (optionsFiltered.length) {
            case 1:
                // ok: exactly 1 record retrieved
                break;
            default:
                errorText = `ERROR: ${optionsFiltered.length} options found. Expected result is exactly 1`;
                alert(errorText);
                throw new Error(errorText);
        }
        result = optionsFiltered[0];
    }
    if (result === null) {
        errorText = "ERROR: no result defined";
        alert(errorText);
        throw new Error(errorText);
    }

    return result;
}
