import { httpClient } from "@/services/api/client";

const endpoint = "/ise";

export default {
    // ********************
    // default REST methods
    // ********************

    // **************
    // custom methods
    // **************
    postActivity(payload) {
        return httpClient.post(`${endpoint}/activity`, payload);
    },
    postFlexibilitaet(deviceFlexiblitaetID) {
        return httpClient.post(`${endpoint}/flexibilitaets/${deviceFlexiblitaetID}`);
    },
    postCreateAuftragNoRSE(auftragID, executionDate, devices) {
        return httpClient.post(`${endpoint}/auftrags/no-rse/${auftragID}/${executionDate}`, devices);
    },
    getDeviceFeatures(factoryNo) {
        return httpClient.get(`${endpoint}/device-features/${factoryNo}`);
    },
};
