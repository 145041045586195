<!-- TODO: this page is not production ready -->
<template>
    <div id="calendar-page">
        <calendars-sidebar
            v-bind:calendars="calendarsLight"
            v-bind:weekends-visible="calendarOptions.weekends"
            v-on:calendar-change-visibility="changeCalendarVisibility"
            v-on:set-weekends-visible="setWeekendsVisible"
        >
        </calendars-sidebar>
        <div class="calendar">
            <full-calendar class="full-calendar" :options="calendarOptions" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import CalendarsSidebar from "@/views/users/CalendarsSidebar";
import auftragsAPI from "@/services/api/auftrags.api";
import constants from "@/constants/constants";

export default {
    name: "Calendars",
    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                events: [],
                allDaySlot: true, // Determines if the “all-day” slot is displayed at the top of the calendar.
                allDayText: "Ganztägig",
                nowIndicator: true,
                slotEventOverlap: false, // Determines if timed events in TimeGrid view should visually overlap.
                slotLabelFormat: {
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                },
                firstDay: 1, //starts on monday
                businessHours: {
                    // days of week. an array of zero-based day of week integers (0=Sunday)
                    daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
                    startTime: "08:00", // a start time
                    endTime: "17:00", // an end time
                },
                displayEventTime: false,
                eventTimeFormat: {
                    // like '14:30:00'
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                },
                weekends: true,
                weekNumbers: true,
                locale: "de",
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                },
            },
            calendarsLight: [],
            constants: constants,
        };
    },
    components: {
        CalendarsSidebar,
        FullCalendar, // make the <FullCalendar> tag available
    },
    async mounted() {
        this.auftrags = await auftragsAPI.get();
        this.calendarOptions.events = this.getCalendarEventsFromAuftrags(this.auftrags);
    },
    computed: {
        ...mapGetters(["currentUser"]),
        isEditorUser() {
            return (
                this.currentUser &&
                this.currentUser.userRoles.some((userRole) => userRole.roleID === constants.LEITER_ROLE_ID)
            );
        },
        calendarsData() {
            return this.calendars.data;
        },
    },
    methods: {
        changeCalendarVisibility(calendar) {
            for (const event of this.allEvents) {
                if (event.calendarId === calendar.calendarId) {
                    calendar.isVisible ? (event.display = "block") : (event.display = "none");
                }
            }
        },
        convertToEventsFromCalendars(calendars) {
            // TODO: to be continued
            const events = [];
            for (const calendar of calendars) {
                for (const event of calendar.events) {
                    events.push({
                        id: event.id,
                        calendarId: calendar.calendarId,
                        title: event.description,
                        start: event.start,
                        end: event.end,
                        type: calendar.type,
                        userId: calendar.type === "user" ? calendar.user.id : null,
                        groupId: calendar.type === "group" ? calendar.group.id : null,
                        display: "block",
                        backgroundColor: calendar.color,
                        borderColor: calendar.color,
                        textColor: "#000000",
                    });
                }
            }
            return events;
        },
        getCalendarEventsFromAuftrags(auftrags) {
            /*return [
                { // this object will be "parsed" into an Event Object
                    title: 'The Title', // a property!
                    start: '2021-01-26', // a property!
                    end: '2021-01-27' // a property! ** see important note below about 'end' **
                }
            ]**/
            const events = [];
            for (const auftrag of auftrags) {
                events.push({
                    id: auftrag.auftragID,
                    title: auftrag.hak.tp ?? auftrag.hakID,
                    start: auftrag.fromDate,
                    end: auftrag.toDate,
                });
            }
            return events;
        },
        setWeekendsVisible(value) {
            this.calendarOptions.weekends = value;
        },
    },
};
</script>

<style scoped>
#calendar-page {
    display: flex;
    overflow: hidden;
    height: 100%;
}

.calendar {
    flex: 1;
    padding: 2em;
}
</style>
