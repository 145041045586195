<!-- pie: lo step 2.4.1 si fa se c'é almeno un pericolo (amianto oppure no), lo step 2.5.1 si fa solo se almeno uno dei pericoli è amianto -->
<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !$attrs.devicePool">
        <template v-if="actAuftragDetail && $attrs.devicePool">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <b-alert v-if="!actAuftragDetail.isExecutable" class="my-1 p-2" show>
                Schritt nicht ausführbar (
                <i>isExecutable={{ actAuftragDetail.isExecutable }}</i>
                ).
            </b-alert>
            <div class="mt-2">
                <b-card body-class="p-2">
                    <p class="mb-2">Sie haben eine Asbest-Gefährdung gemeldet.</p>
                    <p class="mb-0">
                        Das Auswechseln von Drähten, Zähler, Empfänger, Schütz, Sicherungselementen,
                        Leitungsschutzschaltern und dergleichen erfordert eine angemessende Arbeitsausrüstung:
                    </p>
                    <ul class="mb-2">
                        <li>
                            Feinstaubmaske FFP3 und Einwegschutzanzug der Kategorie 3 Typ 5/6 tragen. Diese sind nach
                            Gebrauch fachgerecht zu entsorgen.
                        </li>
                        <li>
                            Industriestaubsauger mit H-Filter (Staubklasse H gemäss EN 60335-2-69, mit Zusatzanforderung
                            Asbest) verwenden (es dürfen keine handelsüblichen Haus-Staubsauger verwendet werden).
                        </li>
                    </ul>
                    <p class="mb-2">
                        Weitere Informationen:
                        <b-link
                            href="https://www.eitswiss.ch/fileadmin/user_upload/documents/Dienstleistungen/Arbeitssicherheit/Asbest/_de/VSEI_Broschuere_Asbest_erkennen.pdf"
                            target="_blank"
                        >
                            LINK
                        </b-link>
                        <sup><font-awesome-icon :icon="['fas', 'external-link-alt']" size="sm"/></sup>
                    </p>
                </b-card>
                <div class="font-weight-bold">Kann der Auftrag trotzdem ausgeführt werden?</div>
                <b-form-checkbox v-model="checkboxAccepted">
                    Hiermit bestätige ich, dass ich die für die Arbeiten notwendige Schutzausrüstung trage/verwende und
                    für mich oder andere Personen keine Gefahr besteht!
                </b-form-checkbox>
            </div>
            <b-button
                class="my-3"
                block
                :disabled="!checkboxAccepted || !actAuftragDetail.isExecutable"
                @click="onClickYes"
            >
                <strong>Ja</strong>, der Auftrag kann ohne Gefahr ausgeführt werden
            </b-button>
            <b-button block @click="onClickNo" :disabled="!actAuftragDetail.isExecutable">
                <strong>Nein</strong>, der Auftrag kann nicht ausgeführt werden
            </b-button>
            <b-button
                v-if="false"
                class="my-3 float-right"
                @click="onClickErledigt"
                :disabled="!actAuftragDetail.isExecutable"
            >
                Erledigt
            </b-button>
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import constants from "@/constants/constants";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicePoolsAPI from "@/services/api/devicepools.api";

export default {
    name: "Step_2_5_1",
    components: { ExecButtonNext, ExecButtonPrevious },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            checkboxAccepted: false,
            devicePool: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compAttrsDevicePool() {
            return this.$attrs.devicePool;
        },
    },
    async mounted() {
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        await auftragDetailsAPI
            .getByOrderIdAndStep(this.$route.params.orderId, step, this.$route.params.devicePoolId)
            .then((res) => {
                this.setActAuftragDetail(res);
            });
        this.devicePool = (await devicePoolsAPI.getSingle(this.$route.params.devicePoolId)).data;
        // If no Asbest skip this step -- DONE in previous step
        /*if (this.devicePool.asbestKeyValueOptionText === "Nein") {
            if (
                this.actAuftragDetail.isExecutable &&
                this.actAuftragDetail.status !== constants.auftragDetailStatus.DONE_CONDITION_3
            ) {
                this.onClickYes();
            } else {
                this.onClickNext();
            }
        }*/
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        onClickNo() {
            // 205 - No, auftrag must stop
            this.patchAuftragDetailStatus(constants.auftragDetailStatus.DONE_CONDITION_4).then(() => {
                this.navigateToStepNext = this.actAuftragDetail.stepNextFalse;
            });
        },
        onClickNext() {
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        onClickPrevious(step) {
            // ToDo: do whatever is necessary before moving to previous step
            // on SUCCESS, navigate to previous step
            this.navigateToStepPrevious = step;
        },
        onClickYes() {
            // 204 - Yes, auftrag can go on
            this.patchAuftragDetailStatus(constants.auftragDetailStatus.DONE_CONDITION_3).then(() => {
                this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
            });
        },
        patchAuftragDetailStatus(valStatus) {
            const payload = [
                {
                    path: "/status",
                    op: "REPLACE",
                    value: valStatus,
                },
            ];
            return auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payload);
        },
    },
};
</script>
