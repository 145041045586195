<template>
    <div class="my-2 bg-light p-2">
        <div v-if="compIsEmailValid">
            <div>Folgende Empfänger erhalten eine Mitteilung über den ausgebauten Zähler:</div>
            <div v-for="toAddress in emailObject.toAddresses" :key="toAddress.address" class="ml-2">
                <span class="text-secondary font-italic">{{ toAddress.address }}</span>
            </div>
            <div class="my-2 text-secondary">
                Betreff: <span class="font-italic">{{ emailObject.subject }}</span>
            </div>
            <div v-if="actAuftragDetail.status === 202">Ausgebaut: {{ compExecutionTime }}</div>
            <div v-else-if="actAuftragDetail.status === 500">Ausbau nicht möglich: {{ compExecutionTime }}</div>
        </div>
        <div v-else>
            Es wird keine E-Mail versendet
        </div>
    </div>
</template>

<script>
import moment from "moment/moment";

export default {
    name: "EmailInfoViewer",
    props: {
        emailObject: {
            type: Object,
            required: true,
        },
        actAuftragDetail: {
            type: Object,
            required: true,
        },
    },
    computed: {
        compIsEmailValid() {
            return this.emailObject && this.emailObject.toAddresses && this.emailObject.toAddresses.length > 0;
        },
        compExecutionTime() {
            return moment(this.actAuftragDetail.executionTime).format("YYYY-MM-DD HH:mm:ss");
        },
    },
};
</script>
