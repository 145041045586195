import Dexie from "dexie";
import constants from "@/constants/constants";
import cacheStatusService from "@/services/cacheStatusService";
import assetsService from "@/services/api/assets.api";
import { eventBus } from "@/utils/eventBus";

export const db = new Dexie("photoCache");
db.version(1).stores({
    photos: "++id",
});
const table = db.photos;
const { UPLOADING, PAUSE, DONE, DEFAULT } = constants.upload.icon;
export default {
    data() {
        return {
            isUploadInProgress: false,
            globalCancelController: null,
        };
    },
    async getAllPhotos() {
        try {
            const photos = await table.orderBy("id").toArray();
            return photos;
        } catch {
            throw new Error("Fehler beim Abrufen von Fotos");
        }
    },

    async getAllPhotosCount() {
        try {
            const count = await table.count();
            return count;
        } catch {
            throw new Error("Fehler beim Abrufen von Fotos");
        }
    },

    async getUploadablePhotos() {
        const photosInCache = await this.getAllPhotos();
        if (photosInCache.length) {
            await cacheStatusService.updateUploadStatus(DEFAULT);
        }
        const filteredPhotos = photosInCache.filter((photo) => !photo.isUploadSkipped);
        return filteredPhotos;
    },

    async getUploadablePhotosCount() {
        const uploadablePhotos = await this.getUploadablePhotos();
        return uploadablePhotos.length;
    },

    async getPhotosByFilter(payload) {
        try {
            const photos = await this.getAllPhotos();
            const filteredEntries = photos.filter((photo) => {
                for (const key in payload) {
                    if (photo.assetsPayload[key] !== payload[key]) {
                        return false;
                    }
                }
                return true;
            });
            return filteredEntries;
        } catch {
            throw new Error("Fehler beim Abrufen von Fotos");
        }
    },

    async getPhotoByID(photoID) {
        try {
            const photo = await table.get(photoID);
            return photo;
        } catch (error) {
            throw new Error("Fehler beim Abrufen von Fotos");
        }
    },

    async getOldestPhoto() {
        try {
            const uploadablePhotos = await this.getUploadablePhotos();
            return uploadablePhotos[0];
        } catch {
            throw new Error("Fehler beim Laden des ältesten Fotos");
        }
    },

    async savePhoto(data) {
        try {
            const id = await table.add(data);
            const createdObject = await this.getPhotoByID(id);
            return createdObject;
        } catch {
            throw new Error("Fehler beim Speichern des Fotos");
        }
    },

    async deletePhoto(id) {
        try {
            await table.delete(id);
        } catch (error) {
            throw new Error("Fehler beim Löschen des Fotos");
        }
    },

    async convertBlobToImageURL(blob) {
        try {
            const imageUrl = await this.blobToImage(blob);
            return imageUrl;
        } catch {
            throw new Error("Fehler beim Konvertieren des Blob in eine Bild-URL");
        }
    },

    async skipPhotoUpload(id) {
        try {
            await table.update(id, { isUploadSkipped: true });
            eventBus.$emit("photoSkipped", id);
        } catch {
            throw new Error("Fehler beim Überspringen eines Fotos");
        }
    },

    async uploadOldestPhoto(retries = 3) {
        try {
            const uploadablePhotos = await this.getUploadablePhotos();
            if (uploadablePhotos.length) {
                var oldestPhoto = uploadablePhotos[0];
                const uploadedImage = await this.updateFilePath(oldestPhoto);
                eventBus.$emit("photoUpdate", uploadedImage);
            }
        } catch {
            if (retries > 0) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                return this.uploadOldestPhoto(retries - 1);
            } else {
                await this.skipPhotoUpload(oldestPhoto.id);
            }
        }
    },

    async uploadSinglePhotoAndHandleError(asset) {
        try {
            if (!asset.isUploaded) {
                const uploadedImage = await this.uploadSpecificPhoto(asset.id);
                return { id: asset.id, img: uploadedImage };
            }
        } catch (e) {
            const errorMessage = this.getErrorMessage(e.message, asset.description);
            console.error(errorMessage);
            throw errorMessage;
        }
    },

    getErrorMessage(message, description) {
        const { PROGRESS_ERROR, CANCEL_ERROR } = constants.upload.exception;
        return message === PROGRESS_ERROR
            ? PROGRESS_ERROR
            : message === CANCEL_ERROR
            ? "Upload wurde abgebrochen"
            : `${description} konnte nicht hochgeladen werden`;
    },

    updatePhoto(img, newImg) {
        const { id, path } = newImg;
        return { ...img, id, path, isUploaded: true };
    },

    async uploadSpecificPhoto(id) {
        try {
            const photo = await this.getPhotoByID(id);
            const uploadedImage = await this.updateFilePath(photo);
            return uploadedImage;
        } catch (error) {
            throw new Error(error);
        }
    },

    async createNewFile(file) {
        if (!file) return;
        const { path, filename, name, type } = file;

        const data = path ? await fetch(path).then((r) => r.blob()) : "";
        const newFileName = filename ?? name;
        return new File([data], newFileName, {
            type: type,
        });
    },

    async createFormData(file) {
        const newFile = await this.createNewFile(file);
        const formData = new FormData();
        formData.append("folder", file.folder);
        formData.append("file", newFile);

        return formData;
    },

    cancelUpload() {
        this.globalCancelController.abort();
    },

    async updateFilePath(file) {
        this.globalCancelController = new AbortController();

        const { IMAGE } = constants.image.assetType;
        const { IN_PROGRESS, COMPLETED } = constants.upload.status;
        const { PROGRESS_ERROR, CANCEL_ERROR } = constants.upload.exception;

        if (!file) return;

        if (this.isUploadInProgress) throw PROGRESS_ERROR;
        await cacheStatusService.updateUploadStatus(UPLOADING);

        const formData = await this.createFormData(file);
        return new Promise((resolve, reject) => {
            this.isUploadInProgress = IN_PROGRESS;
            assetsService
                .uploadImage(file.id, formData, this.globalCancelController.signal)
                .then((response) => {
                    const asset = response.data;
                    if (asset.type === IMAGE) {
                        // This line deletes the IndexedDB's entry by file.id
                        this.deletePhoto(file.id);
                        resolve(asset);
                    }
                })
                .catch(async (error) => {
                    await cacheStatusService.updateUploadStatus(PAUSE);
                    reject(this.globalCancelController.signal.aborted ? CANCEL_ERROR : error);
                })
                .finally(async () => {
                    await cacheStatusService.updateUploadStatus(DONE);
                    this.isUploadInProgress = COMPLETED;
                });
        });
    },
    async uploadFile(assetsPayload) {
        if (!assetsPayload.isUploaded) {
            await cacheStatusService.updateUploadStatus(DEFAULT);
        }
        const formData = new FormData();
        Object.keys(assetsPayload).forEach((key) => {
            if (assetsPayload[key]) {
                formData.append(`assetDTO[${key}]`, assetsPayload[key]);
            }
        });
        return assetsService.post(assetsPayload);
    },

    blobToImage(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    },
};
